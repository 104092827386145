import React, { useState, useCallback, useEffect, useMemo, memo } from 'react';
import PropTypes from 'prop-types';

import { Checkbox } from 'components/v3';

import { useStyles } from './CustomTable.styles';

const CustomTable = ({
  rows: defaultRows = [],
  hasCheckbox = false,
  displayColumns = [],
  emptyTableMessage = '',
  loading = false,
  additionalRow,
  className,
  columns,
  onViewClick,
  onEditClick,
  onDeleteClick,
  rowClickable,
  hideTitle
}) => {
  const [rows, setRows] = useState([]);
  const [sortingOrder, setSortingOrder] = useState('');
  const { classes, cx } = useStyles();

  useEffect(() => {
    setRows(() =>
      defaultRows.map(row => ({
        ...row,
        ...(hasCheckbox ? { checked: false } : {})
      }))
    );
  }, [defaultRows, hasCheckbox]);

  const checkRow = useCallback(
    (row, index) => {
      if (loading) return null;
      const newRow = { ...row, checked: !row.checked };
      setRows(rows => {
        rows.splice(index, 1, newRow);
        return [...rows];
      });
    },
    [setRows, loading]
  );

  const sortColumn = useCallback(
    key => {
      if (loading) return null;
      const formatCell = cell => (typeof cell === 'string' ? cell.toLowerCase() : cell);

      const sort = (rowA, rowB) => {
        if (rowA > rowB) return 1;
        if (rowA < rowB) return -1;
        return 0;
      };

      setRows(rows => {
        const sorted = rows.sort((a, b) => {
          const rowA = formatCell(a[key]);
          const rowB = formatCell(b[key]);

          return sortingOrder === 'asc' ? sort(rowA, rowB) : sort(rowB, rowA);
        });

        setSortingOrder(() => (sortingOrder === 'asc' ? 'desc' : 'asc'));

        return [...sorted];
      });
    },
    [setRows, sortingOrder, setSortingOrder, loading]
  );

  const toggleAll = useCallback(() => {
    setRows(rows => {
      const hasUnchecked = rows.some(row => row.checked === false);
      return rows.map(row => ({ ...row, checked: hasUnchecked }));
    });
  }, [setRows]);

  const defineColspan = useMemo(() => {
    let count = columns.length;
    if (onEditClick) count++;
    if (onViewClick) count++;
    if (onDeleteClick) count++;
    if (hasCheckbox) count++;
    return count.toString();
  }, [columns, hasCheckbox, onEditClick, onViewClick, onDeleteClick]);

  const columnsToDisplay = useMemo(() => {
    if (displayColumns.length) {
      return columns.filter(column => displayColumns.some(displayColumn => displayColumn === column.key));
    }
    return columns;
  }, [displayColumns, columns]);

  const clickableRowFunction = useCallback(
    (row, rowIndex) => {
      if (loading) return null;
      if (hasCheckbox) return { onClick: () => checkRow(row, rowIndex) };
      if (rowClickable) return { onClick: () => rowClickable(row) };
      return {};
    },
    [checkRow, hasCheckbox, rowClickable, loading]
  );

  return (
    <div className={cx(classes.tableContainer, className)}>
      <table>
        {!hideTitle && (
          <thead>
            <tr>
              {hasCheckbox && (
                <th className={classes.checkboxCell} onClick={toggleAll}>
                  <Checkbox checked={rows.every(row => row.checked)} disableRipple />
                </th>
              )}
              {columnsToDisplay.map((column, index) => (
                <th
                  onClick={() => sortColumn(column.key)}
                  className={cx(column.textAlign ? classes[column.textAlign] : classes.right)}
                  key={`column_${index}`}
                >
                  {column.value}
                </th>
              ))}
              {onViewClick && <th>Visualizar</th>}
              {onEditClick && <th>Editar</th>}
              {onDeleteClick && <th>Excluir</th>}
            </tr>
          </thead>
        )}
        <tbody>
          {rows.length ? (
            rows.map((row, rowIndex) => {
              return (
                <tr key={`table_row_${rowIndex}`} className={rowClickable || hasCheckbox ? classes.rowClickable : ''}>
                  {hasCheckbox && (
                    <td className={classes.checkboxCell} {...clickableRowFunction(row, rowIndex)}>
                      <Checkbox checked={row.checked} onChange={() => checkRow(row, rowIndex)} disableRipple />
                    </td>
                  )}
                  {columnsToDisplay.map((cell, cellIndex) => {
                    return (
                      <td
                        className={cx(
                          cell.textAlign ? classes[cell.textAlign] : classes.right,
                          !cell.custom ? classes.hasPadding : ''
                        )}
                        key={`cell_${rowIndex}_${cellIndex}`}
                        {...clickableRowFunction(row, rowIndex)}
                      >
                        {cell.custom ? cell.custom(row) : row[cell.key]}
                      </td>
                    );
                  })}
                  {onViewClick && (
                    <td
                      className={cx(classes.iconCell, classes.view)}
                      onClick={!loading ? () => onViewClick(row) : null}
                    >
                      <i className="far fa-eye" />
                    </td>
                  )}
                  {onEditClick && (
                    <td
                      className={cx(classes.iconCell, classes.edit)}
                      onClick={!loading ? () => onEditClick(row) : null}
                    >
                      <i className="far fa-edit" />
                    </td>
                  )}
                  {onDeleteClick && (
                    <td
                      className={cx(classes.iconCell, classes.delete)}
                      onClick={!loading ? () => onDeleteClick(row) : null}
                    >
                      <i className="fas fa-trash-alt" />
                    </td>
                  )}
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={defineColspan}>
                <div className={classes.emptyTableMessage}>{emptyTableMessage}</div>
              </td>
            </tr>
          )}
          {additionalRow && (
            <tr>
              <td colSpan={defineColspan}>{additionalRow}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

CustomTable.propTypes = {
  className: PropTypes.string,
  rows: PropTypes.array,
  columns: PropTypes.array,
  onViewClick: PropTypes.func,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  withCheckbox: PropTypes.bool,
  hasCheckbox: PropTypes.bool,
  rowClickable: PropTypes.func,
  displayColumns: PropTypes.array,
  additionalRow: PropTypes.any,
  emptyTableMessage: PropTypes.string,
  hideTitle: PropTypes.bool,
  loading: PropTypes.bool,
  layout: PropTypes.string
};

export default memo(CustomTable);
