import { tss } from 'tss-react';

export const useConfirmUnsubscribeStyles = tss.create({
  browseIconInline: {
    width: 20,
    height: 14,
    paddingBottom: '2px',
    '& path': {
      fill: 'black'
    }
  }
});
