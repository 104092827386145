import { tss } from 'tss-react';

import { layouts } from 'config/constants';

export const useStyles = tss.create(({ isMobile, size, disabled, layout }) => {
  const labelFont = () => {
    if (layout === layouts.signup) {
      return {
        fontSize: isMobile ? '12px' : '14px',
        color: disabled ? 'var(--disabled-color)' : 'inherit'
      };
    }
    if (layout === layouts.veezoo) {
      return {
        fontSize: isMobile || size === 'small' ? '12px' : '14px',
        fontFamily: 'var(--font-family)',
        color: disabled ? 'var(--disabled-color)' : 'inherit'
      };
    }
    return {};
  };

  return {
    container: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer'
    },
    labelFont: { ...labelFont() }
  };
});

export const useCheckboxStyles = ({ size, isMobile }) => ({
  signup: {
    '&.MuiCheckbox-root': {
      padding: '0',
      margin: '0 9px 0 0',
      color: 'rgba(0, 0, 0, 0.54)'
    },
    '&.Mui-checked': {
      color: 'var(--light-blue)'
    },
    '& .MuiSvgIcon-root': {
      fontSize: isMobile ? '17px' : '20px'
    }
  },
  veezoo: {
    '&.MuiCheckbox-root': {
      padding: '0',
      margin: size === 'small' ? '0 3px 0 0' : '0 9px 0 0',
      color: 'rgba(0, 0, 0, 0.54)'
    },
    '&.Mui-checked': {
      color: 'var(--primary-color)'
    },
    '& .MuiSvgIcon-root': {
      fontSize: isMobile || size === 'small' ? '17px' : '20px'
    }
  },
  default: {
    '&.MuiCheckbox-root': {
      padding: '9px !important'
    }
  }
});
