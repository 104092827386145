import { del, get, post, put } from 'services/http';

const getSynchronizationSchedules = kgId =>
  get(`/studio/api/knowledge-graphs/${encodeURIComponent(kgId)}/synchronization/schedules`);

const createSynchronizationSchedule = (kgId, schedule) =>
  post(`/studio/api/knowledge-graphs/${encodeURIComponent(kgId)}/synchronization/schedules`, schedule);

const deleteSynchronizationSchedule = (kgId, scheduleId) =>
  del(
    `/studio/api/knowledge-graphs/${encodeURIComponent(kgId)}/synchronization/schedules/${encodeURIComponent(
      scheduleId
    )}`
  );

const replaceSynchronizationSchedule = (kgId, scheduleId, schedule) =>
  put(
    `/studio/api/knowledge-graphs/${encodeURIComponent(kgId)}/synchronization/schedules/${encodeURIComponent(
      scheduleId
    )}`,
    schedule
  );

const createSynchronization = (kgId, synchronizables) =>
  post(`/studio/api/knowledge-graphs/${encodeURIComponent(kgId)}/synchronization/synchronizations`, {
    synchronizables
  });

const getSynchronizations = kgId =>
  get(`/studio/api/knowledge-graphs/${encodeURIComponent(kgId)}/synchronization/synchronizations`);

export default {
  getSynchronizationSchedules,
  createSynchronizationSchedule,
  replaceSynchronizationSchedule,
  deleteSynchronizationSchedule,
  createSynchronization,
  getSynchronizations
};
