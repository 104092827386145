import BoardDropdown from 'components/board/BoardDropdown';
import clsx from 'clsx';

import { boardFileTypes } from 'store/utils/boardFileTypes';

import PlusIcon from 'svg/v2/plus.svg';

import { useStyles } from './styles';
import styles from 'components/sidebar/sidebar.scss';
import { connect } from 'react-redux';

const BoardSidebarListItem = ({
  board,
  t,
  handleFetchBoardMessage,
  openCreateModal,
  handleExpandedKeys,
  isDemoKg,
  dispatch
}) => {
  const { classes: iconStyles } = useStyles();

  const boardType = board.type.toLowerCase();
  const isFolder = boardType === boardFileTypes.folder;

  return (
    <li className={styles.item} key={board.id}>
      <span data-test="dashboardInSidebar" className={styles.itemAction}>
        {!isFolder && (
          <>
            <span className={board.isEditable ? 'icon-board' : 'icon-board-lock'}>&nbsp;</span>
          </>
        )}

        <div
          className={styles.nameWrap}
          onClick={!isFolder ? () => handleFetchBoardMessage(board.id) : handleExpandedKeys}
        >
          <span className={clsx(styles.name, !isFolder ? styles.clickable : '')}>{board.name}</span>
        </div>
        {isFolder && (
          <div className={iconStyles.iconContainer} onClick={() => openCreateModal(board.id)}>
            <PlusIcon className={`${iconStyles.listPlusIcon} ${styles.listPlusIcon}`} data-test="addChildBoardButton" />
          </div>
        )}
        <BoardDropdown
          id={board.id}
          name={board.name}
          definingResource={board.definingResource}
          type={boardType}
          useHorizontalToggle={false}
          dispatch={dispatch}
          isFolder={isFolder}
          t={t}
          isSharedWithOthers={board.isSharedWithOthers}
          isBoardOwned={board.isOwned}
          canShare={!isFolder && board.isOwned && !isDemoKg}
          canDelete={isFolder || board.isOwned}
          canRename={isFolder || board.isEditable}
          canRemoveFromSidebar={!isFolder && !board.isOwned}
          canCopy={!isFolder}
          canMakeEntrypoint={!isFolder}
        />
      </span>
    </li>
  );
};

const mapStateToProps = state => ({
  isDemoKg: state.knowledgeGraphMeta.meta.isDemo
});

export default connect(mapStateToProps)(BoardSidebarListItem);
