import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import TextField from '@mui/material/TextField';
import WeekSelector from './WeekSelector';

import Popover from '@mui/material/Popover';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import { textFieldSx } from './WeekPicker.styles';

import { getSplittedValue } from './WeekPicker.utils';

import WeekMaskInput from './WeekMaskInput';

import {
  formatDateToWeekString,
  isValidWeek,
  isValidYear,
  startOfWeekInVeezoo,
  getWeekOfTheYear,
  getDateFromYearAndWeek
} from 'utils/datetimeUtils';

const invalidDate = new Date('Invalid Date');

const WeekPicker = ({ value, onChange, error = false }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const [textFieldValue, setTextFieldValue] = useState('');

  // Initial load (if any), should happen only once.
  useEffect(() => {
    if (value) {
      const { year, week } = getWeekOfTheYear(value);
      const weekString = formatDateToWeekString(year, week);

      setTextFieldValue(weekString);
    }
  }, []);

  const openPopover = event => setAnchorEl(event.currentTarget);
  const closePopover = () => setAnchorEl(null);

  const handleTextFieldChange = newValue => {
    setTextFieldValue(newValue);

    const { year, week } = getSplittedValue(newValue);

    if (!isValidYear(year) || !isValidWeek(week)) {
      return onChange(invalidDate);
    }

    const newDate = startOfWeekInVeezoo(getDateFromYearAndWeek(year, parseInt(week)));

    if (newDate !== value) {
      onChange(newDate);
    }
  };

  const handleDateChange = newDate => {
    const firstDayDate = startOfWeekInVeezoo(newDate);
    onChange(firstDayDate);

    const { year, week } = getWeekOfTheYear(firstDayDate);

    const weekString = formatDateToWeekString(year, week);
    if (weekString !== textFieldValue) {
      setTextFieldValue(weekString);
    }

    closePopover();
  };

  // If input is empty, we don't "paint" the input in red borders, as we
  // expect it to be empty initially. But we do return an "Invalid Date"
  // in the prop "value".
  const hasError = textFieldValue.length > 0 && error;

  return (
    <>
      <TextField
        sx={textFieldSx}
        error={hasError}
        value={textFieldValue}
        onChange={handleTextFieldChange}
        name="weekTextField"
        id="weekTextField"
        placeholder="2020-W20"
        {...(hasError ? { helperText: 'Invalid week format' } : {})}
        InputProps={{
          inputComponent: WeekMaskInput,
          endAdornment: <CalendarMonthIcon onClick={openPopover} />
        }}
      />
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={closePopover}
        disableEnforceFocus={true}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left'
        }}
      >
        <WeekSelector value={value} onChange={handleDateChange} onClose={closePopover} />
      </Popover>
    </>
  );
};

export default WeekPicker;

WeekPicker.propTypes = {
  value: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
  error: PropTypes.bool
};
