import React, { useState, useEffect, useMemo, createRef } from 'react';
import PropTypes from 'prop-types';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import { Chip } from 'components/v3';

import useStyles, { defaultContainerHeight } from './TagsContainer.styles';

const TagsContainer = ({ items, onDeleteClick }) => {
  const styles = useStyles();

  const chipRefs = {};

  const [expanded, setExpanded] = useState(false);
  const [hiddenChipsCount, setHiddenChipsCount] = useState(0);

  const formattedCount = useMemo(() => {
    if (expanded) return <ExpandLessIcon classes={{ root: styles.root }} />;
    if (hiddenChipsCount > 99) return '99+';
    return `+${hiddenChipsCount}`;
  }, [hiddenChipsCount, expanded]);

  const toggleExpand = () => setExpanded(prev => !prev);
  const closeExpand = () => expanded && setExpanded(false);
  const openExpand = () => hiddenChipsCount > 0 && !expanded && setExpanded(true);

  useEffect(() => {
    let count = 0;
    Object.values(chipRefs).forEach(chipRef => {
      const chipOffset = chipRef.current.offsetTop;
      if (chipOffset > defaultContainerHeight) {
        count++;
      }
    });

    setHiddenChipsCount(count);
  }, [chipRefs]);

  return (
    <div className={styles.outerContainer}>
      <ClickAwayListener onClickAway={closeExpand}>
        <div onClick={openExpand} className={`${styles.mainContainer} ${expanded ? styles.mainContainerExpanded : ''}`}>
          <div className={`${styles.chipList} ${expanded ? styles.chipListExpanded : ''}`}>
            {hiddenChipsCount > 0 && (
              <div onClick={toggleExpand} className={styles.limitTag}>
                {formattedCount}
              </div>
            )}
            {items.map((item, chipIndex) => {
              const newRef = `chip_${chipIndex}`;
              chipRefs[newRef] = createRef();
              return (
                <div key={newRef} className={styles.tagContainer}>
                  <Chip greyed ref={chipRefs[newRef]} label={item.label} onClose={() => onDeleteClick(item)} />
                </div>
              );
            })}
            <div className={styles.clear} />
          </div>
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default TagsContainer;

TagsContainer.propTypes = {
  items: PropTypes.array.isRequired,
  onDeleteClick: PropTypes.func.isRequired
};
