import React, { useState, useMemo } from 'react';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Button from 'components/v2/Button';

import { Select } from 'components/v3';

import { booleanName, booleanOptions } from 'utils/booleanFilterUtils';

import { layouts } from 'config/constants';
import useStyles from 'styles/JSS/Popover';

const BooleanFilter = ({ initialValue = 'UNSELECTED', valueNames, onConfirm, onClose, title, t }) => {
  const styles = useStyles();

  const [selected, setSelected] = useState(initialValue);

  const options = useMemo(
    () =>
      Object.keys(booleanOptions).map(option => ({
        label: booleanName(option, valueNames, t),
        value: option
      })),
    [t, valueNames]
  );

  const handleChange = event => {
    const value = event.target.value;
    setSelected(value);
  };

  const handleConfirm = () => {
    onConfirm(selected);
    onClose();
  };

  return (
    <div className={styles.container}>
      {title && <div className={clsx(styles.section, styles.title)}>{title}</div>}
      <div className={styles.section}>
        <div className={styles.verticalPadding8}>
          <Select
            layout={layouts.veezoo}
            options={options}
            value={selected}
            onChange={handleChange}
            data-test="selector"
          />
        </div>
      </div>
      <div className={clsx(styles.section, styles.flexContainer)}>
        <div className={clsx(styles.flexGrow1)}>
          <Button fullWidth noBorderRadius label={t('cancel')} onClick={onClose} />
        </div>
        <div className={clsx(styles.flexGrow1)}>
          <Button
            dark
            fullWidth
            noBorderRadius
            label={t('apply')}
            onClick={handleConfirm}
            data-test="modification-apply"
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({ user: state.user });

export default withTranslation('veezoo')(connect(mapStateToProps)(BooleanFilter));

BooleanFilter.defaultProps = {
  initialValue: 'UNSELECTED'
};
