import uuid1 from 'uuid/v1';
import { addLocalMessage, askQuestion } from './chat-messages';
import { fetchPartialAnswer } from './partialAnswers';

import services from 'services';
import { handleError } from 'services/http';
import { trackEvent } from 'utils/eventTracking';

import { messageTypes } from 'store/modules/chat-messages';

export const DISCOVERY_EXAMPLE_REQUEST = 'DISCOVERY_EXAMPLE_REQUEST';
export const DISCOVERY_EXAMPLE_SUCCESS = 'DISCOVERY_EXAMPLE_SUCCESS';
export const DISCOVERY_EXAMPLE_FAILURE = 'DISCOVERY_EXAMPLE_FAILURE';

export const DISCOVERY_TO_CHAT = 'DISCOVERY_TO_CHAT';

export const EXAMPLES_REQUEST = 'EXAMPLES_REQUEST';
export const EXAMPLES_SUCCESS = 'EXAMPLES_SUCCESS';
export const EXAMPLES_FAILURE = 'EXAMPLES_FAILURE';

export const GENERATE_EXAMPLES_REQUEST = 'GENERATE_EXAMPLES_REQUEST';
export const GENERATE_EXAMPLES_SUCCESS = 'GENERATE_EXAMPLES_SUCCESS';
export const GENERATE_EXAMPLES_FAILURE = 'GENERATE_EXAMPLES_FAILURE';

export const generateDiscoveryExamples = kgId => {
  return async dispatch => {
    dispatch({ type: GENERATE_EXAMPLES_REQUEST });

    const result = await services.generateDiscoveryExamples(kgId);
    handleError(result, dispatch);

    if (!result.success) {
      trackEvent('Discovery Generation Failure', { knowledgeGraphId: kgId });
      return dispatch({ type: GENERATE_EXAMPLES_FAILURE });
    }

    trackEvent('Discovery Generation Success', { knowledgeGraphId: kgId });
    dispatch({
      type: GENERATE_EXAMPLES_SUCCESS
    });

    dispatch(fetchExamples());
  };
};

export const fetchExamples = () => {
  return async dispatch => {
    dispatch({ type: EXAMPLES_REQUEST });

    const result = await services.getDiscoveryExamples();
    handleError(result, dispatch);

    if (!result.success) {
      return dispatch({ type: EXAMPLES_FAILURE });
    }

    dispatch({
      type: EXAMPLES_SUCCESS,
      data: result.data
    });
  };
};

export const fetchDiscoveryExample = (exampleId, question, isFetchOfRephrased) => {
  return dispatch => {
    const otherParams = {
      isSuggested: true,
      disableFollowUp: true
    };

    dispatch({ type: DISCOVERY_EXAMPLE_REQUEST, exampleId });

    askQuestion(question, otherParams, dispatch)
      .then(json => {
        const id = uuid1();

        if (json.type === messageTypes.VEEZOO_REPHRASE_MESSAGE) {
          // We received a rephrased question without an actual answer
          if (!isFetchOfRephrased) {
            dispatch({
              type: DISCOVERY_EXAMPLE_SUCCESS,
              exampleId: `${exampleId}-rephrase`,
              message: { ...json, id }
            });

            // As we cannot use the regular rephrasing endpoint (this one is specific to a session), we recursively
            //  ask the rephrased question. By setting isRephrasing, we avoid tracking down a long (potentially
            //  infinite) chain of rephrasings.
            dispatch(fetchDiscoveryExample(exampleId, json.rephrasedQuestion, (isFetchOfRephrased = true)));
          } else {
            dispatch({ type: DISCOVERY_EXAMPLE_FAILURE, exampleId });
            console.log('ERROR', 'Rephrasing led to another rephrasing');
          }
        } else {
          // We received an actual answer, which may however be based on a rephrasing that we want to show in the UI as
          //  well (unless this is already the fetch of a rephrasing)
          if (json.rephrasedQuestion && !isFetchOfRephrased) {
            dispatch({
              type: DISCOVERY_EXAMPLE_SUCCESS,
              exampleId: `${exampleId}-rephrase`,
              message: { ...json, id }
            });
          }

          dispatch({
            type: DISCOVERY_EXAMPLE_SUCCESS,
            exampleId,
            message: { ...json, id }
          });

          dispatch(fetchPartialAnswer(json.answer.interpretationId, json.answerId, id));
        }
      })
      .catch(error => {
        dispatch({ type: DISCOVERY_EXAMPLE_FAILURE, exampleId });
        console.log('ERROR', error);
      });
  };
};

export const discoveryToChat = message => {
  return dispatch => {
    // also display the discovery question in chat (as if it was asked by the person)
    dispatch(addLocalMessage(message.username, message.question));

    dispatch({
      type: DISCOVERY_TO_CHAT,
      message: { ...message, id: uuid1() } // overwrite the id of the message so we have a new unique one with no clashes
    });
  };
};

const initialDiscoveryState = {
  topics: [],
  fetchedExamples: [],
  filePath: undefined,
  isGeneratingExamples: false
};

export function discovery(state = initialDiscoveryState, action) {
  switch (action.type) {
    case EXAMPLES_REQUEST:
      return initialDiscoveryState;

    case GENERATE_EXAMPLES_REQUEST:
      return {
        ...state,
        isGeneratingExamples: true
      };

    case EXAMPLES_SUCCESS:
      return {
        ...state,
        filePath: action.data.filePath,
        topics: action.data.topics
      };

    case GENERATE_EXAMPLES_SUCCESS:
    case GENERATE_EXAMPLES_FAILURE:
      return {
        ...state,
        isGeneratingExamples: false
      };

    case DISCOVERY_EXAMPLE_SUCCESS:
      return {
        ...state,
        fetchedExamples: [...state.fetchedExamples, { ...action.message, exampleId: action.exampleId }]
      };

    default:
      return state;
  }
}
