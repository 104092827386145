import { useCallback, useEffect, useRef } from 'react';

const binarySearch = (low, high, predicate) => {
  const mid = Math.floor((low + high) / 2);
  if (mid === low) return mid;

  if (predicate(mid)) return binarySearch(mid, high, predicate);
  else return binarySearch(low, mid, predicate);
};

const TextFitter = ({ children, min = 0, max = 300, ...props }) => {
  const ref = useRef(null);

  const isOverflown = useCallback(() => {
    const el = ref.current;
    return el.scrollHeight > el.clientHeight || el.scrollWidth > el.clientWidth;
  }, []);

  const setFontSize = useCallback(() => {
    const el = ref.current;

    const originVisibility = el.style.visibility;

    el.style.visibility = 'hidden';
    const fontSize = binarySearch(min, max + 1, mid => {
      el.style.fontSize = `${mid}px`;
      return !isOverflown();
    });
    el.style.fontSize = `${fontSize}px`;
    el.style.visibility = originVisibility;
  }, [isOverflown, min, max]);

  useEffect(() => {
    const el = ref.current;

    setFontSize();
    const observer = new ResizeObserver(setFontSize);
    observer.observe(el);

    return () => {
      observer.disconnect();
    };
  }, [children, setFontSize]);

  return (
    <div ref={ref} style={{ whiteSpace: 'normal' }} {...props}>
      {children}
    </div>
  );
};

export default TextFitter;
