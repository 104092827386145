import { get, post } from 'services/http';

const getDatabaseSystems = () => get('/studio/api/database/systems');

const getDatabaseConnection = id => get(`/studio/api/knowledge-graphs/${encodeURIComponent(id)}/database`);

const getDatabaseFolders = id => get(`/studio/api/knowledge-graphs/${encodeURIComponent(id)}/database/folders`);

const getDatabaseTables = (id, databaseName, schemaName) => {
  const schemaPath = schemaName ? `/${encodeURIComponent(schemaName)}` : '';
  return get(
    `/studio/api/knowledge-graphs/${encodeURIComponent(id)}/database/${encodeURIComponent(
      databaseName
    )}${schemaPath}/tables`
  );
};

const getImportConfiguration = () => get('/studio/api/import/configuration');

const getPublicKey = () => get('/studio/api/database/ssh-tunnel-pk');

const testDatabaseConnectionForNewKg = body => post('/studio/api/database/test', body);

const testDatabaseConnectionForExistingKg = (id, body) =>
  post(`/studio/api/knowledge-graphs/${encodeURIComponent(id)}/database/test`, body);

const getDatabaseColumns = (id, databaseName, schemaName, name) => {
  const schemaPath = schemaName ? `/${encodeURIComponent(schemaName)}` : '';
  return get(
    `/studio/api/knowledge-graphs/${encodeURIComponent(id)}/database/${encodeURIComponent(
      databaseName
    )}${schemaPath}/tables/${encodeURIComponent(name)}/columns`
  );
};

const querySql = (id, sql) => {
  return post(`/studio/api/knowledge-graphs/${encodeURIComponent(id)}/database/query`, sql);
};

export default {
  getDatabaseSystems,
  getDatabaseConnection,
  testDatabaseConnectionForExistingKg,
  testDatabaseConnectionForNewKg,
  getDatabaseFolders,
  getDatabaseTables,
  getImportConfiguration,
  getPublicKey,
  getDatabaseColumns,
  querySql
};
