import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './logo.scss';

const Logo = ({ logoFull, logoShort, displayShortVersion, displayFullVersion, themeName, alt }) => {
  const iconWrapperClass = classnames({
    iconWrapper: true,
    [`${themeName}`]: true
  });

  const logoWrapperClass = classnames({
    logoWrapper: true,
    [`${themeName}`]: true
  });

  const typeOfLogo = classnames({
    logo: displayFullVersion,
    'message-icon': !displayFullVersion
  });

  const image = <img className={typeOfLogo} src={displayShortVersion ? logoShort : logoFull} alt={alt} />;

  return displayShortVersion ? (
    <div className={iconWrapperClass}>{image}</div>
  ) : (
    <span data-testid="logoWrapper" className={logoWrapperClass}>
      {image}
    </span>
  );
};

Logo.propTypes = {
  displayShortVersion: PropTypes.bool,
  displayFullVersion: PropTypes.bool,
  logoFull: PropTypes.string,
  logoShort: PropTypes.string,
  themeName: PropTypes.string,
  alt: PropTypes.string
};

Logo.defaultProps = {
  displayShortVersion: false,
  logoFull: '', // data: URI shouldn't be empty
  logoShort: '',
  alt: 'Logo'
};

const mapStateToProps = state => ({
  logoFull: state.theme.themeDetails.assets.logoFull,
  logoShort: state.theme.themeDetails.assets.logoShort,
  themeName: state.theme.themeDetails.name
});

export default connect(mapStateToProps)(Logo);
