import { tss } from 'tss-react';

export const useStyles = tss.create({
  backdrop: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 2
  }
});

export const useGradientBoxStyles = tss.create(({ isLoading, value }) => ({
  root: {
    maxWidth: 980,
    width: '100%',
    position: 'relative',
    background: isLoading
      ? `repeating-conic-gradient(from ${value}deg, var(--primary-color) 0%, var(--secondary-color) 50%, var(--primary-color) 100%)`
      : 'var(--accent-normal-color)',
    borderRadius: 6,
    padding: '2px'
  }
}));

export const dialogTextSx = {
  '&.MuiDialogContentText-root': {
    fontSize: '14px !important',
    paddingBottom: '10px'
  }
};

export const useModifyVisualizationStyles = tss.create(({ show, isFetchingVisualizationModification }) => ({
  root: {
    display: show ? 'block' : 'none',
    background: '#f8f8f8',
    margin: '0 -25px',
    padding: '13px 0px 10px 0px',
    position: 'relative',
    borderTop: '1px solid var(--accent-normal-color)'
  },
  inputField: {
    color: isFetchingVisualizationModification ? 'var(--accent-normal-color)' : 'black',
    borderRight: 'none',
    height: 'auto',
    width: '100%',
    border: 'none',
    padding: '5px 5px 5px 11px',
    borderRadius: '4px',
    borderTopRightRadius: '0',
    borderBottomRightRadius: '0'
  },
  centerText: {
    color: 'grey',
    fontWeight: 400
  },
  outerDiv: {
    margin: '0 25px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    rowGap: '12px'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

export const buttonSx = {
  editOptions: {
    '&.MuiButtonBase-root': {
      color: 'grey',
      fontSize: 11,
      background: 'white',
      flex: '1 0 auto',
      height: 'auto',
      borderRadius: '0'
    }
  },
  confirmArrow: {
    '&.MuiButtonBase-root': {
      height: 'auto',
      background: 'white',
      borderRadius: '0',
      padding: '10px',
      width: 40,
      '&&:disabled': {
        backgroundColor: 'white',
        '& path': {
          fill: 'var(--light-gray)'
        }
      }
    }
  },
  close: {
    '&.MuiButtonBase-root': {
      position: 'absolute',
      top: 5,
      right: 5
    }
  }
};
