const fontFamily = 'Lexend';
const primaryColor = 'var(--primary-color)';

export default {
  textField: {
    '& .MuiOutlinedInput-input': {
      fontFamily,
      fontSize: '14px',
      padding: '8px 12px',
      color: 'inherit',
      lineHeight: '20px',
      height: '32px',
      boxSizing: 'border-box'
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'white'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      top: 0,
      border: 'solid thin var(--light-gray)',
      borderRadius: '4px',
      '& > legend': {
        display: 'none'
      }
    },
    '& .MuiFormHelperText-root': {
      fontSize: '12px'
    },
    '& .MuiInputLabel-shrink': {
      transform: 'none !important',
      position: 'static'
    },
    '& .MuiOutlinedInput-root.Mui-disabled': {
      backgroundColor: 'transparent'
    }
  },
  clockComponent: {
    '& .MuiTimePickerToolbar-root': {
      backgroundColor: primaryColor,
      // this is the title above the hour/minute label in the round clock.
      '& > span.MuiTypography-root': {
        display: 'none'
      },
      '& .MuiPickersToolbar-penIconButton': {
        display: 'none'
      },
      '& > div.MuiPickersToolbar-content': {
        display: 'flex',
        justifyContent: 'center',
        '& .PrivatePickersToolbarText-root': {
          fontFamily,
          color: 'rgba(255, 255, 255, 0.54)',
          '&.Mui-selected': {
            color: 'white'
          }
        }
      }
    },
    '& .MuiClockPicker-root': {
      '& .MuiClock-pin, .MuiClockPointer-root': {
        backgroundColor: primaryColor,
        '& .MuiClockPointer-thumb': {
          backgroundColor: primaryColor,
          borderColor: primaryColor
        }
      },
      '& .MuiClockNumber-root': {
        fontFamily
      },
      '& .MuiPickersArrowSwitcher-button': {
        color: primaryColor,
        '&.Mui-disabled': {
          color: 'rgba(0, 0, 0, 0.26)'
        }
      }
    },
    '& .MuiDialogActions-root': {
      '& .MuiButtonBase-root': {
        fontFamily,
        color: primaryColor
      }
    }
  }
};
