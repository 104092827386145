import React, { useState, useEffect, useRef, useCallback, useMemo, memo } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form/dist/index.ie11';
import { yupResolver } from '@hookform/resolvers/dist/ie11/yup';
import * as yup from 'yup';
import { withTranslation } from 'react-i18next';

import { resetPassword, validateToken } from 'store/modules/passwordReset';
import { authenticate } from 'store/modules/network';

import PageLayout from 'layouts/PageLayout';

import { Button, PasswordField } from 'components/v3';

import Title from 'components/Signup/Title';
import PageLoader from 'components/Signup/PageLoader';
import MessageModal from 'components/Signup/MessageModal';

import styles from './index.module.scss';
import signupStyles from 'pages/Signup/signup.styles.scss';
import StepperContainer from 'components/Signup/StepperContainer/StepperContainer';
import clsx from 'clsx';

import { layouts } from 'config/constants';

import useMediaQuery from 'utils/mediaQueries';

const validationRules = yup.object().shape({
  password: yup.string().required('Password is required')
});

const defaultValues = {
  password: ''
};

const getErrorMessage = error => {
  return (error && error.message) || '';
};

const RedefinePassword = ({
  dispatch,
  isValidatingToken,
  isResettingPassword,
  isPasswordChanged,
  isAuthorized,
  somethingWentWrong,
  isTokenValid,
  message,
  token,
  t
}) => {
  const history = useHistory();
  const inputRef = useRef(null);
  const isMobile = useMediaQuery();

  const { handleSubmit, control, errors, getValues, setValue } = useForm({
    defaultValues,
    resolver: yupResolver(validationRules)
  });

  const [fields, setFields] = useState(defaultValues);

  const [modal, setModal] = useState({
    open: false,
    message: 'Modal message',
    type: 'info'
  });

  const passwordResetMessage = useMemo(() => {
    if (isValidatingToken) {
      return t('validate-token');
    }
    if (somethingWentWrong) {
      return message;
    }
    return `${t('reset-password-for')} ${message}`;
  }, [isValidatingToken, somethingWentWrong, message, t]);

  const onSubmit = useCallback(
    ({ password }) => {
      setFields({ password });
      dispatch(resetPassword(token, password, message));
    },
    [dispatch, token, message]
  );

  const onModalConfirm = useCallback(() => {
    setModal(prevModal => ({ ...prevModal, open: false }));
    const values = getValues();
    dispatch(authenticate(message, values.password, true));
  }, [setModal, history, dispatch, message, getValues]);

  useEffect(() => {
    dispatch(validateToken(token));
  }, [validateToken, dispatch, token]);

  useEffect(() => {
    if (isAuthorized) {
      history.push('/chat');
    }
  }, [isAuthorized, history]);

  useEffect(() => {
    if (!isResettingPassword && fields.password !== '') {
      setValue('password', fields.password);
    }
  }, [isResettingPassword, fields.password]);

  useEffect(() => {
    if (isPasswordChanged) {
      setModal({
        open: true,
        type: 'success',
        message: <span>Your password was changed successfully!</span>
      });
    }
  }, [isPasswordChanged, setModal]);

  useEffect(() => {
    if (inputRef && inputRef.current && isTokenValid) {
      inputRef.current.focus();
    }
  }, [inputRef, isTokenValid]);

  return (
    <PageLayout>
      <StepperContainer customStepper={<></>}>
        <div className={signupStyles.loginButton}>
          <Button
            onClick={() => history.push('/signup')}
            classes={{
              root: { fontSize: `${isMobile ? '16px' : '20px'} !important`, color: 'black', fontWeight: '600' }
            }}
            mode="simple"
            data-test="gotoSignupButton"
          >
            Sign up
          </Button>
        </div>
        <div className={signupStyles.centerBothDirectionsParent}>
          <div className={signupStyles.centerBothDirectionChild}>
            <div>
              <div>
                <Title>Enter your new password</Title>
                <div className={signupStyles.subTitle}>Make sure it is strong, but still easy to remember.</div>
              </div>
              {isResettingPassword ? (
                <PageLoader message="Redefining your password. Please wait..." />
              ) : (
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className={signupStyles.mt_40}>
                    <div className={signupStyles.formField}>
                      <Controller
                        as={<PasswordField ref={inputRef} />}
                        layout={layouts.signup}
                        name="password"
                        control={control}
                        label="New Password"
                        error={Boolean(errors.password)}
                        helperText={getErrorMessage(errors.password)}
                      />
                    </div>
                  </div>
                  <div className={clsx(signupStyles.mt_40, signupStyles.sendButton)}>
                    <Button type="submit" layout={layouts.signup} size="large" mode="dark">
                      Change password
                    </Button>
                  </div>
                  <div className={styles.messages}>{passwordResetMessage}</div>
                </form>
              )}
            </div>
          </div>
          <MessageModal
            message={modal.message}
            open={modal.open}
            onConfirm={onModalConfirm}
            closeModal={onModalConfirm}
            type={modal.type}
          />
        </div>
      </StepperContainer>
    </PageLayout>
  );
};

const mapStateToProps = state => {
  return {
    message: state.passwordReset.message,
    somethingWentWrong: state.passwordReset.somethingWentWrong,
    isTokenValid: state.passwordReset.isTokenValid,
    isResettingPassword: state.passwordReset.isResettingPassword,
    isPasswordChanged: state.passwordReset.isPasswordChanged,
    isAuthorized: state.network.isAuthorized,
    isValidatingToken: state.network.isValidatingToken
  };
};

export default memo(withTranslation('veezoo')(connect(mapStateToProps)(RedefinePassword)));
