import { useState, memo, useEffect } from 'react';
import PropTypes from 'prop-types';

import ChevronLeft from 'svg/v2/chevron_left.svg';
import ArrowUp from 'svg/v2/chevron_up.svg';
import ArrowDown from 'svg/v2/chevron_down.svg';

import LoadingIcon from 'components/loaders/LoadingIcon';

import Drawer from '@mui/material/Drawer';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import { useStyles, accordionSx, drawerSx } from './DrawerSuggestionsList.styles';

const List = ({ items, handleItemClick }) => {
  const { classes } = useStyles();

  const [listItems, setListItems] = useState([]);

  useEffect(() => {
    setListItems(items);
  }, [items]);

  const handleExpand = item => {
    setListItems(prevItems =>
      prevItems.map(prevItem => ({
        ...prevItem,
        expanded: prevItem.id === item.id ? !prevItem.expanded : prevItem.expanded
      }))
    );
  };

  return (
    <>
      <ul className={classes.list}>
        {listItems.map((item, index) => {
          return (
            <li key={`suggestion_${index}`} onClick={() => !item.options && handleItemClick(item)}>
              {item.options ? (
                <Accordion sx={accordionSx} square expanded={!!item.expanded} onChange={() => handleExpand(item)}>
                  <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    {item.text}
                    {item.options &&
                      (item.expanded ? (
                        <ArrowUp className={classes.showChildrenArrow} />
                      ) : (
                        <ArrowDown className={classes.showChildrenArrow} />
                      ))}
                  </AccordionSummary>
                  {item.options && (
                    <AccordionDetails>
                      <ul className={classes.list}>
                        {item.options.map((option, optionIndex) => (
                          <li
                            key={`accordion_suggestion_${optionIndex}`}
                            onClick={() => {
                              handleItemClick(option);
                            }}
                          >
                            {option.text}
                          </li>
                        ))}
                      </ul>
                    </AccordionDetails>
                  )}
                </Accordion>
              ) : (
                item.text
              )}
            </li>
          );
        })}
      </ul>
    </>
  );
};

const DrawerSuggestionsList = ({
  customInput,
  items,
  title = 'Suggestions',
  loading = false,
  isOpen = true,
  onItemClick = () => {},
  onClose = () => {}
}) => {
  const { classes } = useStyles({ customInput });

  const handleItemClick = item => {
    onItemClick(item);
    onClose();
  };

  return (
    <Drawer anchor="bottom" open={isOpen} onClose={onClose} sx={drawerSx}>
      <div className={classes.header}>
        <button className={classes.backArrowButton} onClick={onClose}>
          <ChevronLeft className={classes.backArrowIcon} />
        </button>
        {customInput ? (
          <div className={classes.customInput}>{customInput}</div>
        ) : (
          <div className={classes.title}>{title}</div>
        )}
      </div>
      {loading ? (
        <div className={classes.loadingContainer}>
          <LoadingIcon color="var(--colorful-button-primary-color)" />
        </div>
      ) : items ? (
        <List items={items} handleItemClick={handleItemClick} />
      ) : (
        <>No items available</>
      )}
    </Drawer>
  );
};

export default memo(DrawerSuggestionsList);

DrawerSuggestionsList.propTypes = {
  customInput: PropTypes.any,
  title: PropTypes.string,
  items: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.element]),
  loading: PropTypes.bool,
  isOpen: PropTypes.bool,
  onItemClick: PropTypes.func,
  onClose: PropTypes.func
};
