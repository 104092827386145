import { get, put } from 'services/http';

const getSettings = () => get('/studio/api/settings');

const replaceSettings = settings => put('/studio/api/settings', settings);

export default {
  getSettings,
  replaceSettings
};
