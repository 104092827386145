import isThursday from 'date-fns/isThursday';
import nextThursday from 'date-fns/nextThursday';
import differenceInCalendarWeeks from 'date-fns/differenceInCalendarWeeks';
import endOfWeek from 'date-fns/endOfWeek';
import startOfWeek from 'date-fns/startOfWeek';
import addWeeks from 'date-fns/addWeeks';
import getISOWeek from 'date-fns/getISOWeek';
import getQuarter from 'date-fns/getQuarter';
import quartersToMonths from 'date-fns/quartersToMonths';
import getISOWeekYear from 'date-fns/getISOWeekYear';

import { firstDayOfWeekInVeezoo, dateViews } from 'config/constants';

const onlyNumbersRegex = /^[0-9]+$/;

/**
 * If the current day / month / hour / etc... is lower than 10 and have only one digit, adds a "0" before it.
 * @param {number / string (but has to be a stringified NUMBER)} item
 * @returns A string of the entry parameter starting with "0" if needed.
 */
export const addZero = item => (parseInt(item) < 10 ? '0' + item.toString() : item.toString());

/**
 * Receives an array of views available for the Datetime component and returns an object with its available attributes as booleans
 * @param {array of strings} item
 * @returns An object with properties as bool: {hasYear, hasMonth, hasDate, hasWeek, hasHour, hasMinute}
 */
export const getAvailableViews = views => {
  const result = {
    hasYear: false,
    hasMonth: false,
    hasDate: false,
    hasWeek: false,
    hasQuarter: false,
    hasHour: false,
    hasMinute: false
  };

  views.forEach(view => {
    if (view === dateViews.year) result.hasYear = true;
    if (view === dateViews.month) result.hasMonth = true;
    if (view === dateViews.day || view === dateViews.date) result.hasDate = true;
    if (view === dateViews.week) result.hasWeek = true;
    if (view === dateViews.quarter) result.hasQuarter = true;
    if (view === dateViews.hour) result.hasHour = true;
    if (view === dateViews.minute) result.hasMinute = true;
  });

  return result;
};

/**
 * Generate a string from the click-moment datetime separated by "-" in this format: YYMMDD-hhmm.
 * This is the return for May 05 2020 at 14:21:
 * @returns 210505-1421
 */
export const getCurrentDateTimeProtocol = () => {
  const date = new Date();

  const year = date
    .getFullYear()
    .toString()
    .substring(2);
  const month = addZero(date.getMonth() + 1);
  const day = addZero(date.getDate());

  const hour = addZero(date.getHours());
  const minute = addZero(date.getMinutes());

  return year + month + day + '-' + hour + minute;
};

/**
 * Receives a datetime and returns an object with its separated values and month already converted (from index to month number);
 * This is the return for May 05 2020 at 14:21:
 * @returns {year: 2020, month: 05, day: 05, hour: 14, minute: 21}
 */
export const dateTimeToObject = date => {
  const year = date.getFullYear().toString();
  const rawMonth = date.getMonth() + 1;
  const month = addZero(rawMonth);
  const day = addZero(date.getDate());
  const hour = addZero(date.getHours());
  const minute = addZero(date.getMinutes());

  return { year, month, day, hour, minute };
};

/**
 * Receives a date and returns the first day of that week, according to Veezoo's first day configuration;
 * @param {date} date
 * @returns
 */
export const startOfWeekInVeezoo = date => startOfWeek(date, { weekStartsOn: firstDayOfWeekInVeezoo });

/**
 * Receives a date and returns the last day of that week, according to Veezoo's first day configuration;
 * @param {date} date
 * @returns date
 */
export const endOfWeekInVeezoo = date => endOfWeek(date, { weekStartsOn: firstDayOfWeekInVeezoo });

/**
 * Receives a year in number format and returns a datetime with the first thursday of the year;
 * @param {number} year
 * @returns date;
 */
export const getFirstThursdayOfTheYear = year => {
  const initialDate = new Date(year, 0, 1);
  if (isThursday(initialDate)) return initialDate;
  return nextThursday(initialDate);
};

/**
 * Convert from first/second datetimes to weeks (integer);
 * @param {date} first
 * @param {date} second
 * @returns number;
 */
export const differenceInCalendarWeeksInVeezoo = (first, second) =>
  differenceInCalendarWeeks(first, second, { weekStartsOn: firstDayOfWeekInVeezoo });

/**
 * Convert from year (integer) and weeks (integer) to datetime;
 * If year or week are invalid, it returns a Datetime's "Invalid Date".
 * @param {number} year
 * @param {number} weeks
 * @returns number
 */
export const getDateFromYearAndWeek = (year, weeks) => {
  const firstThursday = getFirstThursdayOfTheYear(year);
  return addWeeks(firstThursday, weeks - 1);
};

/**
 * Convert from year (integer) and quarter (integer) to datetime;
 * @param {number} year
 * @param {number} quarter
 * @returns number
 */
export const getDateFromYearAndQuarter = (year, quarter) => {
  const firstMonthOfQuarter = quartersToMonths(quarter) - 1;
  return new Date(year, firstMonthOfQuarter, 1);
};

/**
 * convert from datetime to week number, in string format (with zero added, if needed);
 * @param {date} date
 * @returns string;
 */
export const getWeekOfTheYear = date => {
  const year = getISOWeekYear(date);
  const week = getISOWeek(date);
  return { year, week: addZero(week) };
};

/**
 * receives a JS datetime  and returns an object with two attributes: year and quarter,
 * both as number. Quarter will be a number between 1 and 4.
 * @param {datetime} date E.g.: 2024-11-12T11:52:46.133Z
 * @returns {object} E.g.: {year: 2015, quarter: 3};
 */
export const getQuarterOfTheYear = date => {
  const year = getISOWeekYear(date);
  const quarter = getQuarter(date);
  return { year, quarter };
};

/**
 * Receives a date and returns if it is a valid date, as a boolean;
 * @param {date} date
 * @returns boolean;
 */
export const isValidDate = date => date instanceof Date && !isNaN(date);

/**
 * Receives a "week" as number and validates if it is a valid week;
 * @param {number} week
 * @returns boolean;
 */
export const isValidWeek = week => typeof week === 'number' && !isNaN(week) && week > 0 && week < 53;

/**
 * Receives a quarter as number and validates if it is a valid quarter;
 * @param {number} quarter
 * @returns boolean;
 */
export const isValidQuarter = quarter => typeof quarter === 'number' && quarter >= 1 && quarter <= 4;

/**
 * Receives a year and returns if it is a valid year, as a boolean;
 * @param {number} year
 * @returns boolean;
 */
export const isValidYear = year => {
  if (!onlyNumbersRegex.test(year) || typeof year !== 'number' || isNaN(year) || year.toString().length < 4 || year < 1)
    return false;
  return true;
};

/**
 * Receives a year and a week number and returns a string formatted to Veezoo's default week format;
 * @param {number} year
 * @param {number} week
 * @returns
 */
export const formatDateToWeekString = (year, week) => {
  return `${year}-W${week}`;
};

/**
 * Receives a year and a quarter number and returns a string formatted to Veezoo's default quarter format;
 * @param {number} year (e.g. 2024)
 * @param {number} quarter (e.g. 1) (should be a number between 1 and 4)
 * @param {function} t (optional, receives the "t" function from react-i18next)
 * @returns the formatted year/quarter string, e.g. '2024-Q1'
 */
export const formatYearAndQuarterToQuarterString = (year, quarter, t = null) => {
  const quarterInitial = t ? t('quarter-initial') : 'Q';
  return `${year}-${quarterInitial}${quarter}`;
};

/**
 * Receives a quarter string and returns a datetime;
 * @param {string} quarterString
 * @returns datetime 2024-11-12T11:52:46.133Z
 */
export const formatQuarterStringToDatetime = (quarterString, t = null) => {
  const quarterInitial = t ? t('quarter-initial') : '-Q';

  // Splits the "quarterString" in two, having as separator the "quarterInitial" element above.
  // e.g., if quarterString is `2024-Q3`, we'll split it in half, using as separator the "-Q",
  // returning an array with two elements: [2024,3].
  // replaceAll is necessary because the mask used as placeholder, when the input is not filled, is
  // "____-Q_", so we need to remove these "_". It's possible that the results are truncated, that's why we
  // call "isValidYear" and "isValidQuarter" below.
  const [year, quarter] = quarterString.split(quarterInitial).map(item => item.replaceAll('_', ''));
  const yearAsNumber = parseInt(year);
  const quarterAsNumber = parseInt(quarter);
  const formattedYear = isValidYear(yearAsNumber) ? yearAsNumber : undefined;
  const formattedQuarter = isValidQuarter(quarterAsNumber) ? quarterAsNumber : undefined;

  const formattedMonth = formattedQuarter ? quartersToMonths(formattedQuarter) - 1 : undefined;
  return new Date(formattedYear, formattedMonth, 1);
};

/**
 *
 * This function first calculates the time difference in seconds, minutes, hours,
 * and days between the input datetime and the current time. Based on these calculations,
 * it returns an object containing two attributes: text and value. "text" is a string formatted to be used
 * with "react-i18next" translation, and "value" is optional, representing the number of years/month/weeks/days/hours/minutes.
 *
 * @param {datetime} datetime (example: new Date('2023-10-15T12:00:00'));
 * @returns object (example: {text: 'weeks-ago', value: 2});
 */

export const datetimeToTranslatableObject = ({ first, second }) => {
  const timestampFirst = first instanceof Date ? first : new Date(first);
  const timestampSecond = second instanceof Date ? second : new Date(second);

  const timeDifference = timestampFirst - timestampSecond;
  const positiveDifference = timeDifference < 0 ? timeDifference * -1 : timeDifference;
  const seconds = Math.floor(positiveDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30.44);
  const years = Math.floor(months / 12);

  if (seconds < 60) {
    return { text: 'just-now' };
  } else if (minutes < 60) {
    const unit = minutes === 1 ? 'minute' : 'minutes';
    return { text: `${unit}-ago`, value: minutes };
  } else if (hours < 24) {
    const unit = hours === 1 ? 'hour' : 'hours';
    return { text: `${unit}-ago`, value: hours };
  } else if (days === 1) {
    return { text: 'yesterday' };
  } else if (days < 7) {
    return { text: 'days-ago', value: days };
  } else if (weeks < 5) {
    const weeks = Math.floor(days / 7);
    if (weeks === 1) return { text: 'last-week' };
    return { text: 'weeks-ago', value: weeks };
  } else if (months < 12) {
    if (months === 1) return { text: 'last-month' };
    return { text: 'months-ago', value: months };
  } else {
    if (years === 1) return { text: 'last-year' };
    return { text: 'years-ago', value: years };
  }
};

export const secondsToMiliseconds = seconds => seconds * 1000;
export const milisecondsToSeconds = miliseconds => miliseconds / 1000;
