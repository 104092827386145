import { useState, useEffect, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { discoveryToChat, fetchDiscoveryExample } from 'store/modules/discovery';
import UserMessage from 'components/message/UserMessage';
import InfoMessage from 'components/message/InfoMessage';
import AnswerMessage from 'components/message/AnswerMessage';
import LoadingMessage from 'components/message/LoadingMessage';
import DiscoveryAnswerFooter from 'components/message/DiscoveryAnswerFooter';

import { messageTypes } from 'store/modules/chat-messages';

import styles from './discovery.scss';

const DiscoveryDetail = ({
  discovery,
  selectedTopicIndex,
  containerRef,
  isScrolling,
  shouldScrollAutomatically,
  showOverviewCallback,
  isMobile,
  dispatch,
  t
}) => {
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0);

  const selectedTopic = useMemo(() => discovery.topics[selectedTopicIndex], [discovery.topics, selectedTopicIndex]);

  const exampleId = useMemo(() => selectedTopicIndex + '-' + selectedQuestionIndex, [
    selectedTopicIndex,
    selectedQuestionIndex
  ]);

  const selectedExample = useMemo(() => discovery.fetchedExamples.find(fe => fe.exampleId === exampleId), [
    discovery?.fetchedExamples,
    exampleId
  ]);

  const rephrasedQuestionExample = useMemo(
    () => discovery.fetchedExamples.find(fe => fe.exampleId === exampleId + '-rephrase'),
    [discovery?.fetchedExamples, exampleId]
  );

  const transferToChat = useCallback(() => dispatch(discoveryToChat(selectedExample)), [selectedExample, dispatch]);

  const getDiscoveryExample = useCallback(() => {
    const selectedQuestion = selectedTopic.questions.length > 0 && selectedTopic.questions[selectedQuestionIndex];
    const hasExampleId = discovery.fetchedExamples.some(fe => fe.exampleId === exampleId);

    if (selectedQuestion && !hasExampleId) {
      dispatch(fetchDiscoveryExample(exampleId, selectedQuestion));
    }
  }, [exampleId, selectedTopic?.questions, discovery.fetchedExamples, selectedQuestionIndex, dispatch]);

  const rephrasedQuestion = useMemo(() => {
    if (rephrasedQuestionExample) {
      return (
        <UserMessage
          isDiscovery
          isRephraseMessage
          key={rephrasedQuestionExample.id}
          id={rephrasedQuestionExample.id}
          textAnswer={rephrasedQuestionExample.rephrasedQuestion}
          timestamp={rephrasedQuestionExample.timestamp}
          isFollowUp={rephrasedQuestionExample.referenceTo}
        />
      );
    }
    return null;
  }, [exampleId, selectedExample, rephrasedQuestionExample, discovery.fetchedExamples]);

  const previewExample = useMemo(() => {
    if (!selectedExample) return <LoadingMessage />;

    if (selectedExample.type === messageTypes.VEEZOO_INFO_MESSAGE) {
      return (
        <InfoMessage
          message={selectedExample}
          key={selectedExample.id}
          chatContainer={containerRef.current}
          isScrolling={isScrolling}
          shouldScrollAutomatically={shouldScrollAutomatically}
          t={t}
        />
      );
    }

    if (!selectedExample.answer) return <LoadingMessage />;

    return (
      <AnswerMessage
        {...selectedExample}
        key={selectedExample.id}
        customFooter={<DiscoveryAnswerFooter callback={() => transferToChat(selectedExample)} />}
        isMobile={isMobile}
      />
    );
  }, [selectedExample, containerRef?.current, isScrolling, shouldScrollAutomatically, t, isMobile, transferToChat]);

  useEffect(() => {
    getDiscoveryExample();
  }, [selectedQuestionIndex]);

  return (
    <div className={styles.topicContainer}>
      <h2 className={styles.title}>
        <span className={styles.linkedDiscoveryTitle} onClick={showOverviewCallback}>
          Discovery
        </span>
        <span>{' > ' + selectedTopic.title}</span>
      </h2>
      <div>
        <span className={styles.description}>{selectedTopic.description}</span>
        <p className={styles.examples}>{t('examples')}</p>
        <ul className={styles.exampleList} data-test="example-questions-list">
          {selectedTopic.questions.map((question, index) => {
            return (
              <li key={index} className={styles.exampleItem}>
                <button
                  data-test="example-question-handle"
                  onClick={() => setSelectedQuestionIndex(index)}
                  className={selectedQuestionIndex === index ? styles.selectedExample : styles.example}
                >
                  {question}
                </button>
              </li>
            );
          })}
        </ul>
      </div>
      <p className={styles.examples}>
        {t('preview')}
        {selectedExample?.answer && (
          <span>
            <span onClick={() => transferToChat(selectedExample)} className={styles.hyperlink}>
              &nbsp;{t('show-in-chat')}
            </span>
          </span>
        )}
      </p>
      <div>{rephrasedQuestion}</div>
      <div className={styles.previewExample}>{previewExample}</div>
    </div>
  );
};

DiscoveryDetail.propTypes = {
  discovery: PropTypes.object,
  username: PropTypes.string,
  selectedTopicIndex: PropTypes.number,
  showOverviewCallback: PropTypes.func,
  isMobile: PropTypes.bool,
  containerRef: PropTypes.object,
  shouldScrollAutomatically: PropTypes.bool,
  isScrolling: PropTypes.bool,
  t: PropTypes.func
};

const mapStateToProps = state => ({
  username: state.user.username
});

export default withTranslation('veezoo')(connect(mapStateToProps)(DiscoveryDetail));
