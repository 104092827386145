import { get, post } from 'services/http';

const createDevKnowledgeGraph = (id, name) =>
  post(`/studio/api/knowledge-graphs/${encodeURIComponent(id)}/create-dev`, JSON.stringify({ name: name }));

const pushDevKgToMainKg = id => post(`/studio/api/knowledge-graphs/${encodeURIComponent(id)}/dev/push`);
const resetDevKgToMainKg = id => post(`/studio/api/knowledge-graphs/${encodeURIComponent(id)}/dev/reset`);
const pullDevKgFromMainKg = id => post(`/studio/api/knowledge-graphs/${encodeURIComponent(id)}/dev/pull`);

const compareDevKgToMainKg = id => get(`/studio/api/knowledge-graphs/${encodeURIComponent(id)}/dev/compare`);

export default {
  createDevKnowledgeGraph,
  pushDevKgToMainKg,
  resetDevKgToMainKg,
  pullDevKgFromMainKg,
  compareDevKgToMainKg
};
