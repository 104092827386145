import { useMemo } from 'react';
import { useTssStyles } from './ShareModal.styles';

import CloseIcon from 'svg/close2.svg';

import IconContainer from './IconContainer';
import GroupIcon from 'svg/people-group.svg';

import { Avatar } from 'components/v3';

const UsersList = ({ allUsersAndRoles, values, disabled, currentUser, isAdminOrSuperUser, onRemoveClick, t }) => {
  const { classes } = useTssStyles();

  // apply filter to maintain sorting order
  const selected = useMemo(() => allUsersAndRoles.filter(user => values.includes(user.value)), [
    allUsersAndRoles,
    values
  ]);

  return (
    <div className={classes.usersListContainer}>
      <ul className={classes.list}>
        {selected.length > 0 ? (
          selected.map((user, index) => {
            return (
              <li key={index}>
                <div className={classes.image}>
                  {user.type === 'user' ? <Avatar username={user.label} /> : <IconContainer icon={GroupIcon} />}
                </div>
                <div className={classes.content}>
                  <span className={classes.username}>
                    {user.label} {user.id === currentUser.id && `(${t('you')})`}
                  </span>
                </div>
                <div className={classes.listField}>
                  <button
                    onClick={() => onRemoveClick(user)}
                    disabled={disabled}
                    className={classes.removeButton}
                    data-test={`usersListRemove-${index}`}
                  >
                    <CloseIcon />
                  </button>
                </div>
              </li>
            );
          })
        ) : (
          <div className={classes.noUsersSelected}>
            {isAdminOrSuperUser ? t('sharing.no-users-or-roles-selected') : t('sharing.no-users-selected')}
          </div>
        )}
      </ul>
    </div>
  );
};

export default UsersList;
