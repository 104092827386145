import { tss } from 'tss-react';

export const useStyles = tss.create({
  typeContainer: {
    marginTop: '25px'
  },
  inputContainer: {
    marginTop: '25px'
  },
  buttonContainer: {
    marginTop: '25px'
  },
  browseIcon: {
    width: 17,
    height: 13,
    '& path': {
      fill: 'white'
    }
  },
  plusIcon: {
    width: 13,
    height: 13,
    '& path': {
      fill: 'white'
    }
  },
  listPlusIcon: {
    width: 13,
    height: 13,
    '& path': {
      fill: 'white'
    }
  },
  backArrowIcon: {
    cursor: 'pointer',
    display: 'inline-block',
    width: 20,
    height: 20,
    '& svg': {
      '& path': {
        fill: 'var(--secondary-color)'
      }
    },
    '&:hover': {
      '& path': {
        fill: 'var(--primary-color)'
      }
    }
  },
  customTitle: {
    display: 'inline-block',
    '& span': {
      verticalAlign: 'middle'
    }
  },
  dividerDot: {
    backgroundColor: 'var(--light-darker-gray)',
    borderRadius: '50%',
    width: 4,
    height: 4,
    display: 'inline-block',
    margin: '0 8px'
  }
});
