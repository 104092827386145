import { cloneElement, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useStyles } from './LabelButton.styles';

const LabelButton = ({ text, color, icon: Icon, onClick = () => {}, ...rest }) => {
  const { classes } = useStyles({ color, Icon });

  const hasIcon = useMemo(() => Icon && cloneElement(Icon, { className: `${classes.icon}` }), [Icon]);
  return (
    <div className={classes.container} onClick={onClick}>
      {hasIcon}
      <span className={classes.text} {...rest}>
        {text}
      </span>
    </div>
  );
};

export default LabelButton;

LabelButton.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
  icon: PropTypes.element,
  onClick: PropTypes.func
};
