import { get, post } from 'services/http';

const getIntegrations = () => get('/studio/api/integrations');

const getDbtCloudDescriptions = (kgId, jobId) =>
  get(
    `/studio/api/knowledge-graphs/${encodeURIComponent(kgId)}/integrations/dbt-cloud/descriptions/${encodeURIComponent(
      jobId
    )}`
  );

const updateDbtCloudDescriptions = (kgId, body) =>
  post(`/studio/api/knowledge-graphs/${encodeURIComponent(kgId)}/integrations/dbt-cloud/descriptions`, body);

const updateIntegration = (id, body) => post(`/studio/api/integrations/${encodeURIComponent(id)}`, body);

const exasolCallback = body => post('/exasol/callback', body);

export default {
  getIntegrations,
  getDbtCloudDescriptions,
  updateDbtCloudDescriptions,
  updateIntegration,
  exasolCallback
};
