import React, { PureComponent } from 'react';
import { boundMethod } from 'autobind-decorator';
import Joyride, { ACTIONS } from 'react-joyride';
import { connect } from 'react-redux';
import Title from 'components/tutorial/Title';
import { finishedTutorial } from 'store/modules/tutorial';

import { withTranslation } from 'react-i18next';
import { BookmarkIcon } from '../Icons';
import { tutorialNames } from 'config/constants';
import { veezooRoutes } from '../app/routes';
import { withRouter } from 'react-router-dom';

const tutorialId = tutorialNames.BOOKMARK_TUTORIAL;
const overviewTutorialId = tutorialNames.OVERVIEW_TUTORIAL;
const firstAnswerTutorialId = tutorialNames.FIRST_ANSWER_TUTORIAL;

// In this Tutorial we abuse the Tutorial Functionality to implement a "Saving Bookmark" prompt.
// the skip and next buttons become: "I already have it as bookmark" and "Save"
export class BookmarkTutorial extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      runTutorial: false,
      tutorialComplete: false,
      shouldSave: false
    };
  }

  @boundMethod
  callback(tour) {
    const { action } = tour;

    const shouldSave = action === ACTIONS.NEXT;
    const alreadySaved = action === ACTIONS.SKIP;

    if (alreadySaved || shouldSave) {
      this.props.dispatch(finishedTutorial(tutorialId, true));
      this.setState({ runTutorial: false, shouldSave, tutorialComplete: true });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      !this.state.runTutorial &&
      !this.state.tutorialComplete &&
      nextProps.location.pathname === veezooRoutes.chat &&
      nextProps.uncompletedTutorials.some(tutorial => tutorial.id === tutorialId) &&
      // If Overview (or First Answer) tutorial not yet completed, do not show tutorial, to avoid running both together.
      (!nextProps.uncompletedTutorials.some(tutorial => tutorial.id === overviewTutorialId) ||
        !nextProps.uncompletedTutorials.some(tutorial => tutorial.id === firstAnswerTutorialId))
    ) {
      this.setState({
        runTutorial: true
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.shouldSave) {
      this.setState({ shouldSave: false });
    }
  }

  render() {
    const { t } = this.props;

    const steps = [
      {
        target: 'body',
        disableBeacon: true,
        placement: 'center',
        showProgress: false,
        locale: { skip: t('tutorial.bookmark.skip'), next: t('tutorial.bookmark.next') },
        content: t('tutorial.bookmark.1'),
        title: <Title text={t('tutorial.bookmark.1-title')} icon={<BookmarkIcon />} />
      },
      {
        target: 'body',
        disableBeacon: true,
        placement: 'center',
        showProgress: false,
        locale: { skip: t('tutorial.bookmark.skip'), next: t('tutorial.bookmark.next') },
        content: t('tutorial.bookmark.1'),
        title: <Title text={t('tutorial.bookmark.1-title')} icon={<BookmarkIcon />} />
      }
    ];

    let result;
    if (this.state.shouldSave) {
      if (window.sidebar && window.sidebar.addPanel) {
        // Firefox <23
        window.sidebar.addPanel(document.title, window.location.href, '');
      } else if (window.external && 'AddFavorite' in window.external) {
        // Internet Explorer
        window.external.AddFavorite(location.href, document.title);
      } else {
        // For the other browsers (mainly WebKit) we use a simple alert to inform users that they can add to bookmarks with ctrl+D/cmd+D
        alert(
          t('tutorial.bookmark.save', {
            combination: navigator.userAgent.toLowerCase().indexOf('mac') !== -1 ? 'Command/Cmd + D' : 'CTRL + D'
          })
        );
      }
      result = null;
    } else {
      result = (
        <div>
          <Joyride
            {...this.props.generalOptions}
            ref="joyride"
            steps={steps}
            run={this.state.runTutorial} // or some other boolean for when you want to start it
            callback={this.callback}
          />
        </div>
      );
    }

    return result;
  }
}

const mapStateToProps = state => {
  return {
    uncompletedTutorials: state.tutorial.uncompletedTutorials
  };
};

export default withTranslation('veezoo')(connect(mapStateToProps)(withRouter(BookmarkTutorial)));
