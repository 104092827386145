import React, { useState, useCallback, memo } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form/dist/index.ie11';
import { yupResolver } from '@hookform/resolvers/dist/ie11/yup';
import * as yup from 'yup';
import clsx from 'clsx';

import { Button } from 'components/v3';

import Title from 'components/Signup/Title';
import MessageModal from 'components/Signup/MessageModal';
import StepperContainer from 'components/Signup/StepperContainer/StepperContainer';
import PageLoader from 'components/Signup/PageLoader';

import { layouts } from 'config/constants';

import useMediaQuery from 'utils/mediaQueries';

import signupStyles from 'pages/Signup/signup.styles.scss';

import services from 'services';
import { handleError } from 'services/http';

const TOSAndPrivacyLabel = () => (
  <div className={clsx(signupStyles.agreementLabel, signupStyles.mt_16)}>
    By signing up, I agree to the Veezoo{' '}
    <a href="https://www.veezoo.com/terms-and-conditions" rel="noopener noreferrer" target="_blank">
      Terms of Service
    </a>{' '}
    and{' '}
    <a href="https://www.veezoo.com/privacy-policy" rel="noopener noreferrer" target="_blank">
      Privacy Policy
    </a>
    .
  </div>
);

const validationRules = yup.object().shape({
  email: yup
    .string()
    .required('E-mail is required')
    .email('Format should be like: email@domain.com')
});

const defaultValues = { email: '' };

const CreateAccountLight = ({ dispatch }) => {
  const [savedFields, setSavedFields] = useState(null);
  const [modal, setModal] = useState({
    open: false,
    message: 'Modal message',
    type: 'info'
  });

  // this is true while we're sending out the verification link email at the backend
  const [isSendingVerificationEmailPending, setIsSendingVerificationEmailPending] = useState(null);
  const [isVerificationEmailSent, setIsVerificationEmailSent] = useState(null);

  const history = useHistory();
  const isMobile = useMediaQuery();

  const { handleSubmit, control, reset } = useForm({
    defaultValues,
    resolver: yupResolver(validationRules)
  });

  const reopenForm = useCallback(() => {
    setModal(prevModal => ({ ...prevModal, open: false }));
    reset(savedFields);
  }, [setModal, savedFields, reset]);

  const onSubmit = useCallback(
    async data => {
      setSavedFields(data);
      setIsSendingVerificationEmailPending(true);
      const response = await services.sendSignupVerificationEmail(data.email);
      handleError(response, dispatch);
      if (response.success) {
        setIsVerificationEmailSent(true);
      } else {
        // an error happened while verifying the signup email
        const errorMessage =
          response.response.data.type === 'Error'
            ? // this is a validation error, in which case we need to get the message inside the object
              response.response.data.data.message
            : // otherwise, just take the plaintext message
              response.response.data;
        setModal({
          open: true,
          type: 'error',
          message: errorMessage
        });
      }
      setIsSendingVerificationEmailPending(false);
    },
    [dispatch]
  );

  let content;
  if (isSendingVerificationEmailPending) {
    content = (
      <div className={signupStyles.loaderContainer}>
        <PageLoader message="Verifying your e-mail..." />
      </div>
    );
  } else if (isVerificationEmailSent) {
    content = (
      <div className={signupStyles.titleContainer}>
        <Title>Check your inbox</Title>
        <div className={signupStyles.subTitle}>
          We&apos;ve emailed you a verification link. Click it to continue signing up!
        </div>
      </div>
    );
  } else {
    content = (
      <>
        <div className={signupStyles.titleContainer}>
          <Title>Enter your email to get started for free</Title>
          <div className={signupStyles.subTitle}>
            Within 3 minutes you will be asking questions to your own data or demo data.
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={clsx(signupStyles.formField, signupStyles.mt_16)}>
            <Controller
              as={<input type="email" autoFocus className={signupStyles.signupInput} placeholder="name@company.com" />}
              name="email"
              control={control}
              data-test="signupEmailInitialPage"
            />
          </div>
          <div className={clsx(signupStyles.sendButton, signupStyles.mt_16)}>
            <Button type="submit" data-test="signupSubmitButton" layout={layouts.signup} size="large" mode="dark">
              Start for free
            </Button>
          </div>
          <TOSAndPrivacyLabel />
        </form>
      </>
    );
  }

  return (
    <StepperContainer customStepper={<></>}>
      <div className={signupStyles.loginButton}>
        <span>
          <Button
            onClick={() => history.push('/login')}
            classes={{
              root: { fontSize: `${isMobile ? '16px' : '20px'} !important`, color: 'black', fontWeight: '600' }
            }}
            mode="simple"
            data-test="gotoSignupButton"
          >
            Log in
          </Button>
        </span>
      </div>
      <div className={signupStyles.centerBothDirectionsParent}>
        <div className={signupStyles.centerBothDirectionChild}>{content}</div>
      </div>
      <MessageModal
        message={modal.message}
        open={modal.open}
        onConfirm={reopenForm}
        closeModal={reopenForm}
        type={modal.type}
      />
    </StepperContainer>
  );
};

const mapStateToProps = state => {
  return {
    isAuthorized: state.network.isAuthorized,
    signupSuccess: state.network.signupSuccess,
    signupFailure: state.network.signupFailure,
    signupStatusCode: state.network.signupStatusCode,
    signupError: state.network.signupError,
    isSigningUp: state.network.isSigningUp,
    username: state.user.username
  };
};

export default connect(mapStateToProps)(memo(CreateAccountLight));
