import { makeStyles } from 'tss-react/mui';

const primaryColor = 'var(--primary-color)';
const opacity = 0.6;

export const useStyles = makeStyles()((_, { color, Icon }) => ({
  container: {
    display: 'inline-block',
    '&:hover > $icon': {
      '& > path': {
        opacity
      }
    },
    '&:hover > $text': {
      opacity
    }
  },
  icon: {
    verticalAlign: 'middle',
    width: 16,
    height: 16,
    '& > path': {
      fill: color || primaryColor
    }
  },
  text: {
    marginLeft: Icon ? 5 : 0,
    verticalAlign: 'middle',
    fontSize: 14,
    color: color || primaryColor,
    cursor: 'pointer'
  }
}));
