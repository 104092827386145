import { del, get, patch } from 'services/http';

const getUsers = () => get('/studio/api/users');

const getUser = id => get(`/studio/api/users/${encodeURIComponent(id)}`);

const getUserDeprecated = () => get('/user');

const updateUserSettings = update => patch('/user/settings', update);

const deleteUser = id => del(`/studio/api/users/${encodeURIComponent(id)}`);

export default { getUsers, getUser, getUserDeprecated, updateUserSettings, deleteUser };
