import { withTranslation } from 'react-i18next';
import Button from 'components/buttons/Button';
import useMediaQuery from 'utils/mediaQueries';
import React, { Fragment, useRef, useState } from 'react';
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { customMapName, isCustomMapType, isHighChartsType, normalizedType } from 'config/constants';
import { isTableChart } from 'components/chart/Chart';
import _ from 'lodash';
import EditIcon from '@mui/icons-material/Edit';
import { Check } from '@mui/icons-material';
import { ChartIconFromType } from 'components/buttons/ChartIconFromType';

import { InfoTooltip } from 'components/v3';

import { useMenuStyles } from './ChangeVisualizationButton.styles';

const ChangeVisualizationButton = ({
  changeToVisualizationType,
  t,
  hideButtonText,
  showModifyOption,
  hideTableCharts,
  visualizationTypes,
  currentVisualizationType,
  isModifyVisualizationBlockOpen,
  setShowModifyVisualization
}) => {
  const isMobile = useMediaQuery();
  const [isOpen, setIsOpen] = useState(false);
  // currentVisualizationType includes the preview we do, so we need some state for the actual visualization type that
  // was before opening the menu.
  const [actualVisualizationType, setActualVisualizationType] = useState(currentVisualizationType);
  const buttonRef = useRef(null);
  const [anchorPosition, setAnchorPosition] = useState(null);

  const menuStyles = useMenuStyles();

  const handleMenuOpen = () => {
    if (buttonRef.current) {
      const { top, left, height } = buttonRef.current.getBoundingClientRect();
      setAnchorPosition({ top: top + height, left });
    }
    setIsOpen(!isOpen);
    // Need to update this on menu open, as e.g. changing inverted charts would lead to stale entries.
    // VZN-10600 will fix this.
    setActualVisualizationType(currentVisualizationType);
  };

  const handleModifyVisualization = () => {
    debouncedChangeVisualizationType.cancel();
    changeToVisualizationType(actualVisualizationType);
    setShowModifyVisualization(!isModifyVisualizationBlockOpen);
    setIsOpen(false); // Close menu after modification
  };

  const debouncedChangeVisualizationType = _.debounce(type => {
    changeToVisualizationType(type);
  }, 300);

  let menuItems = [];
  if (showModifyOption && isHighChartsType(actualVisualizationType)) {
    menuItems = [
      <MenuItem
        sx={menuStyles}
        key="modify"
        divider
        onClick={handleModifyVisualization}
        onMouseEnter={() => {
          debouncedChangeVisualizationType.cancel();
          isOpen && debouncedChangeVisualizationType(actualVisualizationType);
        }}
      >
        <ListItemIcon>
          <EditIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText secondary={<b style={{ color: 'orange' }}>BETA</b>}>
          {t('modify-visualization.title')}
        </ListItemText>
      </MenuItem>
    ];
  }

  menuItems = menuItems.concat(
    visualizationTypes
      .filter(type => !hideTableCharts || !isTableChart(type))
      .map((type, index) => {
        const customMapNameOrNull = isCustomMapType(type) && customMapName(type);
        const translatedName = t(`chart-types.${normalizedType(type)}`);
        const chartName = customMapNameOrNull || translatedName;
        return (
          <MenuItem
            sx={menuStyles}
            key={type + index}
            data-test="changeVisualizationTo"
            data-value={type}
            onClick={() => {
              debouncedChangeVisualizationType.cancel();
              changeToVisualizationType(type);
              setIsOpen(false);
              setActualVisualizationType(type);
            }}
            onMouseEnter={() => {
              debouncedChangeVisualizationType.cancel();
              isOpen && debouncedChangeVisualizationType(type);
            }}
          >
            <ListItemIcon>
              <ChartIconFromType type={type} />
            </ListItemIcon>
            <ListItemText>
              {type === actualVisualizationType ? (
                <b style={{ color: 'var(--secondary-color)' }}>
                  {chartName} <Check />
                </b>
              ) : (
                chartName
              )}
            </ListItemText>
          </MenuItem>
        );
      })
  );

  return (
    <Fragment>
      <InfoTooltip text={t('answer-message.change-visualization')}>
        <Button
          ref={buttonRef}
          onClick={handleMenuOpen}
          className="button-secondary"
          showOnlyIcon={isMobile}
          text={!hideButtonText ? t('answer-message.change-visualization') : undefined}
          data-test="changeVisualization"
          aria-label={t('change-visualization-title')}
        >
          <span className="icon-chart" />
        </Button>
      </InfoTooltip>
      <Menu
        anchorReference="anchorPosition"
        anchorPosition={anchorPosition}
        open={isOpen}
        onClose={() => {
          debouncedChangeVisualizationType.cancel();
          changeToVisualizationType(actualVisualizationType);
          setIsOpen(false);
          setAnchorPosition(null); // Reset position
        }}
      >
        {menuItems}
      </Menu>
    </Fragment>
  );
};

export default withTranslation('veezoo')(ChangeVisualizationButton);
