import { PENDING_STATUS, REJECTED_STATUS } from 'config/constants';
import { trackEvent } from 'utils/eventTracking';
import { askLocalQuestion } from 'store/modules/chat-messages';

import styles from './common-functions.scss';

import parse from 'html-react-parser';

export const isAwaitingApproval = shareableObject =>
  (shareableObject && shareableObject.userSharingStatus === PENDING_STATUS) || false;

export const wasRejected = shareableObject =>
  (shareableObject && shareableObject.userSharingStatus === REJECTED_STATUS) || false;

const handleSpecialLinkClick = (event, username, dispatch, t) => {
  event.preventDefault();
  event.stopPropagation();

  const question = event.currentTarget.innerHTML;
  const otherParams = {
    isQuestionPill: false,
    isLlmSuggestion: true,
    isSuggested: true,
    isVoice: false,
    linkedEntities: []
  };

  // Track the event
  trackEvent('Suggested Question Hyperlink Clicked', {
    username,
    suggestedQuestion: question
  });

  // Dispatch the action
  dispatch(askLocalQuestion(username, question, otherParams, t));
};

/**
 * Handles special tags in a chat message.
 * A special tag is a tag that needs to be formatted before it is rendered on the DOM.
 * By default, it handles the "<a href="#suggested-question" />" tag,
 * which we remove the "href" and add the "handleSpecialLinkClick" function within an "onClick" atribute.
 * Since "a" validation is default, the parameters "username", "dispatch" and "t" are mandatory.
 *
 * @param nodeString REQUIRED, The custom HTML content, formatted as "string".
 * @param username REQUIRED, The username of the user of the session
 * @param dispatch REQUIRED, The Redux dispatch function
 * @param t REQUIRED, The translation function
 * @param customModifications Object containing custom modifications set by user. Receives an array of objects containing two attributes:
 *  "nodeName": the tag name as string (a, div, span);
 *  "processModification": callback function where the first attribute "domNode" is an object with all node props.
 *  check below the input structure:
 *   const customModification = [
 *    {
 *       nodeName: 'div',
 *       processModification: (domNode) => {
 *           ** modifications here **
 *        }
 *     }
 *   ];
 */
export const handleReactTagsFromString = (nodeString, username, dispatch, t, customModifications) => {
  return parse(nodeString, {
    replace: domNode => {
      if (domNode.name === 'a' && domNode.attribs?.href === '#suggested-question') {
        delete domNode.attribs.href;
        domNode.attribs.onClick = event => handleSpecialLinkClick(event, username, dispatch, t);
        domNode.attribs.className = styles.suggestedQuestionLink;
      }

      const customMod = customModifications?.find(mod => mod.nodeName === domNode.name);
      if (customMod) {
        return customMod.processModification(domNode);
      }
    }
  });
};
