import React, { useEffect, useState } from 'react';
import Q2QTooltip from 'components/tooltips/Q2QTooltip';
import services from 'services';

import Skeleton from '@mui/material/Skeleton';

const TitleSkeleton = () => <Skeleton variant="rect" width={108} height={25} />;
const DescriptionSkeleton = () => (
  <>
    <br />
    <Skeleton variant="rect" width={180} height={15} />
    <div style={{ height: '8px' }} />
    <Skeleton variant="rect" width={180} height={15} />
  </>
);

const DescriptionTooltip = ({ suggestion, children, shouldOpen }) => {
  // go for the last passageInfo there is that has an id
  // e.g on How many Customers, we only get the infrom from "Customers"
  const lastPassageInfoWithIdOpt = suggestion?.passageInfos
    ?.slice()
    .reverse()
    .find(passage => passage.id);
  const [resourceInfo, setResourceInfo] = useState(null);
  const [loading, setLoading] = useState(false);

  // whenever a new suggestion comes in, we need to fetch more information about it from the API
  useEffect(() => {
    let timeoutId = null;
    async function fetchData() {
      if (!lastPassageInfoWithIdOpt) return;
      setLoading(true);
      const response = await services.fetchResource(lastPassageInfoWithIdOpt.id);
      const concept = response.data;
      // if this is not set, the whole tooltip will not be rendered
      setLoading(false);
      setResourceInfo(concept);
    }
    if (lastPassageInfoWithIdOpt) {
      // clear previous timer if new URI comes in before delay
      clearTimeout(timeoutId);
      // start new 500ms delay
      timeoutId = setTimeout(() => fetchData(), 100);
    }
    // this will clear the timeout if the component is unmounted or if the URI changes
    return () => clearTimeout(timeoutId);
  }, [lastPassageInfoWithIdOpt]);

  // Wrap the children in a tooltip if we have a description available
  if (resourceInfo?.description?.html) {
    // move the tooltip 20px more to the left
    return (
      <Q2QTooltip
        title={loading ? <TitleSkeleton /> : resourceInfo.name}
        description={loading ? <DescriptionSkeleton /> : resourceInfo.description.html}
        open={shouldOpen}
        placement="right"
        interactive={true} // this is needed to keep the tooltip open when hovering over it, e.g. if there are links in the description
        tooltipForAutocomplete={true}
        controlled
      >
        {children}
      </Q2QTooltip>
    );
  } else {
    return children;
  }
};

export default DescriptionTooltip;
