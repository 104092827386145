export const addCommaThousandSeparator = myNumber => myNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

/**
 * Parses a number from the chart data.
 *
 * A number from the chart data is either:
 *   - a string representing positive or negative infinity
 *   - a number (in which case no parsing is actually needed)
 *
 * For any other value, `Number.NaN` is returned.
 */
export const parseNumber = value => {
  switch (value) {
    case '∞':
      return Number.POSITIVE_INFINITY;
    case '-∞':
      return Number.NEGATIVE_INFINITY;
    default:
      try {
        // parse the value
        const number = JSON.parse(value);
        // use 'isFinite' to check if it is a number, else return NaN
        return Number.isFinite(number) ? number : Number.NaN;
      } catch (error) {
        return Number.NaN;
      }
  }
};

/**
 * Formats the specified number according to the specified number format and taking into account the optional
 * locale / options overrides.
 *
 * The formatting is implemented using an `Intl.NumberFormat` object:
 *   - it allows for very flexible / customized formatting and easy overriding of options
 *   - its options match closely the ones from Java's 'DecimalFormat' class, which the backend uses for number formatting
 *   - cf. https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
 *
 * This should maximize compatibility between the formatting of numbers in the frontend and backend.
 *
 * @param number the number to format
 * @param numberFormat the number format to use
 * @param localeOverride allows to override the locale passed to the `Intl.NumberFormat` object: by default, the locale
 *                       from the `numberFormat` is used
 * @param optionsOverrides allows to override any option passed to the `Intl.NumberFormat`: the default options are set
 *                         based on properties of the `numberFormat`
 * @return the formatted number
 */
export const formatNumber = (number, numberFormat, localeOverride, optionsOverrides) => {
  if (number === Number.POSITIVE_INFINITY) {
    return '∞';
  } else if (number === Number.NEGATIVE_INFINITY) {
    return '-∞';
  } else if (Number.isNaN(number) || number === null || number === undefined) {
    return '—';
  }
  const locale = localeOverride || numberFormat.data.locale;
  const options = {
    // we omit 'maximumIntegerDigits' here, because 'Intl.NumberFormat' doesn't support it
    minimumIntegerDigits: numberFormat.data.minimumIntegerDigits,
    minimumFractionDigits: numberFormat.data.minimumFractionDigits,
    maximumFractionDigits: numberFormat.data.maximumFractionDigits,
    useGrouping: numberFormat.data.isGroupingUsed,
    ...optionsOverrides
  };
  // for convenience: if the minimum is overridden, make sure the maximum is >= the minimum to avoid an error
  if (optionsOverrides?.minimumFractionDigits != null && optionsOverrides?.maximumFractionDigits == null) {
    options.maximumFractionDigits = Math.max(options.minimumFractionDigits, options.maximumFractionDigits);
  }
  // for convenience: if the maximum is overridden, make sure the minimum is <= the maximum to avoid an error
  if (optionsOverrides?.minimumFractionDigits == null && optionsOverrides?.maximumFractionDigits !== null) {
    options.minimumFractionDigits = Math.min(options.minimumFractionDigits, options.maximumFractionDigits);
  }
  try {
    const format = Intl.NumberFormat(locale, options);
    // format to parts instead of directly to a string: this allows us to manually override the decimal / grouping
    // separators, which cannot be customized via options
    const parts = format.formatToParts(number);
    return parts
      .map(part => {
        if (part.type === 'decimal') {
          return optionsOverrides?.decimalSeparator || numberFormat.data.decimalSeparator;
        } else if (part.type === 'group') {
          return optionsOverrides?.groupingSeparator || numberFormat.data.groupingSeparator;
        } else {
          return part.value;
        }
      })
      .join('');
  } catch (error) {
    console.warn(`Error formatting number ${number}: ${error.message}`);
    // fall back to formatting the number using the number format's locale
    return number?.toLocaleString?.(numberFormat?.data?.locale) || number;
  }
};

// expose the 'formatNumber' function s.t. it can be called from Highcharts' 'numberFormatter' function
window.veezoo = {
  ...window.veezoo,
  formatNumber: formatNumber
};
