import { dateViews, datetimeViews, dateComputations } from 'config/constants';

export const defaultValues = [
  {
    Year: new Date().getFullYear(),
    Month: 1,
    Day: 1,
    Hour: 0,
    Minute: 0,
    Second: 0
  }
];

export const formatGranularities = granularities => {
  granularities = granularities || [];
  const result = [];
  granularities.forEach(item => {
    const newItem = item.toLowerCase();
    if (newItem === dateViews.day || newItem === dateViews.date) {
      result.push(dateViews.week);
      if (newItem === dateViews.day) return result.push(dateViews.date);
    }
    return result.push(newItem);
  });

  return result;
};

export const formatDateFilterInitialView = values => {
  if (values) {
    let hasYear = false;
    let hasMonth = false;
    let hasDay = false;
    let hasWeek = false;
    let hasHour = false;

    values.forEach(value => {
      if (value.Year) hasYear = true;
      if (value.Month) hasMonth = true;
      if (value.Day) hasDay = true;
      if (value.Week) hasWeek = true;
      if (value.Hour) hasHour = true;
    });

    if (hasWeek) return datetimeViews.week;
    if (hasDay && hasMonth && hasYear) return datetimeViews.day_month_year;
    if (hasMonth && hasYear) return datetimeViews.month_year;
    if (hasYear) return datetimeViews.year;
    if (hasHour) return datetimeViews.hour_minute;
    return datetimeViews.day_month_year;
  }
  return datetimeViews.day_month_year;
};

export const formatDateModificationPayload = (data, modificationText, t) => {
  const computation = Object.values(dateComputations).find(item => item.value === data.selectedComputation);

  let questionMessage = '';
  const payload = [];

  data.values.forEach(({ outputFormat }, index) => {
    payload.push(outputFormat.payloadString);

    if (index > 0) {
      if (index < data.values.length - 1) {
        questionMessage += ',';
      } else {
        questionMessage += computation.value === dateComputations.BETWEEN.value ? ` ${t('and')}` : ` ${t('or')}`;
      }
    }

    questionMessage += ' ' + outputFormat.readableString;
  });

  const message = `${modificationText ? modificationText + ': ' : ''}${t('date')} ${t(
    `date-modifications.${computation.name}`
  )} ${questionMessage}`.trim();

  return {
    message,
    payload
  };
};
