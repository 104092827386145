import { get, post, put, del } from 'services/http';

const getRoles = () => get('/studio/api/roles');

const createRole = (name, description) => post('/studio/api/roles', { name, description });

const updateRole = (id, name, description) => put(`/studio/api/roles/${id}`, { name, description });

const deleteRole = id => del(`/studio/api/roles/${id}`);

const getUsersWithRole = roleId => get(`/studio/api/roles/${roleId}/users`);

const assignUsersToRole = (roleId, userIds) => put(`/studio/api/roles/${roleId}/users`, userIds);

const getRolesForKnowledgeGraph = knowledgeGraphId => get(`/studio/api/knowledge-graphs/${knowledgeGraphId}/roles`);

const updateRolesForKnowledgeGraph = (knowledgeGraphId, roleIds) =>
  put(`/studio/api/knowledge-graphs/${knowledgeGraphId}/roles`, roleIds);

export default {
  getRoles,
  createRole,
  updateRole,
  deleteRole,
  getUsersWithRole,
  assignUsersToRole,
  getRolesForKnowledgeGraph,
  updateRolesForKnowledgeGraph
};
