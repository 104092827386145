import React, { memo, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';

import { CustomModal, InfoTooltip } from 'components/v3';
import DownloadButton from 'components/buttons/DownloadButton';
import FollowUpButton from 'components/buttons/FollowUpButton';
import Button from 'components/buttons/Button';
import PrintButton from 'components/buttons/PrintButton';
import SmartActionButton from 'components/buttons/SmartActionButton';

import InputModal from 'components/modals/InputModal/InputModal';

import ShareAnswerForm from 'forms/ShareAnswerForm';

import useMediaQuery from 'utils/mediaQueries';

import withEmbedded from 'root/hocs/withEmbedded';

import { saveModes as saveModeConstants } from 'store/modules/chat-messages';
import { saveCustomerSelection } from 'store/modules/customers-of-the-day';

import classnames from 'classnames';
import styles from './answer-message-footer.scss';
import SaveDefinitionForm from 'forms/SaveDefinitionForm';
import { fetchGraph, fetchGraphLayout } from 'store/modules/graph/graph';
import { trackEvent } from 'utils/eventTracking';
import KnowledgeGraphIcon from 'svg/knowledge-graph.svg';
import CodeDialog from 'components/shared/CodeDialog';
import { isHighChartsType, layouts } from 'config/constants';
import ModifyVisualizationBlock from 'components/message/ModifyVisualizationBlock/ModifyVisualizationBlock';
import ChangeVisualizationButton from 'components/buttons/ChangeVisualizationButton';

const AnswerMessageFooter = ({
  addToBoard,
  showRawData,
  toggleShowRawData,
  toggleShowOpenInVeezooMapButton,
  veezooMapChartIsActive,
  showOpenInVeezooMapButton,
  changeToVisualizationType,
  answerId,
  followUpState,
  id,
  isEmbedded,
  tableIndex,
  sharedAnswer,
  question,
  targetRef,
  isSuperUserOrHigher,
  answer,
  partialAnswer,
  visualizationType,
  customVisualizationOptions,
  visualizations: outerVisualizations,
  onShareAnswerClick,
  hasWritePermission,
  dispatch,
  language,
  isLlmParserEnabled,
  t
}) => {
  const [state, setState] = useState({
    displayCustomerSelectionsModal: false,
    displayShareAnswerModal: false,
    displaySaveDefinitionModal: false
  });

  const [sqlDialogIsOpen, setSqlDialogIsOpen] = useState(false);
  const [vqlDialogIsOpen, setVqlDialogIsOpen] = useState(false);
  const [showModifyVisualization, setShowModifyVisualization] = useState(false);

  const isMobile = useMediaQuery();

  // if the visualization type changes, check if the new type is a highcharts type, otherwise hide the modify visualization
  useEffect(() => {
    setShowModifyVisualization(prevState => prevState && isHighChartsType(visualizationType));
  }, [visualizationType, showModifyVisualization]);

  const showCustomerSelectionModal = () => setState(prev => ({ ...prev, displayCustomerSelectionsModal: true }));

  const closeCustomerSelectionModal = () => setState(prev => ({ ...prev, displayCustomerSelectionsModal: false }));

  const showShareAnswerModal = () => {
    setState(prev => ({ ...prev, displayShareAnswerModal: true }));
    onShareAnswerClick();
  };

  const closeShareAnswerModal = () => setState(prev => ({ ...prev, displayShareAnswerModal: false }));

  const saveCustomerSelectionModal = name => {
    setState(prev => ({ ...prev, displayCustomerSelectionsModal: false }));
    dispatch(saveCustomerSelection({ name, interpretationId: answer.interpretationId }));
  };

  const showDefinitionModal = () => {
    trackEvent('New Definition Form Opened');
    setState(prev => ({ ...prev, displaySaveDefinitionModal: true }));
  };

  const closeDefinitionModal = () => {
    setState(prev => ({ ...prev, displaySaveDefinitionModal: false }));
    dispatch(fetchGraph());
    dispatch(fetchGraphLayout());
  };

  const { displayCustomerSelectionsModal, displayShareAnswerModal } = state;

  // answer contains the parsing result and partial answer contains the execution result, see also AnswerMessage
  const combinedAnswer = { ...answer, ...partialAnswer };
  const {
    visualizations: extractedVisualizations,
    interpretationId,
    partialAnswerId,
    saveModes,
    actions,
    possibleDefinitions,
    sql,
    vql
  } = combinedAnswer;
  const visualizations = outerVisualizations || extractedVisualizations || [];
  const shouldShowRawDataButton = !(tableIndex === 0 && visualizations?.length === 1) && visualizations?.length > 0;
  const shouldIncludeChooseVisualizationButton = shouldShowRawDataButton;
  const shouldIncludeExportButton = interpretationId?.length > 1;
  const shouldShowSqlButton = isSuperUserOrHigher && !!sql; // demo user can also see veezoo's generated sql
  const shouldShowVqlButton = isSuperUserOrHigher && !!vql;
  const shouldShowActionsButton = actions?.length > 0;
  const shouldShowPrintButton = !isMobile && visualizations?.length > 0;
  const shouldShowSaveDefinitionButton =
    possibleDefinitions?.length > 0 && (isSuperUserOrHigher && hasWritePermission && !isEmbedded);
  // Not that interesting when partial answer is not available yet
  const shouldShowShareButton = partialAnswer && !isEmbedded;

  // Show each smart action as its own button if 2 or less. Show dropdown otherwise.
  const smartActions = useMemo(() => {
    if (!actions || actions.length < 1) return null;
    if (actions.length < 3)
      return (
        <span>
          {actions.map((action, idx) => (
            <li className={styles.item} data-smartaction-tutorial={action.id} key={idx}>
              <SmartActionButton action={action} iconClassName="icon-bolt-highlighted" dispatch={dispatch} />
            </li>
          ))}
        </span>
      );
    return (
      <li className={styles.item} data-smartaction-tutorial={actions.map(a => a.id).join(' ')}>
        <Dropdown className="dropup-button-in-message" drop="up" as={ButtonGroup} id="action-dropdown" role="menuitem">
          <InfoTooltip text={t('tooltip.smart-action')}>
            <Dropdown.Toggle className="button-secondary" data-test="smartActionButton">
              <span className="icon-bolt-highlighted" />
              <span className="text">Smart Action</span>
            </Dropdown.Toggle>
          </InfoTooltip>

          <Dropdown.Menu>
            {actions.map((action, idx) => (
              <Dropdown.Item key={idx}>
                <SmartActionButton action={action} iconClassName="icon-external-link" dispatch={dispatch} />
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </li>
    );
  }, [actions, dispatch, t]);

  const displaySavingActions = useMemo(() => {
    if (saveModes.includes(saveModeConstants.CUSTOMER_SELECTION) && saveModes.includes(saveModeConstants.BOARD)) {
      // display 2 actions
      return (
        <div data-answer-tutorial="save-mode">
          <Dropdown
            className="dropup-button-in-message"
            drop="up"
            as={ButtonGroup}
            id="save-mode-dropdown"
            role="menuitem"
          >
            <InfoTooltip text={t('tooltip.save-mode')}>
              <Dropdown.Toggle className={`${'button-secondary'} ${styles.saveModeAction}`}>
                <span className="icon-plus-filled" />
                {!isMobile && <span className="text">{t('answer-message.save')}</span>}
              </Dropdown.Toggle>
            </InfoTooltip>

            <Dropdown.Menu>
              <Dropdown.Item eventKey="2">
                <InfoTooltip text={t('tooltip.save-to-customer-selections')}>
                  <Button
                    className={`${'button-secondary'} ${styles.saveToCustomerSelectionButton}`}
                    onClick={showCustomerSelectionModal}
                    data-test="addToCustomerSelections"
                    text={t('customer-selections.save')}
                  >
                    <span className="icon-people-group" />
                  </Button>
                </InfoTooltip>
              </Dropdown.Item>
              <Dropdown.Item eventKey="1">
                <InfoTooltip text={t('tooltip.add-to-board')}>
                  <Button
                    className="button-secondary"
                    onClick={addToBoard}
                    text={t('answer-message.add-to-board')}
                    data-test="addToBoardButton"
                  >
                    <span className="icon-add-dashboard" />
                  </Button>
                </InfoTooltip>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      );
    } else {
      // display only possibility to add widget to board
      return (
        <div data-answer-tutorial="add-to-board">
          <InfoTooltip text={t('tooltip.add-to-board')}>
            <Button
              className="button-secondary"
              onClick={addToBoard}
              text={t('answer-message.add-to-board')}
              data-test="addToBoardButton"
              showOnlyIcon={isMobile}
            >
              <span className="icon-add-dashboard" />
            </Button>
          </InfoTooltip>
        </div>
      );
    }
  }, [saveModes, showCustomerSelectionModal, t]);

  const visualizationTypes = visualizations?.map(v => v.type);

  return (
    <>
      <ModifyVisualizationBlock
        show={showModifyVisualization}
        visualizationType={visualizationType}
        originalVisualizationOptions={visualizations?.find(v => v.type === visualizationType)?.visualizationOptions}
        customVisualizationOptions={customVisualizationOptions} // either from widget or metaPartialAnswer
        partialAnswerId={answer.partialAnswerId}
        messageId={id}
        onAcceptChanges={() => setShowModifyVisualization(false)}
      />
      <aside className={styles.buttonsContainer}>
        <ul className={styles.list}>
          <li className={styles.item} data-answer-tutorial="followUp">
            <FollowUpButton
              data-test-answer-message="followUpButton"
              interpretationId={interpretationId}
              partialAnswerId={partialAnswerId}
              answerId={answerId}
              messageId={id}
              followUpState={followUpState}
              dispatch={dispatch}
            />
          </li>
          {shouldIncludeChooseVisualizationButton && (
            <li className={styles.item} data-answer-tutorial="change-visualization">
              <ChangeVisualizationButton
                changeToVisualizationType={changeToVisualizationType}
                visualizationTypes={visualizationTypes}
                setShowModifyVisualization={setShowModifyVisualization}
                currentVisualizationType={visualizationType}
                showModifyOption={isLlmParserEnabled}
                hideTableCharts={true}
                isModifyVisualizationBlockOpen={showModifyVisualization}
              />
            </li>
          )}
          {shouldShowRawDataButton && (
            <li className={styles.item} data-answer-tutorial="raw-table">
              <InfoTooltip text={t('tooltip.raw-data')}>
                <Button
                  className={classnames('button-secondary', { [styles.buttonActive]: showRawData })}
                  onClick={toggleShowRawData}
                  text={t('raw-data')}
                  showOnlyIcon={isMobile}
                  data-test="showRawDataButton"
                >
                  <span className="icon-table" />
                </Button>
              </InfoTooltip>
            </li>
          )}
          {shouldIncludeExportButton && (
            <li className={styles.item} data-answer-tutorial="download-excel">
              <DownloadButton
                partialAnswerOptions={[
                  {
                    partialAnswerId: answer.partialAnswerId,
                    visualizationType,
                    visualizationOptions: customVisualizationOptions
                  }
                ]}
                question={question}
              />
            </li>
          )}
          {visualizations?.length > 0 && <li className={styles.item}>{displaySavingActions}</li>}
          {shouldShowSaveDefinitionButton && (
            <li className={styles.item}>
              <InfoTooltip text={t('tooltip.save-as-definition')}>
                <Button
                  className={`${'button-secondary'} ${styles.saveDefinitionButton}`}
                  onClick={showDefinitionModal}
                  data-test="saveDefinitionFromAnswer"
                  showOnlyIcon={isMobile}
                  text={t('answer-message.save-as-definition')}
                >
                  <KnowledgeGraphIcon className={styles.iconColor} />
                </Button>
              </InfoTooltip>
            </li>
          )}
          {showOpenInVeezooMapButton && (
            <li className={styles.item}>
              <InfoTooltip text={t('tooltip.open-in-maps')}>
                <Button
                  className={classnames('button-secondary', { [styles.buttonActive]: veezooMapChartIsActive })}
                  text={t('tooltip.open-in-maps')}
                  showOnlyIcon={isMobile}
                  onClick={toggleShowOpenInVeezooMapButton}
                >
                  <span className="icon-maps-button" />
                </Button>
              </InfoTooltip>
            </li>
          )}
          {shouldShowPrintButton && (
            <li className={styles.item}>
              <PrintButton targetRef={targetRef} />
            </li>
          )}
          {shouldShowSqlButton && (
            <li className={styles.item} data-answer-tutorial="show-query">
              <InfoTooltip text={t('tooltip.query')}>
                <Button
                  className={classnames('button-secondary', { [styles.buttonActive]: sqlDialogIsOpen })}
                  onClick={() => setSqlDialogIsOpen(!sqlDialogIsOpen)}
                  text={t('query')}
                  showOnlyIcon={isMobile}
                >
                  <span className="icon-sql-file" />
                </Button>
              </InfoTooltip>
              <CodeDialog
                title="SQL Query"
                open={sqlDialogIsOpen}
                onClose={() => setSqlDialogIsOpen(!sqlDialogIsOpen)}
                code={sql}
                language="sql"
              />
            </li>
          )}
          {shouldShowVqlButton && (
            <li className={styles.item}>
              <InfoTooltip text={t('tooltip.vql')}>
                <Button
                  className={classnames('button-secondary', { [styles.buttonActive]: vqlDialogIsOpen })}
                  onClick={() => setVqlDialogIsOpen(!vqlDialogIsOpen)}
                  text="VQL"
                  showOnlyIcon={isMobile}
                >
                  <span className="icon-vql-file" />
                </Button>
              </InfoTooltip>
              <CodeDialog
                title="VQL"
                open={vqlDialogIsOpen}
                onClose={() => setVqlDialogIsOpen(!vqlDialogIsOpen)}
                code={vql}
                language="vql"
              />
            </li>
          )}
          {shouldShowActionsButton && smartActions}
          {shouldShowShareButton && (
            <li className={styles.item}>
              {' '}
              <InfoTooltip text={t('sharing.share-answer-modal.tooltip')}>
                <Button
                  className="button-secondary"
                  text={t('sharing.share')}
                  onClick={showShareAnswerModal}
                  data-test="shareAnswerButton"
                  showOnlyIcon={isMobile}
                >
                  <span className="icon-shared-board" />
                </Button>
              </InfoTooltip>
            </li>
          )}
        </ul>

        <InputModal
          title={t('customer-selections.modal-title')}
          defaultText={question || ''}
          open={displayCustomerSelectionsModal}
          confirmButtonText={t('create')}
          onConfirm={saveCustomerSelectionModal}
          onClose={closeCustomerSelectionModal}
        />

        <CustomModal
          layout={layouts.veezoo}
          title={t('sharing.share-answer-modal.title')}
          content={<ShareAnswerForm sharedAnswer={sharedAnswer} t={t} />}
          open={displayShareAnswerModal}
          onClose={closeShareAnswerModal}
          hideButtons
        />

        <CustomModal
          layout={layouts.veezoo}
          title={t('save-new-definition-title')}
          content={
            <SaveDefinitionForm
              language={language}
              possibleDefinitions={possibleDefinitions}
              onClose={closeDefinitionModal}
              t={t}
            />
          }
          open={state.displaySaveDefinitionModal}
          onClose={closeDefinitionModal}
          hideButtons
        />
      </aside>
    </>
  );
};

const mapStateToProps = (state, ownProps) => ({
  followUpState: state.followUpState,
  isSuperUserOrHigher: state.user.isSuperUser || state.user.isAdmin,
  language: state.user.language,
  isLlmParserEnabled: state.user.isLlmParserEnabled,
  sharedAnswer: state.sharing.sharedAnswers.find(s => s.partialAnswerId === ownProps?.answer?.partialAnswerId),
  hasWritePermission: state.knowledgeGraphMeta.meta.hasWritePermission
});

export default withTranslation('veezoo')(connect(mapStateToProps)(withEmbedded(memo(AnswerMessageFooter))));
