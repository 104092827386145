import { Component, createRef } from 'react';

/**
 * Wherever this element is put, on mount it's empty span will be scrolled into view.
 */
const styles = {
  height: '1px'
};

export default class ScrollOnMountElement extends Component {
  constructor(props) {
    super(props);
    this.scrollRef = createRef();
  }

  componentDidMount() {
    const messagesEnd = this.scrollRef.current;
    messagesEnd.scrollIntoView({
      // method scrolls the element on which it's called into the visible area of the browser window
      behavior: 'auto',
      block: 'end'
    });
  }

  render() {
    return <div ref={this.scrollRef} style={styles} />;
  }
}
