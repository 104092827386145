import { useState, useEffect } from 'react';
import { Pagination } from 'components/v3';

import BrowseBoardsSearchBar from './BrowseBoardsSearchBar';
import BrowseBoardsList from './BrowseBoardsList';
import LoadingIcon from 'components/loaders/LoadingIcon';

import { useViewStyles } from './BrowseBoards.styles';

import { browseBoardsSortingOptions, browseBoardsSubscribedOptions, layouts } from 'config/constants';
import services from 'services';

const rowsPerPageOptions = [5, 10, 15, 20];

const initialSearchState = {
  text: '',
  subscribed: browseBoardsSubscribedOptions.ALL_BOARDS.value,
  // by default, sort by sharing timestamp descending
  sorting: browseBoardsSortingOptions.SHARED_NEW_FIRST.value
};

const BrowseBoardsView = ({ user, boards, toggleSubscribe, isLoading, goToNextSlide, setSelectedBoardId, t }) => {
  const viewClasses = useViewStyles();

  // state for search component
  const [search, setSearch] = useState(initialSearchState);

  // state for pagination
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  // contains, indexed by board IDs, info objects related to board subscribers with the follwing properties:
  //   - 'loading': true while the subscribers are being retrieved
  //   - 'count': the number of subscribers of the board, set after the subscribers have been retrieved, or -1 if
  //              there was an error retrieving the subscribers
  //   - 'isEnabled': the enabled status of the board at the time of the retrieval of its subscribers
  const [subscribersInfos, setSubscribersInfos] = useState({});

  // contains, indexed by board IDs, the subscribers counts of the boards (or 'undefined' if currently being loaded,
  // or -1 if there was an error retrieving a board's subscribers)
  const subscribersCounts = Object.keys(subscribersInfos).reduce(
    (counts, boardId) => ({
      ...counts,
      [boardId]: subscribersInfos[boardId]?.count
    }),
    {}
  );

  // retrieves the subscribers count of the specified board and updates the 'subscribersInfos' state appropriately
  const getSubscribersCount = async board => {
    // set the loading flag and keep track of the board's enabled state
    setSubscribersInfos(infos => ({ ...infos, [board.id]: { loading: true, isEnabled: board.isEnabled } }));
    // retrieve the subscribers
    const response = await services.getSharedBoardSubscribers(board.id);
    // count the subscribers or fallback to -1 if there was an error
    const count = response.success ? response.data.length : -1;
    // unset the loading flag and store the count
    setSubscribersInfos(infos => ({ ...infos, [board.id]: { ...infos[board.id], loading: false, count } }));
  };

  // gets the subscribers counts for the boards: initially and when a board's enabled status has changed
  useEffect(() => {
    (async () =>
      await Promise.all(
        boards
          .filter(board => {
            // the board's subscribers info object (if any)
            const subscribersInfo = subscribersInfos[board.id];
            // keep the board if its subscribers haven't been retrieved yet...
            return (
              subscribersInfo === undefined ||
              // ...or if the board's enabled state has changed
              subscribersInfo.isEnabled !== board.isEnabled
            );
          })
          .map(board => getSubscribersCount(board))
      ))();
  }, [boards, subscribersInfos]);

  const isSearchMatch = (baseString = '', searchString = '') => {
    return baseString.toLowerCase().includes(searchString.toLowerCase());
  };

  // apply filtering according to search component
  const { text, subscribed } = search;
  const filteredBoards = boards.filter(board => {
    const isTextMatch =
      isSearchMatch(board.boardName, text) || (board.owner?.fullName && isSearchMatch(board.owner?.fullName, text));
    const isSelfOwned = board.owner?.id === user.id;
    const isSubscribedMatch =
      subscribed === browseBoardsSubscribedOptions.ALL_BOARDS.value ||
      (subscribed === browseBoardsSubscribedOptions.SUBSCRIBED.value && board.isEnabled && !isSelfOwned) ||
      (subscribed === browseBoardsSubscribedOptions.NOT_SUBSCRIBED.value && !board.isEnabled && !isSelfOwned) ||
      (subscribed === browseBoardsSubscribedOptions.SHARED_BY_YOU.value && isSelfOwned);
    return isTextMatch && isSubscribedMatch;
  });

  // apply sorting criterion
  const sortedBoards = (() => {
    switch (search.sorting) {
      case browseBoardsSortingOptions.NAME_ASC.value:
        return filteredBoards.sort((a, b) => a.boardName.localeCompare(b.boardName));
      case browseBoardsSortingOptions.NAME_DESC.value:
        return filteredBoards.sort((a, b) => b.boardName.localeCompare(a.boardName));
      case browseBoardsSortingOptions.CREATED_NEW_FIRST.value:
        return filteredBoards.sort((a, b) => b.createdOn - a.createdOn);
      case browseBoardsSortingOptions.CREATED_OLD_FIRST.value:
        return filteredBoards.sort((a, b) => a.createdOn - b.createdOn);
      case browseBoardsSortingOptions.SHARED_NEW_FIRST.value:
        return filteredBoards.sort((a, b) => b.sharedOn - a.sharedOn);
      case browseBoardsSortingOptions.SHARED_OLD_FIRST.value:
        return filteredBoards.sort((a, b) => a.sharedOn - b.sharedOn);
      case browseBoardsSortingOptions.SUBSCRIBERS_MOST_FIRST.value:
        return filteredBoards.sort((a, b) => subscribersCounts[b.id] - subscribersCounts[a.id]);
      case browseBoardsSortingOptions.SUBSCRIBERS_LEAST_FIRST.value:
        return filteredBoards.sort((a, b) => subscribersCounts[a.id] - subscribersCounts[b.id]);
      default:
        return filteredBoards;
    }
  })();

  // set result counter and pages
  const count = sortedBoards.length;
  const previous = page > 1 ? page - 1 : null;
  const next = page * rowsPerPage < count ? page + 1 : null;

  // apply pagination
  const start = (page - 1) * rowsPerPage;
  const end = start + rowsPerPage;
  const currentBoards = sortedBoards.length > 0 ? sortedBoards.slice(start, end) : [];

  const handlePageChange = newPage => setPage(newPage);

  const handleSearchChange = props => {
    // Everytime we change the search criteria, we reset the page to 1
    setPage(1);
    setSearch(props);
  };

  const handlePreviewClick = board => {
    setSelectedBoardId(board.boardId);
    goToNextSlide();
  };

  const onChangeRowsPerPage = event => {
    const value = event.target.value;
    setRowsPerPage(value);
  };

  return (
    <>
      <BrowseBoardsSearchBar values={search} onChange={handleSearchChange} t={t} />
      <div className={viewClasses.browseBoardsListContainer}>
        {isLoading ? (
          <div className={viewClasses.loadingContainer} data-test="browseBoardsLoading">
            <LoadingIcon size={20} color="var(--accent-dark-color)" />
          </div>
        ) : (
          <>
            <BrowseBoardsList
              user={user}
              boards={currentBoards}
              subscribersCounts={subscribersCounts}
              toggleSubscribe={toggleSubscribe}
              t={t}
              onPreviewClick={handlePreviewClick}
            />
            <div className={viewClasses.paginationContainer}>
              <Pagination
                count={count}
                page={page}
                next={next}
                previous={previous}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={rowsPerPageOptions}
                onChangeRowsPerPage={onChangeRowsPerPage}
                onChangePage={handlePageChange}
                layout={layouts.veezoo}
                t={t}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default BrowseBoardsView;
