import { Component } from 'react';
import ReactDOM from 'react-dom';

import SearchNoResultsResultsPanel from './SearchNoResultsResultsPanel';
import scrollIntoView from 'scroll-into-view-if-needed';
import { withTranslation } from 'react-i18next';

import styles from './search-results-panel.scss';

class SearchResultsPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showButton: true,
      graphSearchResults: this.props.graphSearchResults
    };
  }

  UNSAFE_componentWillMount() {
    document.addEventListener('mousedown', this.handleClickOutside.bind(this));
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.showMore.bind(this), false);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      graphSearchResults: nextProps.graphSearchResults,
      showButton:
        nextProps.graphSearchResults.entities &&
        nextProps.graphSearchResults.entities.results.length < nextProps.graphSearchResults.entities.resultCount
    });
    if (this.props.searchQueryText !== nextProps.searchQueryText) {
      this.refs.searchBlock.scrollTop = 0;
    }
    const container = ReactDOM.findDOMNode(this.refs['selectableElement' + nextProps.activeSelectableElement]);
    if (container) {
      scrollIntoView(container, {
        scrollMode: 'if-needed',
        block: 'nearest',
        inline: 'nearest'
      });
    }
  }

  handleClickOutside(event) {
    if (
      this.refs.searchContainer &&
      !this.refs.searchContainer.contains(event.target) &&
      !document.getElementById('searchBlock').contains(event.target)
    ) {
      this.props.closeSearchPanel();
      document.removeEventListener('mousedown', this.handleClickOutside);
    }
  }

  showMore(event) {
    if (this.refs.showMoreButton && this.refs.showMoreButton.contains(event.target)) {
      event.preventDefault();
      event.stopPropagation();
      this.props.getMoreData();
      document.getElementById('showMoreButton').removeEventListener('mousedown', this.showMore.bind(this), false);
    }
  }

  focusClassNode(classItem) {
    this.props.focusClassNode(classItem);
  }
  focusEntityNode(entity, node) {
    this.props.focusEntityNode({ entity, node });
  }
  focusLiteralNode(literal) {
    this.props.focusLiteralNode(literal);
  }
  focusMeasureNode(measure) {
    this.props.focusMeasureNode(measure);
  }
  indexElementLiteral(idx) {
    return (this.state.graphSearchResults.classes?.length || 0) + 1 + idx;
  }
  indexElementMeasure(idx) {
    return (
      (this.state.graphSearchResults.classes?.length || 0) +
      (this.state.graphSearchResults.literals?.length || 0) +
      1 +
      idx
    );
  }
  indexElementEntity(idx) {
    return (
      (this.state.graphSearchResults.classes?.length || 0) +
      (this.state.graphSearchResults.literals?.length || 0) +
      (this.state.graphSearchResults.measures?.length || 0) +
      1 +
      idx
    );
  }

  render() {
    const { graphSearchResults, showButton } = this.state;
    const { t } = this.props;

    return (
      <div className={styles.searchPanelOpened} ref="searchContainer">
        <div className={styles.searchPanelContainer}>
          <div className={styles.searchPanel} ref="searchBlock" tabIndex={-1}>
            {graphSearchResults.classes && (
              <div className={styles.searchPanelBlock}>
                <div className={styles.searchPanelTitle}>{this.props.t('Classes')}</div>
                {graphSearchResults.classes.map((classItem, idx) => {
                  let span;
                  if (classItem.highlightedText) {
                    span = <span dangerouslySetInnerHTML={{ __html: classItem.highlightedText }} />;
                  } else {
                    span = <span>{classItem.name}</span>;
                  }
                  return (
                    <div
                      id="ClassItemHook"
                      className={[
                        styles.classItem,
                        styles.selectableElement,
                        this.props.activeSelectableElement === idx + 1 ? styles.selectableElementActive : ''
                      ].join(' ')}
                      ref={'selectableElement' + (idx + 1)}
                      onClick={this.focusClassNode.bind(this, classItem)}
                      key={idx}
                    >
                      {span}
                    </div>
                  );
                })}
              </div>
            )}

            {graphSearchResults.literals && (
              <div className={styles.searchPanelBlock}>
                <div className={styles.searchPanelTitle}>{this.props.t('Literals')}</div>
                {graphSearchResults.literals.map((literalItem, idx) => {
                  let span;
                  if (literalItem.highlightedText) {
                    span = <span dangerouslySetInnerHTML={{ __html: literalItem.highlightedText }} />;
                  } else {
                    span = <span>{literalItem.name}</span>;
                  }
                  return (
                    <div
                      className={[
                        styles.classItem,
                        styles.selectableElement,
                        this.props.activeSelectableElement === this.indexElementLiteral(idx)
                          ? styles.selectableElementActive
                          : ''
                      ].join(' ')}
                      onClick={this.focusLiteralNode.bind(this, literalItem)}
                      ref={'selectableElement' + this.indexElementLiteral(idx)}
                      key={idx}
                    >
                      {span}
                    </div>
                  );
                })}
              </div>
            )}

            {graphSearchResults.measures && (
              <div className={styles.searchPanelBlock}>
                <div className={styles.searchPanelTitle}>{this.props.t('Measures')}</div>
                {graphSearchResults.measures.map((measureItem, idx) => {
                  let span;
                  if (measureItem.highlightedText) {
                    span = <span dangerouslySetInnerHTML={{ __html: measureItem.highlightedText }} />;
                  } else {
                    span = <span>{measureItem.name}</span>;
                  }
                  return (
                    <div
                      className={[
                        styles.classItem,
                        styles.selectableElement,
                        this.props.activeSelectableElement === this.indexElementMeasure(idx)
                          ? styles.selectableElementActive
                          : ''
                      ].join(' ')}
                      onClick={this.focusMeasureNode.bind(this, measureItem)}
                      ref={'selectableElement' + this.indexElementMeasure(idx)}
                      key={idx}
                    >
                      {span}
                    </div>
                  );
                })}
              </div>
            )}

            {graphSearchResults.entities && (
              <div className={showButton ? styles.searchPanelBlockEntities : styles.searchPanelBlock}>
                <div className={styles.searchPanelTitle}>
                  {t('showing-entities', {
                    foundCount: graphSearchResults.entities.results.length,
                    count: graphSearchResults.entities.resultCount
                  })}
                </div>
                {graphSearchResults.entities.results.map((entity, idx) => {
                  let entityName;
                  if (entity.highlightedText) {
                    entityName = <span dangerouslySetInnerHTML={{ __html: entity.highlightedText }} />;
                  } else {
                    entityName = <span>{entity.name}</span>;
                  }

                  let entityClass;
                  if (entity.class === undefined) {
                    entityClass = '-';
                  } else {
                    entityClass = <span> - {entity.class.name}</span>;
                  }

                  return (
                    <div
                      id="EntityItemHook"
                      className={[
                        styles.entityItem,
                        styles.selectableElement,
                        this.props.activeSelectableElement === this.indexElementEntity(idx)
                          ? styles.selectableElementActive
                          : ''
                      ].join(' ')}
                      key={idx}
                      ref={'selectableElement' + this.indexElementEntity(idx)}
                      onClick={this.focusEntityNode.bind(this, entity, entity.class)}
                    >
                      <span className={styles.entityName}>{entityName}</span>
                      <span className={styles.entityClass}>{entityClass}</span>
                    </div>
                  );
                })}

                {showButton && (
                  <button id="showMoreButton" ref="showMoreButton" className={styles.showMoreButton}>
                    {t('show-more')}
                  </button>
                )}
              </div>
            )}

            {graphSearchResults.isFetching &&
              !graphSearchResults.classes &&
              !graphSearchResults.literals &&
              !graphSearchResults.measures &&
              !graphSearchResults.entities && <SearchNoResultsResultsPanel />}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation('veezoo')(SearchResultsPanel);
