import { authFetchPost } from 'store/utils/authFetch';
import { url } from 'store/urls';

export const EXCEPTION_REQUEST = 'EXCEPTION_REQUEST';
export const EXCEPTION_SUCCESS = 'EXCEPTION_SUCCESS';

export function captureException(data) {
  return dispatch => {
    dispatch({
      type: EXCEPTION_REQUEST,
      payload: data
    });

    return authFetchPost(url.EXCEPTION, dispatch, data)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          dispatch({
            type: EXCEPTION_SUCCESS,
            payload: data
          });
          throw Error(response.statusText);
        }
      })
      .then(payload => {
        return null;
      })
      .catch(function(error) {
        return console.log('ERROR', error);
      });
  };
}

export const initException = {
  exceptions: [],
  isCaptchingException: false
};

export function exception(state = initException, action) {
  switch (action.type) {
    case EXCEPTION_REQUEST: {
      return {
        ...state,
        isCaptchingException: true
      };
    }

    default:
      return state;
  }
}
