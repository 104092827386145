import { Component, createRef, memo } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { boundMethod } from 'autobind-decorator';
import { withTranslation } from 'react-i18next';

import { fetchBoardsList, fetchBoardsView } from 'store/modules/board';
import { fetchDataForClass, messageTypes } from 'store/modules/chat-messages';
import { fetchKnowledgeGraphMeta } from 'store/modules/graph/knowledgeGraphMeta';
import { fetchExamples } from 'store/modules/discovery';
import { fetchLanguages } from 'store/modules/user';
import { fetchGraph, fetchGraphLayout, setEntitiesSidebar } from 'store/modules/graph/graph';

import Tutorial from 'components/tutorial/Tutorial';
import { tutorialNames } from 'config/constants';

import MobileDrawer from './MobileDrawer';
import Sidebar from 'components/sidebar/Sidebar';
import BodyContentSidebar from 'components/BodyContentSidebar';
import EntitiesSidebar from 'components/EntitiesSidebar';
import Settings from 'components/settings/Settings';
import Chat from 'components/chat/Chat';
import MessageComposer from 'components/message/MessageComposer';
import KnowledgeGraph from 'components/knowledgeGraph/KnowledgeGraph';
import Discovery from 'components/discovery/Discovery';
import CustomerSelections from 'components/customers/selections/CustomerSelections';
import QuestionTests from 'components/question-tests/QuestionTests';
import { ChatActiveContext } from 'components/chat/ChatActiveContext';
import SplitSidebarView, { dispatchResizeEvent } from 'components/split/SplitSidebarView';
import PageLoader from 'components/loaders/PageLoader';
import KnowledgeGraphSidebar from 'components/KnowledgeGraphSidebar';
import MobileHeader from './MobileHeader';

import ErrorBoundary from 'error-handler/ErrorBoundary';

import { trackEvent } from 'utils/eventTracking';

import styles from './home.scss';
import { searchGraph } from 'store/modules/graph/graphSearchResults';
import withEmbedded from 'root/hocs/withEmbedded';
import { KnowledgeGraphSidebarDrawerWidth } from 'components/KnowledgeGraphSidebar/KnowledgeGraphSidebar';
import { changeUrlGivenCurrentQueryParams } from 'components/LinkWithQuery';
import { fetchAnswerContextMenu } from 'store/modules/answerContextMenu';

const ContentSidebar = memo(({ isOpen, onClose }) => (
  <BodyContentSidebar open={isOpen} onClose={onClose}>
    <EntitiesSidebar />
  </BodyContentSidebar>
));

const shouldShowKgSidebar = props =>
  props.meta.hasKnowledgeGraphSupport && props.location.search.includes('kgSidebar=true');

const shouldShowVisualGraph = props => shouldShowKgSidebar(props) && props.location.search.includes('visualMode=true');

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      recentlyAddedBoard: this.props.recentlyAddedBoard,
      isQuestionFromURLAsked: false,
      showVisualGraph: false,
      showKgSidebar: false,
      isMobileMenuOpen: false,
      shouldScrollAutomatically: false,
      isScrolling: false
    };

    this.searchGraphTimeout = null;
    // Timeout to set `isScrolling` to false after the user has stopped scrolling
    this.scrollTimeout = null;

    this.containerRef = createRef();
  }

  handleScroll = () => {
    // The button should be displayed if the user has scrolled up at least this number of pixels
    const minimumScrollForButton = 100;
    const shouldScrollAutomatically =
      this.containerRef.current.scrollHeight - this.containerRef.current.clientHeight >
      this.containerRef.current.scrollTop + minimumScrollForButton;

    this.setState({ shouldScrollAutomatically });

    if (!this.state.isScrolling) {
      this.setState({ isScrolling: true });
    }

    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout);
    }

    // Consider scroll has ended after 200ms of inactivity
    this.scrollTimeout = setTimeout(() => {
      this.setState({ isScrolling: false });
    }, 200);
  };

  componentDidMount() {
    this.props.dispatch(fetchBoardsList());
    this.props.dispatch(fetchBoardsView());

    this.props.dispatch(fetchKnowledgeGraphMeta());
    this.props.dispatch(fetchExamples()); // fetch examples already for discovery
    this.props.dispatch(fetchGraph());
    this.props.dispatch(fetchGraphLayout());
    this.props.dispatch(fetchLanguages());

    this.setState({
      showKgSidebar: shouldShowKgSidebar(this.props),
      showVisualGraph: shouldShowVisualGraph(this.props)
    });

    /**
     * UGLY HACK ALERT!!
     * Set a global function to support visual context menu.
     *
     * @param selectedPoint is an array of point dimensions
     * [
     *     {
     *         "value": "Zürich",
     *         "candidateId": "Kanton_m32q"
     *     },
     *     {
     *         "value": 28216,
     *         "candidateId": "retCount_yYvT"
     *     }
     * ]
     * @param e         event to get target from, to know what to follow up on.
     */
    window.showAnswerContextMenu = (selectedPoint, e) => {
      // based on https://stackoverflow.com/a/34064434
      const unescapeHtml = input => new DOMParser().parseFromString(input, 'text/html').documentElement.textContent;
      // extract mouse click coordinates of the click from the event
      const { clientX, clientY } = e;
      console.log('showAnswerContextMenu', selectedPoint, clientX, clientY, e);
      // eslint-disable-next-line
      const veezooAnswerMessageDOM = $(e.target).parents('[data-interpretation-id]')[0];

      if (veezooAnswerMessageDOM && veezooAnswerMessageDOM.dataset) {
        const referenceTo = {
          interpretationId: veezooAnswerMessageDOM.dataset.interpretationId,
          partialAnswerId: veezooAnswerMessageDOM.dataset.partialAnswerId,
          answerId: veezooAnswerMessageDOM.dataset.answerId
        };
        // transform each x.value into a string (could be a string or a number), keep the rest of the object the same
        const referenceToPoint = selectedPoint.map(x => {
          // all strings in Highcharts plots are HTML-escaped (e.g. '&amp;'), which we undo here
          return { ...x, value: unescapeHtml(x.value.toString()) };
        });

        // dispatch a message containing the reference to the answer, the followUp snippets, and the mouse click coordinates
        this.props.dispatch(fetchAnswerContextMenu(referenceTo, referenceToPoint, { clientX, clientY }));
        trackEvent('Answer Context Menu Opened');
      }
    };
  }

  @boundMethod
  handleDrawerClose() {
    this.setState({ showVisualGraph: false });
    this.props.dispatch(setEntitiesSidebar({ isOpen: false }));
  }

  toggleMobileMenu = (isOpen = false) =>
    this.setState(prev => ({
      isMobileMenuOpen: isOpen || !prev.isMobileMenuOpen
    }));

  @boundMethod
  changeVisualModeTo(showVisualGraph) {
    this.setState({ showVisualGraph });
    changeUrlGivenCurrentQueryParams(this.props.history, showVisualGraph ? { visualMode: true } : { visualMode: null });
  }

  @boundMethod
  handleVisualGraphToggle() {
    this.changeVisualModeTo(!this.state.showVisualGraph);
    this.props.dispatch(setEntitiesSidebar({ isOpen: false }));
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.showVisualGraph && this.state.showVisualGraph) {
      trackEvent('KG Visual Mode Opened', { knowledgeGraphId: this.props.meta.id });
    }

    if (prevState.showVisualGraph && !this.state.showVisualGraph) {
      trackEvent('KG Visual Mode Hidden', { knowledgeGraphId: this.props.meta.id });
    }

    // if the user has sent a message, hide visual mode (and remove showDialogue param from url)
    if (prevProps.numberOfUserMessages !== this.props.numberOfUserMessages) {
      this.changeVisualModeTo(false);
      changeUrlGivenCurrentQueryParams(this.props.history, { showDialogue: null });
    }
    // check if location has changed and if the sidebar should be shown
    if (prevProps.location !== this.props.location) {
      this.setState({
        showKgSidebar: shouldShowKgSidebar(this.props),
        showVisualGraph: shouldShowVisualGraph(this.props)
      });
    }
    if (prevState.showKgSidebar !== this.state.showKgSidebar && this.props.isEmbedded) {
      // send a resize event to trigger rerenderings of highcharts, as we dynamically add paddings on the chat in embedded mode w/ kg sidebar
      dispatchResizeEvent();
    }
  }

  @boundMethod
  handleShowData(classUri) {
    this.props.dispatch(fetchDataForClass(classUri, this.props.t));
    this.props.dispatch(setEntitiesSidebar({ isOpen: false }));
  }

  @boundMethod
  handleSearch(e) {
    if (this.searchGraphTimeout) {
      clearTimeout(this.searchGraphTimeout);
    }
    // Delay search by 500ms
    this.searchGraphTimeout = setTimeout(
      (query, searchGraph, dispatch) => dispatch(searchGraph(query, 0)),
      500,
      e.target.value,
      searchGraph,
      this.props.dispatch
    );
  }

  closeEntitiesSidebar = () => this.props.dispatch(setEntitiesSidebar({ isOpen: false }));

  render() {
    const {
      showDiscovery,
      showChat,
      showTests,
      showSettings,
      selectedDiscoveryTopic,
      chatEntryPoint,
      graph,
      isEmbedded,
      graphSearchResults,
      meta,
      t,
      isOpenCustomerSelectionRoute,
      history
    } = this.props;

    const { showVisualGraph, showKgSidebar } = this.state;
    const adjustPaddingForEmbeddedSidebar =
      isEmbedded && showKgSidebar ? { paddingLeft: KnowledgeGraphSidebarDrawerWidth + 'px' } : { paddingLeft: 0 };
    const showChatComponent = showChat && !isOpenCustomerSelectionRoute;

    if (showKgSidebar && meta.isFetched && !meta.hasKnowledgeGraphSupport) {
      history.push('/chat');
    }

    const settingsComponent = (
      <div className={styles.bodyRight}>
        <Settings />
      </div>
    );

    const testComponent = (
      <div className={styles.bodyRight}>
        <div className={styles.tests}>
          <QuestionTests />
        </div>
      </div>
    );

    const { sidebarNodeLayouts, nodes, isLoadingGraph } = this.props.graph;
    const chatComponent = (
      <ChatActiveContext.Provider value={showChatComponent}>
        <div className={`${styles.chatContainer} ${showChatComponent && !showVisualGraph ? null : styles.hideChat}`}>
          <div
            className={styles.chat}
            ref={this.containerRef}
            onScroll={this.handleScroll}
            style={adjustPaddingForEmbeddedSidebar}
            id="ChatComponentHook"
          >
            <ErrorBoundary>
              <Chat
                isVisible={showChatComponent}
                containerRef={this.containerRef}
                isScrolling={this.state.isScrolling}
                shouldScrollAutomatically={this.state.shouldScrollAutomatically}
                chatEntryPoint={chatEntryPoint}
                isMobile={this.props.isMobile}
                enqueueSnackbar={this.props.enqueueSnackbar}
              />
            </ErrorBoundary>
          </div>
        </div>

        <div
          className={`${styles.messageComposer} ${showChatComponent ? null : styles.hideChat}`}
          style={adjustPaddingForEmbeddedSidebar}
        >
          <MessageComposer />
          <Tutorial />
        </div>
      </ChatActiveContext.Provider>
    );

    const knowledgeGraphComponent = (
      <div className={styles.chat} style={adjustPaddingForEmbeddedSidebar}>
        {graph && graph.isLoadingGraphLayout && <PageLoader message="Building Knowledge Graph..." />}
        {graph && !graph.isLoadingGraphLayout && graph.nodes && <KnowledgeGraph graph={graph} />}
      </div>
    );

    const discoveryComponent = <Discovery selectedTopicIndex={selectedDiscoveryTopic} isMobile={this.props.isMobile} />;

    const customerSelectionsComponent = (
      <div className={styles.customerSelections}>
        <CustomerSelections />
      </div>
    );

    let component;
    if (showSettings) {
      component = settingsComponent;
    } else if (showVisualGraph) {
      component = knowledgeGraphComponent;
    } else if (showTests) {
      component = testComponent;
    } else if (showDiscovery) {
      component = discoveryComponent;
    } else if (showChat && isOpenCustomerSelectionRoute) {
      component = customerSelectionsComponent;
    }

    const leftMenu = (
      <Sidebar
        showChat={showChat}
        showSettings={showSettings}
        showGraph={showKgSidebar}
        showDiscovery={showDiscovery}
      />
    );

    const pageContent = (
      <div className={styles.paneBodyRight}>
        {!isEmbedded && <ContentSidebar isOpen={graph.entitiesSidebar.isOpen} onClose={this.closeEntitiesSidebar} />}
        {/* A key allows the error to be removed if the component changes. */}
        <ErrorBoundary key={component}>{component}</ErrorBoundary>
        {/* IMPORTANT! Chat Component should be always in DOM because of performance problems */}
        {chatComponent}
      </div>
    );

    const kgSidebar = (
      <KnowledgeGraphSidebar
        knowledgeGraphId={this.props.meta.id}
        anchor="left"
        loading={isLoadingGraph}
        nodes={nodes}
        sidebarNodeLayouts={sidebarNodeLayouts}
        graphSearchResults={graphSearchResults}
        t={t}
        onClose={this.handleDrawerClose}
        onVisualGraphToggle={this.handleVisualGraphToggle}
        showVisualGraph={showVisualGraph}
        handleSearch={this.handleSearch}
        isEmbedded={isEmbedded}
        open={this.props.hasFetchedUser && showChatComponent && showKgSidebar}
        showData={this.handleShowData}
      />
    );

    return (
      <>
        <div className={styles.container} data-answer-tutorial="start">
          {!this.props.isMobile ? (
            <div className={styles.main}>
              <SplitSidebarView showKgSidebar={showKgSidebar}>
                {leftMenu}
                {pageContent}
              </SplitSidebarView>
              {kgSidebar}
            </div>
          ) : (
            <>
              <MobileHeader isMobileMenuOpen={this.state.isMobileMenuOpen} toggleMobileMenu={this.toggleMobileMenu} />
              <MobileDrawer open={this.state.isMobileMenuOpen} onClose={() => this.toggleMobileMenu(false)}>
                {leftMenu}
              </MobileDrawer>
              {pageContent}
            </>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  username: state.user.username,
  language: state.user.language,
  numberOfUserMessages: state.chatMessages.filter(message => message.type === messageTypes.USER).length,
  recentlyAddedBoard: state.board.recentlyAddedBoard,
  graph: state.graph,
  graphSearchResults: state.graphSearchResults,
  meta: state.knowledgeGraphMeta.meta,
  isOpenCustomerSelectionRoute: state.customerSelections.isOpenCustomerSelectionRoute,
  hasUncompletedTutorial: state.tutorial.uncompletedTutorials.some(tut => tut.id === tutorialNames.OVERVIEW_TUTORIAL),
  hasFetchedUser: state.user.hasFetchedUser
});

export default withTranslation('veezoo')(connect(mapStateToProps)(withEmbedded(withRouter(Home))));
