import React, { memo, useCallback, useEffect, useRef, useState } from 'react';

import MonacoEditor from 'react-monaco-editor';

import { useStyles } from './styles';

import services from 'services';
import Title from 'components/v2/Typography/Title';
import { withSnackbar } from 'notistack';
import { trackEvent } from 'utils/eventTracking';
import { vqlMonarchLanguageDefinition, vqlLanguageId, vqlTheme } from './VqlSyntaxHighlighter';

import { Button, TextField } from 'components/v3';

import { layouts } from 'config/constants';

/**
 * Form for saving new vql ground truth.
 */
const SaveVqlGroundTruthForm = ({ question, vql, onClose, t, enqueueSnackbar }) => {
  const { classes: styles } = useStyles();

  const inputRef = useRef(null);

  const [fields, setFields] = useState({
    question,
    // Identifier of the ground truth once successfully created
    createdVqlGroundTruthIdentifier: undefined
  });

  const [disabled, setDisabled] = useState(false);

  // the VQL to be edited
  const [vqlToEdit, setVqlToEdit] = useState(null);

  // sets the VQL initially
  useEffect(() => {
    const vqlContent = vql;
    const numberOfLines = vqlContent.split('\n').length;
    const minimumNumberOfLines = numberOfLines <= 10 ? 10 : 15;
    const linesToAdd = Math.max(1, minimumNumberOfLines - numberOfLines);
    const filledContent = vqlContent + '\n'.repeat(linesToAdd);
    setVqlToEdit(filledContent);
  }, []);

  const saveVqlGt = useCallback(async () => {
    if (!fields.question) {
      return;
    }
    setDisabled(true);

    const vqlToSave = editor
      .getModel()
      .getValue()
      .trim();

    await services
      .saveVqlGroundTruth(fields.question, vqlToSave)
      .then(response => {
        setFields(prev => ({ ...prev, createdVqlGroundTruthIdentifier: response.data }));
        enqueueSnackbar(t('save-new-vql-ground-truth.saved-new-vql', { question: fields.question }), {
          variant: 'info',
          autoHideDuration: 3000
        });
        trackEvent('New VQL Ground Truth Created', {
          question: fields.question,
          vql: vqlToSave
        });
      })
      .then(_ => onClose());
    setDisabled(false);
  }, [fields]);

  const handleInputQuestionChange = event => {
    const value = event.target.value;
    setFields(prev => ({ ...prev, question: value }));
  };

  // After the editor mounted we set its height according to the content.
  // This ensures that the displayed query is always shown vertically in full.
  // We arbitrarily set the min height to 100px.
  const editorDidMount = editor => {
    const contentHeight = Math.max(100, editor.getContentHeight());
    editor.layout({ height: contentHeight });
  };
  const editorWillMount = useCallback(monaco => {
    monaco.languages.register({ id: vqlLanguageId });
    monaco.languages.setMonarchTokensProvider(vqlLanguageId, vqlMonarchLanguageDefinition);
    monaco.editor.defineTheme('vqlTheme', vqlTheme);
  }, []);

  const onKeyDown = useCallback(
    event => {
      if (event.key === 'Enter') {
        // preventDefault necessary, otherwise the <button /> "+" icon that opens the modal would
        // re-trigger by default, causing the modal to re-open endlessly.
        event.preventDefault();
        saveVqlGt();
      }
    },
    [saveVqlGt]
  );

  const handleFocus = event => event.target.select();

  // Focus name field on opening modal or changing selected definition
  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.focus();
    }
  }, [fields]);

  const scrollbarSize = 6;

  return (
    <div className={styles.wideForm}>
      <div>
        <Title size="small" className={styles.title}>
          {t('save-new-vql-ground-truth.question')}
        </Title>
        <TextField
          layout={layouts.veezoo}
          multiline
          customClasses={{
            'MuiInputBase-multiline': {
              padding: 0
            }
          }}
          value={fields.question}
          ref={inputRef}
          onFocus={handleFocus}
          disabled={disabled}
          onChange={handleInputQuestionChange}
          onKeyDown={onKeyDown}
          error={!fields.question}
          InputProps={{ 'data-test': 'saveVqlGroundTruthFormOriginalQuestion' }}
        />
      </div>
      <div>
        <Title size="small" className={styles.title}>
          {t('save-new-vql-ground-truth.correct-vql')}
        </Title>
        {!!vqlToEdit && (
          <MonacoEditor
            theme="vqlTheme"
            disabled={disabled}
            language={vqlLanguageId}
            value={vqlToEdit}
            options={{
              selectOnLineNumbers: true,
              scrollBeyondLastLine: false,
              quickSuggestions: false,
              minimap: {
                enabled: false
              },
              contextmenu: false,
              automaticLayout: true,
              scrollbar: {
                useShadows: false,
                verticalScrollbarSize: scrollbarSize,
                horizontalScrollbarSize: scrollbarSize,
                alwaysConsumeMouseWheel: false
              }
            }}
            // We set this callback for resizing the editor to fit the content
            // See: https://github.com/microsoft/monaco-editor/issues/794
            editorDidMount={editorDidMount}
            editorWillMount={editorWillMount}
          />
        )}
      </div>

      <div className={styles.buttonContainer}>
        <Button
          layout={layouts.veezoo}
          disabled={disabled}
          mode="dark"
          fullWidth
          onClick={saveVqlGt}
          data-test="saveVqlGtConfirm"
        >
          {t('teach')}
        </Button>
      </div>
    </div>
  );
};

let editor = null;

export default withSnackbar(memo(SaveVqlGroundTruthForm));
