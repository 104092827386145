import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles({
  root: {
    pointerEvents: ({ allowBackgroundInteraction }) => (allowBackgroundInteraction ? 'none' : 'auto')
  },
  paper: {
    pointerEvents: 'auto',
    borderRadius: 6,
    backgroundColor: 'transparent',
    boxShadow: 'none',
    padding: 12
  }
});

export const useButtonContainerStyles = makeStyles({
  popoverButtonContainer: {
    maxHeight: '100%'
  }
});

export const useBackdropStyles = makeStyles({
  backdrop: {
    zIndex: 3,
    color: '#fff'
  }
});

export const getSx = ({ allowBackgroundInteraction }) => ({
  '&.MuiPopover-root': {
    pointerEvents: allowBackgroundInteraction ? 'none' : 'auto'
  },
  '& .MuiPopover-paper': {
    pointerEvents: 'auto',
    borderRadius: '6px',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    padding: '12px'
  }
});

export const backdropSx = {
  '&.MuiBackdrop-root': {
    zIndex: 3,
    color: '#fff'
  }
};
