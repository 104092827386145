import {
  dateTimeToObject,
  getWeekOfTheYear,
  getQuarterOfTheYear,
  formatDateToWeekString,
  formatYearAndQuarterToQuarterString
} from 'utils/datetimeUtils';
import { datetimeViews, dateViews } from 'config/constants';

export const selectableViews = {
  day_month_year: {
    value: datetimeViews.day_month_year,
    views: [dateViews.date, dateViews.month, dateViews.year],
    outputFormat: (datetime, { allowTime = false, t = i => i }) => {
      const date = dateTimeToObject(datetime);
      let payloadString = `${date.year}-${date.month}-${date.day}`;
      let readableString = `${date.day}/${date.month}/${date.year}`;
      if (allowTime) {
        payloadString += ` ${date.hour}:${date.minute}`;
        readableString += ` ${t('datetime-at')} ${date.hour}:${date.minute}`;
      }
      return { payloadString, readableString };
    }
  },
  month_year: {
    value: datetimeViews.month_year,
    views: [dateViews.month, dateViews.year],
    outputFormat: datetime => {
      const date = dateTimeToObject(datetime);
      return { payloadString: `${date.year}-${date.month}`, readableString: `${date.month}/${date.year}` };
    }
  },
  year: {
    value: datetimeViews.year,
    views: [dateViews.year],
    outputFormat: datetime => {
      const date = dateTimeToObject(datetime);
      return { payloadString: `${date.year}`, readableString: `${date.year}` };
    }
  },
  week: {
    value: datetimeViews.week,
    views: [dateViews.week],
    outputFormat: datetime => {
      const weekOfTheYear = getWeekOfTheYear(datetime);
      return {
        payloadString: formatDateToWeekString(weekOfTheYear.year, weekOfTheYear.week),
        readableString: formatDateToWeekString(weekOfTheYear.year, weekOfTheYear.week)
      };
    }
  },
  quarter: {
    value: datetimeViews.quarter,
    views: [dateViews.quarter],
    outputFormat: (datetime, { t = i => i }) => {
      const quarterOfTheYear = getQuarterOfTheYear(datetime);
      return {
        payloadString: formatYearAndQuarterToQuarterString(quarterOfTheYear.year, quarterOfTheYear.quarter),
        readableString: formatYearAndQuarterToQuarterString(quarterOfTheYear.year, quarterOfTheYear.quarter, t)
      };
    }
  },
  hour_minute: {
    value: datetimeViews.hour_minute,
    views: [dateViews.hour, dateViews.minute],
    outputFormat: datetime => {
      const date = dateTimeToObject(datetime);
      return {
        payloadString: `${date.hour}:${date.minute}`,
        readableString: `${date.hour}:${date.minute}`
      };
    }
  }
};
