import { useMemo } from 'react';
import styles from './index.module.scss';
import classnames from 'classnames';
import Checkbox from '@mui/material/Checkbox';
import ReactTableUI from 'components/chart/ReactTableUI';

export default function SampleRowsTable({ columns, checkColumn, loadingColumns, checkboxClasses }) {
  const reactTableColumns = useMemo(() => {
    return columns.map(column => {
      const cellClassName = column.alreadyImported
        ? styles.alreadyImported
        : column.checked
        ? styles.checkedColumn
        : '';
      return {
        // render the column header as title with a checkbox
        Header: () => (
          <div className={classnames(styles.columnHeader, cellClassName)}>
            <Checkbox
              classes={checkboxClasses}
              edge="start"
              tabIndex={-1}
              disableRipple
              size="small"
              onClick={event => event.stopPropagation()}
              onChange={() => checkColumn(column)}
              checked={column.alreadyImported || column.checked}
              disabled={column.alreadyImported}
            />
            {column.name}
          </div>
        ),
        // compute the width based of the length of the column name as well as the column sample values
        // A column can be at most 250px wide. 50px is the width of the checkbox and some padding.
        // A title character is on average 9px wide. A sample value character is on average 7px wide.
        width: Math.min(
          250,
          Math.max(50 + column.name.length * 10, ...column.sampleValues.map(value => value.toString().length * 8))
        ),
        accessor: column.name,
        name: column.name, // this is used by e.g. checkColumn function above
        alreadyImported: column.alreadyImported,
        checked: column.checked,
        className: cellClassName
      };
    });
  }, [columns]);

  // construct react table data based on the property sampleValues within the column object
  // make sure to work row by row and read out the corresponding sample value for each column
  const reactTableData = useMemo(() => {
    if (!columns?.at(0)?.sampleValues) return [];
    const data = [];
    for (let i = 0; i < columns[0]?.sampleValues.length; i++) {
      const row = {};
      for (let j = 0; j < columns.length; j++) {
        row[columns[j].name] = columns[j].sampleValues[i];
      }
      data.push(row);
    }
    return data;
  }, [columns]);

  const getColumnProps = reactTableColumn => {
    return {
      onClick: e => {
        e.stopPropagation(); // we don't want that e.g. the sorting triggers as well
        checkColumn(reactTableColumn);
      }
    };
  };

  return (
    <div className={styles.sampleRowsContainer}>
      <ReactTableUI
        columns={reactTableColumns}
        data={reactTableData}
        getColumnProps={getColumnProps}
        loading={loadingColumns}
        defaultPageSize={5}
        disableResizing={true}
      />
    </div>
  );
}
