import { get, post, patch } from 'services/http';

const shareAnswer = (partialAnswerId, visualizationType, visualizationOptions) =>
  post('/answers/shared', {
    partialAnswerId: partialAnswerId,
    visualizationType: visualizationType,
    visualizationOptions: visualizationOptions
  });

const uploadSharingPreview = (sharedAnswerId, image) =>
  patch(`/answers/shared/${sharedAnswerId}/image`, {
    base64Image: image
  });

const getSharedAnswer = sharedAnswerId => get(`/answers/shared/${sharedAnswerId}?sessionId=${window.veezoo.sessionId}`);

export default {
  shareAnswer,
  getSharedAnswer,
  uploadSharingPreview
};
