export const styles = theme => ({
  '&': {
    lineHeight: '23px'
  },
  '& h2': {
    fontSize: 15,
    fontWeight: 500
  },
  // Large images: fit to full width
  // Small images: keep original size, center horizontally
  '& img': {
    maxWidth: '100%',
    height: 'auto',
    display: 'block',
    margin: '0 auto'
  }
});

export default theme => ({
  root: {
    ...styles(theme)
  }
});
