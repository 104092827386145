import { makeStyles } from '@material-ui/core/styles';

export const defaultContainerHeight = 36;
const transitionTime = '0.3s';
const maxHeight = 142;

export default makeStyles({
  root: { color: 'var(--link-color)', width: 23, height: 23 },
  outerContainer: {
    height: defaultContainerHeight,
    position: 'relative',
    margin: '8px 15px',
    zIndex: 1
  },

  mainContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    overflowY: 'hidden',
    transition: `max-height ${transitionTime}`,
    minHeight: defaultContainerHeight,
    maxHeight: defaultContainerHeight,
    border: 'solid thin var(--light-gray)',
    overflow: 'hidden'
  },

  mainContainerExpanded: {
    maxHeight: maxHeight,
    boxShadow: '2px 2px 2px 0px rgba(0, 0, 0, 0.5)'
  },

  chipList: {
    boxSizing: 'border-box',
    padding: '5px',
    backgroundColor: 'white',
    width: '100%',
    maxHeight: maxHeight
  },

  chipListExpanded: {
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: 4
    }
  },

  tagContainer: {
    margin: '0px 2px 6px 2px',
    display: 'inline-block'
  },

  clear: {
    clear: 'both'
  },

  limitTag: {
    cursor: 'pointer',
    width: 30,
    height: 20,
    border: 'solid thin var(--light-gray)',
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    color: 'var(--link-color)',
    margin: 2,
    float: 'right',
    '&:hover': {
      backgroundColor: '#eeeeee'
    }
  }
});
