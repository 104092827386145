/**
 * The context path, which is extracted from the '<base>' tag, without trailing slash (e.g. '/veezoo' or '').
 */
export const contextPath = document
  .getElementsByTagName('base')[0]
  ?.getAttribute('href')
  .replace(/\/$/, '');

/**
 * The base URL to use for API requests, without trailing slash (e.g. '' or '/backend').
 */
export const baseURL = (window.veezoo?.baseUrl || '').replace(/\/$/, '');
