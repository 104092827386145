import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import { Button } from 'components/v3';
import Modal from 'components/Signup/Modal';

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import { layouts } from 'config/constants';

import styles from './index.module.scss';

const useInfoIconStyles = makeStyles({
  root: {
    color: 'var(--light-blue)',
    width: 40,
    height: 40
  }
});

const useSuccessIconStyles = makeStyles({
  root: {
    color: 'var(--light-blue)',
    width: 40,
    height: 40
  }
});

const useErrorIconStyles = makeStyles({
  root: {
    color: 'var(--error-color)',
    width: 40,
    height: 40
  }
});

const MessageModal = ({ message, onConfirm, open, closeModal, type }) => {
  const successIconClasses = useSuccessIconStyles();
  const errorIconClasses = useErrorIconStyles();
  const infoIconClasses = useInfoIconStyles();

  const renderIcon = useMemo(() => {
    if (type === 'success') return <CheckCircleOutlineIcon classes={successIconClasses} />;
    if (type === 'error') return <HighlightOffIcon classes={errorIconClasses} />;
    return <ErrorOutlineIcon classes={infoIconClasses} />;
  }, [type, successIconClasses, errorIconClasses, infoIconClasses]);

  return (
    <Modal open={open} closeModal={closeModal}>
      <div className={styles.errorModalBody}>
        {renderIcon}
        <div className={styles.errorModalMessage}>{message}</div>
        <Button layout={layouts.signup} onClick={onConfirm} mode="dark">
          OK
        </Button>
      </div>
    </Modal>
  );
};

export default memo(MessageModal);

MessageModal.propTypes = {
  message: PropTypes.any.isRequired,
  onConfirm: PropTypes.func,
  open: PropTypes.bool,
  closeModal: PropTypes.func,
  type: PropTypes.string
};
