import React, { memo, useCallback, useRef, useMemo, useState } from 'react';
import { modifyAnswer } from 'store/modules/chat-messages';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import DatetimeFilter from 'components/v2/DatetimeFilter/DatetimeFilter';

import Popover from '@mui/material/Popover';

import { formatGranularities, formatDateFilterInitialView, formatDateModificationPayload } from 'utils/dateFilterUtils';

import { answerModificationTypes } from 'config/constants';

import PassageSpan from 'components/shared/PassageSpan';

const Q2QEditableDatePassage = ({ filter, answerData, username, dispatch, t }) => {
  const [anchor, setAnchor] = useState(null);

  const updatePositionRef = useRef(null);

  const dateClassPassage = useMemo(() => {
    const passage = filter.passages.find(item => item.type === 'DATE_CLASS');
    return passage || { text: '' };
  }, [filter.passages]);

  const formattedDateFilterEdit = useMemo(() => {
    if (filter.outgoingDateFilterEdit.outgoingFilterComputationEditInfos) {
      let values = [];

      filter.outgoingDateFilterEdit.outgoingFilterComputationEditInfos.forEach(item => {
        values = [...values, ...item.values];
      });

      const result = {
        ...filter.outgoingDateFilterEdit.outgoingFilterComputationEditInfos[0],
        type: filter.outgoingDateFilterEdit.type,
        computationOrCandidateId: filter.outgoingDateFilterEdit.id,
        values
      };

      return result;
    }
    return {
      ...filter.outgoingDateFilterEdit,
      computationOrCandidateId: filter.outgoingDateFilterEdit.id
    };
  }, [filter.outgoingDateFilterEdit]);

  const granularities = useMemo(
    () => formatGranularities(formattedDateFilterEdit.granularities || formattedDateFilterEdit.granularity),
    [formattedDateFilterEdit.granularities]
  );

  const initialView = useMemo(() => formatDateFilterInitialView(formattedDateFilterEdit.values), [
    formattedDateFilterEdit?.values
  ]);

  const resetOptions = () => setAnchor(null);

  const onClose = () => resetOptions();

  const onConfirm = useCallback(
    async data => {
      onClose();

      const dateModificationText = t('date-modifications.modifyDate');

      const { message, payload } = formatDateModificationPayload(data, dateModificationText, t);

      const modificationPayload = {
        username,
        message,
        answerData,
        linkedEntities: filter.passages,
        type: formattedDateFilterEdit.type,
        id: formattedDateFilterEdit.computationOrCandidateId,
        modificationType: answerModificationTypes.datetime.value,
        computationName: data.selectedComputation,
        values: payload,
        t
      };

      await dispatch(modifyAnswer(modificationPayload));
      resetOptions();
    },
    [dispatch, answerData, formattedDateFilterEdit, t]
  );

  const passageSpan = useMemo(() => {
    const resultSpan = [];
    filter.passages.forEach((passage, index) => {
      if (passage?.children?.length > 0) {
        passage.children.forEach((child, childIndex) => {
          resultSpan.push(
            <PassageSpan
              passage={child}
              key={`date-passage-${index}-${childIndex}`}
              text={child.text}
              onClick={({ currentTarget }) => setAnchor(currentTarget)}
              testClass="editableFilterPillSpan"
            />
          );
        });
        return;
      }
      resultSpan.push(
        <PassageSpan
          passage={passage}
          key={`date-passage-${index}`}
          text={passage.text}
          onClick={({ currentTarget }) => setAnchor(currentTarget)}
          testClass="editableFilterPillSpan"
        />
      );
    });
    return resultSpan;
  }, [filter.passages]);

  return (
    <>
      <Popover
        open={!!anchor}
        anchorEl={anchor}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        action={updatePositionRef}
        disableAutoFocus={true}
        disableEnforceFocus={true}
      >
        <DatetimeFilter
          onConfirm={onConfirm}
          onCancel={onClose}
          values={formattedDateFilterEdit.values}
          title={dateClassPassage.text}
          updatePositionRef={updatePositionRef}
          views={granularities}
          initialComputation={formattedDateFilterEdit.computationName}
          initialView={initialView}
        />
      </Popover>
      {passageSpan}
    </>
  );
};

const mapStateToProps = state => ({ username: state.user.username });

export default memo(withTranslation('veezoo')(connect(mapStateToProps)(Q2QEditableDatePassage)));
