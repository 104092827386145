// INSTRUCTIONS:

// Every function that requires the Popover to be closed has to be added as an object inside "popoverClosingActionsFromChild" here in the PopoverButton component, instead of the child component.
// By doing this, we transfer the function to the child as props, but adding an "onClose" function together, so that your function will be executed normally and afterwards the Popover will be closed.
// Every other functions that to NOT require the Popover to be closed you can pass directly to the component.
// Example:

// <PopoverButton
//   button={<SomeButton />}
//   popoverClosingActionsFromChild={{
//   onConfirm: () => console.log('HANDLE CONFIRM'),
//   onClose: () => console.log('HANDLE CLOSE')
//   }}
// >
//   <ChildComponent />
// </PopoverButton>

// Can also be used as a controlled component, by passing the isOpen prop and setting it appropriately in the onOpened and onClosed callbacks.

import React, { useMemo, useState, useEffect, useRef, memo } from 'react';
import PropTypes from 'prop-types';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import Popover from '@mui/material/Popover';
import Backdrop from '@mui/material/Backdrop';
import Paper from '@mui/material/Paper';
import { useStyles, useBackdropStyles, useButtonContainerStyles } from './styles';

const PopoverButton = ({
  children,
  button: Button,
  buttonSelectedClassName,
  enableHover,
  updatePositionRef,
  popoverClosingActionsFromChild,
  allowBackgroundInteraction,
  forceOpen,
  onOpened,
  preventOpening,
  onClose,
  onClosed,
  delay,
  buttonProps,
  isEditing,
  transitionDuration,
  closeFromOutside,
  isOpen,
  ...props
}) => {
  const classes = useStyles({ allowBackgroundInteraction });
  const buttonContainerClasses = useButtonContainerStyles();
  const backdropClasses = useBackdropStyles();

  // the anchor element for the popover
  const [anchor, setAnchor] = useState(null);
  // specifies whether the popover is open or not (can be force to be open by the external 'forceOpen' property)
  // also overwritten by isOpen prop, which makes this a controlled component
  const [open, setOpen] = useState(false);

  const hoverTimeoutRef = useRef(null);

  // when "preventOpening" is false, combines the 'open' and 'forceOpen' properties to determine if the popover is actually open or not
  const isOpenComputed = useMemo(() => {
    if (isOpen !== undefined) return isOpen;
    if (preventOpening || !anchor) return false;
    return open || forceOpen;
  }, [isOpen, preventOpening, open, forceOpen, anchor]);

  // opens the popover and executes the callback (if any)
  const handleOpen = () => {
    setOpen(true);
    if (onOpened) {
      onOpened();
    }
  };

  const handleHover = () => {
    if (delay > 0) {
      hoverTimeoutRef.current = setTimeout(() => {
        handleOpen();
      }, delay);
    } else {
      handleOpen();
    }
  };

  const handleClose = () => {
    clearTimeout(hoverTimeoutRef.current);
    setOpen(false);
    if (onClosed) {
      onClosed();
    }
  };

  const handleHoverOut = () => {
    handleClose();
  };

  // reference callback for the button which sets the anchor element
  const buttonReference = button => {
    if (button) {
      setAnchor(button);
    }
  };

  // We can call the close function from outside by accessing the first parameter of the callback
  // in the "closeFromOutside" prop.
  useEffect(() => {
    if (closeFromOutside) {
      closeFromOutside(handleClose);
    }
  }, [closeFromOutside]);

  const formatClosingFunctions = useMemo(() => {
    const functions = Object.keys(popoverClosingActionsFromChild);
    const hasOnClose = functions.find(item => item === 'onClose');

    return functions.reduce(
      (total, current) => ({
        ...total,
        ...(!hasOnClose ? { onClose: handleClose } : {}),
        [current]: param => {
          handleClose();
          popoverClosingActionsFromChild[current](param);
        }
      }),
      {}
    );
  }, [popoverClosingActionsFromChild]);

  const childrenWithProps = useMemo(
    () =>
      React.Children.map(children, child => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { ...formatClosingFunctions });
        }
        return child;
      }),
    [children, formatClosingFunctions]
  );

  const handleClickAway = () => ((!enableHover || !!forceOpen) && !isEditing ? handleClose() : null);

  return (
    <div
      onMouseEnter={enableHover && !isEditing ? handleHover : () => {}}
      onMouseLeave={enableHover && !isEditing ? handleHoverOut : () => {}}
    >
      {React.cloneElement(Button, {
        ...buttonProps,
        ...(!enableHover ? { onClick: handleOpen } : {}),
        className: `${Button.props.className} ${isOpenComputed ? buttonSelectedClassName : ''}`,
        ref: buttonReference
      })}
      <Popover
        action={updatePositionRef}
        open={isOpenComputed}
        anchorEl={anchor}
        classes={classes}
        onClose={onClose}
        transitionDuration={transitionDuration}
        {...props}
      >
        {/* MUI's Popover has its own Paper, but we don't use it here because we need external padding, and margin wouldn't fill our need */}
        <Paper>
          <ClickAwayListener onClickAway={handleClickAway}>
            <div className={!enableHover ? buttonContainerClasses.popoverButtonContainer : ''}>{childrenWithProps}</div>
          </ClickAwayListener>
        </Paper>
      </Popover>
      <Backdrop className={backdropClasses.backdrop} open={isOpenComputed && isEditing} />
    </div>
  );
};

export default memo(PopoverButton);

PopoverButton.propTypes = {
  updatePositionRef: PropTypes.any,
  children: PropTypes.any,
  button: PropTypes.any,
  onClose: PropTypes.func,
  enableHover: PropTypes.bool,
  isEditing: PropTypes.bool,
  buttonSelectedClassName: PropTypes.string,
  popoverClosingActionsFromChild: PropTypes.object,
  allowBackgroundInteraction: PropTypes.bool,
  forceOpen: PropTypes.bool,
  preventOpening: PropTypes.bool,
  onOpened: PropTypes.func,
  onClosed: PropTypes.func,
  delay: PropTypes.number,
  buttonProps: PropTypes.object,
  transitionDuration: PropTypes.number,
  closeFromOutside: PropTypes.func,
  isOpen: PropTypes.bool
};

PopoverButton.defaultProps = {
  anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
  transformOrigin: { vertical: 'top', horizontal: 'center' },
  enableHover: false,
  isEditing: false,
  buttonSelectedClassName: '',
  popoverClosingActionsFromChild: {},
  allowBackgroundInteraction: false,
  forceOpen: false,
  preventOpening: false,
  buttonProps: {},
  delay: 0,
  transitionDuration: 0
};
