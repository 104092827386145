import makeStyles from '@mui/styles/makeStyles';
import { spacing } from 'studio/helpers';

export const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 600,
    margin: '0 -10px',
    '& .MuiFormControl-root': {
      marginTop: 0
    }
  },
  host: {
    width: 400,
    float: 'left'
  },
  port: {
    width: 200 - spacing(theme, 3),
    float: 'right'
  },
  ssh: {
    marginTop: theme.spacing(2)
  },
  buttonContainer: {
    display: 'flex',
    marginTop: theme.spacing(4)
  },
  buttonCell: {
    width: '100%',
    '& .MuiButton-root:first-child': {
      marginRight: theme.spacing(2)
    }
  },
  alignRight: {
    textAlign: 'right'
  },
  fieldContainer: {
    width: '100%',
    float: 'left',
    padding: '0 10px',
    boxSizing: 'border-box',
    marginBottom: '16px'
  }
}));

export const useIconStyles = makeStyles(theme => ({
  publicKeyIcon: {
    fill: !theme.isDark ? theme.palette.primary.main : 'white',
    cursor: 'pointer'
  }
}));
