import { authFetchGet } from 'store/utils/authFetch';

import { askQuestion } from 'store/modules/chat-messages';
import { fetchPartialAnswer } from './partialAnswers';

export const TESTS_REQUEST = 'TESTS_REQUEST';
export const TESTS_SUCCESS = 'TESTS_SUCCESS';
export const TESTS_FAILURE = 'TESTS_FAILURE';

export const TEST_QUESTION_REQUEST = 'TEST_QUESTION_REQUEST';
export const TEST_QUESTION_SUCCESS = 'TEST_QUESTION_SUCCESS';
export const TEST_QUESTION_FAILURE = 'TEST_QUESTION_FAILURE';

export const fetchTests = limit => {
  return dispatch => {
    dispatch({ type: TESTS_REQUEST });

    // get a list of boards
    authFetchGet('/teach/tests', dispatch)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          dispatch({ type: TESTS_FAILURE });
          throw Error(response.statusText);
        }
      })
      .then(result => {
        dispatch({ type: TESTS_SUCCESS, result, limit });
      })
      .catch(error => {
        dispatch({ type: TESTS_FAILURE });
        console.log('ERROR', error);
      });
  };
};

// we use this method to wait when running the tests until the previous one is finished
async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

export const runTest = (testId, questions, kgId, language) => {
  return async dispatch => {
    const testErrorHandler = error => {
      console.log('test error: ' + error);
      dispatch({ type: TEST_QUESTION_FAILURE, id: testId });
    };

    dispatch({ type: TEST_QUESTION_REQUEST, id: testId });
    let index = 0;
    let reference;
    asyncForEach(questions, async q => {
      const result = await askQuestion(q, { referenceTo: reference, language: language }, dispatch).catch(
        testErrorHandler
      );
      if (result && result.answer) {
        reference = {
          interpretationId: result.answer.interpretationId,
          answerId: result.answerId
        };
        index += 1;
        // last question was asked
        if (index === questions.length) {
          let partialAnswerResult = await fetchPartialAnswer(result.answer.interpretationId, result.answerId)(dispatch);
          if (partialAnswerResult?.answer?.isError !== true) {
            dispatch({ type: TEST_QUESTION_SUCCESS, result: result, id: testId });
          } else {
            // Passing partialAnswerResult?.answer would improve errors but wrongly
            dispatch({ type: TEST_QUESTION_FAILURE, result: partialAnswerResult?.answer, id: testId });
          }
        }
      } else {
        dispatch({ type: TEST_QUESTION_FAILURE, result: result, id: testId });
      }
    });
  };
};

const defaultTestState = {
  isLoading: false,
  tests: []
};

export const tests = (state = defaultTestState, action) => {
  let understoodAnswer;

  switch (action.type) {
    case TESTS_REQUEST:
      return {
        ...state,
        tests: [],
        isLoading: true
      };

    case TESTS_FAILURE:
      return {
        ...state,
        isLoading: false
      };

    case TESTS_SUCCESS:
      return {
        ...state,
        tests: action.result.slice(0, action.limit || action.result.length).map((t, index) => ({
          id: index,
          isRunning: false,
          ...t
        })),
        isLoading: false
      };

    case TEST_QUESTION_REQUEST:
      return {
        ...state,
        tests: state.tests.map(t => (t.id === action.id ? { ...t, isRunning: true } : t))
      };

    case TEST_QUESTION_SUCCESS:
      understoodAnswer = action?.result?.answer?.understood || 'no answer received';

      return {
        ...state,
        tests: state.tests.map(t => (t.id === action.id ? { ...t, isRunning: false, understood: understoodAnswer } : t))
      };

    case TEST_QUESTION_FAILURE:
      understoodAnswer = action?.result?.textAnswer || 'execution failed';

      return {
        ...state,
        tests: state.tests.map(t => (t.id === action.id ? { ...t, isRunning: false, understood: understoodAnswer } : t))
      };

    default:
      return state;
  }
};
