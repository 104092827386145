import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'components/v3';
import Modal from 'components/Signup/Modal';

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';

import { layouts } from 'config/constants';

import styles from './index.module.scss';

const defaultSx = { width: 40, height: 40 };

const iconSx = {
  info: {
    ...defaultSx,
    color: 'var(--light-blue)'
  },
  success: {
    ...defaultSx,
    color: 'var(--light-blue)'
  },
  error: {
    ...defaultSx,
    color: 'var(--error-color)'
  }
};

const MessageModal = ({ message, onConfirm, open, closeModal, type }) => {
  const renderIcon = useMemo(() => {
    if (type === 'success') return <CheckCircleOutlineOutlinedIcon sx={iconSx.success} />;
    if (type === 'error') return <HighlightOffOutlinedIcon sx={iconSx.error} />;
    return <ErrorOutlineOutlinedIcon sx={iconSx.info} />;
  }, [type]);

  return (
    <Modal open={open} closeModal={closeModal}>
      <div className={styles.errorModalBody}>
        {renderIcon}
        <div className={styles.errorModalMessage}>{message}</div>
        <Button layout={layouts.signup} onClick={onConfirm} mode="dark">
          OK
        </Button>
      </div>
    </Modal>
  );
};

export default memo(MessageModal);

MessageModal.propTypes = {
  message: PropTypes.any.isRequired,
  onConfirm: PropTypes.func,
  open: PropTypes.bool,
  closeModal: PropTypes.func,
  type: PropTypes.string
};
