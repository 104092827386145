import { tss } from 'tss-react';

export const useStyles = tss.create(({ isMobile }) => ({
  container: {
    ...(isMobile ? { marginLeft: '10px' } : { marginRight: '10px' }),
    paddingRight: '10px',
    width: 31,
    height: 31,
    '& .MuiButtonBase-root': {
      minWidth: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'var(--accent-normal-color)',
      borderRadius: '50%',
      '&:hover': {
        backgroundColor: 'var(--primary-color)',
        '& .MuiSvgIcon-root': {
          fill: 'white'
        }
      }
    },
    '& .MuiSvgIcon-root': {
      fill: 'var(--primary-color)'
    }
  },
  menuItemContainer: {
    display: 'flex',
    width: '100%'
  },
  menuItemIconBox: {
    display: 'flex',
    minWidth: 31,
    width: 31,
    height: 31,
    backgroundColor: 'var(--accent-normal-color)',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '10px'
  },
  menuItemIcon: {
    '& .MuiSvgIcon-root': {
      fill: 'var(--primary-color)'
    }
  },
  menuItemText: {
    lineHeight: '15px',
    width: '100%'
  },
  menuItemRadio: {
    '& .MuiRadio-root': {
      color: 'var(--primary-color)' // Default color for the radio button
    },
    '& .Mui-checked': {
      color: 'var(--primary-color)' // Color when the radio button is selected
    },
    '& .MuiCheckbox-root': {
      paddingRight: '0px',
      marginLeft: '10px'
    }
  },
  title: {
    fontSize: 14
  },
  description: {
    fontSize: 11,
    color: 'var(--dark-gray)',
    fontWeight: '300'
  },
  loading: {
    animation: `$spin 1s linear infinite`,
    width: 15,
    height: 15,
    '& path': {
      fill: 'var(--primary-color)'
    }
  },
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)'
    },
    '100%': {
      transform: 'rotate(360deg)'
    }
  }
}));

export const sx = {
  menuItem: {
    '&.MuiMenuItem-root': {
      padding: '6px 16px',
      display: 'flex'
    }
  },
  radio: {
    '&.PrivateSwitchBase-root': {
      padding: '9px'
    }
  }
};
