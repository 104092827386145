import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import styles from './loading.scss';
import SpinningInfinityLoader from './SpinningInfinityLoader';

const Loading = ({ isLocal }) => {
  const infinity = (
    <div className={clsx(styles.iconContainer, isLocal ? styles.local : '')}>
      <SpinningInfinityLoader />
    </div>
  );

  return <div className={clsx(styles.loadingContainer, isLocal ? styles.local : '')}>{infinity}</div>;
};

export default Loading;

Loading.propTypes = {
  isLocal: PropTypes.bool
};

Loading.defaultProps = {
  isLocal: false
};
