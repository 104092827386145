import { contextMenuOptionTypes } from 'components/AnswerContextMenu/AnswerContextMenu';
import { authFetchPost } from 'store/utils/authFetch';

export const BREAKDOWN_OPTIONS_REQUEST = 'BREAKDOWN_OPTIONS_REQUEST';
export const BREAKDOWN_OPTIONS_SUCCESS = 'BREAKDOWN_OPTIONS_SUCCESS';
export const SHOW_ANSWER_CONTEXT_MENU = 'SHOW_ANSWER_CONTEXT_MENU';
export const HIDE_ANSWER_CONTEXT_MENU = 'HIDE_ANSWER_CONTEXT_MENU';

export const fetchBreakdownOptions = (referenceTo, referenceToPoint) => {
  return dispatch => {
    dispatch({ type: BREAKDOWN_OPTIONS_REQUEST });

    authFetchPost('/breakdown-options', dispatch, {
      referenceTo,
      referenceToPoint,
      sessionId: window.veezoo.sessionId
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw Error(response.statusText);
        }
      })
      .then(json =>
        dispatch({
          type: BREAKDOWN_OPTIONS_SUCCESS,
          breakdowns: json
        })
      )
      .catch(function(error) {
        console.log('looks like there was a problem: \n', error);
      });
  };
};
export const fetchAnswerContextMenu = (referenceTo, referenceToPoint, location) => {
  return dispatch => {
    // for the backend, we must only send the point dimensions associated with a candidate
    const referenceToPointCandidatesOnly = referenceToPoint
      .filter(dimension => dimension.hasOwnProperty('candidateId'))
      .map(dimension => ({
        candidateId: dimension.candidateId,
        value: dimension.value
      }));
    dispatch(fetchBreakdownOptions(referenceTo, referenceToPointCandidatesOnly));
    dispatch({
      type: SHOW_ANSWER_CONTEXT_MENU,
      referenceTo,
      referenceToPoint,
      location
    });
  };
};

export const hideAnswerContextMenu = () => {
  return {
    type: HIDE_ANSWER_CONTEXT_MENU
  };
};

export const answerContextMenu = (state = null, action) => {
  switch (action.type) {
    case SHOW_ANSWER_CONTEXT_MENU:
      return {
        referenceTo: action.referenceTo,
        referenceToPoint: action.referenceToPoint,
        location: action.location
      };

    case HIDE_ANSWER_CONTEXT_MENU:
      return null;

    // todo needs to be more generic and this shouldn't be the place where the options are being set the first time
    case BREAKDOWN_OPTIONS_SUCCESS:
      return {
        ...state,
        options: [
          {
            type: contextMenuOptionTypes.BREAKDOWN_MENU,
            breakdowns: action.breakdowns.map(breakdown => ({
              ...breakdown,
              type: contextMenuOptionTypes.BREAKDOWN_ITEM
            }))
          },
          {
            type: contextMenuOptionTypes.POINT_FILTER
          }
        ]
      };

    default:
      return state;
  }
};
