import Form from './Form';

import services from 'services';

import { trackEvent } from 'utils/eventTracking';

// We need this wrapper component so that we keep the actual form "pure". This way we can use it in multiple places
// avoiding code repetition, as well as run it on Storybook (which cannot contain redux neither services);
// Basically all calls to the services and redux-related logic is managed here.

const KnowledgeGraphDBConnectionForm = ({
  knowledgeGraph,
  connection,
  databases,
  successMessage,
  errorMessage,
  withLoader,
  handleError,
  setKnowledgeGraph,
  postConnection: postConnectionCallback,
  deleteKnowledgeGraph,
  ...props
}) => {
  const fetchPublicKey = async () => {
    const result = await services.getPublicKey();
    if (!result.success) {
      errorMessage(result?.response?.data?.data?.message || 'There was an error while fetching Public Key.');
      return result;
    }
    return result;
  };

  // We iterate over the fields from the payload to check if they are sensitive through its attribute "obfuscate", and only the ones that are NOT
  // obfuscated are tracked. This way we remove the sensitive information such as "password" (or OAuthPvtKey from BigQuery).
  const filterPublicFields = data => {
    const databaseInfo = databases.find(db => db.id === data.databaseSystemId);
    const publicFields = {};

    Object.keys(data).forEach(keyField => {
      const dbField = databaseInfo.connectionProperties.find(item => item.id === keyField);
      if (dbField) {
        if (!dbField.obfuscate || dbField.obfuscate === 'false') {
          publicFields[keyField] = data[keyField];
        }
      }
    });

    return publicFields;
  };

  const testConnection = async data => {
    let result;
    if (!knowledgeGraph) {
      result = await withLoader(services.testDatabaseConnectionForNewKg(data), 'Testing connection...');
    } else {
      result = await withLoader(
        services.testDatabaseConnectionForExistingKg(knowledgeGraph.id, data),
        'Testing connection...'
      );
    }

    const publicFields = filterPublicFields(data);

    if (!result.success) {
      const message = result?.response?.data?.data?.message || 'There was an error with the connection.';
      errorMessage(message);

      trackEvent('Test Connection Failed', { error: { message }, connection: publicFields });
      return result;
    }

    trackEvent('Test Connection Success', { connection: publicFields });
    return { success: result.success, data: result.data };
  };

  const createKnowledgeGraph = async data => {
    const result = await withLoader(
      services.createKnowledgeGraph(data.knowledgeGraph.language, data.knowledgeGraph.name),
      'Creating new Knowledge Graph...'
    );
    handleError(result);
    if (!result.success) {
      const message = result?.response?.data?.data?.message;
      const publicFields = filterPublicFields(data);
      trackEvent('Knowledge Graph Creation Failed', {
        knowledgeGraph: publicFields,
        ...(message ? { error: { message } } : {})
      });
      errorMessage(message || 'There was an error while creating new Knowledge Graph.');
      return result;
    }
    return result;
  };

  const bindDatabaseToKnowledgeGraph = async data => {
    const result = await withLoader(
      services.bindDatabaseToKnowledgeGraph(data),
      `${connection ? 'Updating' : 'Creating'}  database connection...`
    );
    handleError(result);
    if (!result.success) {
      const message =
        result?.response?.data?.data?.message ||
        `There was an error while ${connection ? 'updating' : 'creating'} database to Knowledge Graph.`;
      trackEvent('Database Connection Failed', {
        connector: { id: data.databaseSystemId },
        knowledgeGraph: { id: data.id },
        error: { message }
      });
      errorMessage(message);
      return result;
    }
    trackEvent(`Database Connection ${connection ? 'updated' : 'created'}`, {
      connector: { id: data.databaseSystemId },
      knowledgeGraph: { id: data.id }
    });
    return result;
  };

  const handleSave = async data => {
    let currentKnowledgeGraph;

    if (!knowledgeGraph) {
      const newKnowledgeGraph = await createKnowledgeGraph(data);

      if (!newKnowledgeGraph.success) {
        return newKnowledgeGraph;
      }

      currentKnowledgeGraph = newKnowledgeGraph.data.data;
      setKnowledgeGraph(currentKnowledgeGraph);
    } else {
      currentKnowledgeGraph = knowledgeGraph;
    }

    const bindingPayload = { ...data, id: currentKnowledgeGraph.id };
    const bindingResult = await bindDatabaseToKnowledgeGraph(bindingPayload);
    if (!bindingResult.success) {
      return;
    }

    if (!knowledgeGraph) {
      // Load the KG anew with the connection information
      const knowledgeGraphResult = await services.getKnowledgeGraph(currentKnowledgeGraph.id);
      if (!knowledgeGraphResult.success) {
        return;
      }
      setKnowledgeGraph(knowledgeGraphResult.data.data);
    }

    if (window.veezoo?.signupVariables?.databaseCallback) {
      window.veezoo?.signupVariables?.databaseCallback();
    }

    postConnectionCallback();
  };

  return (
    <Form
      connection={connection}
      databases={databases}
      testConnection={testConnection}
      postConnection={handleSave}
      deleteKnowledgeGraph={deleteKnowledgeGraph}
      fetchPublicKey={fetchPublicKey}
      successMessage={successMessage}
      knowledgeGraph={knowledgeGraph}
      {...props}
    />
  );
};

export default KnowledgeGraphDBConnectionForm;

KnowledgeGraphDBConnectionForm.defaultProps = {
  handleError: () => {}
};
