import { memo, useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import Logo from 'components/logo/Logo';
import Button from 'components/buttons/Button';

import MenuOpenIcon from '@mui/icons-material/MenuOpen';

import { useTranslation } from 'react-i18next';

import useMediaQuery from 'utils/mediaQueries';
import { colorIsLightOrDark } from 'utils/colorUtils';

import styles from 'components/sidebar/sidebar.scss';

const SidebarHeader = ({ themeName, handleSwitchModes, setIsMinimized, isMinimized, showGraph }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery();
  const headerRef = useRef();

  const iconFill = useMemo(() => {
    if (!headerRef?.current) return null;

    const elementStyles = getComputedStyle(headerRef.current);
    const colorBrightness = colorIsLightOrDark(elementStyles['background-color']);

    if (colorBrightness === 'dark') return 'white';

    return 'var(--primary-color)';
  }, [headerRef?.current]);

  const iconSx = useMemo(
    () => ({
      '&.MuiSvgIcon-root': {
        fill: `${isMinimized ? 'white' : iconFill} !important`,
        // rotate the icon when the sidebar is collapsed
        transform: isMinimized ? 'rotate(180deg)' : 'rotate(0deg)'
      }
    }),
    [iconFill, isMinimized]
  );

  return (
    <div className={clsx(styles.headerContainer, themeName)} ref={headerRef} id="sidebar-header">
      <div className={styles.logoFull}>
        <Link onClick={handleSwitchModes} to="/chat">
          <Logo displayFullVersion alt="Home" />
        </Link>
      </div>
      {!isMobile && (
        <Button
          // in case we are minimized and someone adds a widget, we show the tutorial
          data-addwidget-tutorial={isMinimized ? 'sidebar-collapsed' : undefined}
          className={`button-primary ${styles.openSidebarHeaderButton}`}
          onClick={() => setIsMinimized(!isMinimized)}
          disabled={showGraph}
          title={isMinimized ? t('sidebar.show-sidebar') : t('sidebar.hide-sidebar')}
        >
          <MenuOpenIcon sx={iconSx} fontSize="large" />
        </Button>
      )}
    </div>
  );
};

export default memo(SidebarHeader);
