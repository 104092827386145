import { useEffect, useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';

import Skeleton from '@mui/material/Skeleton';

import styles from './content-loader.scss';
import LinearProgress from '@mui/material/LinearProgress';

import { Button } from 'components/v3';
import { trackEvent } from 'utils/eventTracking';
import Cancel from '@mui/icons-material/Cancel';
import CircularProgress from '@mui/material/CircularProgress';

const linearProgressSx = {
  '&.MuiLinearProgress-root': {
    height: 10,
    border: 'solid 1px var(--accent-light-color)',
    borderRadius: 5
  },
  '&.MuiLinearProgress-colorPrimary': {
    backgroundColor: 'inherit'
  },
  '& .MuiLinearProgress-bar': {
    borderRadius: 5,
    backgroundColor: 'var(--accent-light-color)'
  }
};

const lightButtonCss = {
  root: {
    border: 'solid thin var(--accent-very-light-color)',
    borderRadius: 10,
    fontSize: '0.9rem',
    height: '22px',
    padding: '10px 10px',
    color: 'var(--button-secondary-color)',
    minWidth: 'auto'
  }
};

const ContentLoader = ({ t, isAnswerToQuestion, onCancel }) => {
  const [progress, setProgress] = useState(0);
  // track time spent as well
  const [secondsPassed, setSecondsPassed] = useState(0);
  const intervalTimeInSeconds = 1;
  const intervalTimeInMs = intervalTimeInSeconds * 1000;
  const [loadingMessage, setLoadingMessage] = useState(t('loading-bar.default'));
  const containerRef = useRef(null);
  const containerHeight = containerRef?.current?.clientHeight;

  const [showCancelButton, setShowCancelButton] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);

  useEffect(() => {
    // Show cancel button after 5 seconds to reduce visual noise, accidental misclicks & showing it when it's not needed.
    const cancelButtonTimer = setTimeout(() => {
      setShowCancelButton(true);
    }, 5000);

    return () => {
      clearTimeout(cancelButtonTimer);
    };
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      // we keep track of time to show different messages
      setSecondsPassed(oldTime => oldTime + intervalTimeInSeconds);
      setProgress(oldProgress => {
        if (oldProgress >= 99) {
          return 99;
        } else {
          // choose a random number between 3 and 12
          const k = Math.floor(Math.random() * 10) + 3;
          // what is missing to 100
          const missing = 100 - oldProgress;
          // how much we can add
          const add = Math.min(missing / k, 10);
          // add it
          return oldProgress + add;
        }
      });
    }, intervalTimeInMs);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    setLoadingMessage(oldMessage => {
      if (secondsPassed > 240) {
        // shouldn't happen, because we expect the backend to timeout by then
        return t('loading-bar.default');
      } else if (secondsPassed > 200) {
        // start a countdown until the backend times out to manage user's expectations
        return t('loading-bar.almost-timeout', { seconds: 240 - secondsPassed });
      }
      // if the time is between 10 and 200 seconds, we show a random message every 10 seconds
      else if (secondsPassed >= 10) {
        const numberOfGeneralMessages = 3;
        const numberOfAnswerMessages = 2;
        const generalMessages = Array.from(Array(numberOfGeneralMessages).keys()).map(idx =>
          t('loading-bar.still-loading-' + idx)
        );
        const answerMessages = Array.from(Array(numberOfAnswerMessages).keys()).map(idx =>
          t('loading-bar.still-loading-answer-' + idx)
        );
        const messagesToChooseFrom = isAnswerToQuestion ? [...generalMessages, ...answerMessages] : generalMessages;

        if (secondsPassed % 10 === 0) {
          // take a random one
          return messagesToChooseFrom[Math.floor(Math.random() * messagesToChooseFrom.length)];
        } else {
          return oldMessage;
        }
      } else {
        return oldMessage;
      }
    });
  }, [secondsPassed]);

  // create Skeleton with var(--accent-light-color) background and no animation
  const skeletonClasses = {
    '&.MuiSkeleton-root': {
      backgroundColor: 'var(--accent-light-color)',
      animation: 'none'
    }
  };

  // if the container is too small, we don't show the skeleton
  // if it is an answer to a question, we assume we have enough space until we get more information
  const heightNeeded = 255;
  // in the smallest widget size we don't show the text, so we can still see the cancel button
  const isInSmallestWidget = containerHeight < 150;
  const skeleton = (containerHeight >= heightNeeded || (!containerHeight && isAnswerToQuestion)) && (
    <>
      <div className={styles.line}>
        <Skeleton variant="circular" width="45%" height="100%" sx={skeletonClasses} />
        <Skeleton variant="rectangular" width="45%" height="100%" sx={skeletonClasses} />
      </div>
      <div className={styles.line}>
        <Skeleton variant="rectangular" width="100%" height="70%" sx={skeletonClasses} />
      </div>
    </>
  );

  // If a user interacts with the content loader show the cancel button directly
  const handleHover = () => {
    if (!showCancelButton) {
      setShowCancelButton(true);
    }
  };

  const handleOnCancel = () => {
    if (isCancelling) return;
    setIsCancelling(true);
    trackEvent('Query Cancel Button Clicked');
    onCancel();
  };

  return (
    <div ref={containerRef} className={styles.container} onMouseEnter={handleHover}>
      {skeleton}
      <div className={styles.loading}>
        <LinearProgress sx={linearProgressSx} variant="determinate" thickness={10} value={progress} />
        <div className={styles.text}>
          {!isInSmallestWidget && loadingMessage}
          {onCancel && showCancelButton && (
            <div style={{ paddingTop: isInSmallestWidget ? '0' : '9px' }}>
              <Button
                layout="veezoo"
                size="small"
                classes={lightButtonCss}
                onClick={handleOnCancel}
                icon={isCancelling ? <CircularProgress size={16} color="inherit" /> : <Cancel fontSize="small" />}
              >
                {t('cancel')}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default withTranslation('veezoo')(ContentLoader);
