import { get } from 'services/http';

const getLanguages = () => get('/studio/api/localization/languages');

const getNumberFormats = () => get('/studio/api/localization/number-formats');

export default {
  getLanguages,
  getNumberFormats
};
