import { tss } from 'tss-react';

export const useSliderStyles = tss.create({
  container: {
    height: '100%',
    position: 'relative',
    boxSizing: 'border-box',
    overflowX: 'hidden'
  },
  content: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    overflowY: 'auto',
    overflowX: 'hidden',
    boxSizing: 'border-box'
  }
});
