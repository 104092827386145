import React, { Component, Fragment, createRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { boundMethod } from 'autobind-decorator';
import PropTypes from 'prop-types';

import { studioRoutes } from 'components/app/routes';
import PageLoader from 'components/loaders/PageLoader';

import { Button } from 'components/v3';

import DiscoveryDetail from './DiscoveryDetail';

import List from '@mui/material/List';
import EditIcon from '@mui/icons-material/Edit';

import { askLocalQuestion } from 'store/modules/chat-messages';
import { generateDiscoveryExamples } from 'store/modules/discovery';

import { trackEvent } from 'utils/eventTracking';
import withEmbedded from 'root/hocs/withEmbedded';

import { layouts } from 'config/constants';

import styles from './discovery.scss';

export class Discovery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTopicIndex: -1,
      shouldScrollAutomatically: false,
      isScrolling: false
    };

    this.containerRef = createRef();

    this.scrollTimeout = null;
  }

  componentDidMount() {
    if (
      !(this.props.discovery.topics && this.props.discovery.topics?.length > 0) &&
      this.props.shouldGenerateDiscoveryIfEmpty
    ) {
      this.props.dispatch(generateDiscoveryExamples(this.props.kgId));
    }
  }

  handleScroll = () => {
    // Scroll should be automatic if the user has scrolled up at least this number of pixels
    const minimumScroll = 100;
    const shouldScrollAutomatically =
      this.containerRef.current.scrollHeight - this.containerRef.current.clientHeight >
      this.containerRef.current.scrollTop + minimumScroll;

    this.setState({ shouldScrollAutomatically });

    if (!this.state.isScrolling) {
      this.setState({ isScrolling: true });
    }

    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout);
    }

    // Consider scroll has ended after 200ms of inactivity
    this.scrollTimeout = setTimeout(() => {
      this.setState({ isScrolling: false });
    }, 200);
  };

  @boundMethod
  handleQuestionClick(question) {
    const otherParams = {
      isSuggested: true,
      disableFollowUp: true
    };

    this.props.dispatch(askLocalQuestion(this.props.username, question, otherParams, this.props.t));
  }

  @boundMethod
  setTopicIndex(index) {
    this.setState({ selectedTopicIndex: index });
  }

  redirectToStudio = () => {
    trackEvent('Jump to Studio Button Clicked', {
      kgId: this.props.kgMeta.id,
      filePath: this.props.discovery.filePath,
      vklQualifiedIdentifier: undefined
    });

    const link = `${studioRoutes.editor}?id=${this.props.kgMeta.id}&file=${this.props.discovery.filePath}`;
    window.open(link, '_blank');
  };

  createTile(title, index) {
    return (
      <div
        key={index}
        className={styles.linkStyle}
        onClick={() => this.setTopicIndex(index)}
        data-test="discoveryTopic"
      >
        <div className={styles.tile}>{title}</div>
      </div>
    );
  }

  overviewPage() {
    const { discovery } = this.props;
    const { topics } = discovery;
    const categories = topics.map(e => e.category).filter((cat, i, a) => a.indexOf(cat) === i); // unique categories

    const categoryContainers = categories.map((cat, i) => {
      const tiles = topics.filter(e => e.category === cat).map(e => this.createTile(e.title, topics.indexOf(e)));

      return (
        <Fragment key={'cat_' + i}>
          <div className={styles.tileCategoryContainer}>
            <h2 className={styles.tileCategoryTitle}>{cat}</h2>
            <div className={styles.tileContainer}>{tiles}</div>
          </div>
        </Fragment>
      );
    });

    return (
      <div className={styles.categoriesContainer}>
        <List disablePadding={true}>{categoryContainers}</List>
      </div>
    );
  }

  @boundMethod
  handleShowOverviewCallback() {
    this.setState({ selectedTopicIndex: -1 });
  }

  render() {
    const { discovery } = this.props;
    const { selectedTopicIndex } = this.state;

    let content;
    if (discovery.isGeneratingExamples) {
      content = (
        <div className={styles.loadingContainer}>
          <PageLoader message={this.props.t('generating-discovery')} />
        </div>
      );
    } else if (selectedTopicIndex === -1 || !(discovery.topics && discovery.topics[selectedTopicIndex])) {
      content = (
        <>
          {this.props.discovery.filePath && this.props.isSuperUserOrHigher && !this.props.kgMeta.isDemo && (
            // Position on the top right, and on top of topics in the overview page
            <div style={{ position: 'absolute', top: '10px', right: '10px', zIndex: 1 }}>
              <Button
                layout={layouts.veezoo}
                noBorders
                onClick={this.redirectToStudio}
                iconSize="20px"
                iconPosition="right"
                icon={<EditIcon />}
                // if we are not allowed to see a knowledge graph, we render it but not visible
                // this is to prevent the buttons from jumping around
                classes={{
                  root: {
                    visibility: this.props.hasKnowledgeGraphSupport ? 'visible' : 'hidden'
                  }
                }}
              >
                {this.props.t('edit-in-studio')}
              </Button>
            </div>
          )}
          {this.overviewPage()}
        </>
      );
    } else {
      content = (
        <DiscoveryDetail
          discovery={discovery}
          selectedTopicIndex={selectedTopicIndex}
          showOverviewCallback={this.handleShowOverviewCallback}
          isMobile={this.props.isMobile}
          containerRef={this.containerRef}
          isScrolling={this.state.isScrolling}
          shouldScrollAutomatically={this.state.shouldScrollAutomatically}
        />
      );
    }

    return (
      <div className={styles.discovery} ref={this.containerRef} onScroll={this.handleScroll}>
        {content}
      </div>
    );
  }
}

Discovery.propTypes = {
  discovery: PropTypes.object,
  username: PropTypes.string,
  kgId: PropTypes.string,
  shouldGenerateDiscoveryIfEmpty: PropTypes.bool,
  isMobile: PropTypes.bool,
  isEmbedded: PropTypes.bool,
  hasKnowledgeGraphSupport: PropTypes.bool,
  history: PropTypes.object,
  kgMeta: PropTypes.object,
  isSuperUserOrHigher: PropTypes.bool,
  t: PropTypes.func
};

const mapStateToProps = state => {
  return {
    discovery: state.discovery,
    username: state.user.username,
    kgId: state.knowledgeGraphMeta.meta.id,
    hasKnowledgeGraphSupport: state.knowledgeGraphMeta.meta.hasKnowledgeGraphSupport,
    kgMeta: state.knowledgeGraphMeta.meta,
    shouldGenerateDiscoveryIfEmpty: state.knowledgeGraphMeta.meta.hasWritePermission && state.user.isLlmParserEnabled,
    isSuperUserOrHigher: state.user.isSuperUser || state.user.isAdmin
  };
};

export default withTranslation('veezoo')(connect(mapStateToProps)(withRouter(withEmbedded(Discovery))));
