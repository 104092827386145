export const colorIsLightOrDark = color => {
  let red, green, blue;

  if (color.match(/^rgb/)) {
    // If HEX --> store the red, green, blue values in separate variables
    color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

    red = color[1];
    green = color[2];
    blue = color[3];
  } else {
    // If RGB --> Convert it to HEX
    color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'));

    red = color >> 16;
    green = (color >> 8) & 255;
    blue = color & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(0.299 * (red * red) + 0.587 * (green * green) + 0.114 * (blue * blue));

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) return 'light';
  return 'dark';
};

/**
 * Returns a CSS expression in rgba(...) format for a hex color with an alpha value
 *
 * @param {string} hexColor the color in a hex format, e.g. "#d2e3c3"
 * @param {number} alpha the opacity of the color, between 0.0 (fully transparent) and 1.0 (fully opaque)
 * @returns {`rgba(${number}, ${number}, ${number}, ${string})`}
 */
export const getRgba = (hexColor, alpha) => {
  // extract the primary colors, and convert from hexadecimal to integer
  // e.g. "#d2e3c3" -> "d2", "e3", "c3" -> 210, 227, 195
  const red = parseInt(hexColor.slice(1, 3), 16);
  const green = parseInt(hexColor.slice(3, 5), 16);
  const blue = parseInt(hexColor.slice(5, 7), 16);
  return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
};
