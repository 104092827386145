import { tss } from 'tss-react';

const divisionBorder = 'solid thin var(--light-gray)';

export const useStyles = tss.create({
  tableContainer: {
    position: 'relative',
    fontSize: '1.2rem',
    '& table': {
      width: '100%',
      borderCollapse: 'collapse',
      '& thead': {
        '& tr': {
          borderBottom: divisionBorder
        }
      },
      '& tbody': {
        '& tr:not(:last-child)': {
          borderBottom: divisionBorder
        }
      },
      '& th, td': {
        textAlign: 'left'
      },
      '& th': {
        cursor: 'pointer'
      }
    },
    '& tr': {
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.03)'
      },
      transition: '0.4s',
      transitionTimingFunction: 'ease-out',
      height: '0px'
    }
  },
  rowClickable: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  isExpanded: {
    height: '200px'
  },
  iconCell: {
    width: '46px',
    textAlign: 'right',
    '& i': {
      cursor: 'pointer',
      '&:hover': {}
    }
  },
  pagination: {
    borderTop: divisionBorder,
    padding: '16px',
    textAlign: 'right'
  },
  emptyTableMessage: {
    padding: '12px',
    textAlign: 'center',
    color: 'var(--dark-gray)'
  },
  hasPadding: {
    padding: '8px 10px'
  },
  checkboxCell: {
    width: '15px'
  },
  left: {
    textAlign: 'left'
  },
  center: {
    textAlign: 'center'
  },
  right: {
    texAlign: 'right'
  },
  view: {
    '& i': {
      color: 'var(--primary-color)'
    }
  },
  edit: {
    '& i': {
      color: 'var(--primary-color)'
    }
  },
  delete: {
    '& i': {
      color: 'var(--red-color)'
    }
  }
});

export const usePaginationStyles = () => ({
  signup: {},
  veezoo: {
    fontSize: '12px',
    '& .MuiSelect-select': {
      marginRight: '8px',
      padding: '9px 30px 10px 9px',
      border: 'solid thin var(--light-gray)',
      borderRadius: '4px',
      height: '32px',
      boxSizing: 'border-box',
      fontSize: '12px'
    },
    '& .MuiSelect-icon': {
      top: '1px',
      bottom: '0',
      right: '13px',
      margin: 'auto',
      width: '13.5px',
      height: '13.5px',
      justifyContent: 'flex-start',
      alignItems: 'end',
      fontSize: '12px',
      '& > path': {
        fill: ({ disabled }) => (disabled ? 'var(--disabled-color)' : 'var(--primary-color)')
      }
    },
    '& .MuiTablePagination-selectLabel': {
      fontSize: '12px',
      textTransform: 'capitalize',
      margin: 0
    },
    '&  .MuiTablePagination-displayedRows': {
      fontSize: '12px',
      margin: 0
    },
    '& .MuiTablePagination-actions': {
      marginLeft: '0px !important'
    },
    '& .MuiTablePagination-input': {
      marginRight: '0'
    },
    '& .MuiIconButton-root': {
      padding: '0',
      marginLeft: '8px',
      border: 'solid thin var(--light-gray)',
      borderRadius: '4px',
      width: '30px',
      height: '30px',
      boxSizing: 'border-box',
      '& .MuiSvgIcon-root': {
        fontSize: '20px'
      }
    }
  },
  default: {}
});

export const useMenuListStyles = () => ({
  signup: {},
  veezoo: {
    '& .MuiMenuItem-root': {
      fontSize: '12px !important',
      display: 'flex !important'
    }
  },
  default: {}
});
