import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  fontSize: {
    fontSize: '14px'
  },
  requiredText: {
    color: 'red',
    fontWeight: '500'
  },
  nodeIcon: {
    color: 'var(--primary-color)',
    width: '14px',
    height: '14px'
  },
  nodeName: {
    color: 'var(--primary-color)',
    fontWeight: '500',
    marginLeft: '5px',
    verticalAlign: 'middle'
  },
  highlightedText: {
    color: 'var(--primary-color)',
    fontWeight: '500'
  },
  subtitle: {
    fontSize: '12px',
    fontWeight: '400',
    color: 'rgba(0, 0, 0, 0.54)'
  },
  subtitleContent: {
    '& span': {
      verticalAlign: 'middle'
    }
  }
});
