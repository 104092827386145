import { baseURL } from 'config/baseURL';
import { get, post, put } from 'services/http';

const getSamlConfiguration = () => get('/studio/api/saml/configuration');

const replaceSamlConfiguration = samlConfiguration => put('/studio/api/saml/configuration', samlConfiguration);

const serviceProviderMetadataUrl = (baseURL + '/studio/api/saml/sp-metadata').replace(/^\//, '');

const getServiceProviderMetadata = () => get('/studio/api/saml/sp-metadata');

const testSamlConfiguration = samlConfiguration => post('/studio/api/saml/configuration/test', samlConfiguration);

export default {
  getSamlConfiguration,
  replaceSamlConfiguration,
  serviceProviderMetadataUrl,
  getServiceProviderMetadata,
  testSamlConfiguration
};
