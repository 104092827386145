import { fetchGet } from 'services/http';
import { handleUnauthorized } from 'store/utils/authFetch';

import { CHANGE_LITERAL_SUCCESS } from './literalInfo';
import { CHANGE_MEASURE_SUCCESS } from './measureInfo';

export const SEARCH_GRAPH_REQUEST = 'SEARCH_GRAPH_REQUEST';
export const SEARCH_GRAPH_SUCCESS = 'SEARCH_GRAPH_SUCCESS';
export const SEARCH_GRAPH_CLEAR = 'SEARCH_GRAPH_CLEAR';
export const SEARCH_GRAPH_FAILURE = 'SEARCH_GRAPH_FAILURE';

const SEARCH_LIMIT = 20;

export const fetchGraphSearchedItems = (searchResult, scrollCount, query, type) => {
  return {
    type,
    searchResult,
    query,
    scrollCount
  };
};

const makeCancelable = promise => {
  let hasCanceled = false;

  const wrappedPromise = new Promise((resolve, reject) =>
    promise.then(
      result => (hasCanceled ? reject(new Error('isCanceled')) : resolve(result)),
      error => (hasCanceled ? reject(new Error('isCanceled')) : reject(error))
    )
  );

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled = true;
    }
  };
};

let knowledgeGraphSearch;

export const searchGraph = (query, scrollCount) => {
  const start = scrollCount === 0 ? '&start=0' : '&start=' + SEARCH_LIMIT * scrollCount;
  const limit = '&limit=' + SEARCH_LIMIT;

  return dispatch => {
    dispatch({ type: SEARCH_GRAPH_REQUEST, query });

    if (knowledgeGraphSearch) {
      knowledgeGraphSearch.cancel();
      knowledgeGraphSearch = undefined;
    }

    if (query) {
      knowledgeGraphSearch = makeCancelable(
        fetchGet('/knowledge-graph/search?query=' + encodeURIComponent(query) + start + limit)
      );
      knowledgeGraphSearch.promise
        .then(response => {
          if (response.ok) {
            return response.json();
          } else if (response.status === 401) {
            handleUnauthorized(dispatch);
          } else {
            dispatch({
              type: SEARCH_GRAPH_FAILURE
            });
            throw Error(response.statusText);
          }
        })
        .then(result => {
          dispatch(fetchGraphSearchedItems(result, scrollCount, query, SEARCH_GRAPH_SUCCESS));
        })
        .catch(error => console.log('ERROR', error));
    } else {
      dispatch(fetchGraphSearchedItems({}, 0, query, SEARCH_GRAPH_CLEAR));
    }
  };
};

const initialState = { query: '' };
// reducer which describes search for graph
export const graphSearchResults = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_GRAPH_CLEAR:
      return { query: action.query };

    case SEARCH_GRAPH_REQUEST:
      return {
        ...state,
        query: action.query,
        isSearching: true // TODO: what was value duting initial state
      };

    case SEARCH_GRAPH_SUCCESS: {
      let prevRes = { query: action.query };

      if (action.scrollCount === 0) {
        state = [];
        prevRes = {
          ...action.searchResult,
          isFetching: true, // where is variable defined? in a board reducer?
          query: action.query,
          isSearching: false
        };
      } else {
        Object.assign(prevRes, state);
        // prevRes = JSON.parse(JSON.stringify(state))
        prevRes.entities.results = prevRes.entities.results.concat(action.searchResult.entities.results);
      }
      return prevRes;
    }

    case CHANGE_LITERAL_SUCCESS: {
      if (!state.literals) return {};
      const fItems = {};
      Object.assign(fItems, state);
      fItems.literals = state.literals.map(
        res =>
          (res.name === action.prevLabel && {
            uri: res.uri,
            name: action.literal.name,
            type: res.dataType
          }) ||
          res
      );
      return fItems;
    }

    case CHANGE_MEASURE_SUCCESS: {
      if (!state.measures) return {};
      const fItems = {};
      Object.assign(fItems, state);
      fItems.measures = state.measures.map(
        res =>
          (res.name === action.prevLabel && {
            uri: res.uri,
            name: action.measure.name,
            type: res.dataType
          }) ||
          res
      );
      return fItems;
    }

    default:
      return state;
  }
};
