import { tss } from 'tss-react';

export const useStyles = tss.create({
  selectContainer: {
    marginTop: '15px'
  },
  buttonContainer: {
    marginTop: 10,
    display: 'flex',
    alignItems: 'end',
    fontSize: '14px'
  }
});
