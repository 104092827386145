const containerHeight = { minHeight: 35, maxHeight: 70 };
const unsetPosition = { position: 'unset' };

export default ({ error, fullWidth }) => ({
  '&.MuiAutocomplete-root': {
    ...containerHeight,
    border: `solid thin ${error ? 'red' : 'var(--light-gray)'}`,
    width: fullWidth ? '100%' : 'unset',
    borderRadius: '4px',
    position: 'relative',
    '& .MuiOutlinedInput-notchedOutline': {
      display: 'none'
    },
    '& .MuiOutlinedInput-root': {
      ...containerHeight,
      ...unsetPosition,
      padding: '3px 0 3px 3px',
      overflowY: 'auto'
    },
    '& .MuiChip-root': {
      backgroundColor: '#e0e0e0'
    },
    '& .MuiChip-deleteIcon': {
      width: 15,
      height: 15,
      color: 'var(--primary-color)',
      opacity: '0.6'
    },
    '& .MuiTextField-root': {
      ...unsetPosition
    },
    '& .MuiIconButton-root': {
      padding: '1px'
    }
  },
  '& .MuiInputBase-root': {
    fontSize: 12
  },
  '& .MuiAutocomplete-tag': {
    margin: '3px',
    height: 24,
    fontSize: 12,
    borderRadius: '15px'
  },
  '& .MuiAutocomplete-endAdornment': {
    top: 0,
    position: 'absolute',
    bottom: 0,
    margin: 'auto',
    height: 20,
    transform: 'none'
  },
  '& .MuiAutocomplete-popupIndicator': {
    width: 13.5,
    height: 13.5,
    fill: 'var(--primary-color)',
    '& > path': {
      fill: 'var(--primary-color)'
    }
  }
});
