import { get, post, patch, del } from 'services/http';

const getCustomersOfTheDay = () => get('/customers');

const extendCustomersOfTheDay = () => get('/customers/extend');

const getCustomerSelections = () => get('/customers/selections');

const getCustomerSelectionById = id => get(`/customers/selections/${id}?sessionId=${window.veezoo.sessionId}`);

const saveCustomerSelection = body => post('/customers/selections', body);

const editCustomerSelection = (id, body) => patch(`/customers/selections/${id}`, body);

const deleteCustomerSelectionById = id => del(`/customers/selections/${id}`);

const saveSharedCustomerSelections = (id, body) => post(`/customers/selections/shared/${id}`, body);

const updateSharedCustomerSelections = (id, body) => patch(`/customers/selections/shared/${id}`, body);

const stopSharingCustomerSelection = id => del(`/customers/selections/shared/${id}`);

const acceptOrRejectSharedCustomerSelection = (id, body) => post(`/customers/selections/shared/status/${id}`, body);

export default {
  getCustomersOfTheDay,
  extendCustomersOfTheDay,
  getCustomerSelections,
  getCustomerSelectionById,
  saveCustomerSelection,
  editCustomerSelection,
  deleteCustomerSelectionById,
  saveSharedCustomerSelections,
  updateSharedCustomerSelections,
  stopSharingCustomerSelection,
  acceptOrRejectSharedCustomerSelection
};
