import { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { trackEvent } from 'utils/eventTracking';

import { askLocalQuestion } from 'store/modules/chat-messages';

import { useStyles } from './ChatMessageMarkdownHtml.styles';

// a special markdown HTML container for chat messages which supports running chat-specific actions
// when clicking special hyperlinks (e.g., asking a suggested question)
const ChatMessageMarkdownHtml = ({ username, html, dispatch, t }) => {
  const containerRef = useRef(null);

  const { classes } = useStyles();

  // attach special functionality to all hyperlinks with special anchors (currently, only #suggested-question)
  useEffect(() => {
    const suggestedQuestionLinkClick = event => {
      // stop the browser from following the link
      event.preventDefault();
      event.stopPropagation();

      // extract the suggested question from the DOM and ask it
      const question = event.currentTarget.innerHTML;
      trackEvent('Suggested Question Hyperlink Clicked', {
        username: username,
        suggestedQuestion: question
      });
      const otherParams = {
        isQuestionPill: false,
        isLlmSuggestion: true,
        isSuggested: true,
        isVoice: false,
        linkedEntities: []
      };
      dispatch(askLocalQuestion(username, question, otherParams, t));
    };

    if (containerRef.current) {
      const specialLinks = containerRef.current.querySelectorAll('a[href^="#suggested-question"]');
      specialLinks.forEach(link => {
        link.addEventListener('click', suggestedQuestionLinkClick);
        link.removeAttribute('target');
      });

      // Clean up event listeners on unmount
      return () => {
        specialLinks.forEach(link => link.removeEventListener('click', suggestedQuestionLinkClick));
      };
    }
  }, [username, html]);

  return <div ref={containerRef} className={classes.root} dangerouslySetInnerHTML={{ __html: html }} />;
};

const mapStateToProps = state => ({
  username: state.user.username
});

export default withTranslation('veezoo')(connect(mapStateToProps)(ChatMessageMarkdownHtml));
