import { get, post } from 'services/http';

const getDatabaseSystems = () => get('/studio/api/database/systems');

const getDatabaseConnection = id => get(`/studio/api/knowledge-graphs/${encodeURIComponent(id)}/database`);

const getDatabaseSchemas = id => get(`/studio/api/knowledge-graphs/${encodeURIComponent(id)}/database/schemas`);

const getDatabaseTables = (id, schemaName) => {
  if (schemaName) {
    return get(
      `/studio/api/knowledge-graphs/${encodeURIComponent(id)}/database/schemas/${encodeURIComponent(schemaName)}/tables`
    );
  } else {
    return get(`/studio/api/knowledge-graphs/${encodeURIComponent(id)}/database/tables`);
  }
};

const getImportConfiguration = () => get('/studio/api/import/configuration');

const getPublicKey = () => get('/studio/api/database/ssh-tunnel-pk');

const testDatabaseConnectionForNewKg = body => post('/studio/api/database/test', body);

const testDatabaseConnectionForExistingKg = (id, body) =>
  post(`/studio/api/knowledge-graphs/${encodeURIComponent(id)}/database/test`, body);

const getDatabaseColumns = (id, schemaName, name) => {
  if (schemaName) {
    return get(
      `/studio/api/knowledge-graphs/${encodeURIComponent(id)}/database/schemas/${encodeURIComponent(
        schemaName
      )}/tables/${encodeURIComponent(name)}/columns`
    );
  } else {
    return get(
      `/studio/api/knowledge-graphs/${encodeURIComponent(id)}/database/tables/${encodeURIComponent(name)}/columns`
    );
  }
};

export default {
  getDatabaseSystems,
  getDatabaseConnection,
  testDatabaseConnectionForExistingKg,
  testDatabaseConnectionForNewKg,
  getDatabaseSchemas,
  getDatabaseTables,
  getImportConfiguration,
  getPublicKey,
  getDatabaseColumns
};
