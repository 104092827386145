import { useTssStyles } from './ShareModal.styles';

const IconContainer = ({ icon: Icon }) => {
  const { classes } = useTssStyles();
  return (
    <div className={classes.iconContainer}>
      <Icon className={classes.icon} />
    </div>
  );
};

export default IconContainer;
