import { tss } from 'tss-react';

export const useStyles = tss.create({
  backdrop: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 2
  }
});

export const paperSx = {
  backgroundColor: 'var(--primary-dark-color)',
  position: 'absolute',
  width: 240
};
