export const getSx = ({ selectedIsDevelopmentKg }) => ({
  inputAdornment: {
    '&.MuiInputAdornment-root': {
      '& .MuiSvgIcon-root': {
        width: 20,
        height: 20,
        color: 'white',
        opacity: 0.7,
        fontSize: 20,
        border: 'none',
        top: 'calc(50% - 10px)',
        right: 5
      }
    }
  },
  textField: {
    '&.MuiTextField-root': {
      width: '100%'
    },
    '& .Mui-disabled': {
      color: 'rgba(255, 255, 255, 0.7) !important',
      '-webkit-text-fill-color': 'rgba(255, 255, 255, 0.7) !important'
    },
    '& .MuiOutlinedInput-root': {
      cursor: 'pointer',
      borderRadius: 'var(--border-radius-xs)',
      transition: 'background-color 0.2s ease',
      color: 'rgba(255, 255, 255, 0.7)',
      fontSize: 14,

      fontWeight: '500',
      lineHeight: '1.688em',
      letterSpacing: '0.3px',
      border: `solid 0px transparent`,
      padding: '0 10px',
      '&.Mui-focused .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
        borderWidth: 0
      },
      '&:focus': {
        backgroundColor: 'rgba(255,255,255,0.2)'
      },
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        boxShadow: 'none'
      },
      ...(selectedIsDevelopmentKg
        ? {
            backgroundColor: 'rgba(255, 165, 0, 1)',
            '&:focus': {
              backgroundColor: 'rgba(255, 165, 0, 0.9)'
            },
            '&:hover': {
              backgroundColor: 'rgba(255, 165, 0, 0.9)',
              boxShadow: 'none'
            }
          }
        : {}),
      '&&.Mui-error': {
        border: `solid thin red`
      }
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent'
    }
  },
  select: {
    '& .MuiSelect-select': {
      padding: '8px 0 8px 5px',
      '&:focus': {
        backgroundColor: 'transparent'
      },
      '& > .MuiListItemIcon-root': {
        display: 'none'
      }
    },
    '& .MuiSelect-icon': {
      top: 'calc(50% - 10px)',
      color: 'white',
      right: 5,
      border: 'none',
      opacity: '0.7',
      fontSize: 20,
      width: '18px !important',
      height: '18px !important'
    }
  },
  menu: {
    '& .MuiMenuItem-root': {
      fontSize: 14,
      color: 'var(--font-color)',
      height: 31,
      padding: '6px 16px',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center'
    }
  },
  menuItemIcon: {
    '&.MuiListItemIcon-root': {
      minWidth: 25
    }
  }
});
