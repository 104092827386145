import { Component, createRef } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import uuid1 from 'uuid/v1';
import smoothscroll from 'smoothscroll-polyfill';

import { AddToBoardModal } from 'components/v3/connectedComponents';

import { withTranslation } from 'react-i18next';
import { createSelector } from 'reselect';

import ArrowDown from 'svg/v2/chevron_down.svg';

import { addWidgetToBoard } from 'store/modules/board-widgets';
import {
  messageTypes,
  removeMessageList,
  fetchBoardMessage,
  loadingMessageTypes,
  fetchDataForClass,
  askLocalQuestion
} from 'store/modules/chat-messages';

import { fetchSharedAnswer } from 'store/modules/sharing';

import { fetchCustomersOfTheDay, fetchCustomerSelectionAnswer } from 'store/modules/customers-of-the-day';

import { setEntrypointAsFetched } from 'store/modules/graph/knowledgeGraphMeta';

import LoadingMessage from 'components/message/LoadingMessage';
import UserMessage from 'components/message/UserMessage';
import WelcomeMessage from 'components/message/WelcomeMessage';
import AnswerMessage from 'components/message/AnswerMessage';
import NegativeFeedbackMessage from 'components/message/NegativeFeedbackMessage';
import BoardAnswerMessage from 'components/message/BoardAnswerMessage';
import MultiAnswerMessage from 'components/message/MultiAnswerMessage';
import CustomerSelectionAnswerMessage from 'components/message/CustomerSelectionAnswerMessage';
import CustomersOfTheDay from 'components/customers/CustomersOfTheDay';
import { MessageListRefContext } from 'components/chat/MessageListRefContext';
import SmartActionMessage from 'components/message/SmartActionMessage';
import InfoMessage from 'components/message/InfoMessage';

import { chatUrlEntryPointTypes, entryPointTypes } from 'config/constants';

import { trackEvent } from 'utils/eventTracking';
import HiddenMessage from 'components/message/HiddenMessage';

import styles from './Chat.styles.scss';
import { ErrorBoundary } from '../../error-handler/ErrorBoundary';

// kick off the polyfill
smoothscroll.polyfill();

const initialState = {
  showAddToBoardModal: false,
  shouldScroll: false,
  widgetToBeAdded: {},
  boardToAddWidgetTo: null
};

class Chat extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;

    this.messageListRef = createRef();
    this.dummyDivRef = createRef();
  }

  fetchEntryPoint(username, entryPoint, chatEntryPoint) {
    // if we have for example in the URL the customers of the day route
    // we want to force directly customers of the day
    // for example when we send out links to people to show them this feature

    // URL steered parameters (CoD, Sharing, Asking) take preference over user stored preferences (entry board, CoD)
    if (chatEntryPoint?.type === chatUrlEntryPointTypes.board) {
      trackEvent('Opened board through link URL', { username, boardId: chatEntryPoint.boardId });
      this.props.dispatch(fetchBoardMessage(chatEntryPoint.boardId, this.props.t));
    } else if (chatEntryPoint?.type === chatUrlEntryPointTypes.sharing) {
      this.props.dispatch(fetchSharedAnswer(chatEntryPoint.sharedAnswerId));
    } else if (chatEntryPoint?.type === chatUrlEntryPointTypes.ask) {
      this.props.dispatch(askLocalQuestion(username, chatEntryPoint.question, {}, this.props.t));
    } else if (chatEntryPoint?.type === chatUrlEntryPointTypes.customersOfTheDay) {
      this.props.dispatch(fetchCustomersOfTheDay()); // this is for url triggered CoD
    } else if (entryPoint.entrypoint === entryPointTypes.BOARD) {
      this.props.dispatch(fetchBoardMessage(entryPoint.id, this.props.t));
    } else if (entryPoint.entrypoint === entryPointTypes.DATA) {
      this.props.dispatch(fetchDataForClass(entryPoint.id));
    } else if (entryPoint.entrypoint === entryPointTypes.CUSTOMER_SELECTION) {
      this.props.dispatch(fetchCustomerSelectionAnswer(entryPoint.id));
    } else if (entryPoint.entrypoint === entryPointTypes.CUSTOMERS_OF_THE_DAY) {
      this.props.dispatch(fetchCustomersOfTheDay()); // this is for the user stored preference CoD
    }
  }

  checkAndFetchEntrypoint = () => {
    const { hasFetchedEntryPoint, defaultEntryPoint, boardsList, chatEntryPoint, username, dispatch } = this.props;
    // we need to wait for the boards to be available before fetching the entrypoint, because only after we have
    // received the boards do we know for sure that the user's default board exists (a user's default board gets
    // created during the call that fetches the boards)
    if (!hasFetchedEntryPoint && defaultEntryPoint && boardsList && boardsList.length) {
      dispatch(removeMessageList());
      this.fetchEntryPoint(username, defaultEntryPoint, chatEntryPoint);
      dispatch(setEntrypointAsFetched());
    }
  };

  componentDidMount() {
    trackEvent('Chat Opened', {});
    this.checkAndFetchEntrypoint();
    this.scrollToBottom();
  }

  componentDidUpdate(prevProps) {
    const currentMessages = this.props.messages;
    const prevMessages = prevProps.messages;
    const {
      meta,
      isAddingBoardWidget,
      hasAddedBoardWidget,
      enqueueSnackbar,
      isMobile,
      isVisible,
      partialAnswers,
      t
    } = this.props;

    // also fetch on mount in case all the props are already there before the chat renders
    // which would mean that componentDidUpdate would not be called
    this.checkAndFetchEntrypoint();

    // // When changing Knowledge Graphs, we reset the board information.
    if (prevProps.meta.id !== undefined && prevProps.meta.id !== meta.id) {
      this.setState(initialState);
    }

    if (prevProps.isAddingBoardWidget === true && isAddingBoardWidget === false && hasAddedBoardWidget === true) {
      enqueueSnackbar(`${t('widget-added-to-board')}: ${this.state.boardToAddWidgetTo.name}`, {
        variant: 'info',
        autoHideDuration: 1500,
        ...(isMobile ? { action: <></> } : {}) // Necessary to remove the "x" close button from the snackbar.
      });
    }

    // We scroll to bottom if
    // - there are more messages
    // - the last message was a loading message and is now not anymore
    // - the last message was loading a partial answer and the data is now loaded
    const numberOfMessagesChanged = currentMessages.length !== prevMessages.length;
    const prevLastMessage = prevMessages[prevMessages.length - 1];
    const currentLastMessage = currentMessages[currentMessages.length - 1];
    const finishedLoading =
      loadingMessageTypes.includes(prevLastMessage?.type) && !loadingMessageTypes.includes(currentLastMessage?.type);
    const finishedLoadingPartialAnswer =
      partialAnswers.some(
        w =>
          w.answerId === currentLastMessage?.answer?.answerId &&
          w.interpretationId === currentLastMessage?.answer?.interpretationId
      ) &&
      !prevProps.partialAnswers.some(
        w =>
          w.answerId === prevLastMessage?.answer?.answerId &&
          w.interpretationId === prevLastMessage?.answer?.interpretationId
      );

    if (numberOfMessagesChanged || finishedLoading || finishedLoadingPartialAnswer) {
      if (isVisible) {
        // only scroll if the Chat is currently visible
        // even if the chat is mounted, it can be that it is being hidden from display
        // for performance reasons...
        this.scrollToBottom();
      } else {
        // ... if that's the case, then remember that we should scroll to bottom
        // as soon as the chat becomes visible again
        this.setState({ shouldScroll: true });
      }
    }

    // execute the scrolling once the chat becomes visible and it was marked for scrolling
    if (isVisible && this.state.shouldScroll) {
      this.scrollToBottom();
      this.setState({ shouldScroll: false });
    }
  }

  scrollToBottom = () => {
    // we need to wait for the DOM to be updated before scrolling to the bottom
    // otherwise the scroll position will be wrong
    setTimeout(() => {
      const dummydiv = this.dummyDivRef.current;
      const chatContainer = this.props.containerRef.current;
      if (chatContainer && dummydiv) {
        chatContainer.scrollTop = dummydiv.offsetTop;
      }
    }, 100);
  };

  scrollTo = messageId => {
    const messagesEnd = ReactDOM.findDOMNode(this[messageId]);
    const chatContainer = this.props.containerRef.current;
    if (chatContainer && messagesEnd) {
      chatContainer.scrollTop = messagesEnd.offsetTop;
    }
  };

  addToBoardCallback = obj => {
    this.setState({
      showAddToBoardModal: true,
      widgetToBeAdded: obj
    });
  };

  hideAddToBoardModal = () => {
    this.setState({
      showAddToBoardModal: false
    });
  };

  addWidgetToBoardCallback = boardId => {
    trackEvent('Widget Added', { boardId, widget: this.state.widgetToBeAdded });

    const board = this.props.boardsList.find(b => b.id === boardId);

    this.setState({
      boardToAddWidgetTo: board
    });

    this.props.dispatch(addWidgetToBoard(boardId, this.state.widgetToBeAdded));

    this.hideAddToBoardModal();
  };

  /**
   * Renders the chat messages with proper scroll positioning.
   *
   * This method determines where to place a "dummy div" that serves as an anchor point for
   * auto-scrolling, ensuring the right messages are visible after new content arrives.
   *
   * @param {Array} messageItems - Array of message items with message data and components
   * @returns {Array} - Array of React components ready for rendering
   */
  renderContent(messageItems) {
    // Create a copy of the message items
    const items = [...messageItems];

    // Create the dummy div that will serve as our scroll anchor point
    const dummyDiv = <div className="dummyDiv" key="dummy-div" ref={this.dummyDivRef} />;
    const dummyItem = { message: { type: 'dummy' }, component: dummyDiv };

    if (items.length <= 1) {
      // For 0 or 1 messages, just add dummy div and return
      items.push(dummyItem);
      return items.map(item => item.component);
    }

    // Find the insertion index for our dummy div
    let insertIndex = items.length - 1; // Default: before last message

    // If last message is complementary, put dummy before second-to-last message
    if (items[items.length - 1].message.isComplementary) {
      insertIndex = items.length - 2;
    }

    // If message at insertion point is a rephrase or warning, put dummy before it
    if (
      insertIndex > 0 &&
      (items[insertIndex - 1].message.isRephraseMessage || items[insertIndex - 1].message.isWarningMessage)
    ) {
      insertIndex = insertIndex - 1;
    }

    // Insert the dummy div at the calculated position
    items.splice(insertIndex, 0, dummyItem);

    // Return just the components for rendering
    return items.map(item => item.component);
  }

  transformMessagesForRendering(messages) {
    const hiddenMessageIds = [];

    const createHiddenMessageSequence = (messageId, type) => {
      return {
        messageId,
        localMessageIds: type === messageTypes.USER ? [messageId] : [],
        answerMessageIds: type !== messageTypes.USER ? [messageId] : []
      };
    };

    const transformedMessages = messages.reduce((acc, message) => {
      if (message.type === messageTypes.USER_HIDDEN_MESSAGE) {
        const lastMessage = acc[acc.length - 1];
        if (lastMessage && lastMessage.type === messageTypes.USER_HIDDEN_MESSAGE) {
          if (message.previousType === messageTypes.USER) {
            hiddenMessageIds.find(item => item.messageId === lastMessage.id).localMessageIds.push(message.id);
          } else {
            hiddenMessageIds.find(item => item.messageId === lastMessage.id).answerMessageIds.push(message.id);
          }
          return acc;
        } else {
          hiddenMessageIds.push(createHiddenMessageSequence(message.id, message.previousType));
        }
      }
      return [...acc, message];
    }, []);

    return [hiddenMessageIds, transformedMessages];
  }

  getMessageComponent = (message, hiddenMessageIds) => {
    const {
      t,
      meta,
      isMobile,
      containerRef,
      isScrolling,
      shouldScrollAutomatically,
      messages: chatMessages
    } = this.props;

    switch (message.type) {
      case messageTypes.USER_HIDDEN_MESSAGE:
        return (
          <ErrorBoundary key={message.id}>
            <HiddenMessage
              ref={node => {
                this[message.id] = node;
              }} // ref needs to define position for scrollTo
              hiddenMessageIds={hiddenMessageIds.find(item => item.messageId === message.id)}
            />
          </ErrorBoundary>
        );
      case messageTypes.USER:
        return (
          <ErrorBoundary key={message.id}>
            <UserMessage
              ref={node => {
                this[message.id] = node;
              }} // ref needs to define position for scrollTo
              id={message.id}
              textAnswer={message.textAnswer}
              timestamp={message.timestamp}
              isFollowUp={message.referenceTo}
              scrollTo={this.scrollTo}
            />
          </ErrorBoundary>
        );
      case messageTypes.VEEZOO_CUSTOMER_SELECTION_MESSAGE_LOADING:
        return (
          <ErrorBoundary key={message.id}>
            <CustomerSelectionAnswerMessage
              ref={node => {
                this[message.id] = node;
              }} // ref needs to define position for scrollTo
              message={message}
              isLoading={true}
              addToBoardCallback={this.addToBoardCallback}
              customerSelectionId={message.customerSelectionId}
              scrollTo={this.scrollTo}
              isMobile={isMobile}
            />
          </ErrorBoundary>
        );
      case messageTypes.VEEZOO_CUSTOMER_SELECTION_MESSAGE:
        return (
          <ErrorBoundary key={message.id}>
            <CustomerSelectionAnswerMessage
              ref={node => {
                this[message.id] = node;
              }} // ref needs to define position for scrollTo
              message={message}
              isLoading={false}
              addToBoardCallback={this.addToBoardCallback}
              customerSelectionId={message.customerSelectionId}
              scrollTo={this.scrollTo}
              isMobile={isMobile}
            />
          </ErrorBoundary>
        );
      case messageTypes.VEEZOO_REPHRASE_MESSAGE:
        return (
          <ErrorBoundary key={message.id}>
            <UserMessage
              isRephraseMessage={true}
              ref={node => {
                this[message.id] = node;
              }} // ref needs to define position for scrollTo
              id={message.id}
              textAnswer={message.textAnswer}
              originalQuestion={message.originalQuestion}
              timestamp={message.timestamp}
              isFollowUp={message.referenceTo}
              scrollTo={this.scrollTo}
            />
          </ErrorBoundary>
        );
      case messageTypes.VEEZOO_ANSWER_MESSAGE:
        if (message) {
          return (
            <ErrorBoundary key={message.id}>
              <AnswerMessage
                {...message}
                displayAnswerTutorial={true} // displaying Answer Tutorial only for this type of AnswerMessage
                ref={node => {
                  this[message.id] = node;
                }}
                addToBoardCallback={this.addToBoardCallback}
                disableFooter={false}
                scrollTo={this.scrollTo}
                isMobile={isMobile}
              />
            </ErrorBoundary>
          );
        }
        return null;
      case messageTypes.VEEZOO_MULTI_ANSWER_MESSAGE:
        return (
          <ErrorBoundary key={message.id}>
            <MultiAnswerMessage
              {...message}
              ref={node => {
                this[message.id] = node;
              }} // ref needs to define position for scrollTo
              addToBoardCallback={this.addToBoardCallback}
              scrollTo={this.scrollTo}
              message={message}
            />
          </ErrorBoundary>
        );
      case messageTypes.VEEZOO_INFO_MESSAGE:
        return (
          <ErrorBoundary key={message.id}>
            <InfoMessage
              message={message}
              hasKnowledgeGraphSupport={meta.hasKnowledgeGraphSupport}
              chatContainer={containerRef.current}
              isScrolling={isScrolling}
              shouldScrollAutomatically={shouldScrollAutomatically}
              scrollTo={this.scrollTo}
              t={t}
            />
          </ErrorBoundary>
        );
      case messageTypes.NEGATIVE_FEEDBACK_MESSAGE:
        return (
          <ErrorBoundary key={message.id}>
            <NegativeFeedbackMessage message={message} chatMessages={chatMessages} t={t} />
          </ErrorBoundary>
        );
      case messageTypes.WARNING_INFO_MESSAGE:
        return (
          <ErrorBoundary key={message.id}>
            <InfoMessage
              isWarningMessage
              message={{
                ...message,
                textAnswer: message.textAnswer ? message.textAnswer : t('error'),
                suggestKnowledgeGraph: true
              }}
              hasKnowledgeGraphSupport={meta.hasKnowledgeGraphSupport}
              scrollTo={this.scrollTo}
              t={t}
            />
          </ErrorBoundary>
        );
      case messageTypes.SMART_ACTION_MESSAGE:
        return (
          <ErrorBoundary key={message.id}>
            <SmartActionMessage message={message} isMobile={isMobile} />
          </ErrorBoundary>
        );
      case messageTypes.VEEZOO_COMPLEMENTARY_LOADING:
      case messageTypes.VEEZOO_LOADING:
        return (
          <ErrorBoundary key={uuid1()}>
            <LoadingMessage />
          </ErrorBoundary>
        );
      case messageTypes.VEEZOO_CUSTOMERS_OF_THE_DAY_LOADING:
        return (
          // it's possible that Message with key=message.id will be rendered in Chat, but key prop should be unique
          <ErrorBoundary key={uuid1()}>
            <CustomersOfTheDay isLoading={true} />
          </ErrorBoundary>
        );
      case messageTypes.VEEZOO_CUSTOMERS_OF_THE_DAY_MESSAGE:
        return (
          <ErrorBoundary key={uuid1()}>
            <CustomersOfTheDay {...message} />
          </ErrorBoundary>
        );
      case messageTypes.VEEZOO_BOARD_ANSWER_MESSAGE_LOADING:
      case messageTypes.VEEZOO_BOARD_ANSWER_MESSAGE:
        return (
          <ErrorBoundary key={message.id}>
            <BoardAnswerMessage
              {...message}
              ref={node => {
                this[message.id] = node;
              }} // ref needs to define position for scrollTo
              addToBoardCallback={this.addToBoardCallback}
              scrollTo={this.scrollTo}
              message={message}
            />
          </ErrorBoundary>
        );
      default:
        return null;
    }
  };

  render() {
    const { boardsList, messages } = this.props;
    const { showAddToBoardModal } = this.state;

    // replace all consecutive hidden messages with a single one that contains all the ids
    // construct a new array as well as a map from message id to hidden message ids
    // the structure looks as follows:
    // hiddenMessageIds = [{
    //       messageId: '',
    //       localMessageIds: [],
    //       answerMessageIds: []
    //     }, ...]
    const [hiddenMessageIds, transformedMessages] = this.transformMessagesForRendering(messages);

    // Create welcome message item
    const welcomeMessage = { type: 'welcome' };
    const messageItems = [
      {
        message: welcomeMessage,
        component: <WelcomeMessage key="welcomeMessage" />
      }
    ];

    // Add all other messages with their components
    transformedMessages.forEach(message => {
      // Identify rephrase messages and warning messages, so we can position the scroll dummy appropriately
      message.isWarningMessage = message.type === messageTypes.WARNING_INFO_MESSAGE;
      message.isRephraseMessage = message.type === messageTypes.VEEZOO_REPHRASE_MESSAGE;

      // Create and add message-component pair
      messageItems.push({
        message,
        component: this.getMessageComponent(message, hiddenMessageIds)
      });
    });

    const renderedComponents = this.renderContent(messageItems);

    return (
      <>
        <AddToBoardModal
          open={showAddToBoardModal}
          layout="veezoo"
          boardsList={boardsList}
          onClose={this.hideAddToBoardModal}
          onConfirm={this.addWidgetToBoardCallback}
          loading={this.props.isAddingBoardWidget}
        />
        <MessageListRefContext.Provider value={this.messageListRef}>
          <>
            {this.props.shouldScrollAutomatically && (
              <div className={styles.scrollButton} onClick={this.scrollToBottom}>
                <ArrowDown className={styles.scrollButtonArrow} />
              </div>
            )}
            <div ref={this.messageListRef}>{renderedComponents}</div>
          </>
        </MessageListRefContext.Provider>
      </>
    );
  }
}

// we only operate on visible messages to not break the scroll logic
// sometimes we want to hide messages from the user, e.g. for complementaries while the main answer
// is available but artificially delayed (delay after rephrase message)
const getVisibleMessages = createSelector(
  state => state.chatMessages,
  chatMessages => chatMessages.filter(m => !m.isHidden)
);

const mapStateToProps = state => ({
  messages: getVisibleMessages(state),
  partialAnswers: state.partialAnswers,
  boardsList: state.board.boardsList,
  username: state.user.username,
  defaultEntryPoint: state.knowledgeGraphMeta.meta.defaultEntryPoint,
  hasFetchedEntryPoint: state.knowledgeGraphMeta.meta.hasFetchedEntryPoint,
  isAddingBoardWidget: state.network.isAddingBoardWidget,
  hasAddedBoardWidget: state.network.hasAddedBoardWidget,
  isFetchingMessage: state.network.isFetchingMessage,
  meta: state.knowledgeGraphMeta.meta
});

export default withTranslation('veezoo')(connect(mapStateToProps)(Chat));
