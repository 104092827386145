import { useTheme } from '@mui/material/styles';

import { tss } from 'tss-react';

export const useTssStyles = tss.create(() => {
  const theme = useTheme();
  return {
    progress: {
      padding: theme.spacing(1),
      textAlign: 'center'
    }
  };
});

export const useDialogStyles = () => {
  return {
    signup: {
      title: {
        '& .MuiTypography-root': {
          fontSize: '16px !important'
        }
      },
      content: {
        fontSize: '12px'
      },
      actions: {
        padding: '8px 24px 20px',
        '& .MuiButton-root': {
          color: 'white',
          border: 'solid thin var(--light-blue)',
          padding: '2px 20px',
          fontSize: '17px',
          minWidth: '120px',
          fontWeight: '300',
          borderRadius: '5px',
          textTransform: 'initial',
          backgroundColor: 'var(--light-blue)'
        },
        '& .MuiButton-contained': {
          backgroundColor: 'var(--light-blue)',
          color: 'white'
        },
        '& .MuiButton-outlined': {
          backgroundColor: 'white',
          color: 'var(--light-blue)'
        }
      }
    },
    veezoo: {},
    default: {}
  };
};
