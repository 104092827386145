import { memo, useEffect } from 'react';
import clsx from 'clsx';
import withEmbedded from 'root/hocs/withEmbedded';
import { SIDEBAR_EXPANDED_SIZE } from 'config/constants';

import { useSidebar } from 'hooks';

import resizeOnMouseMove from './resizable';

import styles from './splitSidebarView.scss';

const SplitSidebarView = ({ children, isEmbedded, showKgSidebar }) => {
  const { sidebarSize, setSidebarSize, sidebarIsMinimized } = useSidebar();

  useEffect(() => {
    // If the KG sidebar is shown and we are minimized, expand the sidebar
    if (showKgSidebar && sidebarIsMinimized) {
      setSidebarSize({
        width: SIDEBAR_EXPANDED_SIZE
      });
    }
  }, [showKgSidebar, sidebarIsMinimized]);

  return (
    <div className={clsx(styles.container, sidebarIsMinimized ? 'sidebarShortVersion' : '')}>
      {!isEmbedded ? (
        <>
          <div style={{ width: sidebarSize.width, position: 'relative' }}>
            {children[0]}
            <div className={styles.resizer} onMouseDown={() => resizeOnMouseMove(setSidebarSize)} />
          </div>
          <div style={{ width: `calc(100% - ${sidebarSize.width}px)` }}>{children[1]}</div>
        </>
      ) : (
        <div style={{ width: `100%` }}>{children[1]}</div>
      )}
    </div>
  );
};

export default withEmbedded(memo(SplitSidebarView));
