import { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/buttons/Button';

import styles from './pagination.scss';

const defaultButton = props => <Button {...props}>{props.children}</Button>;

export const Pagination = props => {
  const [inputValue, setInputValue] = useState('');

  const {
    PageButtonComponent = defaultButton,
    page,
    rowCount,
    pages,
    pageSize,
    showPageSizeOptions,
    pageSizeOptions,
    setPageSize,
    showPageJump,
    canPreviousPage,
    canNextPage,
    texts
  } = props;

  const resetInputValue = () => {
    // page has an index of "0", so we have to increment 1 to show the correct page number starting as "1" inside the input;
    setInputValue(props.page + 1);
  };

  useEffect(() => {
    resetInputValue();
  }, [props.page]);

  const isValidInputNumber = value => {
    const intValue = parseInt(value);
    if (isNaN(intValue)) return false;
    if (intValue < 1) return false;
    if (intValue > pages) return false;
    return true;
  };

  const changePage = page => {
    if (props.page !== page) {
      props.onPageChange(page);
    }
  };

  const handleInputChange = e => {
    console.log('CHANGED', e.target.value);
    const value = e.target.value;
    setInputValue(value);
  };

  const validateAndChangePage = e => {
    const value = e.target.value;
    if (!isValidInputNumber(value)) {
      resetInputValue();
    }

    // Since the page is an index, we have to decrement 1 to get the correct page number;
    changePage(value - 1);
  };

  const handleInputBlur = e => {
    if (e) e.preventDefault();
    validateAndChangePage(e);
  };

  const handleEnterPress = e => {
    if (e.which === 13 || e.keyCode === 13) {
      validateAndChangePage(e);
    }
  };

  const handlePrevClick = () => {
    if (canPreviousPage) {
      changePage(props.page - 1);
    }
  };

  const handleNextClick = () => {
    if (canNextPage) {
      changePage(props.page + 1);
    }
  };

  const rowMin = useMemo(() => props.page * props.pageSize + 1, [props.page, props.pageSize]);
  const rowMax = useMemo(() => Math.min((props.page + 1) * props.pageSize, rowCount), [
    props.page,
    props.pageSize,
    rowCount
  ]);

  return (
    <div className={`${styles.pagination} -pagination`}>
      <div className="-center">
        {showPageSizeOptions && (
          <div className="select-wrap -pageSizeOptions">
            <select onChange={e => setPageSize(Number(e.target.value))} value={pageSize}>
              {pageSizeOptions.map((option, index) => (
                <option data-testid="pageSizeOption" key={index} value={option}>
                  {option} {texts?.rows || 'rows'}
                </option>
              ))}
            </select>
          </div>
        )}

        <div className="-pageInfo">
          {texts?.page || 'Page'}&nbsp;
          {showPageJump ? (
            <div className="-pageJump">
              <input
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                onKeyPress={handleEnterPress}
                title={texts?.page || 'Page'}
              />
            </div>
          ) : (
            <span className="-currentPage">{page + 1}</span>
          )}
          &nbsp;
          {texts?.of || 'of'}&nbsp;
          <span data-testid="pages" className="-totalPages">
            {pages || 1}
          </span>
        </div>

        {typeof rowCount !== 'undefined' ? (
          <div className="-rowInfo">
            {texts?.showing || 'Showing'}&nbsp;
            <span className="-rowMin">{rowMin}</span>
            &nbsp;{texts?.between || '-'}&nbsp;
            <span className="-rowMax">{rowMax}</span>
            &nbsp;{texts?.of || 'of'}&nbsp;
            <span className="-rowCount">{rowCount}</span>
            &nbsp;{texts?.totalRows || 'total rows'}
          </div>
        ) : null}
      </div>

      <div className="-previous">
        <PageButtonComponent className="-btn" onClick={handlePrevClick} disabled={!canPreviousPage}>
          {texts?.previous || 'Back'}
        </PageButtonComponent>
      </div>

      <div className="-next">
        <PageButtonComponent className="-btn" onClick={handleNextClick} disabled={!canNextPage}>
          {texts?.next || 'Next'}
        </PageButtonComponent>
      </div>
    </div>
  );
};

Pagination.propTypes = {
  rowCount: PropTypes.number,
  pages: PropTypes.number,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  PageButtonComponent: PropTypes.any,
  onPageChange: PropTypes.func,
  setPageSize: PropTypes.func,
  showPageSizeOptions: PropTypes.bool,
  canNextPage: PropTypes.bool,
  canPreviousPage: PropTypes.bool,
  showPageJump: PropTypes.bool,
  pageSizeOptions: PropTypes.array,
  texts: PropTypes.object
};

export default Pagination;
