import { tss } from 'tss-react';

export const sx = {
  select: {
    '&.MuiInputBase-root': {
      margin: '5px',
      fontSize: 14
    },
    '& .MuiSelect-select': {
      paddingLeft: '10px'
    }
  },
  menuItem: {
    '&.MuiMenuItem-root': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: '6px 16px',
      fontSize: 12
    }
  }
};

export const useStyles = tss.create({
  title: {
    marginTop: '18px !important',
    marginBottom: '5px !important',
    color: 'var(--dark-gray) !important'
  },
  nameWithParentClassNameContainer: {
    display: 'flex',
    marginLeft: '5px',
    width: '75%',
    alignItems: 'center'
  },
  parentClassNameContainer: {
    fontSize: 14,
    fontWeight: 'initial'
  },
  rightElement: {
    marginLeft: '10px'
  },
  buttonContainer: {
    marginTop: '25px'
  },
  sharingElement: {
    paddingRight: '10px',
    '& svg': {
      // needed for IE to have the correct icon width / height
      maxWidth: '32px',
      maxHeight: '32px'
    }
  },
  form: {
    minWidth: 600
  },
  wideForm: {
    minWidth: 800
  }
});
