import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Skeleton from '@mui/material/Skeleton';

import { Button } from 'components/v3';
import MilestonesChecklist from 'components/MilestonesChecklist';

import BaseMessage from './BaseMessage';

import { runOverviewTutorial } from 'store/modules/tutorial';

import { openSupportMailWithFreshId } from 'utils/supportMail';
import useMediaQuery from 'utils/mediaQueries';

import ChatIcon from 'svg/chat.svg';
import PlayIcon from 'svg/play.svg';
import KnowledgeGraphIcon from 'svg/knowledge-graph.svg';
import SupportIcon from 'svg/support.svg';
import QuestionIcon from 'svg/question-white.svg';

import styles from './welcome-message.scss';
import withEmbedded from 'root/hocs/withEmbedded';
import { changeUrlGivenCurrentQueryParams } from 'components/LinkWithQuery';
import { possibleStates } from 'config/constants';
import { handleReactTagsFromString } from './commonFunctions';

const TitleSkeleton = () => (
  <Skeleton
    variant="rectangular"
    sx={{
      marginBottom: '15px',
      width: '100%',
      maxWidth: 300,
      height: 35
    }}
  />
);
const DescriptionSkeleton = () => (
  <Skeleton
    variant="rectangular"
    sx={{
      marginBottom: '20px',
      width: '100%',
      maxWidth: 520,
      height: 25
    }}
  />
);

const lightButtonCss = {
  root: {
    border: 'solid thin var(--accent-very-light-color)',
    backgroundColor: 'var(--accent-very-light-color)',
    minWidth: 'auto'
  }
};

const WelcomeMessage = ({
  meta,
  t,
  isFetching,
  user,
  loadingLanguage,
  dispatch,
  isEmbedded,
  shouldShowDiscoveryButton
}) => {
  const history = useHistory();
  const isMobile = useMediaQuery();

  const showOverviewTutorial = () => dispatch(runOverviewTutorial());

  const getTitleAccordingToLocalTime = userName => {
    // maps wall clock hours to translation keys (for example, at 05:00 use "early", and at 10:00 use "morning")
    const titleKeysForLocalHours = [
      [0, 'night'],
      [5, 'early'],
      [8, 'morning'],
      [12, 'lunch'],
      [13, 'afternoon'],
      [17, 'evening'],
      [22, 'late']
    ];

    // get the wall clock hour (an integer between 0 and 23) according to the browser's local time zone
    const hoursLocalTime = new Date().getHours();

    // find the bucket corresponding to the wall clock hour
    let idx = titleKeysForLocalHours.length - 1;
    while (idx > 0 && titleKeysForLocalHours[idx][0] > hoursLocalTime) {
      idx--;
    }
    const key = `welcome-message.title.${titleKeysForLocalHours[idx][1]}`;
    return t(key, { user: userName });
  };

  const formattedDescription = useMemo(() => {
    if (isFetching) {
      return '';
    } else if (meta.htmlDescription) {
      return handleReactTagsFromString(meta.htmlDescription, user.username, dispatch, t);
    } else {
      return t('welcome-message.default-description', { name: meta.name });
    }
  }, [isFetching, meta?.htmlDescription, meta.name, user?.username, dispatch, t]);

  const content = useMemo(() => {
    return (
      <>
        <header data-test="welcomeMessage" data-kgid={meta.id}>
          {loadingLanguage || isFetching ? (
            <>
              <TitleSkeleton />
              <DescriptionSkeleton />
            </>
          ) : (
            <>
              <h2 className={styles.title}>{getTitleAccordingToLocalTime(user.username)}</h2>
              <p className={styles.description}>{formattedDescription}</p>
            </>
          )}

          {!isMobile && <MilestonesChecklist t={t} />}
        </header>
        <div className={styles.buttonContainer}>
          <div className={styles.buttonGroup}>
            {shouldShowDiscoveryButton && !isMobile && (
              <div className={styles.buttonBox}>
                <Button
                  layout="veezoo"
                  size="small"
                  mode="dark"
                  icon={<ChatIcon />}
                  onClick={() => changeUrlGivenCurrentQueryParams(history, { showDialogue: possibleStates.discovery })}
                  data-test="welcomeMessageDiscoveryButton"
                >
                  {t('welcome-message.what-can-i-ask')}
                </Button>
              </div>
            )}
            {meta.hasKnowledgeGraphSupport && !isMobile && (
              <div className={styles.buttonBox}>
                <Button
                  layout="veezoo"
                  size="small"
                  mode="dark"
                  icon={<KnowledgeGraphIcon />}
                  onClick={() => {
                    const urlParams = isEmbedded
                      ? { showDialogue: possibleStates.knowledgeGraph }
                      : { kgSidebar: true, visualMode: true };
                    changeUrlGivenCurrentQueryParams(history, urlParams);
                  }}
                >
                  {t('welcome-message.what-data-is-there')}
                </Button>
              </div>
            )}
          </div>
          {!isEmbedded && (
            <div className={styles.buttonGroup}>
              <div className={styles.buttonBox}>
                <Button
                  layout="veezoo"
                  size="small"
                  classes={lightButtonCss}
                  icon={<PlayIcon />}
                  onClick={showOverviewTutorial}
                >
                  Tutorial
                </Button>
              </div>
              <div className={styles.buttonBox}>
                <Button
                  layout="veezoo"
                  size="small"
                  classes={lightButtonCss}
                  icon={<SupportIcon />}
                  onClick={() => openSupportMailWithFreshId(meta.htmlSupport, user.username)}
                >
                  {t('welcome-message.support')}
                </Button>
              </div>
              <div className={styles.buttonBox}>
                <Button
                  layout="veezoo"
                  size="small"
                  classes={lightButtonCss}
                  icon={<QuestionIcon />}
                  onClick={undefined}
                  href={meta.linkToDocumentation}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Docs
                </Button>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }, [formattedDescription, meta.id, meta.title, loadingLanguage, isMobile, shouldShowDiscoveryButton]);

  return <BaseMessage content={content} enableStandardFooter={false} />;
};

const mapStateToProps = state => ({
  meta: state.knowledgeGraphMeta.meta,
  shouldShowDiscoveryButton:
    (state.discovery.topics && state.discovery.topics?.length > 0) ||
    (state.knowledgeGraphMeta.meta.hasWritePermission && state.user.isLlmParserEnabled),
  user: state.user,
  isFetching: state.knowledgeGraphMeta.isFetching,
  loadingLanguage: state.user.loadingLanguage
});

export default withTranslation('veezoo', { withRef: true })(
  connect(mapStateToProps)(withEmbedded(memo(WelcomeMessage)))
);

WelcomeMessage.propTypes = {
  meta: PropTypes.object,
  user: PropTypes.object,
  classes: PropTypes.object,
  t: PropTypes.func,
  isFetching: PropTypes.bool,
  loadingLanguage: PropTypes.bool,
  shouldShowDiscoveryButton: PropTypes.bool,
  isEmbedded: PropTypes.bool
};
