import { useEffect, useRef } from 'react';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import { SnackbarProvider } from 'notistack';

import thunk from 'redux-thunk';
import rootReducer from 'store/reducers';
import { BrowserRouter as Router } from 'react-router-dom';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/CloseRounded';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import App from 'components/app/App';
import Theme from 'components/ThemeContainer';
import ConnectedPickersUtilsProvider from 'components/ConnectedPickersUtilsProvider';

import { LoaderProvider } from 'components/v3';
import { ConfirmationModalProvider, KnowledgeGraphProvider } from 'hooks';

import { layouts } from 'config/constants';

import globalMuiTheme from 'components/theme';

import useMediaQuery from 'utils/mediaQueries';
import { contextPath } from 'config/baseURL';

const windowIfDefined = typeof window === 'undefined' ? null : window;
const composeEnhancers = (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

// handle bundled actions in reducer
export const store = createStore(
  rootReducer,

  // IMPORTANT! thunk middleware allows you to write action creators that return a function instead of an action.
  // The thunk can be used to delay the dispatch of an action,
  // or to dispatch only if a certain condition is met.
  // The inner function receives the store methods dispatch and getState as parameters.
  composeEnhancers(applyMiddleware(thunk))
);

const variantStyle = {
  fontSize: 13,
  fontWeight: 300,
  letterSpacing: '0.7px',
  padding: '0 10px',
  borderRadius: 3,
  boxShadow: '0 2px 2px 0 var(--shadow-color)'
};
const snackbarStyles = () => ({
  variantInfo: {
    ...variantStyle,
    backgroundColor: 'var(--primary-color) !important'
  },
  variantSuccess: {
    ...variantStyle
  },
  variantError: {
    ...variantStyle
  }
});

const fixHeight = () => {
  // setTimeout is necessary, otherwise the "window.innerHeight" won't be updated yet when fixHeight is called.
  // This seems to be a bug only on Chrome, check here:
  // https://stackoverflow.com/questions/65463449/window-innerwidth-incorrect-in-chrome-ios-87-0-4280-77-after-orientation-change
  setTimeout(() => {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`);
  }, 100);
};

const Root = ({ classes }) => {
  const notistackRef = useRef(null);
  const isMobile = useMediaQuery();

  // We add the "orientation change" listener so that when the user changes the device orientation (mobile phone, tablet),
  // the window will always remain with 100% height.
  useEffect(() => {
    addEventListener('load', fixHeight);
    addEventListener('resize', fixHeight);
    addEventListener('orientationchange', fixHeight);
    return () => {
      removeEventListener('load', fixHeight);
      removeEventListener('resize', fixHeight);
      removeEventListener('orientationchange', fixHeight);
    };
  }, []);

  const close = key => notistackRef.current.closeSnackbar(key);

  return (
    <Provider store={store}>
      <Router basename={contextPath}>
        <StyledEngineProvider injectFirst>
          <MuiThemeProvider theme={globalMuiTheme}>
            <ThemeProvider theme={globalMuiTheme}>
              <SnackbarProvider
                classes={classes}
                ref={notistackRef}
                maxSnack={1}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                action={key => (
                  <IconButton color="inherit" onClick={() => close(key)}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                )}
              >
                <KnowledgeGraphProvider>
                  <ConfirmationModalProvider>
                    <LoaderProvider layout={layouts.signup}>
                      <ConnectedPickersUtilsProvider>
                        <>
                          <Theme />
                          <App isMobile={isMobile} />
                        </>
                      </ConnectedPickersUtilsProvider>
                    </LoaderProvider>
                  </ConfirmationModalProvider>
                </KnowledgeGraphProvider>
              </SnackbarProvider>
            </ThemeProvider>
          </MuiThemeProvider>
        </StyledEngineProvider>
      </Router>
    </Provider>
  );
};

export default withStyles(snackbarStyles)(Root);
