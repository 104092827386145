export const buttonSx = {
  '&.MuiButtonBase-root': {
    color: 'rgba(255, 255, 255, 0.7)',
    marginLeft: '1px',
    minWidth: 0,
    width: 37,
    height: 39,
    fontSize: 12,
    fontWeight: '500',
    borderRadius: '3px',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.2)'
    }
  }
};

export const menuItemSx = {
  '&.MuiMenuItem-root': {
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '6px 16px'
  }
};

export const menuIconSx = {
  '&.MuiListItemIcon-root': { minWidth: 30 }
};
