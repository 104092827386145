import { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import Paper from '@mui/material/Paper';
import MarkdownHtml from 'studio/components/MarkdownHtml/MarkdownHtml';

const paperSx = {
  '&.MuiPaper-root': {
    padding: '20px'
  }
};

const Q2QTooltip = forwardRef(
  (
    {
      children,
      tooltipForAutocomplete,
      title = '',
      description = '',
      placement = 'top',
      controlled = false,
      open = false,
      ...rest
    },
    ref
  ) => {
    // Tooltip has a slightly different "sx" logic, so it doesn't require "&.MuiTooltip-tooltip" to be specified here.
    // Instead, we add these classes to the "sx" props that stands inside "componentsProps".
    const tooltipSx = {
      backgroundColor: 'transparent',
      fontSize: 14,
      fontWeight: 300,
      ...(tooltipForAutocomplete ? { marginLeft: '-7px !important' } : {})
    };

    const [keepOpen, setKeepOpen] = useState(false);

    /* The description set in the span is HTML sanitized by the backend */
    const tooltipContent = (
      <Paper
        {...(controlled
          ? {
              onMouseEnter: () => setKeepOpen(true),
              onMouseLeave: () => setKeepOpen(false)
            }
          : {})}
        elevation={5}
        sx={paperSx}
      >
        <h4>{title}</h4>
        {typeof description === 'string' ? <MarkdownHtml html={description} /> : description}
      </Paper>
    );

    return (
      <Tooltip
        ref={ref}
        title={tooltipContent}
        placement={placement}
        componentsProps={{
          tooltip: {
            sx: tooltipSx
          }
        }}
        interactive
        {...(controlled ? { open: open || keepOpen } : {})}
        {...rest}
      >
        {children}
      </Tooltip>
    );
  }
);

export default Q2QTooltip;

Q2QTooltip.propTypes = {
  children: PropTypes.any,
  tooltipForAutocomplete: PropTypes.bool,
  open: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  placement: PropTypes.string,
  controlled: PropTypes.bool
};
