import { get, patch } from 'services/http';

const fetchMeasure = uri => {
  return get(`/knowledge-graph/measures/${encodeURIComponent(uri)}`);
};

const updateMeasure = (uri, body) => {
  return patch(`/knowledge-graph/measures/${encodeURIComponent(uri)}`, JSON.stringify(body));
};

export default { fetchMeasure, updateMeasure };
