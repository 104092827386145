import React, { forwardRef } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import MuiLink from '@mui/material/Link';

// The link handles the ?id=... parameter for KGs if knowledgeGraph or kgId is specified (not undefined).
// The ID is taken from kgId, otherwise if knowledgeGraph is specified, the id is taken from the knowledgeGraph object
// If the ID set, the query parameter will be overwritten, and if it is null (not undefined), the query parameter is removed
export const Link = forwardRef(({ href, knowledgeGraph, kgId, newWindow, children, ...props }, ref) => {
  const location = useLocation();

  // fall back to the current path if no href has been specified
  href = href || location.pathname;
  // check if the href is a path
  if (href.startsWith('/')) {
    // use React Router link component
    props.component = RouterLink;
    // use the search if any has been specified via the additional component props
    let search = props.to?.search;
    // add the 'id' parameter if the kgId or a knowledge graph has been specified
    if (kgId || knowledgeGraph) {
      const params = new URLSearchParams(search);
      params.set('id', encodeURIComponent(kgId || knowledgeGraph.data.id));
      search = `?${params.toString()}`;
    }

    props.to = {
      pathname: href,
      search
    };
  }
  // the href is an absolute URL
  else {
    // use the standard link component
    props.href = href;
  }

  // if specified, open the link in a new window
  if (newWindow) {
    props.target = '_blank';
    props.rel = 'noopener noreferrer';
  }

  return (
    <MuiLink ref={ref} {...props}>
      {children}
    </MuiLink>
  );
});

export default Link;
