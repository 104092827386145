import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { AddToBoardModal } from 'components/v3';
import { addBoardToList } from 'store/modules/board';

// We use the "connected" component so that we apply Redux, Translations and Backend services here, keeping the
// "AddToBoardModal" component pure. This way we can test the component inside Storybook, once those resources
// don't work inside Storybook's environment.
const ConnectedAddToBoardModal = ({ boardsList, loading, isCreatingBoard, dispatch, preselectedBoardId, ...props }) => {
  const { t } = useTranslation();

  const filteredBoards = useMemo(() => boardsList.filter(board => board.isOwned, [boardsList]));

  const createBoard = async name => {
    const result = await dispatch(addBoardToList(name, [], undefined, false, t));
    return result;
  };

  return (
    <AddToBoardModal
      boardsList={filteredBoards}
      preselectedBoardId={preselectedBoardId}
      confirmLabel={t('save')}
      closeLabel={t('cancel')}
      loading={loading || isCreatingBoard}
      onCreateBoardClick={createBoard}
      {...props}
    />
  );
};

const mapStateToProps = state => ({
  boardsList: state.board.boardsList,
  isCreatingBoard: state.board.isCreatingBoard,
  preselectedBoardId: state.board.recentlyActiveBoard
});

export default connect(mapStateToProps)(ConnectedAddToBoardModal);
