import { createTheme } from '@mui/material/styles';

// Make sure that all Material UI Components use the same font per default etc.
// That way we don't need to redefine the style in each new component
export default createTheme({
  typography: {
    fontFamily: 'var(--font-family)',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    button: {
      textTransform: 'none'
    }
  }
});
