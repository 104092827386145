import { tss } from 'tss-react';

export const useTssStyles = tss.create({
  title: {
    display: 'inline-block',
    marginTop: '25px',
    fontSize: '14px',
    fontWeight: '400'
  },
  usersListContainer: {
    maxHeight: '200px',
    overflowY: 'scroll',
    overflowX: 'hidden',
    boxSizing: 'border-box',
    margin: '10px 0 0'
  },
  list: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
    ['& > li']: {
      display: 'flex',
      padding: '3px 8px',
      ['&:hover']: {
        backgroundColor: 'var(--light-gray)'
      }
    }
  },
  image: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '10px'
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '5px'
  },
  username: {
    fontWeight: '300',
    fontSize: '14px'
  },
  autocomplete: {
    width: '100%'
  },
  copyField: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 0'
  },
  listField: {
    display: 'flex',
    alignItems: 'center'
  },
  noUsersSelected: {
    width: '100%',
    fontSize: '12px',
    color: 'var(--dark-gray)',
    minHeight: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  copyIconContainer: {
    width: '32px',
    height: '32px',
    borderRadius: '4px',
    marginLeft: '5px',
    marginTop: '2px',
    padding: '4px',
    boxSizing: 'border-box',
    backgroundColor: 'var(--light-gray)',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'var(--light-darker-gray)',
      '& > .copyIcon path': {
        fill: 'var(--primary-color)'
      }
    }
  },
  removeButton: {
    border: 'solid thin transparent',
    borderRadius: '50%',
    backgroundColor: 'transparent',
    width: '22px',
    height: '22px',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    ['& > svg']: {
      width: '12px'
    },
    ['&:hover:not(:disabled)']: {
      backgroundColor: 'var(--primary-color)',
      ['& path']: {
        fill: 'white'
      }
    },
    ['&:disabled']: {
      cursor: 'not-allowed',
      backgroundColor: 'transparent',
      ['& path']: {
        fill: 'var(--normal-gray)'
      }
    }
  },
  customButtonsContainer: {
    width: '100%',
    textAlign: 'right'
  },
  accessContainer: {
    boxSizing: 'border-box',
    margin: '10px 8px 4px',
    ['&:hover']: {
      color: 'var(--secondary-color) !important',
      ['& > svg path']: {
        fill: 'var(--secondary-color)'
      }
    },
    ['&:disabled']: {
      color: 'var(--disabled-veezoo-blue) !important',
      '& > svg path': {
        fill: 'var(--disabled-veezoo-blue)'
      }
    }
  },
  displayFlex: {
    display: 'flex'
  },
  accessIcon: {
    display: 'flex',
    padding: '7px 10px 0 0'
  },
  accessFields: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '5px'
  },
  field: {
    fontWeight: '300',
    fontSize: '14px',
    width: 'min-content'
  },
  description: {
    color: 'var(--dark-gray)',
    fontSize: '12px'
  },
  checkboxContainer: {
    marginTop: '7px',
    marginLeft: '-2px'
  },
  copyLink: {
    display: 'inline-block',
    marginTop: '10px',
    fontSize: '14px',
    cursor: 'pointer',
    color: 'var(--primary-color)',
    ['& > svg']: {
      width: '17px',
      height: '17px',
      marginRight: '4px',
      ['& > path']: {
        fill: 'var(--primary-color)'
      }
    }
  },
  dialogActions: {
    justifyContent: 'space-between !important',
    padding: '0 25px 15px !important'
  },
  iconContainer: {
    width: '30px',
    height: '30px',
    backgroundColor: 'var(--light-medium-gray)',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  icon: {
    width: '18px',
    height: '18px'
  },
  menuItemIcon: {
    display: 'inline-flex',
    width: '20px',
    height: '20px',
    verticalAlign: 'middle',
    marginRight: '7px',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'var(--light-medium-gray)',
    borderRadius: '50%',
    ['& > svg']: {
      maxWidth: '20px',
      maxHeight: '10px',
      fill: 'var(--primary-color)'
    }
  },
  userInitials: {
    backgroundColor: 'var(--primary-color)',
    color: 'white',
    fontSize: '8px'
  }
});
