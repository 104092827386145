import { get, post, patch } from 'services/http';

const fetchWidgetById = id => get(`/widgets/${id}?sessionId=${window.veezoo.sessionId}`);

const fetchWidgetsByBoardId = id => get(`/boards/${id}/widgets`);

const addWidgetToBoard = (boardId, widget) => post(`/boards/${boardId}/widgets`, widget);

const updateWidgetsOfBoard = (boardId, widgets) => patch(`/boards/${boardId}/widgets`, widgets);

export default { fetchWidgetById, fetchWidgetsByBoardId, addWidgetToBoard, updateWidgetsOfBoard };
