import { get, post } from 'services/http';

const getDeclaredVariables = id => get(`/studio/api/knowledge-graphs/${encodeURIComponent(id)}/declared-variables`);

const getVariableAssignment = id => get(`/studio/api/knowledge-graphs/${encodeURIComponent(id)}/variable-assignment`);

const setVariableAssignment = (id, body) =>
  post(`/studio/api/knowledge-graphs/${encodeURIComponent(id)}/variable-assignment`, body);

export default {
  getDeclaredVariables,
  getVariableAssignment,
  setVariableAssignment
};
