import { get, post, del } from 'services/http';

const getInvitations = () => get('/studio/api/invitations');

const getInvitation = id => get(`/studio/api/invitations/${encodeURIComponent(id)}`);

const createInvitations = body => post('/studio/api/invitations', body);

const deleteInvitation = id => del(`/studio/api/invitations/${encodeURIComponent(id)}`);

export default { getInvitations, getInvitation, createInvitations, deleteInvitation };
