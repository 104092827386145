import { fetchGet, fetchPost, handleError } from 'services/http';

import {
  USER_REQUEST,
  USER_FAILURE,
  USER_SUCCESS,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  RESET_PASSWORD_MAIL_REQUEST,
  RESET_PASSWORD_MAIL_SUCCESS,
  RESET_PASSWORD_MAIL_FAILURE,
  fetchUserInformation
} from './user';

import {
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  VALIDATE_TOKEN_REQUEST,
  VALIDATE_TOKEN_SUCCESS,
  VALIDATE_TOKEN_FAILURE
} from './passwordReset';

import { THEME_LOAD, THEME_FETCH_SUCCESS, loadTheme } from './theme';

import {
  CHATMESSAGE_SUCCESS,
  CHATMESSAGE_FAILURE,
  CHATMESSAGES_REQUEST,
  CHATMESSAGES_SUCCESS,
  CHATMESSAGES_FAILURE,
  CHATMESSAGE_REQUEST,
  COMPLEMENTARY_REQUEST,
  COMPLEMENTARY_SUCCESS,
  COMPLEMENTARY_FAILURE,
  BOARD_CHAT_MESSAGE_REQUEST,
  BOARD_CHAT_MESSAGE_SUCCESS,
  BOARD_CHAT_MESSAGE_FAILURE
} from './chat-messages';

import {
  ANALYZE_REQUEST,
  ANALYZE_SUCCESS,
  ANALYZE_FAILURE,
  SUGGESTIONLIST_MARK_REMOVE,
  SUGGESTIONLIST_REMOVE
} from './inputAnalysis';

import {
  ADD_WIDGET_TO_BOARD_REQUEST,
  ADD_WIDGET_TO_BOARD_SUCCESS,
  ADD_WIDGET_TO_BOARD_FAILURE
} from 'store/modules/board-widgets';

import services from 'services';
import {
  MODIFY_VISUALIZATION_FAILURE,
  MODIFY_VISUALIZATION_REQUEST,
  MODIFY_VISUALIZATION_SUCCESS
} from 'store/modules/visualizations';

export const AUTHENTICATION_REQUEST = 'AUTHENTICATION_REQUEST';
export const AUTHENTICATION_INITIAL_SUCCESS = 'AUTHENTICATION_INITIAL_SUCCESS';
export const AUTHENTICATION_INITIAL_FAILURE = 'AUTHENTICATION_INITIAL_FAILURE';
export const AUTHENTICATION_SUCCESS = 'AUTHENTICATION_SUCCESS';
export const AUTHENTICATION_FAILURE = 'AUTHENTICATION_FAILURE';
export const AUTHENTICATION_BLOCKED = 'AUTHENTICATION_BLOCKED';

export const FIRST_LOGIN_REQUEST = 'FIRST_LOGIN_REQUEST';
export const FIRST_LOGIN_SUCCESS = 'FIRST_LOGIN_SUCCESS';
export const FIRST_LOGIN_FAILURE = 'FIRST_LOGIN_FAILURE';

export const SIGNOUT_REQUEST = 'SIGNOUT_REQUEST';
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS';
export const SIGNOUT_FAILURE = 'SIGNOUT_FAILURE';
export const INDIRECT_SIGNOUT_SUCCESS = 'INDIRECT_SIGNOUT_SUCCESS';

export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';

export const ENVIRONMENT_SUCCESS = 'ENVIRONMENT_SUCCESS';
export const ENVIRONMENT_FAILURE = 'ENVIRONMENT_FAILURE';

export const authenticate = (username, password, isFirstLogin = false) => {
  return dispatch => {
    // set the isFetchingAuthentication flag so the UI knows it should show a loader.
    dispatch({
      type: isFirstLogin ? FIRST_LOGIN_REQUEST : AUTHENTICATION_REQUEST
    });

    fetchPost('/login', { username: username, password: password })
      .then(response => response.text().then(text => [response, text]))
      .then(([response, text]) => {
        if (response.ok) {
          // if present, the returned location header contains the base URL (origin) the frontend needs to redirect to
          const newLocation = response.headers.get('Location');
          if (newLocation) {
            // redirect the page
            const url = newLocation + window.location.pathname + window.location.search + window.location.hash;
            window.location.replace(url);
          } else {
            dispatch({
              type: isFirstLogin ? FIRST_LOGIN_SUCCESS : AUTHENTICATION_SUCCESS
            });
            dispatch(loadTheme('default'));
            dispatch(fetchUserInformation());
          }
        } else if (response.status === 403) {
          dispatch({
            type: AUTHENTICATION_BLOCKED
          });
        } else {
          dispatch({
            type: isFirstLogin ? FIRST_LOGIN_FAILURE : AUTHENTICATION_FAILURE
          });
        }
      })
      .catch(error => console.log('ERROR', error));
  };
};

export const checkAuthStatus = () => {
  return dispatch => {
    // get the isFetchingAuthentication flag so the UI knows it should show a loader.
    dispatch({
      type: AUTHENTICATION_REQUEST
    });

    // check if the user is authenticated
    fetchGet('/user/status')
      .then(response => {
        response.ok
          ? dispatch({ type: AUTHENTICATION_INITIAL_SUCCESS })
          : dispatch({ type: AUTHENTICATION_INITIAL_FAILURE });
      })
      .catch(error => console.log('ERROR', error));
  };
};

export const signup = (user, credentials) => async dispatch => {
  dispatch({ type: SIGNUP_REQUEST });

  const result = await services.signup(user);

  if (result.success) {
    dispatch({ type: SIGNUP_SUCCESS });
    return dispatch(authenticate(credentials.email, credentials.password, true));
  }

  dispatch({ type: SIGNUP_FAILURE, statusCode: result.response.status, error: result.response.data });
};

export const signout = (redirectToSignup = false) => async dispatch => {
  dispatch({ type: SIGNOUT_REQUEST });

  const result = await services.signout();
  handleError(result, dispatch);

  if (!result.success) {
    return dispatch({ type: SIGNOUT_FAILURE });
  }

  dispatch({
    type: SIGNOUT_SUCCESS,
    redirectToSignup
  });
};

export const fetchEnvironment = () => {
  return dispatch => {
    // request the environment information
    fetchGet('/environment')
      .then(response => response.json().then(json => [response, json]))
      .then(([response, json]) => {
        if (response.ok) {
          dispatch({
            type: ENVIRONMENT_SUCCESS,
            mode: json.mode
          });
        } else {
          dispatch({
            type: ENVIRONMENT_FAILURE
          });
        }
      })
      .catch(error => console.log('ERROR', error));
  };
};

/**
 * set isFetchingAuthentication to false, so that the loading
 * component is shown on initial page load.
 */
export const initialNetworkState = {
  signupSuccess: false,
  signupFailure: false,
  isAuthorized: false,
  redirectToSignup: false,
  isFetchingVisualizationModification: false,
  isLoginUnsuccessful: false,
  isFetchingAuthentication: false,
  isChangingPassword: false,
  isFetchingMessageList: false,
  isFetchingMessage: false,
  isFetchingUser: false,
  isFetchingTheme: false,
  isFetchingSuggestionList: false,
  isFetchingBoardWidgets: false,
  isAddingBoardWidget: false,
  hasAddedBoardWidget: false,
  isRemovingBoardWidget: false,
  isFetchingPasswordResetMail: false,
  isValidatingToken: false,
  isSigningUp: false,
  isCloud: false,
  isOnPremise: false,
  hasFetchedEnvironment: false,
  isFirstTimeLogging: false,
  isAccountBlocked: false,
  sidebarBoardFetchingInfo: []
};

// describe the status of network calls
export function network(state = initialNetworkState, action) {
  switch (action.type) {
    case USER_REQUEST:
      return {
        ...state,
        isFetchingUser: true
      };

    case USER_FAILURE:
    case USER_SUCCESS:
      return {
        ...state,
        isFetchingUser: false
      };

    case FIRST_LOGIN_REQUEST:
      return {
        ...state,
        isFirstTimeLogging: true
      };

    case FIRST_LOGIN_SUCCESS:
      return {
        ...state,
        isFirstTimeLogging: false,
        isAuthorized: true
      };

    case FIRST_LOGIN_FAILURE:
      return {
        ...state,
        isFirstTimeLogging: false,
        isAuthorized: false
      };

    case MODIFY_VISUALIZATION_REQUEST:
      return {
        ...state,
        isFetchingVisualizationModification: true
      };

    case MODIFY_VISUALIZATION_SUCCESS:
    case MODIFY_VISUALIZATION_FAILURE:
      return {
        ...state,
        isFetchingVisualizationModification: false
      };

    case AUTHENTICATION_REQUEST:
      return {
        ...state,
        isFetchingAuthentication: true
      };

    case AUTHENTICATION_INITIAL_SUCCESS:
      return {
        ...state,
        isFetchingAuthentication: false,
        isAuthorized: true
      };

    case AUTHENTICATION_INITIAL_FAILURE:
      return {
        ...state,
        isFetchingAuthentication: false,
        isAuthorized: false
      };

    case SIGNUP_REQUEST:
      return {
        ...state,
        isSigningUp: true,
        signupFailure: false
      };

    case SIGNUP_SUCCESS:
      return {
        ...state,
        isSigningUp: false,
        signupSuccess: true
      };

    case SIGNUP_FAILURE:
      return {
        ...state,
        isSigningUp: false,
        signupFailure: true,
        signupStatusCode: action.statusCode,
        signupError: action.error
      };

    case AUTHENTICATION_SUCCESS:
      return {
        ...state,
        isFetchingAuthentication: false,
        isAuthorized: true,
        isLoginUnsuccessful: false,
        isAccountBlocked: false
      };

    case AUTHENTICATION_FAILURE:
      return {
        ...state,
        isFetchingAuthentication: false,
        isAuthorized: false,
        isLoginUnsuccessful: true,
        isAccountBlocked: false
      };

    // in case of too many wrong attempts to login
    case AUTHENTICATION_BLOCKED:
      return {
        ...state,
        isFetchingAuthentication: false,
        isAuthorized: false,
        isLoginUnsuccessful: false,
        isAccountBlocked: true
      };

    case ENVIRONMENT_SUCCESS:
      return {
        ...state,
        // for now we just extract if we are on Cloud mode or not
        isCloud: action.mode === 'CLOUD',
        isOnPremise: action.mode === 'ON_PREMISE',
        hasFetchedEnvironment: true
      };

    case ENVIRONMENT_FAILURE:
      return {
        ...state,
        hasFetchedEnvironment: false
      };

    case THEME_LOAD:
      return {
        ...state,
        isFetchingTheme: true
      };

    case THEME_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingTheme: false
      };

    case SIGNOUT_REQUEST:
      return {
        ...state
      };

    case SIGNOUT_SUCCESS:
      return {
        ...state,
        isAuthorized: false,
        redirectToSignup: action.redirectToSignup
      };

    // indirect signout is when we decide to sign out because of a 404
    // this is only because axa redirects our 401's and transforms them into 404
    // so we only want this behavior to happen onPremise
    case INDIRECT_SIGNOUT_SUCCESS:
      return {
        ...state,
        isAuthorized: !state.isOnPremise && state.isAuthorized
      };

    case SIGNOUT_FAILURE:
      return {
        ...state
      };

    case CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        isChangingPassword: true
      };

    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isChangingPassword: false
      };

    case CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        isChangingPassword: false
      };

    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isChangingPassword: true
      };

    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isChangingPassword: false
      };

    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        isChangingPassword: false
      };

    case VALIDATE_TOKEN_REQUEST:
      return {
        ...state,
        isValidatingToken: true
      };

    case VALIDATE_TOKEN_SUCCESS:
      return {
        ...state,
        isValidatingToken: false
      };

    case VALIDATE_TOKEN_FAILURE:
      return {
        ...state,
        isValidatingToken: false
      };

    case CHATMESSAGES_REQUEST:
      return {
        ...state,
        isFetchingMessageList: true
      };

    case CHATMESSAGES_SUCCESS:
    case CHATMESSAGES_FAILURE:
      return {
        ...state,
        isFetchingMessageList: false
      };

    case CHATMESSAGE_REQUEST:
    case COMPLEMENTARY_REQUEST:
      return {
        ...state,
        isFetchingMessage: true
      };

    case CHATMESSAGE_SUCCESS:
    case COMPLEMENTARY_SUCCESS:
      return {
        ...state,
        isFetchingMessage: false
      };

    case COMPLEMENTARY_FAILURE:
    case CHATMESSAGE_FAILURE:
      return {
        ...state,
        isFetchingMessage: false
      };

    case ANALYZE_REQUEST:
      return {
        ...state,
        isFetchingSuggestionList: true,
        shouldRemoveSuggestionList: false
      };

    case ANALYZE_SUCCESS:
      return {
        ...state,
        isFetchingSuggestionList: false
      };

    case ANALYZE_FAILURE:
      return {
        ...state,
        isFetchingSuggestionList: false
      };

    case SUGGESTIONLIST_MARK_REMOVE:
      return {
        ...state,
        shouldRemoveSuggestionList: true
      };

    case SUGGESTIONLIST_REMOVE:
      return {
        ...state,
        shouldRemoveSuggestionList: true // suggestionslist responses coming after this should also be removed
      };

    case ADD_WIDGET_TO_BOARD_REQUEST:
      return {
        ...state,
        hasAddedBoardWidget: false,
        isAddingBoardWidget: true
      };

    case ADD_WIDGET_TO_BOARD_SUCCESS:
      return {
        ...state,
        hasAddedBoardWidget: true,
        isAddingBoardWidget: false
      };

    case ADD_WIDGET_TO_BOARD_FAILURE:
      return {
        ...state,
        hasAddedBoardWidget: false,
        isAddingBoardWidget: false
      };

    case RESET_PASSWORD_MAIL_REQUEST:
      return {
        ...state,
        isFetchingPasswordResetMail: true
      };

    case RESET_PASSWORD_MAIL_SUCCESS:
      return {
        ...state,
        isFetchingPasswordResetMail: false
      };

    case RESET_PASSWORD_MAIL_FAILURE:
      return {
        ...state,
        isFetchingPasswordResetMail: false
      };

    case BOARD_CHAT_MESSAGE_REQUEST:
    case BOARD_CHAT_MESSAGE_SUCCESS:
    case BOARD_CHAT_MESSAGE_FAILURE: {
      return {
        ...state,
        sidebarBoardFetchingInfo: [
          {
            boardId: action.boardId,
            isAlreadyFetchingMessageBoard: action.isAlreadyFetchingMessageBoard
          }
        ]
      };
    }

    default:
      return state;
  }
}
