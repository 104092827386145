import { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import MultiAnswerPureMessage from './MultiAnswerPureMessage';

import ChatBoard from '../board/ChatBoard';

class MultiAnswerMessage extends Component {
  constructor(props) {
    super(props);

    this.multiAnswerMessageRef = createRef();
  }

  render() {
    const {
      answerId,
      message,
      question,
      metaPartialAnswers,
      partialAnswers,
      referenceTo,
      expandedMiniWidget,
      localQuestionId,
      boardName,
      id,
      commonFilters,
      scrollTo,
      t,
      addToBoardCallback,
      dispatch,
      questionId
    } = this.props;

    const miniBoard = (
      <ChatBoard
        answerId={answerId}
        messageId={id}
        isEditing={false}
        metaPartialAnswers={metaPartialAnswers}
        partialAnswers={partialAnswers}
      />
    );

    return (
      <MultiAnswerPureMessage
        ref={this.multiAnswerMessageRef}
        targetRef={this.multiAnswerMessageRef}
        filters={commonFilters}
        message={message}
        answerId={answerId}
        question={question}
        expandedMiniWidget={expandedMiniWidget}
        miniBoard={miniBoard}
        title={boardName}
        messageId={id}
        referenceTo={referenceTo}
        localQuestionId={localQuestionId}
        dispatch={dispatch}
        addToBoardCallback={addToBoardCallback}
        scrollTo={scrollTo}
        t={t}
        questionId={questionId}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  partialAnswers: state.partialAnswers,
  expandedMiniWidget: state.expandedMiniWidgets.find(w => w.messageId === ownProps.id)
});

export default withTranslation('veezoo', { withRef: true })(connect(mapStateToProps)(MultiAnswerMessage));
