import { tss } from 'tss-react';

export const useStyles = tss.create(({ theme }) => ({
  root: {
    width: '100%',
    minWidth: 570,
    height: '100%',
    backgroundColor: theme.palette.background.paper
  }
}));

export const sx = {
  buttonIcon: {
    '&.MuiSvgIcon-root': {
      width: '20px !important',
      height: '20px !important'
    }
  },
  skeleton: {
    '&.MuiSkeleton-root': {
      marginLeft: '3px',
      width: '100%',
      height: 20,
      backgroundColor: 'rgba(0, 0, 0, 0.1)'
    }
  },
  listItem: {
    '&.MuiListItem-root': {
      paddingTop: '4px',
      paddingBottom: '4px'
    }
  },
  icon: {
    '&.MuiListItemIcon-root': {
      fill: 'grey',
      height: 20,
      width: 20,
      minWidth: 20,
      paddingRight: '13px'
    }
  },
  listSubheader: {
    '&.MuiListSubheader-root': {
      lineHeight: 'unset',
      fontSize: 11,
      letterSpacing: '0.4px',
      paddingTop: '10px',
      paddingBottom: '6px',
      background: 'white'
    }
  }
};
