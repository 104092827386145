import { tss } from 'tss-react';
import { makeStyles } from 'tss-react/mui';

const border = 'solid thin var(--light-gray)';
const tableBorderRadius = '10px';

const customCellInlineRow = {
  display: 'inline-block',
  fontSize: 12,
  margin: '4px 0'
};

export const useViewStyles = tss.create({
  browseBoardsListContainer: {
    marginTop: '10px',
    border,
    backgroundColor: 'white',
    borderRadius: tableBorderRadius
  },
  paginationContainer: {
    borderTop: border,
    borderBottomLeftRadius: tableBorderRadius,
    borderBottomRightRadius: tableBorderRadius,
    backgroundColor: 'var(--lightest-gray)',
    paddingRight: '10px'
  },
  loadingContainer: {
    display: 'flex',
    padding: '18px',
    justifyContent: 'center'
  }
});

export const useStyles = makeStyles()((theme, params, classes) => {
  const isMobile = params?.isMobile;
  return {
    filterFields: {
      marginTop: '15px'
    },
    fieldContainer: {
      width: '100%',
      ...(!isMobile ? { maxWidth: 225 } : {})
    },
    left: !isMobile
      ? {
          float: 'left',
          paddingRight: '5px'
        }
      : {},
    right: !isMobile
      ? {
          float: 'right'
        }
      : {},
    customCelContainer: {
      padding: '10px 15px',
      position: 'relative',
      [`&:hover .${classes.displayOnlyOnHover}`]: {
        display: 'block !important'
      }
    },
    customCellTitle: {
      ...customCellInlineRow,
      fontSize: 14,
      fontWeight: 600
    },
    customCellDetails: {
      ...customCellInlineRow,
      color: 'var(--normal-gray)',
      marginBottom: '10px',
      display: 'flex',
      alignItems: 'center'
    },
    customCellButtonsContainer: {
      position: 'absolute',
      top: 10,
      right: 10,
      textAlign: 'right'
    },
    button: {
      display: 'inline-block',
      marginLeft: '10px'
    },
    displayOnlyOnHover: {
      display: 'none'
    },

    subscribedIcon: {
      fill: 'green',
      width: 10,
      marginRight: '5px'
    },
    subscribedLabel: {
      fontWeight: '600',
      color: 'var(--success-color)'
    },
    sharerLabel: {
      fontWeight: '600'
    },
    dividerDot: {
      display: 'inline-block',
      margin: '0 7px',
      width: 5,
      height: 8,
      position: 'relative',
      boxSizing: 'border-box',
      border: 'solid 0px transparent',
      '& > span': {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: 'auto',
        borderRadius: '50%',
        backgroundColor: 'var(--normal-gray)',
        width: 2,
        height: 2,
        display: 'inline-block'
      }
    },
    loaderContainer: {
      width: '100%',
      height: '100%',
      boxSizing: 'border-box'
    },
    emptyBoard: {
      width: '100%',
      height: '100%',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      fontFamily: 'var(--font-family)',
      fontSize: 16,
      color: 'var(--darkest-gray)',
      border: 'solid thin var(--light-medium-gray)'
    },
    boardIcon: {
      width: 90,
      height: 90,
      '& path': {
        fill: 'var(--dark-gray)'
      }
    },
    errorIcon: {
      marginBottom: '10px'
    }
  };
});

export const sx = {
  shareIcon: {
    '&.MuiSvgIcon-root': {
      fill: 'var(--normal-gray)',
      width: 11,
      marginRight: '5px'
    }
  },
  subscribersIcon: {
    '&.MuiSvgIcon-root': {
      fill: 'var(--normal-gray)',
      width: 15,
      height: 15,
      marginRight: '5px'
    }
  }
};
