import { useEffect, useRef } from 'react';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import { SnackbarProvider } from 'notistack';

import { tss } from 'tss-react';

import thunk from 'redux-thunk';
import rootReducer from 'store/reducers';
import { BrowserRouter as Router } from 'react-router-dom';

import IconButton from '@mui/material/IconButton';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import App from 'components/app/App';
import Theme from 'components/ThemeContainer';

import { LoaderProvider } from 'components/v3';
import { ConfirmationModalProvider, KnowledgeGraphProvider, SidebarProvider } from 'hooks';

import { layouts } from 'config/constants';

import globalMuiTheme from 'components/theme';

import useMediaQuery from 'utils/mediaQueries';
import { contextPath } from 'config/baseURL';

const windowIfDefined = typeof window === 'undefined' ? null : window;
const composeEnhancers = (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

// handle bundled actions in reducer
export const store = createStore(
  rootReducer,

  // IMPORTANT! thunk middleware allows you to write action creators that return a function instead of an action.
  // The thunk can be used to delay the dispatch of an action,
  // or to dispatch only if a certain condition is met.
  // The inner function receives the store methods dispatch and getState as parameters.
  composeEnhancers(applyMiddleware(thunk))
);

const variantStyle = {
  fontSize: '13px !important',
  fontWeight: '300 !important',
  letterSpacing: '0.7px !important',
  padding: '0 10px !important',
  borderRadius: '3px !important',
  boxShadow: '0 2px 2px 0 var(--shadow-color) !important'
};

export const useSnackbarStyles = tss.create({
  variantInfo: {
    ...variantStyle,
    backgroundColor: 'var(--primary-color) !important'
  },
  variantSuccess: {
    ...variantStyle
  },
  variantError: {
    ...variantStyle
  }
});

const fixHeight = () => {
  // setTimeout is necessary, otherwise the "window.innerHeight" won't be updated yet when fixHeight is called.
  // This seems to be a bug only on Chrome, check here:
  // https://stackoverflow.com/questions/65463449/window-innerwidth-incorrect-in-chrome-ios-87-0-4280-77-after-orientation-change
  setTimeout(() => {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`);
  }, 100);
};

const Root = () => {
  const notistackRef = useRef(null);
  const isMobile = useMediaQuery();
  const { classes } = useSnackbarStyles();

  // We add the "orientation change" listener so that when the user changes the device orientation (mobile phone, tablet),
  // the window will always remain with 100% height.
  useEffect(() => {
    addEventListener('load', fixHeight);
    addEventListener('resize', fixHeight);
    addEventListener('orientationchange', fixHeight);
    return () => {
      removeEventListener('load', fixHeight);
      removeEventListener('resize', fixHeight);
      removeEventListener('orientationchange', fixHeight);
    };
  }, []);

  const close = key => notistackRef.current.closeSnackbar(key);

  return (
    <Provider store={store}>
      <Router basename={contextPath}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={globalMuiTheme}>
            <SnackbarProvider
              classes={classes}
              ref={notistackRef}
              maxSnack={1}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              action={key => (
                <IconButton color="inherit" onClick={() => close(key)}>
                  <CloseRoundedIcon fontSize="small" />
                </IconButton>
              )}
            >
              <KnowledgeGraphProvider>
                <ConfirmationModalProvider>
                  <SidebarProvider>
                    <LoaderProvider layout={layouts.signup}>
                      <>
                        <Theme />
                        <App isMobile={isMobile} />
                      </>
                    </LoaderProvider>
                  </SidebarProvider>
                </ConfirmationModalProvider>
              </KnowledgeGraphProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </Router>
    </Provider>
  );
};

export default Root;
