import { tss, keyframes } from 'tss-react';

const spinTime = '1s';

const spinAnimation = keyframes({
  '0%': {
    transform: 'rotate(0deg)'
  },
  '100%': {
    transform: 'rotate(360deg)'
  }
});

export const useStyles = tss.create(({ size, color }) => ({
  loading: {
    animation: `${spinAnimation} ${spinTime} linear infinite`,
    width: size,
    height: size,
    fill: color,
    '& path': {
      fill: ({ color }) => color
    }
  }
}));
