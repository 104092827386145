import { handleUnauthorized } from 'store/utils/authFetch';

import { CLOSE_ALL_ENTITIES } from './graphItemEntities';
import { fetchUserInformation } from '../user';
import services from 'services';
import { fetchGet, handleError } from 'services/http';

export const CLASS_ENTITY_REQUEST = 'CLASS_ENTITY_REQUEST';
export const CLASS_ENTITY_SUCCESS = 'CLASS_ENTITY_SUCCESS';
export const CLASS_ENTITY_FAILURE = 'CLASS_ENTITY_FAILURE';

export const CLOSE_ENTITY = 'CLOSE_ENTITY';

export const CHANGE_CLASS_ENTITY_REQUEST = 'CHANGE_CLASS_ENTITY_REQUEST';
export const CHANGE_CLASS_ENTITY_SUCCESS = 'CHANGE_CLASS_ENTITY_SUCCESS';
export const CHANGE_CLASS_ENTITY_FAILURE = 'CHANGE_CLASS_ENTITY_FAILURE';
export const CHANGE_CLASS_ENTITY_UNMANAGED = 'CHANGE_CLASS_ENTITY_UNMANAGED';
export const CLOSE_UNMANAGED_ENTITIES_WARNING = 'CLOSE_UNMANAGED_ENTITIES_WARNING';

export const fetchEntityInfo = link => {
  return dispatch => {
    dispatch({ type: CLASS_ENTITY_REQUEST, link });
    fetchGet('/knowledge-graph/entities/' + encodeURIComponent(link.uri))
      .then(response => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 401) {
          handleUnauthorized(dispatch);
        } else {
          dispatch({ type: CLASS_ENTITY_FAILURE });
          throw Error(response.statusText);
        }
      })
      .then(result => {
        dispatch({ type: CLASS_ENTITY_SUCCESS, entity: result });
      })
      .catch(error => console.log('ERROR', error));
  };
};

export const closeEntity = idx => {
  return {
    type: CLOSE_ENTITY,
    entityIndex: idx
  };
};

export const updateEntity = (uri, property, value) => {
  return async dispatch => {
    dispatch({ type: CHANGE_CLASS_ENTITY_REQUEST, property });

    let body = { [property]: value };
    const result = await services.updateEntity(uri, body);
    handleError(result, dispatch);
    if (!result.success) {
      if (result.response.status === 403) {
        dispatch({ type: CHANGE_CLASS_ENTITY_UNMANAGED });
        console.log('WARNING', 'Entity unmanaged');
      }
      dispatch({ type: CHANGE_CLASS_ENTITY_FAILURE, property });
    } else {
      dispatch({ type: CHANGE_CLASS_ENTITY_SUCCESS, entity: result.data, property });
      // refresh the user information as updating an entity possibly adds a milestone
      dispatch(fetchUserInformation());
      return result.data;
    }
  };
};

export const closeUnmanagedEntitiesWarning = () => {
  return {
    type: CLOSE_UNMANAGED_ENTITIES_WARNING
  };
};

// reducer
export const graphDetailedEntitiesInfo = (state = { entities: [], unmanagedEntityWarning: false }, action) => {
  switch (action.type) {
    case CLASS_ENTITY_REQUEST:
      return { ...state, entities: [{ label: action.name }] };

    case CLASS_ENTITY_SUCCESS:
      return { ...state, entities: [action.entity] };

    case CLASS_ENTITY_FAILURE:
      return { ...state, entities: [] };

    case CLOSE_ENTITY:
      return { ...state, entities: state.entities.filter((item, index) => index !== action.entityIndex) };

    case CHANGE_CLASS_ENTITY_SUCCESS: {
      const existingEntity = state?.entities?.find(entity => entity.uri === action.entity.uri);
      if (existingEntity) {
        const newEntity = { ...existingEntity, ...action.entity };
        return {
          ...state,
          entities: state.entities.map(entity => (entity.uri === action.entity.uri ? newEntity : entity))
        };
      }
      return { ...state };
    }

    case CHANGE_CLASS_ENTITY_UNMANAGED:
      return { ...state, unmanagedEntityWarning: true };

    case CLOSE_UNMANAGED_ENTITIES_WARNING:
      return { ...state, unmanagedEntityWarning: false };

    case CLOSE_ALL_ENTITIES:
      return { ...state, entities: [] };

    default:
      return state;
  }
};
