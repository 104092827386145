import { useMemo } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';

import TextField from '@mui/material/TextField';

import useMediaQuery from 'utils/mediaQueries';

import { isValidDate } from 'utils/datetimeUtils';

import sx from './TimePicker.styles';

const TimePicker = ({ error = false, value, onChange }) => {
  const isMobile = useMediaQuery();

  const hasError = useMemo(() => value && !isValidDate(value), [value]) || error;

  const commonProps = {
    ampm: false,
    value,
    onChange
  };

  const textFieldProps = {
    sx: sx.textField,
    error: hasError,
    'data-test': 'keyboard-time-picker',
    ...(hasError ? { helperText: 'Invalid time format' } : {})
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {isMobile ? (
        <MobileTimePicker
          {...commonProps}
          renderInput={params => <TextField {...params} {...textFieldProps} />}
          DialogProps={{ sx: sx.clockComponent }}
        />
      ) : (
        <DesktopTimePicker
          {...commonProps}
          renderInput={params => <TextField {...params} {...textFieldProps} />}
          showToolbar
          PopperProps={{ sx: sx.clockComponent }}
        />
      )}
    </LocalizationProvider>
  );
};

export default TimePicker;
