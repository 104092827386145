import React, { useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';

import { Button, ToggleButtonList } from 'components/v3';

import Autocomplete from 'components/Autocomplete/Autocomplete';

import Collapse from '@mui/material/Collapse';
import CircularProgress from '@mui/material/CircularProgress';

import { hideSharedCustomerSelectionModal } from 'store/modules/sharedModal';
import { saveSharedCustomerSelection, stopSharingCustomerSelection } from 'store/modules/customers-of-the-day';

import { layouts } from 'config/constants';

import styles from './sharedModal.scss';

import services from 'services';

// shared with options
const SHARE_WITH_SPECIFIC_ROLE_OR_USER = 'specificRoleOrUser';
const SHARE_WITH_EVERYONE = 'everyone';

const ShareModalForm = ({ isSuperUser, isAdmin, knowledgeGraph, customerSelection, dispatch, t }) => {
  const closeSharedModal = () => dispatch(hideSharedCustomerSelectionModal());

  const saveShared = saveSharedCustomerSelection;
  const stopSharing = stopSharingCustomerSelection;

  const shareableObject = customerSelection;
  const chooseUsersText = t('sharing.user-modal-text.choose-users-selection');
  const noteText = t('sharing.user-modal-text.note-selection');

  const shareWithOptions = [
    { label: t('sharing.everyone'), value: SHARE_WITH_EVERYONE, dataTest: SHARE_WITH_EVERYONE },
    {
      label: t('sharing.specific-role'),
      value: SHARE_WITH_SPECIFIC_ROLE_OR_USER,
      dataTest: SHARE_WITH_SPECIFIC_ROLE_OR_USER
    }
  ];

  const currentSharedRoleIds = shareableObject?.roles || [];
  const currentSharedUserIds = shareableObject?.userIds || [];

  const defaultShareWithOptions =
    currentSharedRoleIds.length > 0 || currentSharedUserIds.length > 0
      ? SHARE_WITH_SPECIFIC_ROLE_OR_USER
      : SHARE_WITH_EVERYONE;

  // the options for the users the board / customer selection can be shared with
  const [usersOptions, setUsersOptions] = useState(null);
  // the options roles the board / customer selection can be shared with
  const [rolesOptions, setRolesOptions] = useState(null);

  const loading = !usersOptions || !rolesOptions || customerSelection?.fetchingInfo;

  const toOption = userOrRole => ({ value: userOrRole.id, label: userOrRole.name });

  const getUsersOptions = async knowledgeGraph => {
    const response = await services.getKnowledgeGraphUsers(knowledgeGraph.id);
    const users = response.data.data;
    const usersOptions = users.map(toOption);
    const currentUsersOptions = currentSharedUserIds
      .map(userId => users.find(user => user.id === userId))
      .map(toOption);
    setUsersOptions(usersOptions);
    setState(state => ({ ...state, users: currentUsersOptions }));
  };

  const getRolesOptions = async () => {
    const response = await services.getRoles();
    const roles = response.data.data.map(r => r.data);
    const rolesOptions = roles.map(toOption);
    const currentRolesOptions = currentSharedRoleIds
      .map(roleId => roles?.find(role => role.id === roleId))
      .map(toOption);
    setRolesOptions(rolesOptions);
    setState(state => ({ ...state, roles: currentRolesOptions }));
  };

  const [state, setState] = useState({
    dirty: false,
    sharedWith: defaultShareWithOptions,
    roles: [],
    users: []
  });

  // load the users / roles options initially
  useEffect(() => {
    if (knowledgeGraph) {
      getUsersOptions(knowledgeGraph);
    }
    getRolesOptions();
  }, [knowledgeGraph]);

  const handleRolesChange = (_, roles) => {
    setState(prev => ({ ...prev, roles }));
  };

  const handleUsersChange = (_, users) => {
    setState(prev => ({ ...prev, users }));
  };

  const rolesAndUsersAreValid = useMemo(() => {
    // for non-super users (or higher), sharing with no users is valid, because it unshares the object
    if (!isSuperUser && !isAdmin) return true;
    return !(
      state.sharedWith === SHARE_WITH_SPECIFIC_ROLE_OR_USER &&
      state.roles.length === 0 &&
      state.users.length === 0
    );
  }, [isSuperUser, state.sharedWith, state.roles, state.users]);

  const handleWithChange = sharedWith => sharedWith && setState(prev => ({ ...prev, sharedWith }));

  const handleConfirm = () => {
    if (!rolesAndUsersAreValid) {
      setState(prev => ({ ...prev, dirty: true }));
      return;
    }

    const shouldShareWithEveryone = state.sharedWith === SHARE_WITH_EVERYONE;

    const data = {
      ...state,
      shareableObject,
      users: isSuperUser && shouldShareWithEveryone ? [] : state.users.map(user => user.value),
      roles: isSuperUser && shouldShareWithEveryone ? [] : state.roles.map(role => role.value)
    };

    if (!isSuperUser && state.users.length < 1) {
      return dispatch(stopSharing(shareableObject.id));
    }

    return dispatch(saveShared(data));
  };

  return (
    <div className={styles.container} data-test="shareModalForm">
      {loading ? (
        <div className={styles.progress}>
          <div className={styles.progressContent}>
            <CircularProgress />
          </div>
        </div>
      ) : (
        <>
          {isSuperUser ? (
            <>
              <div className={styles.fieldContainer}>
                <span className={styles.label}>{t('sharing.shared-with')}</span>
                <div className={styles.toggleContainer}>
                  <ToggleButtonList
                    direction="row"
                    justifyContent="flex-start"
                    items={shareWithOptions}
                    selectedValue={state.sharedWith}
                    setSelectedValue={handleWithChange}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <p className={styles.textNote}>{chooseUsersText + ' ' + t('sharing.user-modal-text.accept-or-refuse')}</p>
            </>
          )}

          <Collapse in={state.sharedWith === SHARE_WITH_SPECIFIC_ROLE_OR_USER || !isSuperUser}>
            {isSuperUser && (
              <div className={styles.selectBoxWithoutMarginTop} data-test="rolesInputForm">
                <span className={styles.label}>{t('sharing.user-roles')}</span>
                <Autocomplete
                  fullWidth
                  optionLabel="label"
                  optionEqualityValue="value"
                  options={rolesOptions}
                  value={state.roles}
                  onChange={handleRolesChange}
                  error={state.dirty && !rolesAndUsersAreValid}
                />
              </div>
            )}
            <div className={styles.selectBox} data-test="usersInputForm">
              <span className={styles.label}>{t('sharing.users')}</span>
              <Autocomplete
                fullWidth
                optionLabel="label"
                optionEqualityValue="value"
                options={usersOptions}
                value={state.users}
                onChange={handleUsersChange}
                error={state.dirty && !rolesAndUsersAreValid}
              />
            </div>
          </Collapse>

          <div className={styles.infoAlert}>
            <p className={styles.textNote}>{noteText}</p>
          </div>
          <div className={styles.buttonsContainer}>
            <Button
              layout={layouts.veezoo}
              classes={{ root: { width: 'calc(50% - 10px)' } }}
              onClick={closeSharedModal}
            >
              {t('cancel')}
            </Button>
            <Button
              layout={layouts.veezoo}
              mode="dark"
              classes={{ root: { width: 'calc(50% - 10px)' } }}
              disabled={state.dirty && !rolesAndUsersAreValid}
              onClick={handleConfirm}
              data-test="saveSharedForm"
            >
              OK
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default withTranslation('veezoo')(ShareModalForm);
