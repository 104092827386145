import { get, post } from 'services/http';

const askModifiedAnswer = payload => {
  const fullPayload = {
    ...payload,
    sessionId: window.veezoo.sessionId
  };
  return post('/answers/edit', JSON.stringify(fullPayload));
};

const getAnswerTotalRowCount = partialAnswerId =>
  get(`/answers/result/metadata/${partialAnswerId}?sessionId=${window.veezoo.sessionId}`);

export default { askModifiedAnswer, getAnswerTotalRowCount };
