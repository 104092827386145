import { createContext, useContext, useState } from 'react';

import { ConfirmationModal } from 'components/v3';

import { layouts } from 'config/constants';

const ConfirmationModalContext = createContext(null);

const initialState = {
  open: false,
  title: '',
  layout: layouts.default,
  content: null,
  onConfirm: null,
  confirmButtonText: 'Confirm',
  onClose: null,
  confirmDataTest: '',
  cancelDataTest: '',
  maxWidth: 'sm',
  fixedHeight: undefined
};

export const ConfirmationModalProvider = ({ children }) => {
  const [modal, setModal] = useState(initialState);

  const closeModal = () => setModal(prev => ({ ...prev, open: false }));

  // function exported by HooksContext, where we open the modal and pass the parameters inside an object.
  // The parameters are optional, if you don't use any of them, these values below will be used as default.
  // I recommend always fill the `Title` and `Content`, otherwise they will show up as empty strings.
  const showConfirmationModal = async propsOrBuilder => {
    const passedFunctions = { closeModal };

    const props = typeof propsOrBuilder === 'function' ? await propsOrBuilder(passedFunctions) : propsOrBuilder;

    setModal({ ...initialState, ...props, open: true });
  };

  // Closes the modal when clicking `Close/Cancel` button.
  // If any function is passed as `onClose` parameter in the `showConfirmationModal` function, it is called here before closing the modal.
  const onCloseModal = () => {
    modal.onClose?.();
    closeModal();
  };

  // Function that confirms the modal. The function passed as `onConfirm` in the `showConfirmationModal` function is called here, and then the modal is closed.
  // We can pass the onConfirm as a promise, in case of backend requests. If we do so, a `loading` spinner will be displayed replacing the `content`
  // and then the modal is closed.
  const onConfirmModal =
    modal.onConfirm &&
    (async () => {
      await modal.onConfirm();
      closeModal();
    });

  return (
    <ConfirmationModalContext.Provider value={{ showConfirmationModal }}>
      <ConfirmationModal
        open={modal.open}
        title={modal.title}
        onClose={onCloseModal}
        onConfirm={onConfirmModal}
        confirmButtonText={modal.confirmButtonText}
        confirmDataTest={modal.confirmDataTest}
        cancelDataTest={modal.cancelDataTest}
        maxWidth={modal.maxWidth}
        fixedHeight={modal.fixedHeight}
        layout={modal.layout}
        className={modal.className}
      >
        {modal.content}
      </ConfirmationModal>
      {children}
    </ConfirmationModalContext.Provider>
  );
};

export const useConfirmationModal = () => {
  return useContext(ConfirmationModalContext);
};
