import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { CustomModal, TextField } from 'components/v3';

import { availableGroups, OntologyClass } from 'store/utils/knowledgeGraphOntologyIcons';

import services from 'services';
import { trackEvent } from 'utils/eventTracking';

import { useStyles } from './CreateRelationshipDialog.styles';

const NodeLabel = ({ node }) => {
  const { classes: styles } = useStyles();
  const IconComponent =
    availableGroups[node?.ontologyType || OntologyClass]?.componentIcon ||
    availableGroups[OntologyClass]?.componentIcon;
  return (
    <>
      <IconComponent className={styles.nodeIcon} />
      <span className={styles.nodeName}>{node?.name}</span>
    </>
  );
};

const CreateRelationshipDialog = ({ open, fromNode, toNode, foreignKeyNode, onClose }) => {
  const { classes: styles } = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const [displayName, setDisplayName] = useState(t('with'));

  const handleDisplayNameChange = event => {
    setDisplayName(event.target.value);
  };

  const handleConfirm = async () => {
    const payload = {
      from: fromNode.uri,
      to: toNode.uri,
      via: foreignKeyNode.uri,
      displayName
    };

    setLoading(true);

    const result = await services.createRelationship(payload);
    if (result.success) {
      trackEvent('Relationship Created', { fromNode, toNode, foreignKeyNode });
      setLoading(false);
      enqueueSnackbar(t('visual-kg.relationship-created-successfully'), { variant: 'success' });
      return;
    }

    setLoading(false);
    // get error message
    const errorMessage = result.response?.data;
    const userMessage = t('visual-kg.error-creating-relationship') + (errorMessage ? ': ' + errorMessage : '');
    enqueueSnackbar(userMessage, { variant: 'error' });
  };

  useEffect(() => {
    if (open && fromNode && toNode && foreignKeyNode) {
      trackEvent('Relationship Creation Dialog Opened', { fromNode, toNode, foreignKeyNode });
    }
  }, [open, fromNode, toNode, foreignKeyNode]);

  return (
    <CustomModal
      layout="veezoo"
      open={!!open}
      title={t('visual-kg.create-relationship')}
      content={
        <>
          <TextField
            shrink
            layout="veezoo"
            label={t('name')}
            value={displayName}
            onChange={handleDisplayNameChange}
            error={displayName.length < 1}
          />
          <br />
          <br />
          <div className={styles.fontSize}>
            <span className={styles.subtitle}>{t('visual-kg.relationship')}</span>
            <br />
            <span className={styles.subtitleContent}>
              <NodeLabel node={fromNode} />
              &nbsp;
              {displayName.length > 0 ? <span>{displayName}</span> : <span className={styles.requiredText}>____</span>}
              &nbsp;
              <NodeLabel node={toNode} />
            </span>
          </div>
          <br />
          <div className={styles.fontSize}>
            <span className={styles.subtitle}>{t('visual-kg.replacing')}</span>
            <br />
            <span className={styles.subtitleContent}>
              <span className={styles.highlightedText}>{fromNode?.name + '.' + foreignKeyNode?.name}</span>
            </span>
          </div>
        </>
      }
      closeLabel={t('close')}
      confirmLabel={t('visual-kg.create-relationship')}
      onConfirm={handleConfirm}
      onClose={onClose}
      loading={loading}
      disabled={loading || !displayName}
      DialogActionsProps={{
        styles: {
          root: {
            justifyContent: 'space-around'
          }
        }
      }}
    />
  );
};

export default CreateRelationshipDialog;
