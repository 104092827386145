import { useState, useEffect, useMemo, memo } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';

import { useTranslation } from 'react-i18next';

import { MiniBoard } from 'components/message/BoardAnswerMessage';
import ContentLoader from 'components/loaders/ContentLoader';

import { fetchBoardWidgets } from 'store/modules/board-widgets';

import { useStyles } from './BrowseBoards.styles';

import EmptyBoardIcon from 'svg/emptyboard.svg';
import CloseRoundIcon from 'svg/close-round.svg';

import services from 'services';

const initialState = {
  data: null,
  loading: false,
  error: false
};

const BoardPreview = ({ publicBoard, allBoardWidgets, dispatch }) => {
  const { classes: styles } = useStyles();
  const { t } = useTranslation();

  const [boardAnswer, setBoardAnswer] = useState(initialState);

  const boardId = publicBoard?.id;

  const boardWidgetProperties = useMemo(() => boardId && allBoardWidgets.find(w => w.boardId === boardId), [
    allBoardWidgets,
    boardId
  ]);
  const boardWidgets = boardWidgetProperties?.widgets || [];

  const fetchBoardAnswer = async () => {
    setBoardAnswer(prev => ({ ...prev, loading: true }));
    const result = await services.getBoardPreview(boardId);
    if (result.success) {
      setBoardAnswer(prev => ({ ...prev, data: result.data }));
    } else {
      setBoardAnswer(prev => ({ ...prev, error: true }));
    }
    setBoardAnswer(prev => ({ ...prev, loading: false }));
  };

  useEffect(() => {
    if (boardId && !boardAnswer.data) {
      fetchBoardAnswer();
    }

    if (!boardId && boardAnswer.data) {
      setBoardAnswer(initialState);
    }
  }, [boardId, boardAnswer.data]);

  useEffect(() => {
    if (
      publicBoard?.id &&
      !boardWidgetProperties?.isLoadingMetaWidgets &&
      !boardWidgetProperties?.isFinishedLoadingMetaWidgets
    ) {
      dispatch(fetchBoardWidgets(publicBoard.id));
    }
  }, [publicBoard?.id, boardWidgetProperties]);

  if (boardAnswer.error) {
    return (
      <div className={styles.emptyBoard}>
        <CloseRoundIcon className={clsx(styles.boardIcon, styles.errorIcon)} />
        <span>{t('browse-boards.error-loading-board')}</span>
      </div>
    );
  }

  if (boardAnswer.data && boardWidgets.length > 0) {
    return <MiniBoard {...boardAnswer.data} boardWidgets={boardWidgets} previewMode />;
  }

  if (boardAnswer.loading) {
    return (
      <div className={styles.loaderContainer}>
        <ContentLoader />
      </div>
    );
  }

  return (
    <div className={styles.emptyBoard}>
      <EmptyBoardIcon className={styles.boardIcon} />
      <span>{t('browse-boards.there-are-no-widgets')}</span>
    </div>
  );
};

const mapStateToProps = state => ({
  allBoardWidgets: state.boardWidgets
});

export default connect(mapStateToProps)(memo(BoardPreview));
