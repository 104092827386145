import Drawer from '@mui/material/Drawer';

import { SIDEBAR_EXPANDED_SIZE } from 'config/constants';

const sx = {
  '& .MuiPaper-root': {
    width: '100%',
    maxWidth: SIDEBAR_EXPANDED_SIZE
  }
};

const MobileDrawer = ({ children, open, onClose }) => {
  return (
    <Drawer anchor="left" sx={sx} open={open} onClose={onClose}>
      {children}
    </Drawer>
  );
};

export default MobileDrawer;
