import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import MultiselectList from 'components/MultiselectList/MultiselectList';

import services from 'services';
import { handleError } from 'services/http';

import { passageTypes } from 'config/constants';

import getClassFromClassUri from 'utils/getClassFromClassUri';

const initialOptions = {
  query: '',
  start: 0,
  limit: 20,
  next: true,
  passages: []
};

// returns the (shorthand) URI of the passage
const getUri = passage => passage.uri.split('#')[1] || passage.uri;

const fetchPassageByCode = async rawPassage => {
  let result = await services.getEntity(getUri(rawPassage));
  if (result.success) return result.data;
  return {};
};

const PassageSelector = ({ passages, onConfirm, onClose, dispatch, t }) => {
  const [options, setOptions] = useState(initialOptions);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [loading, setLoading] = useState({ options: false, selectedOptions: false });

  const getPassagesByCode = async () => {
    setLoading(prev => ({ ...prev, selectedOptions: true }));
    const fetched = passages.map(rawPassage => fetchPassageByCode(rawPassage));

    return Promise.all(fetched).then(result => {
      const newSelectedOptions = result
        .filter(item => item.uri)
        .map(item => ({ text: item.name, value: item.uri, ...item }));
      setLoading(prev => ({ ...prev, selectedOptions: false }));
      setSelectedOptions(newSelectedOptions);
    });
  };

  const fetchPassages = async (newOptions = initialOptions) => {
    const passageModel = passages[0];

    if (!newOptions.next || passageModel.type !== passageTypes.ENTITY) return;

    const passageClass = getClassFromClassUri(passageModel.classUri);

    setLoading(prev => ({ ...prev, options: true }));
    let result = await services.getEntitiesByClass({ classUri: passageClass, ...newOptions });
    handleError(result, dispatch);

    setOptions({
      ...newOptions,
      next: !!result?.data?.next,
      // convert results (containing names of entities) into passages (that expects text)
      passages: result?.data?.results
        ? [...newOptions.passages, ...result.data.results.map(item => ({ ...item, text: item.name }))]
        : []
    });
    setLoading(prev => ({ ...prev, options: false }));
  };

  const handleConfirm = selected => {
    onConfirm(selected);
    onClose();
  };

  const handleSearch = async search => {
    await fetchPassages({ ...initialOptions, query: search });
  };

  const handleScrollToBottom = async () => {
    const newOptions = { ...options, start: options.start + options.limit };
    fetchPassages(newOptions);
  };

  const formattedPassages = useMemo(() => {
    if (options.passages.length) {
      return options.passages.map(passage => ({ text: passage.text, value: passage.uri, ...passage }));
    }
    return [];
  }, [options.passages]);

  useEffect(() => {
    if (passages.length) {
      getPassagesByCode();
    }
  }, [passages]);

  return (
    <MultiselectList
      options={formattedPassages}
      selectedOptions={selectedOptions}
      onConfirm={handleConfirm}
      onClose={onClose}
      onScrollToBottom={handleScrollToBottom}
      asyncSearch={handleSearch}
      confirmButtonText={t('apply')}
      cancelButtonText={t('cancel')}
      loading={loading.options}
      showLimitTags
      allowEmptySelection // allow users to de-select all passages and send this to the backend
    />
  );
};

PassageSelector.propTypes = {
  passages: PropTypes.array,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func
};

PassageSelector.defaultProps = {
  onClose: () => {}
};

export default connect()(PassageSelector);
