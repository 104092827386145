import { useState, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomModal, TextField, ToggleButtonList, Select } from 'components/v3';

import PlusIcon from 'svg/plus_3.svg';
import BoardIcon from 'svg/board.svg';

import { layouts } from 'config/constants';

import { useStyles } from './AddToBoardModal.styles';

const values = { new: 'new', existing: 'existing' };

const AddToBoardModal = ({
  title,
  open,
  preselectedBoardId,
  onClose,
  onConfirm,
  onCreateBoardClick,
  boardsList,
  loading,
  ...props
}) => {
  const { t } = useTranslation();

  const itemList = [
    {
      icon: <BoardIcon />,
      iconSize: 20,
      label: t('dashboard-existing-board'),
      value: values.existing
    },
    {
      icon: <PlusIcon />,
      iconSize: 16,
      label: t('dashboard-new-board'),
      value: values.new,
      dataTest: 'dashboardNewBoardItem'
    }
  ];

  const initialState = {
    selectedBoard: '',
    newBoardName: '',
    toggleValue: values.existing
  };

  const [state, setState] = useState(initialState);

  // If we have a preselected board, we set it as the selected board when the modal is opened.
  useEffect(() => {
    if (preselectedBoardId && open) {
      setState(prev => ({ ...prev, selectedBoard: preselectedBoardId }));
    }
  }, [preselectedBoardId, open]);

  const { classes } = useStyles();
  const nameRef = useRef(null);

  const formattedBoardsList = useMemo(() => {
    if (boardsList?.length > 0) {
      return boardsList.map(board => ({ value: board.id, label: board.name }));
    }
    return [];
  }, [boardsList]);

  const onSelectChange = event => {
    const board = event.target.value;
    setState(prev => ({ ...prev, selectedBoard: board }));
  };

  const onTextFieldChange = event => {
    const name = event.target.value;
    setState(prev => ({ ...prev, newBoardName: name }));
  };

  const handleToggleChange = value => {
    setState(prev => ({ ...prev, toggleValue: value }));
  };

  const handleConfirm = async () => {
    let boardId;
    if (state.toggleValue === values.new) {
      const result = await onCreateBoardClick(state.newBoardName);
      if (!result || !result.success) return;
      boardId = result.data.id;
    } else {
      boardId = state.selectedBoard;
    }

    return await onConfirm(boardId);
  };

  const handleEnterPress = event => {
    if (event.key === 'Enter') {
      handleConfirm();
    }
  };

  const resetState = () => setState(initialState);

  // We set the translated form of "New board" because, when opening veezoo, the default language is always "EN" and then
  // after fetching User info from the backend, we know the default language for this KG.
  // Setting the translated form directly into "initialState" doesn't update when the new language is fetched,
  // so we have to set it here through "setState" (and anywhere in any other case, this is default behavior of React).
  useEffect(() => {
    if (open) {
      setState(prev => ({ ...prev, newBoardName: t('dashboard-new-board-name') }));
    }
  }, [open]);

  // If we already have boards but haven't selected one yet, or have a selected but it doesn't match any board,
  // we select the first item of the board list.
  useEffect(() => {
    if (boardsList?.length > 0) {
      if (!state.selectedBoard || !boardsList.some(board => board.id === state.selectedBoard)) {
        setState(prev => ({ ...prev, selectedBoard: boardsList[0].id }));
      }
    }
  }, [boardsList, state.selectedBoard]);

  const handleFocus = event => event.target.select();

  // When opening name input, we focus on it.
  useEffect(() => {
    if (state.toggleValue === values.new && nameRef.current) {
      nameRef?.current.focus();
    }
  }, [state.toggleValue]);

  return (
    <CustomModal
      autoFocusOnConfirm
      layout={layouts.veezoo}
      title={t('add-to-board-modal-title')}
      content={
        <>
          <ToggleButtonList
            items={itemList}
            direction="row"
            layout={layouts.veezoo}
            selectedValue={state.toggleValue}
            setSelectedValue={handleToggleChange}
          />

          {state.toggleValue === values.new ? (
            <div className={classes.buttonContainer}>
              <TextField
                ref={nameRef}
                onFocus={handleFocus}
                layout={layouts.veezoo}
                label={t('enter-name')}
                value={state.newBoardName}
                onChange={onTextFieldChange}
                onKeyPress={handleEnterPress}
                disabled={loading}
                data-test="newBoardNameTextField"
              />
            </div>
          ) : (
            <div className={classes.selectContainer}>
              <Select
                label={t('choose-dashboard')}
                layout={layouts.veezoo}
                value={state.selectedBoard}
                onChange={onSelectChange}
                options={formattedBoardsList}
                disabled={loading}
                data-test="AddToBoardSelect"
              />
            </div>
          )}
        </>
      }
      loading={loading}
      disabled={
        (state.toggleValue === values.existing && !state.selectedBoard) ||
        (state.toggleValue === values.new && state.newBoardName.length < 1) ||
        loading
      }
      open={open}
      onClose={onClose}
      onConfirm={handleConfirm}
      // Make sure to reset the state in case the modal is closed.
      // Have in mind that Mui's modal is always rendered, even though it's invisible and "open" is false.
      TransitionProps={{ onExited: resetState }}
      data-test="AddToBoardDialog"
      {...props}
    />
  );
};

export default AddToBoardModal;
