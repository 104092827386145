import { createContext, useContext, useMemo } from 'react';
import useLocalStorageState from 'use-local-storage-state';

import { SIDEBAR_MIN_SIZE, SIDEBAR_EXPANDED_SIZE } from 'config/constants';

const LocalStorageSidebarSizeKey = 'veezoo.sidebar.size';

const initialSidebarSizeState = () => {
  const params = new URLSearchParams(location.search);
  const isMinimized = params.get('firstLoadSidebarCollapsed');
  return {
    isMinimized,
    width: isMinimized ? SIDEBAR_MIN_SIZE : SIDEBAR_EXPANDED_SIZE
  };
};

const SidebarContext = createContext(null);

export const SidebarProvider = ({ children }) => {
  const [sidebarSize, setSidebarSize] = useLocalStorageState(LocalStorageSidebarSizeKey, {
    defaultValue: initialSidebarSizeState(),
    storageSync: false
  });

  const sidebarIsMinimized = useMemo(() => sidebarSize?.width < SIDEBAR_EXPANDED_SIZE, [sidebarSize?.width]);

  return (
    <SidebarContext.Provider value={{ sidebarSize, setSidebarSize, sidebarIsMinimized }}>
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebar = () => useContext(SidebarContext);
