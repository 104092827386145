import React, { memo, useEffect, useRef } from 'react';
import { withSnackbar } from 'notistack';

import { layouts } from 'config/constants';

import { EmailIcon, EmailShareButton } from 'react-share';

import { useStyles } from './styles';
import { Button, TextField } from 'components/v3';
import FileCopyRoundedIcon from '@mui/icons-material/FileCopyRounded';

const ShareAnswerForm = ({ sharedAnswer, enqueueSnackbar, t }) => {
  const { classes: styles } = useStyles();

  const inputRef = useRef();

  useEffect(() => {
    inputRef?.current?.select();
  });

  let sharingUrl = t('sharing.share-answer-modal.failed');
  if (sharedAnswer?.url) {
    sharingUrl = sharedAnswer.url;
  } else if (sharedAnswer?.isLoading) {
    sharingUrl = t('loading');
  }

  const emailShareButton = (
    <span className={styles.sharingElement}>
      <EmailShareButton url={sharingUrl}>
        <EmailIcon size={32} round={true} />
      </EmailShareButton>
    </span>
  );

  const copyToClipboard = async text => {
    await navigator.clipboard.writeText(text);
    enqueueSnackbar(t('copied-to-clipboard'), { variant: 'info', autoHideDuration: 2000 });
  };

  const copyToClipboardButton = (
    <span className={styles.sharingElement} style={{ float: 'right' }}>
      <Button onClick={() => copyToClipboard(sharingUrl)} layout="veezoo" mode="dark" icon={<FileCopyRoundedIcon />}>
        {t('copy-action')}
      </Button>
    </span>
  );

  return (
    <>
      <div data-test="sharedUrlInput" data-shared-answer-id={sharedAnswer?.sharedAnswerId}>
        <TextField layout={layouts.veezoo} value={sharingUrl} ref={inputRef} fullWidth disabled />
      </div>
      <div className={styles.buttonContainer}>
        {emailShareButton}
        {copyToClipboardButton}
      </div>
    </>
  );
};

export default memo(withSnackbar(ShareAnswerForm));
