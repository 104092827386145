import { get, patch, post } from 'services/http';

const getTenantKnowledgeGraphs = id => get(`/studio/api/knowledge-graphs/${encodeURIComponent(id)}/tenants`);

const createTenantKnowledgeGraph = (kgId, tenantId) =>
  post(`/studio/api/knowledge-graphs/${encodeURIComponent(kgId)}/tenants/${encodeURIComponent(tenantId)}`);

const updateTenantKnowledgeGraph = (kgId, tenantId) =>
  patch(`/studio/api/knowledge-graphs/${encodeURIComponent(kgId)}/tenants/${encodeURIComponent(tenantId)}`);

export default {
  getTenantKnowledgeGraphs,
  createTenantKnowledgeGraph,
  updateTenantKnowledgeGraph
};
