import { SIGNOUT_SUCCESS, INDIRECT_SIGNOUT_SUCCESS } from 'store/modules/network';
import { fetchGet, fetchPost, fetchPatch, fetchPut, fetchDelete } from 'services/http';

export const authFetchGet = (endpoint, dispatch, ignoreUnauthorized = false) =>
  authFetch(fetchGet, endpoint, null, dispatch, ignoreUnauthorized);

export const authFetchPost = (endpoint, dispatch, body, ignoreUnauthorized = false) =>
  authFetch(fetchPost, endpoint, body, dispatch, ignoreUnauthorized);

export const authFetchPatch = (endpoint, dispatch, body, ignoreUnauthorized = false) =>
  authFetch(fetchPatch, endpoint, body, dispatch, ignoreUnauthorized);

export const authFetchPut = (endpoint, dispatch, body, ignoreUnauthorized = false) =>
  authFetch(fetchPut, endpoint, body, dispatch, ignoreUnauthorized);

export const authFetchDelete = (endpoint, dispatch, ignoreUnauthorized = false) =>
  authFetch(fetchDelete, endpoint, dispatch, ignoreUnauthorized);

const authFetch = (fetch, endpoint, body, dispatch, ignoreUnauthorized = false) =>
  fetch(endpoint, body).then(response => {
    // the second condition is only because axa redirects our 401's and transforms them into 404
    // remove this once they handle that stuff correctly
    // we only refresh it if we are on-premise, check the logic in network module
    if (!ignoreUnauthorized && response.status === 401) {
      handleUnauthorized(dispatch);
    } else if (!ignoreUnauthorized && response.status === 404) {
      handleUnauthorizedIndirect(dispatch);
    }
    return response;
  });

export const handleUnauthorized = dispatchFunction => {
  dispatchFunction(signoutImmediately());
};

export const handleUnauthorizedIndirect = dispatchFunction => {
  dispatchFunction(indirectSignoutImmediately());
};

/**
 * Call this method when signing out the user due to a 401 (Unauthorized) response.
 * Immediately set the authorized state to false without calling the /logout
 * endpoint, thus redirecting the user to /login.
 */
export const signoutImmediately = () => {
  return { type: SIGNOUT_SUCCESS };
};

export const indirectSignoutImmediately = () => {
  return { type: INDIRECT_SIGNOUT_SUCCESS };
};
