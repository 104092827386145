import { tss } from 'tss-react';

// for the material ui menu we want to disable the padding at the top and bottom of the list
// each list item should have font size 12 px
export const menuSx = {
  '& .MuiMenu-list': {
    padding: 0
  }
};

export const menuItemSx = {
  '&.MuiMenuItem-root': {
    fontSize: '12px',
    overflow: 'hidden',
    boxSizing: 'border-box',
    fontFamily: 'var(--font-family)',
    fontWeight: '400',
    padding: '6px',
    whiteSpace: 'nowrap',
    display: 'flex',
    justifyContent: 'flex-start'
  }
};

export const getContextMenuClasses = tss.create({
  root: {
    padding: '5px 20px',
    fontWeight: 600,
    cursor: 'pointer',
    fontSize: '12px',
    background: 'var(--follow-up-color)'
  }
});
