const fontFamily = 'Lexend';
const primaryColor = 'var(--primary-color)';

export default {
  textField: {
    '& .MuiOutlinedInput-input': {
      fontFamily,
      fontSize: '14px',
      padding: '8px 12px',
      color: 'inherit',
      lineHeight: '20px',
      height: '32px',
      boxSizing: 'border-box'
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'white'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      top: 0,
      border: 'solid thin var(--light-gray)',
      borderRadius: '4px',
      '& > legend': {
        display: 'none'
      }
    },
    '& .MuiFormHelperText-root': {
      fontSize: '12px'
    },
    '& .MuiInputLabel-shrink': {
      transform: 'none !important',
      position: 'static'
    },
    '& .MuiOutlinedInput-root.Mui-disabled': {
      backgroundColor: 'transparent'
    }
  },
  container: {
    '& .MuiPickersToolbar-root': {
      display: 'none'
    },
    '& .MuiPickersDay-root': {
      fontFamily,
      fontSize: '14px',
      fontWeight: 300,
      color: 'rgba(0, 0, 0, 0.54)',
      borderRadius: '4px',
      '&.Mui-selected': {
        backgroundColor: primaryColor,
        color: 'white',
        '&:focus': {
          backgroundColor: primaryColor
        }
      }
    },
    '& .MuiDayCalendar-weekDayLabel': {
      fontFamily,
      fontSize: '12px'
    },
    '& .MuiPickersDay-dayOutsideMonth': {
      fontFamily,
      color: '#dddddd'
    },
    '& .MuiPickersArrowSwitcher-button': {
      padding: '3px',
      '& svg': {
        width: '25px',
        height: '25px',
        fill: primaryColor
      }
    },
    '& .MuiPickersCalendarHeader-labelContainer': {
      fontSize: '14px'
    },
    '& .PrivatePickersMonth-root': {
      fontSize: '14px',
      borderRadius: '4px',
      boxSizing: 'border-box',
      '&.Mui-selected': {
        backgroundColor: primaryColor,
        '&:focus': {
          backgroundColor: primaryColor
        }
      }
    },
    '& .PrivatePickersYear-yearButton': {
      fontSize: '14px',
      borderRadius: '4px',
      '&.Mui-selected': {
        backgroundColor: primaryColor,
        '&:focus': {
          backgroundColor: primaryColor
        }
      }
    },
    '& .MuiDialogActions-root': {
      '& .MuiButtonBase-root': {
        fontFamily,
        color: primaryColor
      }
    }
  }
};
