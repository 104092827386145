import IconButton from '@mui/material/IconButton';
import EditFilterIcon from 'svg/v2/filter.svg';
import styles from './edit-filter-button.scss';
import React from 'react';
import { connect } from 'react-redux';

import { InfoTooltip } from 'components/v3';

const editButtonSx = {
  '&.MuiIconButton-root': {
    padding: 0,
    margin: 0
  }
};

const EditFilterButton = ({ t, onClick }) => {
  return (
    <InfoTooltip text={t('tooltip.add-new-filter')}>
      <IconButton sx={editButtonSx} onClick={onClick}>
        <EditFilterIcon className={styles.FilterIcon} />
      </IconButton>
    </InfoTooltip>
  );
};

export default connect()(EditFilterButton);
