import makeStyles from '@mui/styles/makeStyles';

export default makeStyles({
  supportMessage: {
    marginTop: 25,
    fontWeight: 500,
    fontSize: 12,
    '& a': {
      textDecoration: 'underline !important',
      cursor: 'pointer'
    }
  },
  blue: {
    color: 'var(--light-blue) !important'
  }
});
