import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import TablePagination from '@mui/material/TablePagination';

import ArrowIcon from 'svg/v2/chevron_down.svg';

import { usePaginationStyles, useMenuListStyles } from './CustomTable.styles';

import { layouts } from 'config/constants';

const toZeroBasedIndex = page => page - 1;

const toOneBasedIndex = page => page + 1;

const defaultRowsPerPage = 5;

const Pagination = ({
  count,
  page,
  rowsPerPage,
  rowsPerPageOptions,
  onChangeRowsPerPage,
  onChangePage,
  next,
  previous,
  loading,
  layout = layouts.default,
  t = item => item
}) => {
  const paginationClasses = usePaginationStyles();
  const menuListClasses = useMenuListStyles();

  const [currentRowsPerPage, setCurrentRowsPerPage] = useState(defaultRowsPerPage);

  useEffect(() => {
    if (rowsPerPage) {
      setCurrentRowsPerPage(rowsPerPage);
    }
  }, [rowsPerPage]);

  return (
    <TablePagination
      component="div"
      sx={paginationClasses[layout]}
      count={count}
      SelectProps={{
        IconComponent: ArrowIcon,
        inputProps: { title: t('rows') },
        MenuProps: {
          MenuListProps: {
            sx: menuListClasses[layout]
          }
        }
      }}
      page={toZeroBasedIndex(page)}
      onPageChange={!loading ? (_, page) => onChangePage(toOneBasedIndex(page)) : () => {}}
      rowsPerPage={currentRowsPerPage}
      rowsPerPageOptions={rowsPerPageOptions}
      onRowsPerPageChange={onChangeRowsPerPage}
      labelDisplayedRows={({ from, to, count }) =>
        `${t('showing')} ${from} ${t('-')} ${to} ${t('of')} ${count} ${t('total-rows')}`
      }
      labelRowsPerPage={`${t('rows')}:`}
      nextIconButtonProps={{
        disabled: loading || !next
      }}
      backIconButtonProps={{
        disabled: loading || !previous
      }}
    />
  );
};

Pagination.propTypes = {
  count: PropTypes.number,
  page: PropTypes.number,
  onChangePage: PropTypes.func,
  rowsPerPage: PropTypes.number,
  onChangeRowsPerPage: PropTypes.func,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  loading: PropTypes.bool,
  next: PropTypes.any,
  previous: PropTypes.any,
  layout: PropTypes.string,
  t: PropTypes.func
};

export default Pagination;
