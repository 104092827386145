import { authFetchPost } from 'store/utils/authFetch';

export const PILLS_REQUEST = 'PILLS_REQUEST';
export const PILLS_SUCCESS = 'PILLS_SUCCESS';
export const PILLS_FAILURE = 'PILLS_FAILURE';

export const fetchPills = (
  disableFollowUp = false,
  answerId = null,
  partialAnswerId = null,
  interpretationId = null
) => {
  return dispatch => {
    dispatch({ type: PILLS_REQUEST });

    // If the answerId is defined, we are following-up on an answer
    // NOTE: This could be a multi-answer, in which case the interpretation is not defined
    const referenceToObject = answerId
      ? {
          referenceTo: {
            answerId,
            ...(interpretationId && { interpretationId }),
            ...(partialAnswerId && { partialAnswerId })
          }
        }
      : {};

    authFetchPost('/pills', dispatch, {
      disableFollowUp,
      ...referenceToObject,
      sessionId: window.veezoo.sessionId
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          dispatch({ type: PILLS_FAILURE });
          throw Error(response.statusText);
        }
      })
      .then(json =>
        dispatch({
          type: PILLS_SUCCESS,
          pills: json
        })
      )
      .catch(function(error) {
        console.log('looks like there was a problem: \n', error);
      });
  };
};

export const defaultPillsState = {
  isFetchingPills: false,
  hasFetchedPills: false,
  list: []
};

export const pills = (state = defaultPillsState, action) => {
  switch (action.type) {
    case PILLS_REQUEST:
      return {
        ...state,
        isFetchingPills: true,
        hasFetchedPills: false
      };

    case PILLS_FAILURE: {
      return {
        ...state,
        isFetchingPills: false,
        hasFetchedPills: true
      };
    }

    case PILLS_SUCCESS:
      return {
        ...state,
        isFetchingPills: false,
        hasFetchedPills: true,
        list: action.pills
      };

    default:
      return state;
  }
};
