import enUS from 'date-fns/locale/en-US';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { PickersDay } from '@mui/x-date-pickers';

import isSameDay from 'date-fns/isSameDay';
import isWithinInterval from 'date-fns/isWithinInterval';

import { startOfWeekInVeezoo, endOfWeekInVeezoo, getWeekOfTheYear } from 'utils/datetimeUtils';

import { getDayWrapperStyles, getStaticDatePickerClasses } from './WeekPicker.styles';

const customLocalizationOptions = {
  ...enUS,
  options: {
    ...enUS.options,
    // Sunday = 0, Monday = 1.
    weekStartsOn: 1
  }
};

const WeekSelector = ({ value, onChange }) => {
  const { classes: dayWrapperStyles } = getDayWrapperStyles();
  const { classes: staticDatePickerClasses } = getStaticDatePickerClasses();

  const renderDay = (renderedDay, selectedDays, dayProps) => {
    const selectedDay = selectedDays[0];

    // When hovering over, week filter highlights the whole week in the calendar, so we have to get
    // first and last day of the week.
    const firstDayOfWeek = startOfWeekInVeezoo(renderedDay);
    const lastDayOfWeek = endOfWeekInVeezoo(renderedDay);

    const isFirstDayOfWeek = isSameDay(renderedDay, firstDayOfWeek);
    const { week } = getWeekOfTheYear(renderedDay);

    const selectedDayIsBetween = isWithinInterval(selectedDay, { start: firstDayOfWeek, end: lastDayOfWeek });
    const selectedDayIsFirstDay = isSameDay(selectedDay, firstDayOfWeek);
    const selectedDayIsLastDay = isSameDay(selectedDay, lastDayOfWeek);

    const isSelectedRow = selectedDayIsBetween || selectedDayIsFirstDay || selectedDayIsLastDay;

    return (
      <div className={`dayContainer ${isSelectedRow ? 'selectedRow' : ''}`} key={dayProps.key}>
        {isFirstDayOfWeek && <div className={dayWrapperStyles.weekLabel}>{week}</div>}
        <PickersDay {...dayProps} />
      </div>
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={customLocalizationOptions}>
      {/* "sx" props is not available for StaticDatePicker, so we have to use "className" */}
      <StaticDatePicker
        className={staticDatePickerClasses.default}
        showDaysOutsideCurrentMonth={true}
        disableHighlightToday={true}
        value={value}
        onChange={onChange}
        views={['day']}
        showToolbar={false}
        renderDay={renderDay}
        renderInput={() => <></>}
      />
    </LocalizationProvider>
  );
};

export default WeekSelector;
