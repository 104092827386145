import { forwardRef } from 'react';
import TextField from './TextField';

import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';

export const useStyles = () => ({
  width: '20px !important',
  color: 'red !important',
  cursor: 'pointer'
});

const KGFormTextField = forwardRef(({ deleteClick = () => {}, highlighted, ...props }, ref) => {
  const classes = useStyles();

  const Icon = () => <HighlightOffRoundedIcon fontSize="large" color="action" sx={classes} onClick={deleteClick} />;

  return <TextField {...props} highlighted={highlighted} {...(highlighted ? { endIcon: <Icon /> } : {})} ref={ref} />;
});

export default KGFormTextField;
