import { useEffect, useState } from 'react';
import { Liquid as LiquidJs } from 'liquidjs';
import sanitizeHtml from 'sanitize-html';

export const Liquid = ({ template, scope, filters, htmlSanitizationOptions }) => {
  // the rendered value, potentially containing HTML
  const [html, setHtml] = useState(null);
  useEffect(() => {
    const engine = new LiquidJs();
    // register the filters if there are any
    Object.entries(filters || {}).forEach(([name, filter]) => engine.registerFilter(name, filter));
    // sanitize the template based on the provided or default sanitization options
    const sanitizedTemplate = sanitizeHtml(template, htmlSanitizationOptions || defaultHtmlSanitizationOptions);
    // render the template
    const html = engine.parseAndRenderSync(sanitizedTemplate, scope);
    setHtml(html);
  }, [template, scope, filters]);

  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};

/**
 * Returns the default HTML sanitization options, which can be used as a basis to create custom sanitization options.
 *
 * The rules have been copied from Looker, cf. https://cloud.google.com/looker/docs/html-sanitization.
 */
export const getHtmlSanitizationOptions = () => ({
  allowedTags: [
    'a',
    'abbr',
    'acronym',
    'address',
    'area',
    'article',
    'aside',
    'audio',
    'b',
    'bdi',
    'bdo',
    'big',
    'blockquote',
    'br',
    'button',
    'canvas',
    'caption',
    'center',
    'cite',
    'code',
    'col',
    'colgroup',
    'datalist',
    'dd',
    'del',
    'details',
    'dfn',
    'dir',
    'div',
    'dl',
    'dt',
    'em',
    'fieldset',
    'figcaption',
    'footer',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'header',
    'ins',
    'hr',
    'i',
    'img',
    'kbd',
    'label',
    'legend',
    'li',
    'map',
    'mark',
    'menu',
    'meter',
    'nav',
    'ol',
    'output',
    'p',
    'pre',
    'q',
    's',
    'samp',
    'section',
    'small',
    'source',
    'span',
    'strike',
    'strong',
    'sub',
    'summary',
    'sup',
    'table',
    'tbody',
    'td',
    'tfoot',
    'th',
    'thead',
    'time',
    'tr',
    'track',
    'tt',
    'u',
    'ul',
    'var',
    'video'
  ],
  allowedAttributes: {
    '*': [
      'abbr',
      'accept',
      'accept-charset',
      'accesskey',
      'action',
      'align',
      'alt',
      'autoplay',
      'axis',
      'border',
      'cellpadding',
      'cellspacing',
      'char',
      'charoff',
      'charset',
      'checked',
      'cite',
      'class',
      'clear',
      'color',
      'cols',
      'colspan',
      'compact',
      'controls',
      'controlslist',
      'coords',
      'datetime',
      'dir',
      'disabled',
      'enctype',
      'for',
      'frame',
      'headers',
      'height',
      'href',
      'hreflang',
      'hspace',
      'id',
      'ismap',
      'label',
      'lang',
      'longdesc',
      'loop',
      'loopcount',
      'loopend',
      'loopstart',
      'maxlength',
      'media',
      'method',
      'multiple',
      'muted',
      'name',
      'nohref',
      'noshade',
      'nowrap',
      'poster',
      'preload',
      'prompt',
      'readonly',
      'rel',
      'rev',
      'rows',
      'rowspan',
      'rules',
      'scope',
      'selected',
      'shape',
      'size',
      'span',
      'src',
      'start',
      'style',
      'summary',
      'tabindex',
      'target',
      'title',
      'type',
      'usemap',
      'valign',
      'value',
      'vspace',
      'width',
      'xml:lang'
    ]
  },
  allowedStyles: {
    '*': Object.fromEntries(
      [
        '-moz-border-radius',
        '-moz-border-radius-bottomleft',
        '-moz-border-radius-bottomright',
        '-moz-border-radius-topleft',
        '-moz-border-radius-topright',
        '-moz-box-shadow',
        '-moz-outline',
        '-moz-outline-color',
        '-moz-outline-style',
        '-moz-outline-width',
        '-o-text-overflow',
        '-webkit-border-bottom-left-radius',
        '-webkit-border-bottom-right-radius',
        '-webkit-border-radius',
        '-webkit-border-radius-bottom-left',
        '-webkit-border-radius-bottom-right',
        '-webkit-border-radius-top-left',
        '-webkit-border-radius-top-right',
        '-webkit-border-top-left-radius',
        '-webkit-border-top-right-radius',
        '-webkit-box-shadow',
        'azimuth',
        'background',
        'background-attachment',
        'background-color',
        'background-image',
        'background-position',
        'background-repeat',
        'border',
        'border-bottom',
        'border-bottom-color',
        'border-bottom-left-radius',
        'border-bottom-right-radius',
        'border-bottom-style',
        'border-bottom-width',
        'border-collapse',
        'border-color',
        'border-left',
        'border-left-color',
        'border-left-style',
        'border-left-width',
        'border-radius',
        'border-right',
        'border-right-color',
        'border-right-style',
        'border-right-width',
        'border-spacing',
        'border-style',
        'border-top',
        'border-top-color',
        'border-top-left-radius',
        'border-top-right-radius',
        'border-top-style',
        'border-top-width',
        'border-width',
        'box-shadow',
        'caption-side',
        'clear',
        'color',
        'cue',
        'cue-after',
        'cue-before',
        'cursor',
        'direction',
        'display',
        'elevation',
        'empty-cells',
        'float',
        'font',
        'font-family',
        'font-size',
        'font-stretch',
        'font-style',
        'font-variant',
        'font-weight',
        'height',
        'image()',
        'letter-spacing',
        'line-height',
        'linear-gradient()',
        'list-style',
        'list-style-image',
        'list-style-position',
        'list-style-type',
        'margin',
        'margin-bottom',
        'margin-left',
        'margin-right',
        'margin-top',
        'max-height',
        'max-width',
        'min-height',
        'min-width',
        'outline',
        'outline-color',
        'outline-style',
        'outline-width',
        'overflow',
        'padding',
        'padding-bottom',
        'padding-left',
        'padding-right',
        'padding-top',
        'pause',
        'pause-after',
        'pause-before',
        'pitch',
        'pitch-range',
        'quotes',
        'radial-gradient()',
        'rect()',
        'repeating-linear-gradient()',
        'repeating-radial-gradient()',
        'rgb()',
        'rgba()',
        'richness',
        'speak',
        'speak-header',
        'speak-numeral',
        'speak-punctuation',
        'speech-rate',
        'stress',
        'table-layout',
        'text-align',
        'text-decoration',
        'text-indent',
        'text-overflow',
        'text-shadow',
        'text-transform',
        'text-wrap',
        'unicode-bidi',
        'vertical-align',
        'voice-family',
        'volume',
        'white-space',
        'width',
        'word-spacing',
        'word-wrap'
      ]
        // allow any value for all styles
        .map(name => [name, [/^.*$/]])
    )
  },
  transformTags: {
    a: (tagName, attribs) => ({
      tagName: tagName,
      attribs: {
        ...attribs,
        // force links to open in a new window
        target: '_blank'
      }
    })
  }
});

const defaultHtmlSanitizationOptions = getHtmlSanitizationOptions();
