import KnowledgeGraph from 'svg/knowledge-graph.svg';
import Slack from 'svg/slack.svg';
import Dbt from 'svg/dbt.svg';
import Veezoo from 'svg/veezoo-logo.svg';
import Preferences from 'svg/preferences.svg';
import CurlyBrackets from 'svg/curly-brackets.svg';
import Tenants from 'svg/tenants.svg';
import Database from 'svg/database.svg';
import Tools from 'svg/tools.svg';
import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

export const MicrophoneIcon = () => (
  <svg width="512" height="512" viewBox="0 0 512 512">
    <path d="M240 352c44.183 0 80-35.817 80-80v-192c0-44.183-35.817-80-80-80s-80 35.817-80 80v192c0 44.183 35.818 80 80 80zM352 224v48c0 61.855-50.145 112-112 112s-112-50.145-112-112v-48h-32v48c0 74.119 56.002 135.15 128 143.11v64.89h-64v32h160v-32h-64v-64.89c71.997-7.96 128-68.991 128-143.11v-48h-32z" />
  </svg>
);

export const HistoryIcon = () => (
  <svg height="48" viewBox="0 0 48 48" width="48" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0h48v48h-48z" fill="none" />
    <path
      d="M25.99 6c-9.95 0-17.99 8.06-17.99 18h-6l7.79 7.79.14.29 8.07-8.08h-6c0-7.73 6.27-14 14-14s14 6.27 14 14-6.27 14-14 14c-3.87 0-7.36-1.58-9.89-4.11l-2.83 2.83c3.25 3.26 7.74 5.28 12.71 5.28 9.95 0 18.01-8.06 18.01-18s-8.06-18-18.01-18zm-1.99 10v10l8.56 5.08 1.44-2.43-7-4.15v-8.5h-3z"
      opacity=".9"
    />
  </svg>
);

export const MicrophoneIconDisabled = () => (
  // Generated by IcoMoon.io
  <svg width="512" height="512" viewBox="0 0 512 512">
    <path d="M240 352c44.183 0 80-35.817 80-80v-192c0-44.183-35.817-80-80-80s-80 35.817-80 80v192c0 44.183 35.818 80 80 80zM352 224v48c0 61.855-50.145 112-112 112s-112-50.145-112-112v-48h-32v48c0 74.119 56.002 135.15 128 143.11v64.89h-64v32h160v-32h-64v-64.89c71.997-7.96 128-68.991 128-143.11v-48h-32z" />
    <line x1="0" y1="100%" x2="90%" y2="0" style={{ stroke: 'rgb(255,0,0)', strokeWidth: '50' }} />
  </svg>
);

export const PlayIcon = () => (
  <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-424.000000, -198.000000)" fill="#fff">
        <g transform="translate(244.000000, 12.000000)">
          <g transform="translate(60.000000, 172.000000)">
            <path d="M132.75,23 C132.75,23.1275 132.6855,23.246 132.57825,23.31575 L127.32825,26.69075 C127.266,26.7305 127.1955,26.75 127.125,26.75 C127.06275,26.75 127.00125,26.73425 126.945,26.7035 C126.825,26.639 126.75,26.51225 126.75,26.375 L126.75,19.625 C126.75,19.48775 126.825,19.361 126.945,19.2965 C127.06575,19.22975 127.212,19.23425 127.32825,19.30925 L132.57825,22.68425 C132.6855,22.754 132.75,22.8725 132.75,23 L132.75,23 Z M133.875,14 L124.125,14 C121.85025,14 120,15.851 120,18.125 L120,27.875 C120,30.149 121.85025,32 124.125,32 L133.875,32 C136.14975,32 138,30.149 138,27.875 L138,18.125 C138,15.851 136.14975,14 133.875,14 L133.875,14 Z"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const SupportIcon = () => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 512 512">
    <circle style={{ fill: '#fff' }} cx="256" cy="256" r="256" />
    <path
      style={{ fill: '#fff' }}
      d="M257.024,511.987c140.588-0.552,254.439-114.42,254.964-255.014L384.18,129.165l-9.395,17.953
      L296.352,68L68,296.352l71.172,71.172l-13.505,13.105L257.024,511.987z"
    />
    <path
      d="M256,75.667c-99.595,0-180.333,80.738-180.333,180.333S156.405,436.333,256,436.333
      S436.333,355.595,436.333,256S355.595,75.667,256,75.667z M256,339.667c-46.208,0-83.667-37.459-83.667-83.667
      s37.459-83.667,83.667-83.667s83.667,37.459,83.667,83.667S302.208,339.667,256,339.667z"
    />
    <path
      d="M256,75.667c-0.016,0-0.032,0.001-0.048,0.001v96.667c0.016,0,0.032-0.001,0.048-0.001
      c46.208,0,83.667,37.459,83.667,83.667S302.208,339.667,256,339.667c-0.016,0-0.032-0.001-0.048-0.001v96.667
      c0.016,0,0.032,0.001,0.048,0.001c99.595,0,180.333-80.738,180.333-180.333S355.595,75.667,256,75.667z"
    />
    <polygon style={{ fill: '#8798a4' }} points="280.314,183.333 231.686,183.333 215.648,68 296.352,68" />
    <polygon style={{ fill: '#8798a4' }} points="296.352,68 255.952,68 255.952,183.333 280.314,183.333" />
    <polygon style={{ fill: '#8798a4' }} points="231.686,328.667 280.314,328.667 296.352,444 215.648,444" />
    <g>
      <polygon style={{ fill: '#8798a4' }} points="280.314,328.667 255.952,328.667 255.952,444 296.352,444" />
      <polygon style={{ fill: '#8798a4' }} points="328.667,280.314 328.667,231.686 444,215.648 444,296.352" />
    </g>
    <polygon style={{ fill: '#8798a4' }} points="183.333,231.686 183.333,280.314 68,296.352 68,215.648" />
  </svg>
);

export const StarIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 15 15">
    <path
      fill="#ffbd00"
      d="M7.5 0.25 L9.375 6 h5.625 L10.375 9.25 L12.25 14.875 L7.5 11.375 L2.75 14.875 L4.625 9.25 L0 6 h5.625 Z"
    />
  </svg>
);

export const HeartIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24" height="24">
    <path
      fill="#F44336"
      d="M34,9c-4.2,0-7.9,2.1-10,5.4C21.9,11.1,18.2,9,14,9C7.4,9,2,14.4,2,21c0,11.9,22,24,22,24s22-12,22-24 C46,14.4,40.6,9,34,9z"
    />
  </svg>
);

export const BookmarkIcon = () => (
  <svg viewBox="0 -256 1792 1792" id="svg2" width="100%" height="100%">
    <g transform="matrix(1,0,0,-1,258.16949,1270.2373)" id="g4">
      <path
        d="m 1164,1408 q 23,0 44,-9 33,-13 52.5,-41 19.5,-28 19.5,-62 V 7 q 0,-34 -19.5,-62 -19.5,-28 -52.5,-41 -19,-8 -44,-8 -48,0 -83,32 L 640,352 199,-72 q -36,-33 -83,-33 -23,0 -44,9 Q 39,-83 19.5,-55 0,-27 0,7 v 1289 q 0,34 19.5,62 19.5,28 52.5,41 21,9 44,9 h 1048 z"
        id="path6"
      />
    </g>
  </svg>
);

export const BoltIcon = () => (
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512">
    <polygon points="426.667,213.333 288.36,213.333 333.706,0 148.817,0 85.333,298.667 227.556,298.667 227.556,512" />
  </svg>
);

export function PeopleGroupIcon() {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 80.13 80.13">
      <g>
        <path
          d="M48.355,17.922c3.705,2.323,6.303,6.254,6.776,10.817c1.511,0.706,3.188,1.112,4.966,1.112
        c6.491,0,11.752-5.261,11.752-11.751c0-6.491-5.261-11.752-11.752-11.752C53.668,6.35,48.453,11.517,48.355,17.922z M40.656,41.984
        c6.491,0,11.752-5.262,11.752-11.752s-5.262-11.751-11.752-11.751c-6.49,0-11.754,5.262-11.754,11.752S34.166,41.984,40.656,41.984
        z M45.641,42.785h-9.972c-8.297,0-15.047,6.751-15.047,15.048v12.195l0.031,0.191l0.84,0.263
        c7.918,2.474,14.797,3.299,20.459,3.299c11.059,0,17.469-3.153,17.864-3.354l0.785-0.397h0.084V57.833
        C60.688,49.536,53.938,42.785,45.641,42.785z M65.084,30.653h-9.895c-0.107,3.959-1.797,7.524-4.47,10.088
        c7.375,2.193,12.771,9.032,12.771,17.11v3.758c9.77-0.358,15.4-3.127,15.771-3.313l0.785-0.398h0.084V45.699
        C80.13,37.403,73.38,30.653,65.084,30.653z M20.035,29.853c2.299,0,4.438-0.671,6.25-1.814c0.576-3.757,2.59-7.04,5.467-9.276
        c0.012-0.22,0.033-0.438,0.033-0.66c0-6.491-5.262-11.752-11.75-11.752c-6.492,0-11.752,5.261-11.752,11.752
        C8.283,24.591,13.543,29.853,20.035,29.853z M30.589,40.741c-2.66-2.551-4.344-6.097-4.467-10.032
        c-0.367-0.027-0.73-0.056-1.104-0.056h-9.971C6.75,30.653,0,37.403,0,45.699v12.197l0.031,0.188l0.84,0.265
        c6.352,1.983,12.021,2.897,16.945,3.185v-3.683C17.818,49.773,23.212,42.936,30.589,40.741z"
        />
      </g>
    </svg>
  );
}

export const SlackIcon = props => <SvgIcon component={Slack} viewBox={Slack().props.viewBox} {...props} />;

export const DbtIcon = props => <SvgIcon component={Dbt} viewBox={Dbt().props.viewBox} {...props} />;

export const KnowledgeGraphIcon = props => (
  <SvgIcon component={KnowledgeGraph} viewBox={KnowledgeGraph().props.viewBox} {...props} />
);

export const VeezooIcon = props => <SvgIcon component={Veezoo} viewBox={Veezoo().props.viewBox} {...props} />;

export const VeezooAdminIcon = props => (
  <SvgIcon component={Preferences} viewBox={Preferences().props.viewBox} {...props} />
);

export const VeezooStudioIcon = props => (
  <SvgIcon component={CurlyBrackets} viewBox={CurlyBrackets().props.viewBox} {...props} />
);

export const TenantsIcon = props => <SvgIcon component={Tenants} viewBox={Tenants().props.viewBox} {...props} />;

export const DatabaseIcon = props => <SvgIcon component={Database} viewBox={Database().props.viewBox} {...props} />;

export const ToolsIcon = props => <SvgIcon component={Tools} viewBox={Tools().props.viewBox} {...props} />;
