import { useMemo } from 'react';
import clsx from 'clsx';

import TimeIcon from 'svg/v2/clock-icon.svg';
import IconClose from 'svg/v2/close.svg';

import { DatePicker, QuarterPicker, TimePicker, WeekPicker } from 'components/v3';

import { dateViews } from 'config/constants';

import useStyles from 'styles/JSS/Popover';

export const DatetimeComponent = ({
  views,
  value,
  setDisplayTimeInput,
  onChange,
  displayDate,
  displayTime,
  timeIsMandatory,
  t
}) => {
  const styles = useStyles();

  const datetimeComponent = useMemo(() => {
    let Component = DatePicker;
    if (views.includes(dateViews.week)) {
      Component = WeekPicker;
    } else if (views.includes(dateViews.quarter)) {
      Component = QuarterPicker;
    }

    return (
      <Component
        fullWidth
        value={value.date}
        onChange={value => onChange('date', value)}
        title={t('date')}
        views={views}
      />
    );
  }, [views, value, onChange]);

  return (
    <>
      {displayDate && <div className={styles.mb5}>{datetimeComponent}</div>}
      {displayTime &&
        (timeIsMandatory || value.allowTime ? (
          <div className={clsx(styles.timeComponentContainer, styles.mb5)}>
            <TimePicker value={value.time} onChange={value => onChange('time', value)} title={t('hour')} />
            {!timeIsMandatory && (
              <div className={styles.removeTime} onClick={() => setDisplayTimeInput(false)}>
                <IconClose className={styles.timeCloseIcon} data-test="date-modification-remove-time-button" />
              </div>
            )}
          </div>
        ) : (
          <div
            className={styles.addTimeButton}
            data-test="date-modification-add-time-button"
            onClick={() => setDisplayTimeInput(true)}
          >
            <TimeIcon className={styles.timeIcon} />
            <span>{t('date-modifications.add-time')}</span>
          </div>
        ))}
    </>
  );
};

export default DatetimeComponent;
