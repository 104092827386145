import React, { useEffect, useRef } from 'react';
import moment from 'moment';
import _ from 'lodash';

/**
 * Checks if the specified email address is valid.
 */
export const isEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

/**
 * Checks if the specified URL is a valid URL and uses the HTTP or HTTPS protocol.
 *
 * @see https://stackoverflow.com/a/43467144
 */
export const isHttpUrl = url => {
  try {
    const protocol = new URL(url).protocol;
    return protocol === 'http:' || protocol === 'https:';
  } catch (_) {
    return false;
  }
};

/**
 * Returns the right form (plural or singular) of the specified word based on the given count.
 *
 * Examples:
 *   - pluralize(4, 'object')                'objects'
 *   - pluralize(1, 'object')                'object'
 *   - pluralize(0, 'object')                'objects'
 *   - pluralize(0.1, 'second')              'seconds'
 *   - pluralize(4, 'has', 'have')           'have'
 *   - pluralize(4, 'Objekt', s => s + 'e')  'Objekte'
 *
 * @param arrayOrCount the array containing the or the number of objects represented by the word
 * @param word the word in singular form
 * @param plural either the plural form of the word as a string or a function receiving the singular form and
 *        returning the plural form (default: function appending 's' to the singular form)
 */
export const pluralize = (arrayOrCount, word, plural = singular => singular + 's') => {
  const pluralWord = typeof plural === 'function' ? plural(word) : plural;
  const count = Array.isArray(arrayOrCount) ? arrayOrCount.length : arrayOrCount;
  return Math.abs(count) === 1 ? word : pluralWord;
};

/**
 * Returns the specified string with first character converted to upper case.
 */
export const capitalize = ([firstCharacter, ...remainingCharacters], locale = navigator.language) =>
  firstCharacter.toLocaleUpperCase(locale) + remainingCharacters.join('');

/**
 * Formats a price.
 *
 * @param amount the amount, in the smallest unit of the currency (e.g. cents for the USD currency)
 * @param currency the currency of the amount, as an ISO 4217 code
 * @returns the formatted price
 */
export const formatPrice = (amount, currency) => {
  const options = {
    style: 'currency',
    currency,
    minimumFractionDigits: 0
  };
  const formatter = new Intl.NumberFormat('en-US', options);
  return formatter.format(amount / 100);
};

/**
 * Formats a date.
 *
 * @param date the date to format: either a Date object or a timestamp
 * @param dateOnly true to return the date only (without the time)
 * @returns the formatted date
 */
export const formatDate = (date, dateOnly) => {
  const dateString = date instanceof Date ? date.toLocaleString('de-CH') : new Date(date).toLocaleString('de-CH');
  return dateOnly ? dateString.substr(0, dateString.indexOf(',')) : dateString;
};

/**
 * Formats the specified date as a human-readable string.
 *
 * Example: formatDuration(123)            = '0.1 seconds'
 * Example: formatDuration(1234567)        = '20 minutes 34 seconds'
 * Example: formatDuration(12345678)       = '3 hours 25 minutes'
 * Example: formatDuration(12345678, true) = '3 hours 25 minutes 45 seconds'
 * @param duration the duration in milliseconds
 * @param allUnits if true, then all units are displayed, else only 2
 */
export const formatDuration = (duration, allUnits = false) => {
  duration = moment.duration(duration, 'milliseconds');
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();
  const fractionalSeconds = Math.round(duration.milliseconds() / 100) / 10;
  const parts = [
    days && `${days} ${pluralize(days, 'day')}`,
    hours && `${hours} ${pluralize(hours, 'hour')}`,
    minutes && `${minutes} ${pluralize(minutes, 'minute')}`,
    seconds && `${seconds} ${pluralize(seconds, 'second')}`,
    // only display fractional seconds if we don't display anything else
    !hours && !minutes && !seconds && `${fractionalSeconds} ${pluralize(fractionalSeconds, 'second')}`
  ];
  return (
    // remove empty parts
    _.compact(parts)
      // keep only two or all parts, depending on the 'allUnits' flag
      .slice(0, allUnits ? parts.length : 2)
      // combine the parts to a string
      .join(' ')
  );
};

/**
 * Format's a quantity.
 *
 * @param quantity the quantity to format
 * @param type the type of objects the quantity refers to
 * @returns {*}
 */
export const formatQuantity = (quantity, type) =>
  (isFinite(quantity) ? quantity : 'unlimited') + (type ? ' ' + pluralize(quantity, type) : '');

/**
 * Calculates different totals of a subscription configuration.
 *
 * @param price the price
 * @param viewerQuantity the number of viewers
 * @param creatorQuantity the number of creators
 * @returns an object containing the monthly total for the viewers, the monthly total for the creators, the full
 *          monthly total, the full yearly total and the full total for the billing interval of the price
 */
export const getTotals = (price, viewerQuantity, creatorQuantity) => {
  const viewersMonthlyTotal = viewerQuantity * price.monthlyViewerPrice;
  const creatorsMonthlyTotal = creatorQuantity * price.monthlyCreatorPrice;
  const monthlyTotal = viewersMonthlyTotal + creatorsMonthlyTotal;
  const months = price.billingInterval === 'year' ? 12 : 1;
  return {
    viewersMonthlyTotal,
    creatorsMonthlyTotal,
    monthlyTotal,
    yearlyTotal: monthlyTotal * 12,
    total: monthlyTotal * months
  };
};

/**
 * Opens a centered popup window.
 *
 * Code taken from https://stackoverflow.com/a/16861050.
 *
 * @param url the URL to open
 * @param title the title of the popup window
 * @param width the width of the popup window
 * @param height the height of the popup window
 * @returns {Window} the popup window
 */
export const openPopup = (url, title, width, height) => {
  // fixes dual-screen position
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;
  const screenWidth = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : screen.width;
  const screenHeight = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : screen.height;
  const systemZoom = screenWidth / window.screen.availWidth;
  const left = (screenWidth - width) / 2 / systemZoom + dualScreenLeft;
  const top = (screenHeight - height) / 2 / systemZoom + dualScreenTop;

  return window.open(
    url,
    title,
    `
      scrollbars=yes,
      width=${width / systemZoom}, 
      height=${height / systemZoom}, 
      top=${top}, 
      left=${left}
      `
  );
};

/**
 * Hook keeping track of the previous version of the specified state.
 *
 * @see https://blog.logrocket.com/accessing-previous-props-state-react-hooks/
 */
export const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

/**
 * Hook which allows to check if the element with the specified reference overflows.
 *
 * @see https://www.robinwieruch.de/react-custom-hook-check-if-overflow/
 */
export const useIsOverflow = ref => {
  const [isOverflow, setIsOverflow] = React.useState(undefined);

  React.useLayoutEffect(() => {
    const { current } = ref;
    if (current) {
      const hasOverflowX = current.scrollWidth > current.clientWidth;
      const hasOverflowY = current.scrollHeight > current.clientHeight;
      const hasOverflow = hasOverflowX || hasOverflowY;
      setIsOverflow(hasOverflow);
    }
  }, [ref.current?.clientWidth, ref.current?.clientHeight]);

  return isOverflow;
};

/**
 * Returns a promise which resolves after the specified duration.
 *
 * @param duration the duration in milliseconds
 */
export const sleep = duration => new Promise(resolve => setTimeout(resolve, duration));

/**
 * Returns the spacing for the specified theme with the specified value as a number.
 */
export const spacing = (theme, value) =>
  // remove the 'px' suffix and convert to a number
  Number(theme.spacing(value).slice(0, -2));
