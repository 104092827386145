import { del, get, post, put } from 'services/http';

const getTenants = () => get('/studio/api/tenants');

const createTenant = tenant => post('/studio/api/tenants', tenant);

const updateTenant = (id, tenant) => put(`/studio/api/tenants/${encodeURIComponent(id)}`, tenant);

const deleteTenant = id => del(`/studio/api/tenants/${encodeURIComponent(id)}`);

export default { getTenants, createTenant, updateTenant, deleteTenant };
