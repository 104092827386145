import { memo } from 'react';
import UISkeleton from '@mui/material/Skeleton';

import styles from './styles.scss';

const types = {
  title: 'title',
  line: 'line'
};

const useStyles = ({ type }) => {
  const commonStyles = {
    width: '100%',
    height: 20,
    backgroundColor: 'rgba(255, 255, 255, 0.2)'
  };

  if (type === types.title) {
    return {
      ...commonStyles,
      margin: '30px 0 15px',
      maxWidth: 120
    };
  }
  return {
    ...commonStyles,
    margin: '20px 0 0 15px',
    maxWidth: 140
  };
};

const Skeleton = memo(({ type }) => {
  const classes = useStyles({ type });
  return <UISkeleton variant="rectangular" sx={classes} />;
});

export const SidebarLoader = memo(() => (
  <div className={styles.loading}>
    <Skeleton type={types.title} />
    <Skeleton type={types.line} />
    <Skeleton type={types.line} />
    <Skeleton type={types.line} />
    <Skeleton type={types.title} />
    <Skeleton type={types.line} />
    <Skeleton type={types.line} />
    <Skeleton type={types.line} />
    <Skeleton type={types.title} />
    <Skeleton type={types.line} />
    <Skeleton type={types.line} />
  </div>
));
