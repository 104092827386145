import { useMemo } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Collapse } from 'react-bootstrap';

import AnswerExplanation from 'components/shared/AnswerExplanation';

import { trackEvent } from 'utils/eventTracking';
import { fetchAnswerOfInterpretation } from 'store/modules/chat-messages';

import styles from './answer-message.scss';

const AlternativeAnswers = ({
  showAlternativeInterpretations,
  mainConfidence,
  mainTitle,
  mainFilters,
  alternativeInterpretations,
  isInAdminMode,
  dispatch,
  t
}) => {
  const handleAlternativeInterpretationClick = (interpretationId, answerId) => {
    trackEvent('Alternative Answer Selected', { interpretation: { id: interpretationId }, answer: { id: answerId } });
    dispatch(fetchAnswerOfInterpretation(interpretationId, answerId, ''));
  };

  const createAlternatives = useMemo(
    () =>
      showAlternativeInterpretations &&
      alternativeInterpretations?.map(interpretation => {
        const { title, filters, confidence, interpretationId, answerId } = interpretation;

        const filtersCopy = [...filters];

        const removedFilters = mainFilters
          ?.map((mainFilter, index) => {
            const filterIndex = filtersCopy.indexOf(mainFilter);

            const isRemoved = !filtersCopy || filterIndex < 0;

            if (filterIndex > 0) {
              filtersCopy.splice(filterIndex, 1);
            }

            return {
              isRemoved,
              label: mainFilter,
              mainIdx: index
            };
          })
          .filter(mainFilter => mainFilter.isRemoved);

        const mainFiltersCopy = [...mainFilters];

        let count = 0;
        const alternativeFilters =
          filters &&
          filters.map(filter => {
            const filterIndex = mainFiltersCopy.indexOf(filter);

            const isHighlighted = filterIndex < 0;

            if (filterIndex > 0) {
              mainFiltersCopy.splice(filterIndex, 1);
            }

            const mainIdx = Math.max(count, filterIndex);
            count = count + 1;

            return {
              mainIdx,
              isHighlighted,
              label: filter,
              isGreyedOut: mainFilters.includes(filter)
            };
          });

        const allFilters = [...alternativeFilters, ...removedFilters].sort((a, b) => {
          const aTuple = [a.mainIdx, !a.isGreyedOut, !a.isRemoved, !a.isHighlighted];
          const bTuple = [b.mainIdx, !b.isGreyedOut, !b.isRemoved, !b.isHighlighted];

          return aTuple === bTuple ? 0 : aTuple > bTuple ? 1 : -1;
        });

        const alternativeTitle = {
          title,
          isGreyedOut: false,
          isHighlighted: title !== mainTitle
        };

        return (
          <div
            key={interpretationId}
            className={styles.alternativeItem}
            onClick={() => handleAlternativeInterpretationClick(interpretationId, answerId)}
          >
            {isInAdminMode && 'Confidence Delta: ' + (mainConfidence - confidence)}
            <AnswerExplanation
              titleObject={alternativeTitle}
              filterObjects={allFilters}
              possibleToAddNewFilter={false}
            />
          </div>
        );
      }),
    [showAlternativeInterpretations, alternativeInterpretations, isInAdminMode, mainConfidence, mainTitle, mainFilters]
  );

  return (
    <Collapse in={showAlternativeInterpretations}>
      <div>
        <div className={styles.alternativeInformation}>{t('did-you-mean.title')}</div>
        <div className={styles.alternativeItemList}>{createAlternatives}</div>
      </div>
    </Collapse>
  );
};

export default withTranslation('veezoo')(connect()(AlternativeAnswers));

AlternativeAnswers.propTypes = {
  isInAdminMode: PropTypes.bool,
  answerId: PropTypes.string,
  alternativeInterpretations: PropTypes.array,
  mainConfidence: PropTypes.number,
  confidenceDeltaThreshold: PropTypes.number,
  mainTitle: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.object]),
  mainFilters: PropTypes.array,
  showAlternativeInterpretations: PropTypes.bool
};
