import { get, patch } from 'services/http';

const fetchLiteral = uri => {
  return get(`/knowledge-graph/literals/${encodeURIComponent(uri)}`);
};

const updateLiteral = (uri, body) => {
  return patch(`/knowledge-graph/literals/${encodeURIComponent(uri)}`, JSON.stringify(body));
};

export default { fetchLiteral, updateLiteral };
