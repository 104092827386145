import { SIDEBAR_MIN_SIZE, SIDEBAR_EXPANDED_SIZE, MOBILE_DEVICE } from 'config/constants';

const resizeOnMouseMove = setMenuWidth => {
  const viewportWidth = window.innerWidth;
  const viewportHeight = window.innerHeight;

  // for the chat there must be always as much space as at the mobile device threshold
  // which means min_chat_size = MOBILE_DEVICE - SIDEBAR_EXPANDED_SIZE
  // so the max sidebar size is max(window.innerWidth - min_chat_size, SIDEBAR_EXPANDED_SIZE)
  const maxSidebarSize = Math.max(viewportWidth - (MOBILE_DEVICE - SIDEBAR_EXPANDED_SIZE), SIDEBAR_EXPANDED_SIZE);

  const isOutOfScreen = event =>
    event.clientX < 10 ||
    event.clientX > viewportWidth - 10 ||
    event.clientY < 10 ||
    event.clientY > viewportHeight - 10;

  const handleResizable = () => {
    document.body.addEventListener('mousemove', calculate);
    document.body.addEventListener('mouseup', clearListeners);
  };

  const calculate = () => {
    const event = window.event;
    if (isOutOfScreen(event)) clearListeners();

    if (event.clientX > SIDEBAR_MIN_SIZE && event.clientX < maxSidebarSize) {
      setMenuWidth(prev => ({ ...prev, width: event.clientX }));
    }
  };

  const clearListeners = () => {
    const event = window.event;
    if (event.clientX > SIDEBAR_MIN_SIZE && event.clientX < SIDEBAR_EXPANDED_SIZE) {
      setMenuWidth(prev => ({
        ...prev,
        width: prev.isMinimized ? SIDEBAR_EXPANDED_SIZE : SIDEBAR_MIN_SIZE,
        isMinimized: !prev.isMinimized
      }));
    }

    document.body.removeEventListener('mousemove', calculate);
    document.body.removeEventListener('mouseup', clearListeners);
  };
  return handleResizable();
};

export default resizeOnMouseMove;
