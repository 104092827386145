import { tss } from 'tss-react';

export const useStyles = tss.create({
  root: {
    '&': {
      lineHeight: '23px'
    },
    '& h1': {
      fontSize: '1.7em',
      marginTop: 0
    },
    '& h2': {
      fontSize: '1.4em'
    },
    '& h3': {
      fontSize: '1.1em'
    },
    '& ul': {
      paddingLeft: '2em'
    },
    '& ol': {
      paddingLeft: '2em'
    }
  }
});
