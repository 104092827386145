import { chartTypes, isCustomMapType } from 'config/constants';
import {
  AlignHorizontalLeft,
  ArrowDownward,
  ArrowForward,
  BarChart,
  CalendarViewMonth,
  Dashboard,
  PieChart,
  Place,
  Public,
  ScatterPlot,
  ShowChart,
  SsidChart,
  StackedBarChart,
  ViewList,
  WbSunny
} from '@mui/icons-material';
import { SvgIcon } from '@mui/material';
import React from 'react';

const AreaChartSVG = (
  <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path d="M20 2v16H.32c-.318 0-.416-.209-.216-.465l4.469-5.748a.526.526 0 0 1 .789-.062l1.419 1.334a.473.473 0 0 0 .747-.096l3.047-4.74a.466.466 0 0 1 .741-.09l2.171 2.096c.232.225.559.18.724-.1l5.133-7.785C19.51 2.062 19.75 2 20 2z" />
  </svg>
);
const MultiAreaChartSVG = (
  <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24">
    <script xmlns="" />
    <rect fill="none" height="24" width="24" />
    <path d="M8,17l3.39-4.66c0.33-0.46,0.98-0.55,1.42-0.2L21,18.5V19c0,0.55-0.45,1-1,1H4c-0.55,0-1-0.45-1-1l0-5.72 c0.22,0,0.44,0.07,0.62,0.22L8,17z M3,11c0.44,0,0.88,0.15,1.25,0.44l3.37,2.69l2.77-3.81c0.66-0.91,1.95-1.1,2.85-0.4L21,15.97V8 c0-0.55-0.45-1-1-1h-3l-4.18-3.34c-0.45-0.36-1.1-0.27-1.44,0.2L7,10L3.6,7.45C3.42,7.32,3.21,7.25,3,7.25L3,11z" />
  </svg>
);

export const ChartIconFromType = ({ type }) => {
  switch (type) {
    case chartTypes.BAR_CHART:
      return <BarChart style={{ transform: 'rotate(90deg)' }} />;
    case chartTypes.MULTI_AXES_BAR_CATEGORICAL_CHART:
    case chartTypes.MULTI_BAR_CHART:
      return <AlignHorizontalLeft />;
    case chartTypes.STACKED_BAR_CHART:
      return <StackedBarChart style={{ transform: 'rotate(90deg)' }} />;
    case chartTypes.TIMELINE_CHART:
    case chartTypes.LINE_CHART:
      return <ShowChart />;
    case chartTypes.PIE_CHART:
      return <PieChart />;
    case chartTypes.ORDERED_CATEGORICALS_MULTI_LINE_CHART:
    case chartTypes.MULTI_LINE_CHART:
    case chartTypes.RANGE_MULTI_LINE_CHART:
    case chartTypes.MULTI_AXES_LINE_CHART:
      return <SsidChart />;
    case chartTypes.HISTOGRAM_CHART:
    case chartTypes.TOP_COLUMN_CHART:
    case chartTypes.COLUMN_CHART:
      return <BarChart />;
    case chartTypes.TREE_MAP:
      return (
        <span style={{ position: 'relative' }}>
          <span style={{ opacity: '80%' }}>
            <Dashboard />
          </span>
          <span style={{ position: 'absolute', left: 12, top: -3, transform: 'scale(0.6)' }}>
            <ArrowDownward />
          </span>
        </span>
      );
    case chartTypes.MULTI_DRILL_DOWN_COLUMN_CHART:
      return (
        <span style={{ position: 'relative' }}>
          <span style={{ opacity: '80%' }}>
            <ChartIconFromType type={chartTypes.MULTI_COLUMN_CHART} />
          </span>
          <span style={{ position: 'absolute', left: 12, top: -3, transform: 'scale(0.6)' }}>
            <ArrowDownward />
          </span>
        </span>
      );
    case chartTypes.DRILL_DOWN_COLUMN_CHART:
      return (
        <span style={{ position: 'relative' }}>
          <span style={{ opacity: '80%' }}>
            <ChartIconFromType type={chartTypes.COLUMN_CHART} />
          </span>
          <span style={{ position: 'absolute', left: 12, top: -3, transform: 'scale(0.6)' }}>
            <ArrowDownward />
          </span>
        </span>
      );
    case chartTypes.DRILL_DOWN_PIE_CHART:
      return (
        <span style={{ position: 'relative' }}>
          <span style={{ opacity: '80%' }}>
            <ChartIconFromType type={chartTypes.PIE_CHART} />
          </span>
          <span style={{ position: 'absolute', left: 12, top: -3, transform: 'scale(0.6)' }}>
            <ArrowDownward />
          </span>
        </span>
      );
    case chartTypes.MULTI_AXES_LINE_COLUMN_CHART:
      return (
        <span style={{ position: 'relative' }}>
          <span style={{ opacity: '40%' }}>
            <ChartIconFromType type={chartTypes.COLUMN_CHART} />
          </span>
          <span style={{ position: 'absolute', left: 0 }}>
            <ChartIconFromType type={chartTypes.LINE_CHART} />
          </span>
        </span>
      );
    case chartTypes.MULTI_COLUMN_CHART:
    case chartTypes.MULTI_AXES_COLUMN_CHART:
    case chartTypes.MULTI_AXES_COLUMN_CATEGORICAL_CHART:
    case chartTypes.TIME_MULTI_COLUMN_CHART:
    case chartTypes.TIME_MULTI_COLUMN_CHART2:
      return <AlignHorizontalLeft style={{ transform: 'rotate(-90deg)' }} />;
    case chartTypes.STACKED_COLUMN_CHART:
    case chartTypes.TIME_STACKED_COLUMN_CHART:
      return <StackedBarChart />;
    case chartTypes.HEAT_MATRIX_CHART:
      return <CalendarViewMonth />;
    case chartTypes.PERCENT_STACKED_BAR_CHART:
      return <ViewList />;
    case chartTypes.PERCENT_TIME_STACKED_COLUMN_CHART:
    case chartTypes.PERCENT_STACKED_COLUMN_CHART:
      return <ViewList style={{ transform: 'rotate(90deg)' }} />;
    case chartTypes.AREA_CHART:
      return <SvgIcon>{AreaChartSVG}</SvgIcon>;
    case chartTypes.MULTI_AREA_CHART:
    case chartTypes.STACKED_AREA_CHART:
      return <SvgIcon>{MultiAreaChartSVG}</SvgIcon>;
    case chartTypes.PERCENT_STACKED_AREA_CHART:
      return <SvgIcon style={{ transform: 'rotate(180deg)' }}>{MultiAreaChartSVG}</SvgIcon>;
    case chartTypes.CHOROPLETH_MAP:
      return <Public />;
    case chartTypes.SIMPLE_NUMBER_CHART:
      return <span>123</span>;
    case chartTypes.TWO_NUMBER_CHART:
      return (
        <span style={{ position: 'relative' }}>
          <div style={{ opacity: '90%', transform: 'scale(0.8)' }}>123</div>
          <div style={{ position: 'absolute', left: 12, top: -3, transform: 'scale(0.6)' }}>
            <ArrowDownward />
          </div>
        </span>
      );
    case chartTypes.NUMBER_COMPARISON_CHART:
      return (
        <span style={{ position: 'relative' }}>
          <div style={{ opacity: '90%', transform: 'scale(0.8)' }}>123</div>
          <div style={{ position: 'absolute', left: 12, top: -3, transform: 'scale(0.6)' }}>
            <ArrowForward />
          </div>
        </span>
      );
    case chartTypes.HIERARCHICAL_TABLE_CHART:
    case chartTypes.TABLE_CHART:
      return <span className="icon-table" style={{ transform: 'scale(0.7)' }} />;
    case chartTypes.LABELED_MULTI_SCATTER_CHART:
    case chartTypes.MULTI_SCATTER_CHART:
    case chartTypes.SCATTER_CHART:
    case chartTypes.MULTI_HISTOGRAM_CHART:
    case chartTypes.MULTI_BUBBLE_CHART:
    case chartTypes.BUBBLE_CHART:
      return <ScatterPlot />;
    case chartTypes.VEEZOO_MAP_CHART:
      return <Place />;
    case chartTypes.SUNBURST_CHART:
      return <WbSunny />;
    default:
      if (isCustomMapType(type)) {
        return <Public />;
      } else {
        return <span />;
      }
  }
};
