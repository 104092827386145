import React from 'react';

import UIDrawer from '@mui/material/Drawer';

import { getSx } from './styles';

const Drawer = ({
  open,
  onClose,
  children,
  paperClasses = {},
  width = 300,
  anchor = 'left',
  variant = 'persistent',
  ...rest
}) => {
  const sx = getSx({ width, paperClasses });

  return (
    <UIDrawer variant={variant} anchor={anchor} open={open} onClose={onClose} sx={sx} {...rest}>
      {children}
    </UIDrawer>
  );
};

export default Drawer;
