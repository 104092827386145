import { get } from 'services/http';

const autocompleteEditor = (kgId, prefix) =>
  get(`/studio/api/knowledge-graphs/${encodeURIComponent(kgId)}/editor/autocomplete`, { prefix });

const getDocumentation = keyword => get(`/studio/api/editor/documentation/${encodeURIComponent(keyword)}`);

export default {
  autocompleteEditor,
  getDocumentation
};
