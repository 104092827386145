import UISkeleton from '@mui/material/Skeleton';

const useSkeletonStyles = () => ({
  '&.MuiSkeleton-root': {
    backgroundColor: 'rgba(255, 255, 255, 0.2)'
  }
});

const Skeleton = ({ type, ...props }) => {
  const classes = useSkeletonStyles();
  return <UISkeleton variant="rectangular" sx={classes} {...props} />;
};

export default Skeleton;
