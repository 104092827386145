import { Component, createRef, memo, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { boundMethod } from 'autobind-decorator';
import { findDOMNode, createPortal } from 'react-dom';
import debounce from 'lodash/debounce';
import clsx from 'clsx';

import {
  Editor,
  EditorState,
  ContentState,
  SelectionState,
  convertToRaw,
  Modifier,
  getDefaultKeyBinding
} from 'draft-js';

import { InfoTooltip } from 'components/v3';
import ScrollOnMountElement from 'components/shared/ScrollOnMountElement';
import VoiceRecognition from 'components/VoiceRecognition';
import Button from 'components/buttons/Button';

import Drawer from '@mui/material/Drawer';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';

import { analyzeInput, tryRemoveSuggestions, fetchRecentQuestions } from 'store/modules/inputAnalysis';
import { clearAnnotatedQuestion } from 'store/modules/annotatedQuestion';

import ChevronLeft from 'svg/v2/chevron_left.svg';
import CloseIcon from 'svg/v2/close.svg';
import SendIcon from 'svg/send.svg';

import { passageInfoStrategyTypes, possibleStates } from 'config/constants';

import parseSuggestion from 'utils/suggestionParser';
import { trackEvent } from 'utils/eventTracking';

import {
  applyPassageInfos,
  decorator,
  getLinkedEntitiesFromRawContent,
  getEntitiesFromRawContent,
  removePassageInfos,
  replaceInput
} from 'utils/draft-js-utils';

import defineEntitiesWithHighlight from './highlight';
import styles from './input.scss';

/**
 * Tell webpack to import this CSS as-is, without
 * any mangling or css-modules transforms.
 */
import '!style-loader!css-loader!draft-js/dist/Draft.css'; // eslint-disable-line

import { veezooRoutes } from 'components/app/routes';
import { ConceptNotInDataSpan } from './../decorators/ConceptNotInDataSpan';
import { LinkedSpan } from './../decorators/LinkedSpan';
import UnrecognizedSpan from './../decorators/UnrecognizedSpan';
import { HighlightedSpan } from './../decorators/HighlightedSpan';
import { HistoryIcon, KnowledgeGraphIcon } from './../Icons';
import EmptyInputSuggestions from 'components/input/EmptyInputSuggestions';
import withEmbedded from 'root/hocs/withEmbedded';
import { changeUrlGivenCurrentQueryParams } from 'components/LinkWithQuery';
import DescriptionTooltip from 'components/input/DescriptionTooltip';

const DEFAULT_HIGHLIGHTED_SUGGESTION = -1;
const DEFAULT_SUGGESTION_COUNT = 10;
const MAX_SUGGESTION_COUNT = 20;
const MINIMUM_SUGGESTION_WIDTH = 310;
const EDITOR_CONTAINER_ID = 'EditorContainerHook';
const KNOWLEDGE_GRAPH_BUTTON_CONTAINER_ID = 'KnowledgeGraphButtonContainerHook';
const CHAR_WIDTH_FOR_SUGGESTION_OFFSET = 8.2;

const paperSx = {
  '& .MuiDrawer-paper': {
    borderRadius: 0,
    height: '100%'
  }
};

const isAndroid = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return /android/i.test(userAgent);
};

const isShowingKnowledgeGraph = history => {
  // in embedded mode we will see the url param showDialogue == possibleStates.knowledgeGraph
  // in non-embedded mode we will see url param kgSidebar == true
  const urlParams = new URLSearchParams(history.location.search);
  const showDialogue = urlParams.get('showDialogue');
  const kgSidebar = urlParams.get('kgSidebar');
  return showDialogue === possibleStates.knowledgeGraph || kgSidebar === 'true';
};

const AskButton = memo(({ displayErrorButton, onClick, followUpState, invertColors, t }) => {
  const buttonColor = displayErrorButton
    ? styles.errorButton
    : followUpState
    ? styles.followButton
    : styles.normalButton;

  return (
    <div className={styles.buttonContainer}>
      <Button
        type="submit"
        className={`${'button-primary'} ${styles.mobileButton} ${buttonColor}
         ${invertColors ? styles.invertColors : null}`}
        id="AskButtonHook"
        onClick={onClick}
      >
        <span className={styles.showSpanOnDesktop}>{displayErrorButton ? t('submit') : t('input-ask')}</span>
        <SendIcon className={styles.mobileAskIcon} />
      </Button>
    </div>
  );
});

class Input extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editorState: EditorState.createEmpty(
        decorator(LinkedSpan, UnrecognizedSpan, ConceptNotInDataSpan, HighlightedSpan)
      ),
      isInArrowMode: false,
      isHighlighted: false,
      isInVoiceMode: false,
      justRefocusedEditorOnAndroid: false,
      showDialogue: props.location.search.includes('showDialogue'),
      showRecentQuestions: false,
      showMoreButtonActive: false,
      showSuggestions: false,
      lastAnalyzedInput: undefined,
      showMoreSuggestionsOrRecent: false,
      highlightedSuggestion: DEFAULT_HIGHLIGHTED_SUGGESTION,
      isNegativeFeedback: false,
      lastInputContent: ContentState.createFromText(''),
      shouldFocus: false,
      inputBoxOffset: 0,
      knowledgeGraphButtonOffset: 0,
      editorComponentCounter: 0, // this is to fix the annoying backspace bug in draft
      showSuggestionsDrawer: false
    };

    this.handleInterimVoiceResult = this.handleInterimVoiceResult.bind(this);
    this.keyBindingFn = this.keyBindingFn.bind(this);
    this.setSuggestionHighlight = this.setSuggestionHighlight.bind(this);
    this.handleReturn = this.handleReturn.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.handleKeyCommand = this.handleKeyCommand.bind(this);
    this.expandRecentOrSuggestions = this.expandRecentOrSuggestions.bind(this);
    this.expandRecentOrSuggestionsWithWait = debounce(this.expandRecentOrSuggestions.bind(this), 200);

    this.textInputRef = createRef();
    this.knowledgeGraphButtonRef = createRef();
    this.outerSuggestionRefClassic = createRef();
    this.outerSuggestionRefNonInput = createRef();

    this.highlightDebounce = null;
    this.onChangeDebounce = null;
  }

  componentDidMount() {
    // We need this because otherwise it will fail on IE11 when focusing when going directly on e.g. /graph
    if (this.props.location.pathname === veezooRoutes.chat && this.textInputRef.current) {
      this.textInputRef.current.focus();
    }

    if (!this.props.isMobile) {
      // Recompute element offsets only if the window was resized (or component initialised)
      // if we update it at all times it has quite an impact on performance
      this.updateElementOffsets();
      window.addEventListener('resize', this.updateElementOffsets);
    }
  }

  componentWillUnmount() {
    if (!this.props.isMobile) {
      window.removeEventListener('resize', this.updateElementOffsets);
    }
  }

  applyPassagesWithHighlight = prevProps => {
    const { editorState, showSuggestions, showRecentQuestions } = this.state;
    const { suggestions, selectedPassageInfos } = this.props;
    const { LINKED_PASSAGE_INFO, HIGHLIGHTED_PASSAGE_INFO } = passageInfoStrategyTypes;

    const currentPassages = getEntitiesFromRawContent(convertToRaw(editorState.getCurrentContent()));
    const linkedPassages = currentPassages.filter(entity => entity.type === LINKED_PASSAGE_INFO);
    const highlightedPassages = currentPassages.filter(entity => entity.type === HIGHLIGHTED_PASSAGE_INFO);

    const clearEditorState = removePassageInfos(editorState, [
      ...prevProps.selectedPassageInfos,
      ...highlightedPassages
    ]);

    const isShowingSuggestionsBox = (showSuggestions || showRecentQuestions) && suggestions.length > 0;

    if (!isShowingSuggestionsBox) {
      return this.setState({
        editorState: applyPassageInfos(clearEditorState, selectedPassageInfos),
        lastInputContent: editorState.getCurrentContent()
      });
    }

    const inputOffset = Math.max.apply(Math, suggestions.map(s => s.inputOffset));
    const { anchorOffset } = editorState.getSelection().toJSON();
    const highlightEntities = defineEntitiesWithHighlight([...selectedPassageInfos, ...linkedPassages], {
      inputOffset,
      anchorOffset
    });

    let newEditorState;
    try {
      newEditorState = applyPassageInfos(clearEditorState, [...selectedPassageInfos, ...highlightEntities]);

      this.setState({
        editorState: newEditorState,
        lastInputContent: editorState.getCurrentContent()
      });
    } catch (e) {
      console.log(e);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const { selectedPassageInfos } = this.props;
    const { lastInputContent, editorComponentCounter, shouldFocus, editorState } = this.state;

    /**
     * The shallow array comparison works because our reducers are pure functions
     */

    const inputChanged = prevState.lastInputContent !== lastInputContent;
    const isNewEditor = prevState.editorComponentCounter !== editorComponentCounter;
    const inputNonEmpty = editorState.getCurrentContent().getPlainText().length > 0;
    const differentSelectedPassages =
      JSON.stringify(prevProps.selectedPassageInfos) !== JSON.stringify(selectedPassageInfos);
    const differentSuggestions = JSON.stringify(prevProps.suggestions) !== JSON.stringify(this.props.suggestions);

    if (isNewEditor && !shouldFocus) {
      this.setState({ shouldFocus: true });
    }

    if ((isNewEditor || inputChanged || prevState.showRecentQuestions) && shouldFocus && this.textInputRef.current) {
      this.textInputRef.current.focus();
      this.setState({ shouldFocus: false });
    }

    const followUpToggled = this.props.followUpState !== prevProps.followUpState;
    if (followUpToggled) {
      if (this.textInputRef.current) {
        this.textInputRef.current.focus();
        this.setState({ shouldFocus: false });
      }
    }

    // VZN-4885: There can be situations where the inputBoxOffset is still 0 even after Mount
    // This way we make sure to get the correct offset as soon as it's available
    if (!this.props.isMobile && (this.state.inputBoxOffset === 0 || this.state.knowledgeGraphButtonOffset === 0)) {
      this.updateElementOffsets();
    }

    // 220310 Commit Note: This refactoring happened due to the Input Component continously re-rendering
    // when suggestions were displayed
    if (inputChanged || (inputNonEmpty && (differentSelectedPassages || differentSuggestions))) {
      if (this.highlightDebounce) {
        clearTimeout(this.highlightDebounce);
      }
      this.highlightDebounce = setTimeout(() => this.applyPassagesWithHighlight(prevProps), 500);
    }

    // only reset button activity state once the button disappears i.e
    // when there are no suggestions
    if (this.props.suggestions.length === 0 && prevProps.suggestions.length > 0) {
      this.setState({
        showMoreButtonActive: false
      });
    }

    if (this.props.kgMeta.id !== prevProps.kgMeta.id) {
      // Reset input, thus force getting autocomplete again, and close autocomplete just in case
      this.setState({
        lastAnalyzedInput: undefined,
        showSuggestions: false
      });
    }

    // set the showDialogue flag based on whether the url contains the dialogue param
    if (
      this.props.location.pathname !== prevProps.location.pathname ||
      this.props.location.search !== prevProps.location.search
    ) {
      const { search } = this.props.location;
      const params = new URLSearchParams(search);
      const showDialogue = params.get('showDialogue') !== null;
      this.setState({ showDialogue });
      this.hideSuggestions();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const nonEmptySuggestionsLessThanDefault =
      nextProps.suggestions.length > 0 && nextProps.suggestions.length <= DEFAULT_SUGGESTION_COUNT;

    const differentSuggestions = JSON.stringify(nextProps.suggestions) !== JSON.stringify(this.props.suggestions);
    if (differentSuggestions) {
      this.setState({
        showMoreSuggestionsOrRecent: nonEmptySuggestionsLessThanDefault && !nextProps.shouldRemoveSuggestions
      });
    }

    if (nextProps.annotation && nextProps.annotation !== this.props.annotation) {
      this.selectAnnotation(nextProps.annotation);
    }
  }

  @boundMethod
  updateElementOffsets() {
    // IMPORTANT: element.getBoundingClientRect method returns the size of an element
    // and its position relative to viewport
    if (this.textInputRef.current) {
      const rectInput = findDOMNode(this.textInputRef.current).getBoundingClientRect();
      if (rectInput.left !== this.state.inputBoxOffset) {
        this.setState({ inputBoxOffset: rectInput.left });
      }
    }

    if (this.knowledgeGraphButtonRef.current) {
      const rectKnowledgeGraph = findDOMNode(this.knowledgeGraphButtonRef.current).getBoundingClientRect();
      if (rectKnowledgeGraph.left !== this.state.knowledgeGraphButtonOffset) {
        this.setState({ knowledgeGraphButtonOffset: rectKnowledgeGraph.left });
      }
    }
  }

  hideSuggestions = () => {
    this.setState({
      showRecentQuestions: false,
      showMoreSuggestionsOrRecent: false,
      showSuggestions: false
    });
    this.resetSuggestionHighlight();
  };

  expandRecentOrSuggestions() {
    trackEvent('AutoComplete Suggestions Expanded');
    this.setState({
      showMoreSuggestionsOrRecent: true,
      isHighlighted: true,
      showMoreButtonActive: false
    });
  }

  // If force open is true, the suggestions will be opened even if the text did not change
  @boundMethod
  analyzeCurrentInput(editorState, forceOpen) {
    const { followUpState } = this.props;
    const currentText = editorState.getCurrentContent().getPlainText();
    // We do not show suggestions for multi-line inputs as this would currently mess too much with the UI
    const isMultiLine = this.isMultiLineInput(editorState);
    const currentSelectionOffset = editorState.getSelection().anchorOffset;
    const searchText = currentText.substr(0, currentSelectionOffset);
    // We do not open the suggestions when loading the page (analyzeCurrentInput is called due to new editor state)
    const hasPreviousAnalysis = this.state.lastAnalyzedInput !== undefined;
    if (searchText !== this.state.lastAnalyzedInput && (forceOpen || searchText.length > 0 || hasPreviousAnalysis)) {
      const linkedPassages = getLinkedEntitiesFromRawContent(convertToRaw(editorState.getCurrentContent()));
      this.props.dispatch(analyzeInput(searchText, linkedPassages, followUpState));
      this.setState({ showSuggestions: !isMultiLine, lastAnalyzedInput: searchText, showRecentQuestions: false });
    } else if (forceOpen) {
      this.setState({ showSuggestions: !isMultiLine, showRecentQuestions: false });
    }
  }

  @boundMethod
  onChange(editorState) {
    if (this.onChangeDebounce) {
      clearTimeout(this.onChangeDebounce);
    }

    // Checks if the user has finished typing, then call analyze function.
    this.onChangeDebounce = setTimeout(() => {
      if (isAndroid() && !this.state.justRefocusedEditorOnAndroid) {
        // We quickly blur and refocus to prevent predictive typing on andorid to not letting draftjs know about a change
        // After that the editorState gets updated and will call the onChange method again with the correct editorState
        // That's when we should actually directly call analyze current input - and this time not, as still the wrong text is in editorState
        this.textInputRef.current?.blur();
        this.textInputRef.current?.focus();
        this.setState({ justRefocusedEditorOnAndroid: true });
      } else {
        // call analyzeCurrentInput using up-to-date editorState as it might have changed meanwhile
        this.analyzeCurrentInput(this.state.editorState, false);
      }
    }, 250);

    this.setState({
      editorState,
      isHighlighted: false,
      highlightedSuggestion: DEFAULT_HIGHLIGHTED_SUGGESTION,
      showRecentQuestions: false,
      lastInputContent: editorState.getCurrentContent()
    });

    // We now analyze the correct state of the editor in order to find out the suggestions
    if (isAndroid() && this.state.justRefocusedEditorOnAndroid) {
      this.setState({ justRefocusedEditorOnAndroid: false });
      this.analyzeCurrentInput(editorState, false);
    }
  }

  // suggestion is optional, and will be provided via selection through the empty input suggestions component
  @boundMethod
  selectHighlightedSuggestion(event, suggestion = undefined) {
    if (event !== undefined) {
      event.preventDefault();
    }

    // Populate input with highlighted suggestion when in arrow mode.
    this.setState(
      state => {
        const currentContent = state.editorState.getCurrentContent();
        let replaceWithSuggestion = suggestion;
        if (replaceWithSuggestion === undefined) {
          replaceWithSuggestion = this.state.showRecentQuestions
            ? this.props.recentQuestions[state.highlightedSuggestion]
            : this.props.suggestions[state.highlightedSuggestion];
        }

        // in some weird cases replace with suggestion can be undefined, so let's better check for it here.
        if (replaceWithSuggestion) {
          const editorState = replaceInput(state.editorState, replaceWithSuggestion);
          this.analyzeCurrentInput(editorState, true);
          trackEvent('AutoComplete Suggestion Selected', {
            input: currentContent.getPlainText(),
            offset: state.editorState.getSelection().anchorOffset,
            suggestionText: replaceWithSuggestion.text
          });
          return {
            editorState,
            lastInputContent: currentContent,
            showRecentQuestions: false,
            shouldFocus: true
          };
        } else {
          // we update nothing...
          return {};
        }
      },
      () => this.resetSuggestionHighlight()
    );
  }

  selectAnnotation(annotation) {
    const { followUpState } = this.props;

    this.setState(() => {
      const emptyEditorState = EditorState.createEmpty(
        decorator(LinkedSpan, UnrecognizedSpan, ConceptNotInDataSpan, HighlightedSpan)
      );
      const currentContent = emptyEditorState.getCurrentContent();
      const editorState = replaceInput(emptyEditorState, annotation);

      const newPlainText = editorState.getCurrentContent().getPlainText();
      const linkedPassages = getLinkedEntitiesFromRawContent(convertToRaw(editorState.getCurrentContent()));
      this.props.dispatch(analyzeInput(newPlainText, linkedPassages, followUpState));

      const newEditorState = Input.moveSelectionToEnd(editorState);

      this.props.dispatch(clearAnnotatedQuestion());

      return {
        editorState: newEditorState,
        lastInputContent: currentContent
      };
    });
  }

  static moveSelectionToEnd(editorState) {
    /**
     * Returns a new EditorState where the Selection is at the end.
     *
     * This ensures to mimic the textarea behaviour where the Selection is placed at
     * the end. This is needed when blocks (like stickers or other media) are added
     * without the editor having had focus yet. It still works to place the
     * Selection at a specific location by clicking on the text.
     */
    const content = editorState.getCurrentContent();
    const blockMap = content.getBlockMap();

    const key = blockMap.last().getKey();
    const length = blockMap.last().getLength();

    const selection = new SelectionState({
      anchorKey: key,
      anchorOffset: length,
      focusKey: key,
      focusOffset: length
    });

    return EditorState.forceSelection(editorState, selection);
  }

  submitMessage = (message, isSuggestion, isVoice) => {
    if (message.trim() === '') {
      return;
    }

    const linkedEntities = getLinkedEntitiesFromRawContent(convertToRaw(this.state.editorState.getCurrentContent()));

    this.setState({ showSuggestions: false, lastAnalyzedInput: undefined });
    this.props.onSubmit(message, isSuggestion, isVoice, linkedEntities);
    this.props.dispatch(tryRemoveSuggestions(this.props.isFetching));
    this.resetContent();
  };

  @boundMethod
  displayRecentQuestions() {
    // we need to know here if we already show recent questions!
    if (!this.state.showRecentQuestions || this.state.showSuggestions) {
      this.setState({
        showRecentQuestions: true,
        showSuggestions: false
      });
    } else {
      // else we hide the drop up
      this.hideRecentQuestions();
    }
  }

  @boundMethod
  hideRecentQuestions() {
    this.setState({
      showRecentQuestions: false,
      showSuggestions: false
    });
  }

  handleInterimVoiceResult(interimResult) {
    this.setState(state => ({
      isHighlighted: false,
      isInVoiceMode: true,
      highlightedSuggestion: DEFAULT_HIGHLIGHTED_SUGGESTION,
      editorState: EditorState.push(state.editorState, ContentState.createFromText(interimResult))
    }));
  }

  handleVoiceResult(result) {
    this.setState(
      state => ({
        isHighlighted: false,
        isInVoiceMode: false,
        highlightedSuggestion: DEFAULT_HIGHLIGHTED_SUGGESTION,
        editorState: EditorState.push(state.editorState, ContentState.createFromText(result))
      }),
      // Show the text for one second before clearing and sending to the server.
      () => setTimeout(this.submitMessage(result, false, true), 1000)
    );
  }

  @boundMethod
  handlePaste(text) {
    this.onChange(
      EditorState.push(
        this.state.editorState,
        Modifier.replaceText(this.state.editorState.getCurrentContent(), this.state.editorState.getSelection(), text)
      )
    );
    return 'handled';
  }

  setSuggestionHighlight(index, isInArrowMode) {
    this.setState({
      // save current input
      highlightedSuggestion: index,
      isHighlighted: true,
      isInArrowMode
    });
  }

  @boundMethod
  resetSuggestionHighlight() {
    this.setState({
      isHighlighted: false,
      isInArrowMode: false,
      highlightedSuggestion: DEFAULT_HIGHLIGHTED_SUGGESTION
    });
  }

  resetContent = () => {
    this.setState(state => ({
      isHighlighted: false,
      highlightedSuggestion: DEFAULT_HIGHLIGHTED_SUGGESTION,
      editorComponentCounter: state.editorComponentCounter + 1,
      lastInputContent: state.editorState.getCurrentContent(),
      // clear Input field
      editorState: EditorState.push(state.editorState, ContentState.createFromText(''))
    }));
  };

  handleReturn(event) {
    // If Shift+Enter is pressed, allow the newline
    if (event.shiftKey) {
      this.hideSuggestions(); // close in case something was open
      return 'not-handled';
    }
    // Handle regular Enter press (submission)
    if (this.showEmptyInputSuggestions()) {
      // gets handled in showEmptyInputSuggestions
      return 'handled';
    }
    if (this.state.showSuggestionsDrawer) {
      this.closeSuggestionsDrawer();
    }
    if (this.state.isHighlighted && this.state.isInArrowMode) {
      this.selectHighlightedSuggestion(event);
    } else {
      const refText = this.textInputRef.current?.editor?.innerText;
      const stateText = this.state.editorState.getCurrentContent().getPlainText();
      const questionToSend = refText || stateText;

      this.submitMessage(questionToSend, this.state.isHighlighted, false);
    }
    this.setState({ shouldFocus: true });
    return 'handled';
  }

  onBlur() {
    this.setState({
      isHighlighted: false,
      highlightedSuggestion: DEFAULT_HIGHLIGHTED_SUGGESTION
    });
  }

  isMultiLineInput = (editorState = null) => {
    const currentState = editorState || this.state.editorState;

    // Check for explicit line breaks first
    const hasLineBreaks = currentState
      .getCurrentContent()
      .getPlainText()
      .includes('\n');

    if (hasLineBreaks) {
      return true;
    }

    // Then check for wrapped text
    if (this.textInputRef.current) {
      const editorNode = findDOMNode(this.textInputRef.current);
      if (!editorNode) return false;

      // Get the editor's content wrapper element
      const contentNode = editorNode.querySelector('.public-DraftEditor-content');
      if (!contentNode) return false;

      // Get the first text block
      const textBlock = contentNode.querySelector('[data-contents="true"] > div');
      if (!textBlock) return false;

      // Get the text spans within the block
      const textSpans = textBlock.getElementsByTagName('span');
      if (textSpans.length === 0) return false;

      // Get the computed style to find the line height
      const computedStyle = window.getComputedStyle(textSpans[0]);
      const lineHeight = parseFloat(computedStyle.lineHeight);

      if (isNaN(lineHeight)) return false;

      // If the block height is greater than 1.5x the line height,
      // we can assume the text has wrapped
      // (using 1.5x instead of exactly 2x to account for padding/margins)
      return textBlock.getBoundingClientRect().height > lineHeight * 1.5;
    }

    return false;
  };

  showEmptyInputSuggestions = () => {
    const showRecentOrSuggestions = this.state.showSuggestions || this.state.showRecentQuestions;
    const isEmptyInput = this.state.editorState.getCurrentContent().getPlainText().length === 0;
    const showDialogue = this.state.showDialogue;
    const negativeFeedbackMode = this.props.recentFeedback.wasNegative;

    return (showDialogue || (showRecentOrSuggestions && isEmptyInput)) && !negativeFeedbackMode;
  };

  onUpOrDownArrow(isUp) {
    const { highlightedSuggestion, showMoreSuggestionsOrRecent, showRecentQuestions } = this.state;
    let suggestionsLength = this.props.suggestions.length;

    // account for the show more button
    if (!showMoreSuggestionsOrRecent) {
      suggestionsLength = DEFAULT_SUGGESTION_COUNT;
    }

    // Open suggestions if closed on up key.
    if (!this.state.showSuggestions && !showRecentQuestions && isUp) {
      if (this.state.editorState.getCurrentContent().getPlainText() === '') {
        this.displayRecentQuestions();
      } else {
        this.analyzeCurrentInput(this.state.editorState, true);
      }
    }

    // Do nothing if no suggestions exist.
    if (suggestionsLength === undefined || suggestionsLength === 0) {
      return;
    }

    // Highlight is at the top of the menu, reset highlight.
    if (highlightedSuggestion === suggestionsLength - 1 && isUp) {
      // if show more button exists in DOM
      if (!showMoreSuggestionsOrRecent) {
        this.setState({ showMoreButtonActive: true });
        // display more suggestions, wait shortly to highlight "Show more"
        this.expandRecentOrSuggestionsWithWait();
      } else {
        this.setSuggestionHighlight(0, true);
      }
    } else if (highlightedSuggestion <= 0 && !isUp) {
      this.resetSuggestionHighlight();
    } else {
      this.setSuggestionHighlight(highlightedSuggestion + (isUp ? 1 : -1), true);
    }
  }

  // Handle our special logic for arrow up and down
  handleKeyCommand(command) {
    // Handling is done by the EmptyInputSuggestions component in this case
    if (this.showEmptyInputSuggestions()) {
      return 'handled';
    }
    if (command === 'arrow-up') {
      this.onUpOrDownArrow(true);
      return 'handled';
    } else if (command === 'arrow-down') {
      this.onUpOrDownArrow(false);
      return 'handled';
    } else if (command === 'escape') {
      this.hideSuggestions();
      return 'handled';
    } else if (command === 'tab') {
      this.selectHighlightedSuggestion();
      return 'handled';
    }

    return 'not-handled';
  }

  // We have our own logic on arrow up and down
  keyBindingFn(event) {
    // if we have a multi line input box we are currently not running the Autocomplete so no need to handle arrow up and down
    const isMultiLine = this.isMultiLineInput();
    if (event.keyCode === 38 && !isMultiLine) {
      return 'arrow-up';
    } else if (event.keyCode === 40 && !isMultiLine) {
      return 'arrow-down';
    } else if (event.keyCode === 27) {
      return 'escape';
    } else if (event.keyCode === 9 && this.state.isHighlighted) {
      // Do not capture tab events if not highlighted for accessability reasons.
      return 'tab';
    }

    return getDefaultKeyBinding(event);
  }

  suggestionIndentation(inputOffset, isForDialogue) {
    const {
      editorState,
      isInArrowMode,
      lastInputContent: stateLastInputContent,
      showRecentQuestions,
      knowledgeGraphButtonOffset,
      inputBoxOffset,
      showDialogue
    } = this.state;

    const { LINKED_PASSAGE_INFO } = passageInfoStrategyTypes;

    const lastInputContent = isInArrowMode ? stateLastInputContent : editorState.getCurrentContent();
    const entityMap = Object.values(convertToRaw(lastInputContent).entityMap);
    const linkedPassages = entityMap.filter(x => x.type === LINKED_PASSAGE_INFO);
    const isSuffix = inputOffset === 0 ? 0 : 1;

    // This is the default in embedded as well as non-embedded mode
    let left = knowledgeGraphButtonOffset;
    if (!showRecentQuestions && !isForDialogue) {
      left =
        inputBoxOffset - 19 + inputOffset * CHAR_WIDTH_FOR_SUGGESTION_OFFSET + linkedPassages.length * 2 * isSuffix;
    }

    // in dialogue mode, we need to restrict the width of the suggestion box to calc(90vw - ${knowledgeGraphButtonOffset}px)
    const maxWidth = showDialogue ? `calc(90vw - ${knowledgeGraphButtonOffset}px)` : '100%';

    const suggestionBoxWidth =
      this.outerSuggestionRefClassic.current && this.outerSuggestionRefClassic.current.clientWidth;
    const viewportWidth = document.body.clientWidth;

    const isWiderThanViewportWidth =
      left + MINIMUM_SUGGESTION_WIDTH > viewportWidth ||
      (suggestionBoxWidth && left + suggestionBoxWidth > viewportWidth);

    if (isWiderThanViewportWidth) {
      return { left: 'unset', right: '0px' };
    }
    return { left: `${left}px`, maxWidth };
  }

  recentQuestionsIndentation(inputOffset) {
    const {
      editorState,
      isInArrowMode,
      lastInputContent: stateLastInputContent,
      showRecentQuestions,
      knowledgeGraphButtonOffset,
      inputBoxOffset
    } = this.state;

    const { LINKED_PASSAGE_INFO } = passageInfoStrategyTypes;

    const lastInputContent = isInArrowMode ? stateLastInputContent : editorState.getCurrentContent();
    const entityMap = Object.values(convertToRaw(lastInputContent).entityMap);
    const linkedPassages = entityMap.filter(x => x.type === LINKED_PASSAGE_INFO);
    const isSuffix = inputOffset === 0 ? 0 : 1;

    // This is the default in embedded as well as non-embedded mode
    let left = knowledgeGraphButtonOffset;
    if (!showRecentQuestions) {
      left =
        inputBoxOffset - 19 + inputOffset * CHAR_WIDTH_FOR_SUGGESTION_OFFSET + linkedPassages.length * 2 * isSuffix;
    }

    const viewportWidth = document.body.clientWidth;
    const maxWidth = viewportWidth - left - 1;

    return { left: `${left}px`, maxWidth: `${maxWidth}px` };
  }

  openSuggestionsDrawer = showHistory => {
    if (showHistory) {
      this.displayRecentQuestions();
      this.props.dispatch(fetchRecentQuestions());
    } else {
      this.hideRecentQuestions();
      this.analyzeCurrentInput(this.state.editorState, true);
    }
    this.setState({ showSuggestionsDrawer: true }, () => this.textInputRef.current?.focus());
  };

  closeSuggestionsDrawer = () => {
    this.setState({ showSuggestionsDrawer: false, showRecentQuestions: false, showSuggestions: false });
  };

  onClickAskButton = () => {
    const refText = this.textInputRef.current?.editor?.innerText;
    const stateText = this.state.editorState.getCurrentContent().getPlainText();
    const questionToSend = refText || stateText;
    this.submitMessage(questionToSend, false, false);
    this.closeSuggestionsDrawer();
    this.setState({ shouldFocus: true });
  };

  onClickInputBox = () => {
    if (this.props.isMobile) {
      this.openSuggestionsDrawer(false);
    } else {
      this.textInputRef.current.focus();
      this.setState({ shouldFocus: false });
      this.analyzeCurrentInput(this.state.editorState, true);
    }
  };

  @boundMethod
  toggleKnowledgeGraph() {
    if (this.props.isEmbedded) {
      changeUrlGivenCurrentQueryParams(
        this.props.history,
        isShowingKnowledgeGraph(this.props.history)
          ? { showDialogue: null }
          : { showDialogue: possibleStates.knowledgeGraph }
      );
    } else {
      changeUrlGivenCurrentQueryParams(
        this.props.history,
        isShowingKnowledgeGraph(this.props.history)
          ? { kgSidebar: null, visualMode: null }
          : { kgSidebar: true, visualMode: true }
      );
    }
  }

  // if we have two rendered click away listeners we run into issues and only one gets executed
  // that's why there's custom logic to handle click away based on the state
  @boundMethod
  handleClickAway(e) {
    // Only hide suggestions if clicking away somewhere else than the input, keep open if clicking on input
    // We traverse the DOM upwards in case a child of the editor container was clicked (which is usually the case)
    let el = e?.target;
    let shouldHide = true;
    let i = 0; // Avoid infinite recursion just in case
    while (el && i < 200) {
      if (el.id === EDITOR_CONTAINER_ID || el.id === KNOWLEDGE_GRAPH_BUTTON_CONTAINER_ID) {
        shouldHide = false;
        break;
      }
      el = el.parentNode;
      i += 1;
    }
    if (shouldHide) {
      // if suggestions are showing hide them, otherwsie it must be the click away listener for the dialogue
      if (this.state.showSuggestions || this.state.showRecentQuestions) {
        this.hideSuggestions();
      } else {
        changeUrlGivenCurrentQueryParams(this.props.history, { showDialogue: null });
      }
    }
  }

  render() {
    const { showMoreSuggestionsOrRecent, showMoreButtonActive, showDialogue } = this.state;
    const { t, recentFeedback, followUpState, isEmbedded } = this.props;
    const { suggestions, recentQuestions } = this.props; // todo: instead using let better to use different variable name
    const SUGGESTION_HEIGHT = 41;
    let key = 0;

    const showRecentOrSuggestions = this.state.showSuggestions || this.state.showRecentQuestions;
    const isEmptyInput = this.state.editorState.getCurrentContent().getPlainText().length === 0;
    const inputOffset = Math.min.apply(Math, suggestions.map(s => s.inputOffset));
    let recentOrSuggestions = this.state.showRecentQuestions ? recentQuestions : suggestions;
    let displayedRecentOrSuggestions = null;

    if (!this.props.isMobile) {
      if (!showMoreSuggestionsOrRecent) {
        // keep only the first DEFAULT_SUGGESTION_COUNT elements.
        recentOrSuggestions = recentOrSuggestions.slice(0, DEFAULT_SUGGESTION_COUNT);
      }
      // Reverse the array because the autosuggest menu drops upwards.
      displayedRecentOrSuggestions = recentOrSuggestions.reduceRight((previous, current, index) => {
        const isHighlighted = this.state.highlightedSuggestion === index;
        const suggestionElement = (
          <DescriptionTooltip key={`description_tooltip_${index}`} suggestion={current} shouldOpen={isHighlighted}>
            <div
              id="SuggestionHook" // todo id should be unique
              key={key++}
              className={isHighlighted ? styles.suggestionHighlighted : styles.suggestion}
              onMouseOver={() => this.setSuggestionHighlight(index, false)}
              onMouseDown={event => this.selectHighlightedSuggestion(event)}
              onMouseOut={() => this.resetSuggestionHighlight()}
            >
              {current.inputOffset - inputOffset > 0 && <>...&nbsp;</>}
              {parseSuggestion(
                current,
                styles._plain,
                styles._linked,
                styles._linkedDescription,
                styles._onlyShow,
                styles._icon
              )}
            </div>
          </DescriptionTooltip>
        );
        previous.push(suggestionElement);
        return previous;
      }, []);
    } else {
      // Reverse the array because the autosuggest menu drops upwards.
      displayedRecentOrSuggestions = recentOrSuggestions.reduce((previous, current, index) => {
        previous.push({
          id: `mobile_suggestion_${index}`,
          text: (
            <div
              id={`mobile_suggestion_div_${index}`}
              key={key++}
              className={clsx(styles.suggestion, styles.fullWidth)}
              onMouseOver={() => this.setSuggestionHighlight(index, false)}
              onMouseDown={event => this.selectHighlightedSuggestion(event)}
              onMouseOut={() => this.resetSuggestionHighlight()}
            >
              {current.inputOffset > 0 && <>...&nbsp;</>}
              {parseSuggestion(
                current,
                styles._plain,
                styles._linked,
                styles._linkedDescription,
                styles._onlyShow,
                styles._icon
              )}
            </div>
          )
        });
        return previous;
      }, []);
    }

    const emptyInputSuggestions = (
      <EmptyInputSuggestions
        t={t}
        graph={this.props.graph}
        recentQuestions={recentQuestions}
        suggestions={suggestions}
        onShowKnowledgeGraph={this.toggleKnowledgeGraph}
        dispatch={this.props.dispatch}
        isEmbedded={isEmbedded}
        shouldShowDiscoveryButton={this.props.shouldShowDiscoveryButton}
        hasKnowledgeGraphSupport={this.props.kgMeta.hasKnowledgeGraphSupport}
        onSuggestionSelection={this.selectHighlightedSuggestion}
        onClickAway={this.handleClickAway}
      />
    );

    const classicSuggestions = (
      <Fragment>
        {!showMoreSuggestionsOrRecent && (
          <button
            id="showMoreSuggestions"
            className={showMoreButtonActive ? 'showMoreSuggestionsActive' : 'showMoreSuggestions'}
            onMouseDown={event => {
              event.preventDefault(); // Do not remove focus from input box
              this.expandRecentOrSuggestions();
            }}
            ref={this.showMoreButtonRef}
          >
            {t('show-more')}
          </button>
        )}
        {displayedRecentOrSuggestions}
        {/* scroll to the bottom of suggestions after click on show more button */}
        {showMoreSuggestionsOrRecent && <ScrollOnMountElement />}
      </Fragment>
    );

    const defaultSuggestionContainerHeight = DEFAULT_SUGGESTION_COUNT * SUGGESTION_HEIGHT + 50 + 'px';

    // Avoid it being too tall for small screens, leaving some leeway (note: this does not necessarily automatically react to window resizing)
    const maxSuggestionContainerHeight =
      Math.min(MAX_SUGGESTION_COUNT * SUGGESTION_HEIGHT, window.innerHeight - 200) + 'px';

    // Portal the suggestion list to the top level of the body for performance reasons (as it's absolutely positioned)
    // This way the browser will spend less time layouting.
    const classicSuggestionsList =
      classicSuggestions &&
      createPortal(
        <ClickAwayListener onClickAway={this.handleClickAway}>
          <div
            id="outer-suggestion"
            ref={this.outerSuggestionRefClassic}
            className={styles.suggestionContainer}
            style={{
              ...this.suggestionIndentation(inputOffset, false),
              maxHeight: showMoreSuggestionsOrRecent ? maxSuggestionContainerHeight : defaultSuggestionContainerHeight
            }}
          >
            {classicSuggestions}
          </div>
        </ClickAwayListener>,
        document.body
      );

    const emptyInputSuggestionsList =
      emptyInputSuggestions &&
      createPortal(
        <ClickAwayListener onClickAway={this.handleClickAway}>
          <div
            id="outer-suggestion-empty-input"
            ref={this.outerSuggestionRefNonInput}
            className={showDialogue ? styles.emptyInputContainerFloating : styles.emptyInputContainer}
            style={{
              ...(!showDialogue ? { ...this.recentQuestionsIndentation(0) } : {}),
              maxHeight: null
            }}
          >
            {emptyInputSuggestions}
          </div>
        </ClickAwayListener>,
        document.body
      );

    const placeholder = this.props.recentFeedback.wasNegative ? t('feedback-placeholder') : t('input-placeholder');

    return (
      <>
        <div
          data-overview-tutorial="step-6"
          className={styles.container}
          onBlur={() => !this.props.isMobile && this.onBlur()}
        >
          {this.props.isMobile && (
            <InfoTooltip text={this.props.t('tooltip.recent-questions')}>
              <button
                type="button"
                className={clsx(
                  styles.nextToInputButton,
                  this.state.showRecentQuestions ? styles.nextToInputButtonSelected : ''
                )}
                onClick={() => this.openSuggestionsDrawer(true)}
              >
                <HistoryIcon />
              </button>
            </InfoTooltip>
          )}
          {!this.props.isMobile && this.props.kgMeta.hasKnowledgeGraphSupport && (
            <InfoTooltip text="Knowledge Graph">
              <button
                type="button"
                id={KNOWLEDGE_GRAPH_BUTTON_CONTAINER_ID}
                ref={this.knowledgeGraphButtonRef}
                className={clsx(
                  styles.nextToInputButton,
                  isShowingKnowledgeGraph(this.props.history) ? styles.nextToInputButtonSelected : ''
                )}
                onClick={this.toggleKnowledgeGraph}
              >
                <KnowledgeGraphIcon />
              </button>
            </InfoTooltip>
          )}
          {!this.props.isMobile && (
            <>
              <VoiceRecognition
                onResult={result => this.handleVoiceResult(result)}
                onInterimResult={result => this.handleInterimVoiceResult(result)}
              />

              {recentOrSuggestions.length > 0 && !isEmptyInput && showRecentOrSuggestions && classicSuggestionsList}
              {this.showEmptyInputSuggestions() && emptyInputSuggestionsList}
            </>
          )}

          <div id={EDITOR_CONTAINER_ID} className={styles.editorContainer} onClick={this.onClickInputBox}>
            {!this.props.isMobile ? (
              <Editor
                editorState={this.state.editorState}
                onChange={this.onChange}
                placeholder={placeholder}
                keyBindingFn={this.keyBindingFn}
                handleKeyCommand={this.handleKeyCommand}
                handlePastedText={this.handlePaste}
                handleReturn={this.handleReturn}
                stripPastedStyles
                ref={this.textInputRef}
              />
            ) : (
              <Editor editorState={this.state.editorState} placeholder={placeholder} stripPastedStyles readOnly />
            )}
          </div>
          <AskButton
            displayErrorButton={recentFeedback.wasNegative}
            onClick={this.onClickAskButton}
            t={t}
            followUpState={followUpState}
            disabled={this.props.isMobile}
            invertColors={!isEmptyInput}
          />
        </div>
        <Drawer
          anchor="bottom"
          open={this.state.showSuggestionsDrawer}
          onClose={this.closeSuggestionsDrawer}
          sx={paperSx}
        >
          <div className={styles.header}>
            <button className={styles.backArrowButton} onClick={this.closeSuggestionsDrawer}>
              <ChevronLeft className={styles.backArrowIcon} />
            </button>
            <div className={styles.mobileInput}>
              <div className={styles.container}>
                <div className={styles.editorContainer}>
                  <Editor
                    editorState={this.state.editorState}
                    onChange={this.onChange}
                    placeholder={placeholder}
                    keyBindingFn={this.keyBindingFn}
                    handleKeyCommand={this.handleKeyCommand}
                    handlePastedText={this.handlePaste}
                    handleReturn={this.handleReturn}
                    stripPastedStyles
                    autoCapitalize="off"
                    autoComplete="off"
                    autoCorrect="off"
                    spellCheck={false}
                    ref={this.textInputRef}
                  />
                  {!isEmptyInput && (
                    <div className={styles.clearInput} onClick={this.resetContent}>
                      <CloseIcon />
                    </div>
                  )}
                </div>
                <AskButton
                  displayErrorButton={recentFeedback.wasNegative}
                  onClick={this.onClickAskButton}
                  t={t}
                  followUpState={followUpState}
                  invertColors={!isEmptyInput}
                />
              </div>
            </div>
          </div>
          {displayedRecentOrSuggestions.length > 0 ? (
            <ul className={styles.list}>
              {displayedRecentOrSuggestions.map((item, index) => (
                <li key={`suggestion_${index}`}>{item.text}</li>
              ))}
            </ul>
          ) : (
            <div className={styles.drawerNoItemsMessage}>No items available</div>
          )}
        </Drawer>
      </>
    );
  }
}

const mapStateToProps = state => {
  const {
    suggestions,
    recentQuestions,
    fetchingRecentQuestions,
    analyzingInput,
    selectedPassageInfos,
    shouldRemove
  } = state.inputAnalysis;
  return {
    recentQuestions,
    fetchingRecentQuestions,
    analyzingInput,
    graph: state.graph,
    suggestions: state.recentFeedback.wasNegative ? [] : suggestions, // disable autocomplete if in neg. feedback mode
    selectedPassageInfos,
    shouldRemoveSuggestions: shouldRemove,
    shouldShowDiscoveryButton:
      (state.discovery.topics && state.discovery.topics?.length > 0) ||
      (state.knowledgeGraphMeta.meta.hasWritePermission && state.user.isLlmParserEnabled),
    isFetching: state.network.isFetchingSuggestionList,
    recentFeedback: state.recentFeedback,
    annotation: state.annotatedQuestion.annotation,
    followUpState: state.followUpState,
    kgMeta: state.knowledgeGraphMeta.meta
  };
};

export default withTranslation('veezoo')(connect(mapStateToProps)(withEmbedded(withRouter(Input))));
