import { authFetchGet } from 'store/utils/authFetch';
import { CHANGE_ENTRY_POINT_SUCCESS } from 'store/modules/user';

export const KNOWLEDGE_GRAPH_META_REQUEST = 'KNOWLEDGE_GRAPH_META_REQUEST';
export const KNOWLEDGE_GRAPH_META_SUCCESS = 'KNOWLEDGE_GRAPH_META_SUCCESS';
export const KNOWLEDGE_GRAPH_META_FAILURE = 'KNOWLEDGE_GRAPH_META_FAILURE';

export const SET_ENTRYPOINT_AS_NOT_FETCHED = 'SET_ENTRYPOINT_AS_NOT_FETCHED';
export const SET_ENTRYPOINT_AS_FETCHED = 'SET_ENTRYPOINT_AS_FETCHED';

export const fetchKnowledgeGraphMeta = () => {
  return dispatch => {
    dispatch({
      type: KNOWLEDGE_GRAPH_META_REQUEST
    });

    // question the suggestions endpoint with an emtpy string to return initial suggestions.
    authFetchGet('/knowledge-graph/meta', dispatch)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          dispatch({
            type: KNOWLEDGE_GRAPH_META_FAILURE,
            payload: response.error,
            error: true
          });
          throw Error(response.statusText);
        }
      })
      .then(payload =>
        dispatch({
          type: KNOWLEDGE_GRAPH_META_SUCCESS,
          meta: payload
        })
      )
      .catch(function(error) {
        return dispatch({
          type: KNOWLEDGE_GRAPH_META_FAILURE,
          payload: error,
          error: true
        });
      });
  };
};

export const setEntrypointAsNotFetched = () => dispatch => dispatch({ type: SET_ENTRYPOINT_AS_NOT_FETCHED });

export const setEntrypointAsFetched = () => dispatch => dispatch({ type: SET_ENTRYPOINT_AS_FETCHED });

// reducer which describes knowledge graph meta information
export const defaultKnowledgeGraphMeta = {
  meta: {
    title: '',
    htmlSupport: 'mailto:support@veezoo.com',
    htmlDescription: '', // This will be set to a default in WelcomeMessage.js because it depends on the language
    hasKnowledgeGraphSupport: true,
    linkToDocumentation: 'https://docs.veezoo.com',
    hasWritePermission: false,
    defaultEntryPoint: undefined,
    hasFetchedEntryPoint: false,
    isDemo: true
  },
  isFetching: false,
  isFetched: false,
  errorMessage: null
};

export function knowledgeGraphMeta(state = defaultKnowledgeGraphMeta, action) {
  switch (action.type) {
    case KNOWLEDGE_GRAPH_META_REQUEST:
      return {
        ...state,
        isFetching: true,
        meta: { ...state.meta }
      };

    case KNOWLEDGE_GRAPH_META_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        meta: { ...state.meta, ...action.meta, htmlDescription: action.meta.htmlDescription || '' }
        // validation on htmlDescription is required. Otherwise if we change KG and it doesn't
        // come from the backend, it will take the previous KG value. By doing this we guarantee
        // that the value will be empty and it will get the default value on the translations.
      };

    case KNOWLEDGE_GRAPH_META_FAILURE:
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        errorMessage: action.payload // TODO: figure out why it's always undefined
      };

    case CHANGE_ENTRY_POINT_SUCCESS:
      return {
        ...state,
        meta: {
          ...state?.meta,
          defaultEntryPoint: { entrypoint: action.entryPointType, id: action.id }
        }
      };

    case SET_ENTRYPOINT_AS_NOT_FETCHED:
      return {
        ...state,
        meta: { ...state.meta, hasFetchedEntryPoint: false }
      };

    case SET_ENTRYPOINT_AS_FETCHED:
      return {
        ...state,
        meta: { ...state.meta, hasFetchedEntryPoint: true }
      };

    default:
      return state;
  }
}
