import { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { boundMethod } from 'autobind-decorator';
import {
  editCustomerSelection,
  getCustomerSelectionSharedInfo,
  stopSharingCustomerSelection
} from 'store/modules/customers-of-the-day';
import { showSharedCustomerSelectionModal } from 'store/modules/sharedModal';

import ShareButton from 'components/buttons/ShareButton';
import FollowUpButton from 'components/buttons/FollowUpButton';
import DownloadButton from 'components/buttons/DownloadButton';
import CustomersOfTheDayButton from 'components/buttons/CustomersOfTheDayButton';
import PrintButton from 'components/buttons/PrintButton';
import StopSharingButton from 'components/buttons/StopSharingButton';

import styles from './answer-message-footer.scss';

class CustomerSelectionAnswerMessageFooter extends Component {
  @boundMethod
  toggleCustomersOfTheDay(customerSelection) {
    const editRequestJson = {
      name: customerSelection.name,
      isInCustomersOfTheDay: !customerSelection.isInCustomersOfTheDay,
      isInSidebar: customerSelection.isInSidebar
    };

    this.props.dispatch(editCustomerSelection(customerSelection.id, editRequestJson));
  }

  render() {
    const {
      answerId,
      interpretationId,
      partialAnswerId,
      messageId,
      followUpState,
      dispatch,
      t,
      customerSelection,
      isEnabledCustomersOfTheDay,
      targetRef
    } = this.props;

    const followUpButton = (
      <FollowUpButton
        interpretationId={interpretationId}
        partialAnswerId={partialAnswerId}
        answerId={answerId}
        messageId={messageId}
        followUpState={followUpState}
        dispatch={dispatch}
      />
    );

    const shareButton = customerSelection.isOwned ? (
      <ShareButton
        id={customerSelection.id}
        dispatch={dispatch}
        isSharedWithOthers={customerSelection && customerSelection.isShared}
        tooltipText={t('tooltip.share-selection')}
        showModal={id => dispatch(showSharedCustomerSelectionModal(id))}
        getPermissions={getCustomerSelectionSharedInfo}
      />
    ) : null;

    const stopSharingButton =
      customerSelection.isOwned && customerSelection && customerSelection.isShared ? (
        <StopSharingButton
          id={customerSelection.id}
          stopSharingAction={id => dispatch(stopSharingCustomerSelection(id))}
          dispatch={dispatch}
          tooltipText={t('tooltip.stop-sharing-selection')}
        />
      ) : null;

    const exportButton = <DownloadButton partialAnswerOptions={[{ partialAnswerId }]} />;
    const printButton = <PrintButton targetRef={targetRef} />;

    return (
      <div className={styles.buttonsContainer}>
        <ul className={styles.list}>
          <li className={styles.item}>{followUpButton}</li>
          <li className={styles.item}>{exportButton}</li>
          <li className={styles.item}>{shareButton}</li>
          <li className={styles.item}>{stopSharingButton}</li>
          <li className={styles.item}>{printButton}</li>
          {isEnabledCustomersOfTheDay && (
            <li className={styles.item}>
              <CustomersOfTheDayButton
                text={t('customers-of-the-day.title')}
                tooltipText={customerSelection.isInCustomersOfTheDay}
                data-test="isInCustomersOfTheDay"
                data-customer-selection-tutorial="action"
                handleClick={() => this.toggleCustomersOfTheDay(customerSelection)}
              />
            </li>
          )}
        </ul>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    followUpState: state.followUpState,
    isAdmin: state.user.isAdmin,
    roles: state.roles,
    userOptions: state.userOptions,
    isEnabledCustomersOfTheDay: state.customersOfTheDay.isEnabled
  };
}

export default withTranslation('veezoo')(connect(mapStateToProps)(CustomerSelectionAnswerMessageFooter));
