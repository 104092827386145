import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';
import styles from './index.module.scss';

const Progress = ({ type, fullSize, size = 40 }) => {
  const useStyles = makeStyles(theme => ({
    root: {
      color: theme.isDark ? 'black' : 'white'
    }
  }));

  const classes = useStyles();

  const containerProps = fullSize === false ? {} : { className: styles.container };
  const progressProps = {
    ...(type === 'snackbar' ? { classes, size: 25 } : { size })
  };

  return (
    <div {...containerProps}>
      <CircularProgress {...progressProps} />
    </div>
  );
};

export default Progress;

Progress.propTypes = {
  type: PropTypes.string,
  fullSize: PropTypes.bool,
  size: PropTypes.number
};
