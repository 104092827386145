import { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import BoltRoundedIcon from '@mui/icons-material/BoltRounded';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import MuiButton from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';

import { fetchUserInformation } from 'store/modules/user';

import { trackEvent } from 'utils/eventTracking';
import useMediaQuery from 'utils/mediaQueries';

import LoaderIcon from 'svg/loader.svg';
import services from 'services';

import { sx, useStyles } from './SystemZeroSelectButton.styles';

const SystemZeroSelectButton = ({ user, dispatch }) => {
  const isMobile = useMediaQuery();
  const { classes: styles } = useStyles({ isMobile });
  const { t } = useTranslation();

  const [anchor, setAnchor] = useState(null);
  const [loading, setLoading] = useState(false);

  const items = [
    {
      title: 'Veezoo Classic',
      description: t('user-settings.fast-model-for-objective-questions'),
      icon: BoltRoundedIcon,
      key: 'classic',
      isActivated: false
    },
    {
      title: 'Veezoo Neo (beta)',
      description: t('user-settings.smart-model-for-vague-questions'),
      icon: AutoAwesomeRoundedIcon,
      key: 'neo',
      isActivated: true
    }
  ];

  const isActivated = user.isSystemZeroActivated;
  const selectedItem = items.find(item => item.isActivated === isActivated);

  const open = event => {
    setAnchor(event.currentTarget);
  };
  const close = () => setAnchor(null);

  const handleSystemZeroToggleChange = async value => {
    if (value === isActivated) return;

    close();
    setLoading(true);

    const update = { isSystemZeroActivated: value };
    const result = await services.updateUserSettings(update);
    if (result.success) {
      trackEvent('Veezoo Model Changed', update);
      dispatch(fetchUserInformation());
    }

    setLoading(false);
  };

  return (
    <div className={styles.container} data-test="system-zero-select">
      <MuiButton aria-controls="simple-menu" aria-haspopup="true" onClick={open} disabled={loading}>
        {loading ? <LoaderIcon className={styles.loading} /> : <selectedItem.icon />}
      </MuiButton>
      <Menu
        anchorEl={anchor}
        keepMounted
        open={!!anchor}
        onClose={close}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        {items.map((item, index) => (
          <MenuItem
            sx={sx.menuItem}
            key={`menu_option_${index}`}
            onClick={() => handleSystemZeroToggleChange(item.isActivated)}
          >
            <div className={styles.menuItemContainer} data-test={`model-key-${item.key}`}>
              <div className={styles.menuItemIconBox}>
                <div className={styles.menuItemIcon}>
                  <item.icon />
                </div>
              </div>
              <div className={styles.menuItemText}>
                <span className={styles.title}>{item.title}</span>
                <br />
                <span className={styles.description}>{item.description}</span>
              </div>
              <div className={styles.menuItemRadio}>
                <Radio sx={sx.radio} checked={isActivated === item.isActivated} disableRipple size="medium" />
              </div>
            </div>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

const mapStateToProps = state => ({ user: state.user });

export default connect(mapStateToProps)(SystemZeroSelectButton);
