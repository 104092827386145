import { connect } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';

import { Button, CustomModal } from 'components/v3';
import { layouts } from 'config/constants';

import { closeUpdateLinkedBoardModal, rejectBaseBoardUpdates, updateFollowUpBoard } from 'store/modules/board';

import ChainIcon from 'svg/chain2.svg';

import useStyles from './UpdateLinkedBoardModal.styles';

const UpdateLinkedBoardModal = ({ updateModal, boardsList, dispatch }) => {
  const { t } = useTranslation();
  const styles = useStyles();

  const boardId = updateModal?.boardId;

  const boardName = boardsList?.find(b => b.id === boardId)?.name || '';
  const baseBoardName = updateModal?.baseBoardChanges?.boardName || '';
  const renamedWidgets = updateModal?.baseBoardChanges?.renamedWidgets || [];
  const modifiedWidgets = updateModal?.baseBoardChanges?.modifiedWidgets || [];
  const addedWidgets = updateModal?.baseBoardChanges?.addedWidgets || [];
  const removedWidgets = updateModal?.baseBoardChanges?.removedWidgets || [];

  const modifiedWidgetsParagraph = (renamedWidgets.length > 0 || modifiedWidgets.length > 0) && (
    <>
      {t('update-linked-board-modal.modified-widgets')}:
      <ul className={styles.widgetChanges}>
        {renamedWidgets.map((widgetNames, index) => (
          <li key={index}>
            <span className={styles.oldWidgetText}>{"'" + widgetNames[0] + "'"}</span>{' '}
            {t('update-linked-board-modal.renamed-to')} <strong>{"'" + widgetNames[1] + "'"}</strong>
          </li>
        ))}
        {modifiedWidgets.map((widgetName, index) => (
          <li key={index}>
            <strong>{widgetName}</strong>
          </li>
        ))}
      </ul>
    </>
  );

  const addedWidgetsParagraph = addedWidgets.length > 0 && (
    <>
      {t('update-linked-board-modal.added-widgets')}:
      <ul className={styles.widgetChanges}>
        {addedWidgets.map((widgetName, index) => (
          <li key={index} className={styles.added}>
            <strong>{"+ '" + widgetName + "'"}</strong>
          </li>
        ))}
      </ul>
    </>
  );

  const removedWidgetsParagraph = removedWidgets.length > 0 && (
    <>
      {t('update-linked-board-modal.removed-widgets')}:
      <ul className={styles.widgetChanges}>
        {removedWidgets.map((widgetName, index) => (
          <li key={index} className={styles.deleted}>
            <strong>{"- '" + widgetName + "'"}</strong>
          </li>
        ))}
      </ul>
    </>
  );

  return (
    <CustomModal
      layout={layouts.veezoo}
      title={
        <span>
          <ChainIcon className={styles.titleIcon} />
          {t('update-linked-board-modal.title', { boardName })}
        </span>
      }
      content={
        <div className={styles.content}>
          <Trans
            i18nKey="update-linked-board-modal.introduction"
            values={{ boardName, baseBoardName }}
            components={[<strong key={0}>{boardName}</strong>]}
          />

          <div className={styles.modificationParagraph}>
            {modifiedWidgetsParagraph}
            {removedWidgetsParagraph}
            {addedWidgetsParagraph}
          </div>
        </div>
      }
      maxWidth="xs"
      DialogContentProps={{
        styles: {
          root: {
            fontSize: '14px'
          }
        }
      }}
      DialogActionsProps={{
        styles: {
          root: {
            justifyContent: 'space-around'
          }
        }
      }}
      open={!!updateModal}
      onClose={() => dispatch(closeUpdateLinkedBoardModal(boardId))}
      customButtons={
        <>
          <Button
            onClick={() => dispatch(updateFollowUpBoard(boardId))}
            layout={layouts.veezoo}
            data-test="updateLinkedBoardButton"
          >
            {t('update-linked-board-modal.update-button')}
          </Button>
          <Button
            onClick={() => dispatch(rejectBaseBoardUpdates(boardId))}
            layout={layouts.veezoo}
            data-test="unlinkBoardButton"
          >
            {t('update-linked-board-modal.unlink-button')}
          </Button>
        </>
      }
    />
  );
};

const mapStateToProps = state => ({
  updateModal: state.board.updateModal,
  boardsList: state.board.boardsList
});

export default connect(mapStateToProps)(UpdateLinkedBoardModal);
