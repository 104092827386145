import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Beforeunload } from 'react-beforeunload';
import { connect } from 'react-redux';

import MessageModal from 'components/Signup/MessageModal';

import CreateAccount from './steps/CreateAccount';
import ChooseDataSource from './steps/ChooseDataSource';
import CreateKnowledgeGraph from './steps/CreateKnowledgeGraph';
import ImportTables from './steps/ImportTables';

import { handleError } from 'services/http';
import { trackEvent } from 'utils/eventTracking';
import imageImport from 'images';

import TagManager from 'react-gtm-module';

import services from 'services';

import PageLayout from 'layouts/PageLayout';
import signupStyles from './signup.styles.scss';
import CreateAccountLight from 'pages/Signup/steps/CreateAccountLight';
import useMediaQuery from 'utils/mediaQueries';

import { useLoader } from 'components/v3';

const excelConnector = {
  type: 'EXCEL_CONNECTOR',
  id: 'excel',
  name: 'Excel'
};

const Signup = ({ isAuthorized, hasUnfinishedSignup, creatingAccount, dispatch }) => {
  const { invitationId } = useParams();
  const history = useHistory();
  const isMobile = useMediaQuery();
  const { loading, withLoader } = useLoader();

  const [step, setStep] = useState(0);
  const [signupEmail, setSignupEmail] = useState(null);
  const [knowledgeGraph, setKnowledgeGraph] = useState(null);
  const [connector, setConnector] = useState({
    id: '',
    name: '',
    defaultPort: 5432,
    usesSchemas: true
  });
  const [connectors, setConnectors] = useState(null);
  const [knowledgeGraphs, setKnowledgeGraphs] = useState(null);

  const [modal, setModal] = useState({
    open: false,
    message: 'Modal message',
    type: 'info'
  });

  const isExcelConnector = connector?.id === excelConnector.id;

  const inApplicationConnectorStep = step === 3 && !isExcelConnector && connector?.type !== 'DATABASE_CONNECTOR';

  useEffect(() => {
    // Used for Search Engine Indexing as it's a public page
    document.title = 'Veezoo - Signup';
    return () => (document.title = 'Veezoo');
  });

  const successMessage = useCallback(message => {
    setModal({
      open: true,
      type: 'success',
      message: <span>{message}</span>
    });
  }, []);

  const errorMessage = useCallback(message => {
    setModal({
      open: true,
      type: 'error',
      message: <span>{message}</span>
    });
  }, []);

  const fetchConnectors = useCallback(async () => {
    if (!connectors) {
      const result = await services.getConnectors();
      handleError(result, dispatch);
      if (result.success) {
        const connectors = result.data.data.map(({ data }) => data);
        setConnectors([...connectors, excelConnector]);
      }
    }
  }, [dispatch]);

  const fetchKnowledgeGraphs = useCallback(async () => {
    if (!knowledgeGraphs) {
      const result = await services.getKnowledgeGraphs();
      handleError(result, dispatch);

      if (result.success) {
        const formattedResult = result.data.data
          .map(({ data }) => data)
          .filter(({ isUserCreatedKg }) => isUserCreatedKg);
        setKnowledgeGraphs(formattedResult);
      }
    }
  }, [dispatch, knowledgeGraphs]);

  const selectConnector = useCallback(
    connector => {
      trackEvent('Data Source Selected', { connector: { id: connector.id } });
      setConnector(connector);
      setStep(step => step + 1);
    },
    [setConnector]
  );

  const formattedConnectors = useMemo(() => {
    if (connectors) {
      return connectors.map(data => ({
        id: data.id,
        name: data.name,
        image: imageImport(data.id),
        onClick: () => selectConnector(data)
      }));
    }
    return [];
  }, [connectors, selectConnector]);

  const formattedInvitation = useMemo(() => {
    if (invitationId) {
      setStep(1); // We know already the email of the user
      return decodeURIComponent(invitationId);
    }
    return null;
  }, [invitationId]);

  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-5LZWR9C' });
  }, []);

  useEffect(() => {
    if (isAuthorized) {
      fetchKnowledgeGraphs();
      fetchConnectors();
    }
  }, [isAuthorized, fetchKnowledgeGraphs, fetchConnectors]);

  // Skip first step (inputting email) if an email is already provided
  const skipFirstStep = window.veezoo?.signupVariables?.signup?.email;
  const fixedDbSystemId = window.veezoo?.signupVariables?.database?.dbSystemId;

  useEffect(() => {
    const knowledgeGraphHasConnector = async knowledgeGraph => {
      const result = await withLoader(services.getDatabaseConnection(knowledgeGraph.id));
      if (result.success) {
        const connector = connectors.find(data => data.id === result.data.databaseSystemId);
        setConnector(connector);
        setStep(() => (!isMobile ? 4 : 2));
        return;
      }
      setStep(2);
    };

    if (step === 0 && skipFirstStep) {
      setStep(1);
    }

    // Skip choosing data source if a DB system is already provided
    if (connectors && step === 2 && fixedDbSystemId) {
      setConnector(connectors.find(data => data.id === fixedDbSystemId));
      setStep(3);
    }

    if (isAuthorized && step <= 1 && connectors && knowledgeGraphs && hasUnfinishedSignup !== undefined) {
      if (!creatingAccount) {
        if (!hasUnfinishedSignup) return history.push('/chat');
        if (knowledgeGraphs.length === 0) return setStep(2);
        setKnowledgeGraph(knowledgeGraphs[0]);
        knowledgeGraphHasConnector(knowledgeGraphs[0]);
      } else if (hasUnfinishedSignup && knowledgeGraphs.length === 0) {
        setStep(2);
      }
    }
  }, [
    history,
    step,
    connectors,
    knowledgeGraphs,
    isAuthorized,
    hasUnfinishedSignup,
    creatingAccount,
    skipFirstStep,
    fixedDbSystemId
  ]);

  const isInitialSignupPage = step === 0 && (!isAuthorized || creatingAccount);

  return (
    <PageLayout enableSignupSidebar={isInitialSignupPage}>
      {/* Allow closing page also in second step (account creation) if first step was skipped (inputting email) */}
      {((step > 1 && !skipFirstStep) || step > 2) &&
      !inApplicationConnectorStep && ( // We need to be able to redirect to Fivetran for application connectors
          <Beforeunload
            onBeforeunload={() =>
              'You have not finished creating your Knowledge Graph, are you sure you want to leave?'
            }
          />
        )}
      <div className={signupStyles.children}>
        {isInitialSignupPage && <CreateAccountLight setStep={setStep} setSignupEmail={setSignupEmail} />}
        {step === 1 && (!isAuthorized || creatingAccount) && (
          <CreateAccount setStep={setStep} invitationId={formattedInvitation} signupEmail={signupEmail} />
        )}
        {step === 2 && <ChooseDataSource connectors={formattedConnectors} />}
        {step === 3 && (
          <CreateKnowledgeGraph
            connector={connector}
            connectors={connectors}
            knowledgeGraph={knowledgeGraph}
            loading={loading}
            setKnowledgeGraph={setKnowledgeGraph}
            setStep={setStep}
            successMessage={successMessage}
            errorMessage={errorMessage}
            withLoader={withLoader}
            showBackButton={!fixedDbSystemId}
          />
        )}
        {step === 4 && knowledgeGraph && knowledgeGraph.id && (
          <ImportTables
            connector={connector}
            knowledgeGraph={knowledgeGraph}
            // going back from this step is not allowed for Excel connector as file import DB is already set up
            allowBack={!isExcelConnector}
            setStep={setStep}
            setKnowledgeGraphs={setKnowledgeGraphs}
          />
        )}

        <MessageModal
          closeModal={() => setModal(modal => ({ ...modal, open: false }))}
          message={modal.message}
          onConfirm={() => setModal(modal => ({ ...modal, open: false }))}
          open={modal.open}
          type={modal.type}
        />
      </div>
    </PageLayout>
  );
};

const mapStateToProps = state => {
  return {
    isFetchingLanguages: state.languages.fetching,
    hasFetchedLanguages: state.languages.fetched,
    isAuthorized: state.network.isAuthorized,
    hasUnfinishedSignup: state.user.hasUnfinishedSignup,
    creatingAccount: state.network.signupSuccess || state.network.signupFailure || state.network.isSigningUp
  };
};

export default memo(connect(mapStateToProps)(Signup));
