import React, { forwardRef, useState, memo } from 'react';
import PropTypes from 'prop-types';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import UIInputAdornment from '@mui/material/InputAdornment';

import LoadingIcon from 'svg/v2/loading.svg';

import { getSx } from './SidebarFooterSelect.styles';

import styles from 'components/sidebar/sidebar.scss';

const SidebarFooterSelect = forwardRef(
  (
    { startIcon, items = [], showEmptyOption, minimized, loading = false, value, onChange, title = '', ...rest },
    ref
  ) => {
    const selectedIsDevelopmentKg = items?.some(kg => kg.value === value && kg.isDevelopmentKg);

    const sx = getSx({ selectedIsDevelopmentKg });

    const [open, setOpen] = useState(false);

    const setAdornment = (icon, position) => (
      <UIInputAdornment onClick={!rest?.disabled && (() => setOpen(true))} sx={sx.inputAdornment} position={position}>
        {icon}
      </UIInputAdornment>
    );

    return (
      <TextField
        select
        value={value}
        onChange={onChange}
        sx={sx.textField}
        label=""
        InputProps={{
          sx: sx.inputProps,
          startAdornment: startIcon ? setAdornment(startIcon, 'start') : null
        }}
        inputProps={{ title }}
        SelectProps={{
          sx: sx.select,
          open,
          onOpen: () => setOpen(true),
          onClose: () => setOpen(false),
          MenuProps: {
            sx: sx.menu,
            anchorOrigin: { vertical: 'center', horizontal: 'right' },
            transformOrigin: { vertical: 'center', horizontal: 'left' },
            // add the 'select-popover' class for e2e tests
            PopoverClasses: { root: 'select-popover' }
          }
        }}
        InputLabelProps={{
          shrink: true
        }}
        ref={ref}
        title={title}
        {...rest}
      >
        {showEmptyOption && <MenuItem value="">--</MenuItem>}
        {items.map((item, index) => (
          <MenuItem key={`item_${index}`} value={item.value}>
            {loading ? <LoadingIcon className={styles.loadingIcon} /> : <span className="text">{item.name}</span>}
          </MenuItem>
        ))}
      </TextField>
    );
  }
);

const iconProps = PropTypes.oneOfType([PropTypes.element, PropTypes.string]);

SidebarFooterSelect.propTypes = {
  loading: PropTypes.bool,
  startIcon: iconProps,
  items: PropTypes.array,
  showEmptyOption: PropTypes.bool,
  minimized: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  title: PropTypes.string
};

export default memo(SidebarFooterSelect);
