import { CHATMESSAGE_HIDE, CHATMESSAGE_REQUEST } from 'store/modules/chat-messages';

export const TOGGLE_FOLLOW_UP = 'TOGGLE_FOLLOW_UP';
export const UNTOGGLE_FOLLOW_UP = 'UNTOGGLE_FOLLOW_UP';

export const toggleFollowUp = (interpretationId, partialAnswerId, answerId, messageId) => {
  return {
    type: TOGGLE_FOLLOW_UP,
    interpretationId,
    partialAnswerId,
    answerId,
    messageId
  };
};

export const untoggleFollowUp = () => {
  return {
    type: UNTOGGLE_FOLLOW_UP
  };
};

export const followUpState = (state = null, action) => {
  switch (action.type) {
    case TOGGLE_FOLLOW_UP:
      return {
        messageId: action.messageId,
        interpretationId: action.interpretationId,
        partialAnswerId: action.partialAnswerId,
        answerId: action.answerId
      };

    // it is about clicking enter and remove followUp mode otherwise it would be always mode FOLLOW_UP
    case CHATMESSAGE_REQUEST:
      return null;

    // Cancel followUp mode if it was active on the now hidden message
    case CHATMESSAGE_HIDE:
      if (state && state.messageId === action.messageId) {
        return null;
      }
      return state;

    case UNTOGGLE_FOLLOW_UP:
      return null;

    default:
      return state;
  }
};
