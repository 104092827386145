import services from 'services';

/**
 * Load all themes and set on of activeTheme as default
 * Further action with themes and activeTheme will be dispatched
 */

// actions type
export const THEME_LOAD = 'THEME_LOAD';
export const THEME_FETCH_SUCCESS = 'THEME_FETCH_SUCCESS';
export const THEME_FAILURE = 'THEME_FAILURE';
export const THEME_SWITCH = 'THEME_SWITCH';

export const loadTheme = activeTheme => {
  return async dispatch => {
    dispatch({ type: THEME_LOAD });

    const result = await services.fetchThemes();

    if (!result.success) {
      const errorMessage = result?.response?.data || result?.statusText || 'There was an error fetching theme';
      dispatch({
        type: THEME_FAILURE,
        errorMessage
      });
      return;
    }

    dispatch({
      type: THEME_FETCH_SUCCESS,
      themes: result.data,
      activeTheme
    });
  };
};

export const switchTheme = newTheme => {
  return dispatch => {
    dispatch({
      type: THEME_SWITCH,
      activeTheme: newTheme
    });
  };
};

export const defaultAlternativeWarningThreshold = 0.1;

// The backend theme can overwrite it, so we need to read the colors from the vars in the CSS
// If the backend doesn't have it set, we use the one froms constants.css basically
export const getChartColors = theme => {
  const primaryColor = theme.cssVars['--primary-color'];
  return theme.cssVars['--chart-colors'] || [primaryColor];
};

// active theme details: css variables and assets
const initialThemeState = {
  // TODO: activeTheme: window.localStorage.getItem('veezooTheme') || 'default', // current active theme
  activeTheme: 'default', // current active theme
  chartColors: [],
  themeDetails: {
    cssVars: {},
    assets: {},
    alternativeWarningThreshold: defaultAlternativeWarningThreshold
  }, // active theme details: css variables and assets
  themes: {},
  error: null
};

// define a reducer which load a theme
export const theme = (state = initialThemeState, action) => {
  switch (action.type) {
    case THEME_LOAD:
      return {
        ...state
      };

    case THEME_FETCH_SUCCESS: {
      return {
        ...state,
        chartColors: getChartColors(action.themes[action.activeTheme]),
        themes: action.themes, // expected type is object
        activeTheme: action.activeTheme, // expected type is string
        themeDetails: action.themes[action.activeTheme] // expected type is object
      };
    }

    case THEME_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case THEME_SWITCH:
      // TODO: use localStorage
      // const { activeThemeName } = action;
      // window.localStorage.setItem('veezooTheme', action.activeTheme);

      return {
        ...state,
        chartColors: getChartColors(state.themes[action.activeTheme]),
        activeTheme: action.activeTheme,
        themeDetails: state.themes[action.activeTheme]
      };

    default:
      return state;
  }
};
