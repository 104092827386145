import { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { runTest, fetchTests } from 'store/modules/questionTests';
import Button from '../buttons/Button';

import styles from './question-tests.scss';
import { withRouter } from 'react-router-dom';

import { layouts } from 'config/constants';

import { Checkbox, NumberField } from 'components/v3';

class QuestionTests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      run: false,
      parallel: 1,
      hideSuccessful: true
    };

    this.runAll = this.runAll.bind(this);
  }

  componentDidMount() {
    const limit = new URLSearchParams(location.search).get('limit');
    this.props.dispatch(fetchTests(limit));
  }

  componentDidUpdate(prevProps, prevState) {
    const runMoreTests = this.props.tests.filter(t => t.isRunning).length < Math.min(this.state.parallel, 16);
    const nextTest = this.props.tests.find(t => !t.isRunning && !t.understood);
    if (this.state.run && runMoreTests && nextTest) {
      this.props.dispatch(runTest(nextTest.id, nextTest.questions, nextTest.language));
    }
  }

  runAll() {
    this.setState({ run: true });
  }

  render() {
    const testList = this.props.tests.map((t, index) => {
      let status;
      let errorMessage;
      let successful = false;
      if (t.isRunning) {
        status = <span data-test="testRunning">Running</span>;
      } else if (!t.understood) {
        status = <span data-test="testNotRun">Not Run</span>;
      } else if (t.q2q === t.understood) {
        status = (
          <span data-test="testPassed" className={styles.passed}>
            Passed
          </span>
        );
        successful = true;
      } else {
        status = (
          <span data-test="testFailed" className={styles.failed}>
            Failed
          </span>
        );
        errorMessage = (
          <div className={styles.errorMessage}>
            <br />
            <div className={styles.errorMessageTitle}>Expectation vs. Reality:</div>
            <div>{t.q2q}</div>
            <div>{t.understood}</div>
          </div>
        );
      }

      const questions = t.questions.map((q, index) => <div key={index}>{q}</div>);

      return (
        !(this.state.hideSuccessful && successful) && (
          <li key={t.id} className={styles.item}>
            <h2 className={styles.title}>
              Test {index + 1} - {status}
            </h2>
            <div className={styles.question}>{questions}</div>
            {errorMessage}
          </li>
        )
      );
    });

    let statusMessage;
    if (this.props.isLoading) {
      statusMessage = 'Loading tests...';
    } else if (this.props.tests.length === 0) {
      statusMessage = 'No tests found';
    } else {
      statusMessage = 'Found ' + this.props.tests.length + ' Tests';
    }

    const runButton = <Button text="RUN" data-test="runButton" onClick={this.runAll} className="button-primary" />;
    const parallelInput = (
      <span className={styles.parallelInput}>
        <NumberField
          label="Parallel tests"
          layout={layouts.signup}
          min={0}
          max={16}
          value={this.state.parallel}
          onChange={event => this.setState({ parallel: event.target.value })}
        />
      </span>
    );
    const hideBox = (
      <Checkbox
        layout={layouts.veezoo}
        classes={{ container: { display: 'inline-flex' } }}
        content="Hide successful tests"
        checked={this.state.hideSuccessful}
        onChange={newValue => this.setState({ hideSuccessful: newValue })}
        data-test="hideSuccessful"
        size="small"
      />
    );

    return (
      <div className={styles.container}>
        <h1>Veezoo Test Suite</h1>
        <p className={styles.status}>{statusMessage}</p>
        {!this.props.isLoading && (
          <>
            {runButton}
            {parallelInput}
            {hideBox}
          </>
        )}
        <ul className={styles.list}>{testList}</ul>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    tests: state.tests.tests,
    isLoading: state.tests.isLoading
  };
};

export default withTranslation('veezoo')(withRouter(connect(mapStateToProps)(QuestionTests)));
