import { cloneElement, useMemo, memo } from 'react';
import PropTypes from 'prop-types';

import UIButton from '@mui/material/Button';

import { layouts } from 'config/constants';
import { useStyles, useTssStyles } from './Button.styles';

const Button = ({
  color,
  children = 'button',
  classes: outerClasses = {}, // The classes prop is used to override the default styles of the button.
  layout = layouts.default,
  onClick = () => {},
  size = 'default',
  mode = 'default',
  variant = 'text',
  disabled = false,
  fullWidth = false,
  iconOnly = false,
  reducedWidth = false,
  iconSize = '15px',
  noBorders = false,
  icon: Icon,
  ...rest
}) => {
  const buttonClasses = useStyles({
    fullWidth,
    size,
    mode,
    iconOnly,
    reducedWidth,
    iconSize,
    noBorders,
    outerClasses
  });

  const { classes: tssClasses } = useTssStyles({ iconSize, mode, layout, outerClasses });

  const hasIcon = Icon ? cloneElement(Icon, { className: tssClasses.icon }) : null;

  const customProps = useMemo(() => {
    if (layout === layouts.signup) return {};
    if (layout === layouts.veezoo) return {};
    return { variant, color };
  }, [layout, variant, color]);

  return (
    <UIButton sx={buttonClasses[layout]} onClick={onClick} disabled={disabled} {...customProps} {...rest}>
      {hasIcon}
      {!iconOnly && <span className={hasIcon && tssClasses.labelSpan}>{children}</span>}
    </UIButton>
  );
};

Button.propTypes = {
  children: PropTypes.string,
  classes: PropTypes.object,
  fullWidth: PropTypes.bool,
  layout: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.string,
  mode: PropTypes.string,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  noBorders: PropTypes.bool,
  icon: PropTypes.any,
  iconOnly: PropTypes.bool,
  reducedWidth: PropTypes.bool,
  color: PropTypes.string,
  iconSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default memo(Button);
