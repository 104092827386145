import { get, post } from 'services/http';

const fetchBoardQuestion = boardId => {
  const suffix = boardId ? '/' + boardId : '';
  return post(`/question/board${suffix}?sessionId=${window.veezoo.sessionId}`, {});
};

const postQuestion = body => {
  const fullPayload = {
    ...body,
    sessionId: window.veezoo.sessionId
  };
  return post('/question', fullPayload);
};

// Fetch (parse) an existing question by its id
const fetchQuestionById = questionId => {
  return post(`/question/rephrased/${questionId}?sessionId=${window.veezoo.sessionId}`, {});
};

const fetchComplementaryQuestion = (answerId, interpretationId) =>
  get(`/answers/partials/${answerId}/${interpretationId}?sessionId=${window.veezoo.sessionId}`);

const fetchAnswerOfInterpretation = (answerId, interpretationId) =>
  get(`/answers/alternatives/${answerId}/${interpretationId}?sessionId=${window.veezoo.sessionId}`);

export default {
  fetchBoardQuestion,
  postQuestion,
  fetchQuestionById,
  fetchComplementaryQuestion,
  fetchAnswerOfInterpretation
};
