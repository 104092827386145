import { get, patch, post } from 'services/http';

const testAnswers = kgId => post(`/studio/api/knowledge-graphs/${encodeURIComponent(kgId)}/interpretations/test`);

const getInterpretation = (kgId, id) =>
  get(`/studio/api/knowledge-graphs/${encodeURIComponent(kgId)}/interpretations/${encodeURIComponent(id)}`);

const runInterpretation = (kgId, id, vql) => {
  const body = vql ? { vql } : null;
  return post(
    `/studio/api/knowledge-graphs/${encodeURIComponent(kgId)}/interpretations/${encodeURIComponent(id)}/run`,
    body
  );
};

const updateInterpretation = (kgId, id, vql) => {
  const body = vql ? { vql } : null;
  return patch(
    `/studio/api/knowledge-graphs/${encodeURIComponent(kgId)}/interpretations/${encodeURIComponent(id)}`,
    body
  );
};

export default {
  testAnswers,
  getInterpretation,
  runInterpretation,
  updateInterpretation
};
