import React, { useCallback, useRef, useEffect, useState } from 'react';
import Tree from 'rc-tree';

import { useTranslation } from 'react-i18next';

import { boardFileTypes } from 'store/utils/boardFileTypes';

import './styles.scss';

const mapTree = (items, key, callback, parentId = null) => {
  items.forEach((item, index, arr) => {
    item.parentId = parentId;
    if (item.key === key) {
      callback(item, index, arr);
      return;
    }
    if (item.children) {
      mapTree(item.children, key, callback, item.id);
    }
  });
};

const BoardFileTree = ({ boardsList, renderListItem, onMoveItem, expandedKeys, setExpandedKeys, loading }) => {
  const [tree, setTree] = useState([]);

  const { t } = useTranslation();

  const treeParentRef = useRef(null);
  const treeRef = useRef(null);

  useEffect(() => {
    setTree(boardsList);
  }, [boardsList]);

  // Ugly workaround to set the "title" attribute to the "input", inside <Tree /> component, from "rc-tree".
  // This input is not accessible through the component API (which has VERY poor documentation), so we need to do it manually.
  // Added to "First Class Code" that as soon as we have Mui v5, we should replace this component with a better one.
  useEffect(() => {
    if (treeParentRef.current) {
      if (treeRef.current) {
        const tree = treeParentRef.current.querySelector('div[role="tree"]');

        if (tree) {
          const myInput = tree.querySelector('input[tabindex="0"]');
          if (myInput) {
            myInput.setAttribute('title', t('board.board-list'));
          }
        }
      }
    }
  }, [treeParentRef.current, treeRef.current, t]);

  // Important: used directly E2E tests, changes here likely also necessitate changes there => Sidebar.dragItemOnto
  const onDrop = useCallback(
    info => {
      const dropKey = info.node.key;
      const dragKey = info.dragNode.key;
      const dropPos = info.node.pos.split('-');
      const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

      const items = [...tree];

      let parentId = null;
      let aboveId = null;

      let dragObject;
      mapTree(items, dragKey, (item, index, arr) => {
        arr.splice(index, 1);
        dragObject = item;
      });

      // Drop as the only or first item inside a folder
      if (dropPosition === 0) {
        mapTree(items, dropKey, item => {
          // eslint-disable-next-line no-param-reassign
          item.children = item.children || [];
          item.children.unshift(dragObject);
          parentId = item.id;
        });
      } else {
        // Drop on the gap (insert before or insert after)
        let ar, i, id;
        mapTree(items, dropKey, (item, index, arr) => {
          [ar, i, id] = [arr, index, item.id];
          parentId = item.parentId;
        });
        if (dropPosition === -1) {
          ar.splice(i, 0, dragObject);
        } else {
          aboveId = id;
          ar.splice(i + 1, 0, dragObject);
        }
      }

      onMoveItem(dragObject.id, parentId, aboveId);
      setTree(items);
    },
    [onMoveItem, tree]
  );

  // Used in E2E tests, available globally!
  window.testBoardFileTreeOnDrop = onDrop;

  return (
    <div className="board-tree" ref={treeParentRef}>
      {tree.length && (
        <Tree
          ref={treeRef}
          draggable={!loading}
          onExpand={eKeys => setExpandedKeys(eKeys)}
          expandedKeys={expandedKeys}
          selectable={false}
          onDrop={onDrop}
          treeData={tree}
          titleRender={nodeData => renderListItem(nodeData)}
          allowDrop={({ dropNode, dropPosition }) => {
            if (dropNode.type.toLowerCase() !== boardFileTypes.folder.toLowerCase() && dropPosition === 0) return false;
            return true;
          }}
        />
      )}
    </div>
  );
};

export default BoardFileTree;
