import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import BaseMessage from './BaseMessage';

import styles from './info-message.scss';
import footerStyles from './answer-message-footer.scss';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import KnowledgeGraphButton from 'components/buttons/KnowledgeGraphButton';
import Icon from '../icons/Icon';
import ChatMessageMarkdownHtml from './ChatMessageMarkdownHtml/ChatMessageMarkdownHtml';

import { fetchEventSource, EventStreamContentType } from '@microsoft/fetch-event-source';

import SpinningInfinityLoader from '../loaders/infinity-loading/SpinningInfinityLoader';

// Important: this is also used to retrieve the cursor element in the removeCursor function, do not use it elsewhere
const cursorStylesClass = styles.blinkingCursor;

const infinity = (
  <div className={styles.spinningVeezooIconContainer}>
    <SpinningInfinityLoader />
  </div>
);

const customFooter = (
  <aside className={footerStyles.buttonsContainer}>
    <ul className={footerStyles.list}>
      <li className={footerStyles.item}>
        <KnowledgeGraphButton />
      </li>
    </ul>
  </aside>
);

const InfoMessage = ({
  message,
  hasKnowledgeGraphSupport,
  isScrolling,
  chatContainer,
  shouldScrollAutomatically,
  isWarningMessage,
  t
}) => {
  const usesStreaming = !!message.streamedPartialAnswerId;

  const [isCurrentlyStreaming, setIsCurrentlyStreaming] = useState(false);
  const [dynamicContent, setDynamicContent] = useState('');

  // separate error flag for the case where the stream is aborted with an error (potentially after it has already
  //  delivered some content)
  const [isStreamError, setIsStreamError] = useState(false);

  const containerRef = useRef(null);

  // Add the cursor to the end of the last text node in the container
  // We do this instead of just appending the cursor to the container directly because the HTML may contain e.g. a paragraph (<p>...</p>) at the end,
  // which would lead to the cursor being put on a new line instead of where we expect new text to appear
  const addCursor = () => {
    const container = containerRef.current;

    // Find the last non-empty text node in the container
    const walker = document.createTreeWalker(container, NodeFilter.SHOW_TEXT, null, false);
    let lastNode;

    while (walker.nextNode()) {
      // Skip empty text nodes
      if (/\S/.test(walker.currentNode.data)) {
        lastNode = walker.currentNode;
      }
    }

    // Append the cursor at the end of the last text node (by appending it to the parent node)
    if (lastNode) {
      // Create the cursor element (as a plain HTML element, we are operating on the DOM directly)
      const cursorSpan = document.createElement('span');
      cursorSpan.className = cursorStylesClass;
      cursorSpan.setAttribute('data-test', 'streamedTextCursor');
      lastNode.parentNode.appendChild(cursorSpan);
    }
  };

  // Remove the cursor from the container
  const removeCursor = () => {
    const container = containerRef.current;
    const existingCursor = container.getElementsByClassName(cursorStylesClass);

    if (existingCursor?.length > 0) {
      existingCursor[0].remove();
    }
  };

  useEffect(() => {
    if (usesStreaming) {
      setIsCurrentlyStreaming(true);
      // If there is an error during streaming or already when getting the stream, t('error') will be displayed at the end of the message
      fetchEventSource(`/answers/partials/streamed-text/${message.streamedPartialAnswerId}`, {
        headers: {
          // X-Requested-With needs to be set to something, otherwise the Play server in the backend rejects the request
          'X-Requested-With': 'XMLHttpRequest'
        },

        // do not stop the SSE stream if the browser is hidden (see https://github.com/Azure/fetch-event-source/issues/36)
        openWhenHidden: true,

        // check the response code when the stream is opened
        async onopen(response) {
          if (!response.ok || response.headers.get('content-type') !== EventStreamContentType) {
            setIsCurrentlyStreaming(false);

            // there was an error when fetching the stream; throw an exception to stop processing
            const errorMessage = `Failed to get answer stream for ${message.streamedPartialAnswerId}`;
            throw new Error(errorMessage);
          }
        },

        // process incoming events
        onmessage(event) {
          // ignore any non-message events, such as periodic pings
          if (event.event === 'message') {
            setDynamicContent(event.data);
            addCursor();
          }
        },

        // need to define an onclose event to make sure the stream is closed once the server closes the HTTP connection
        onclose() {
          setIsCurrentlyStreaming(false);
          removeCursor();
        },

        // throw error in the onerror event to make sure the stream is closed (and not re-tried) upon errors
        onerror(err) {
          console.error(`Error while processing stream: ${err}`);
          setIsStreamError(true);
          setIsCurrentlyStreaming(false);
          removeCursor();
          throw err;
        }
      });
    }
  }, []); // empty list argument ensures this is only run when rendered for the first time

  // Logic that scrolls the chat container to the bottom while streaming
  useEffect(() => {
    // Note: shouldScrollAutomatically is not true when only scrolling for a minimal amount, meaning in this case the scrolling will snap back to the bottom
    if (chatContainer && !isScrolling && !shouldScrollAutomatically) {
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  }, [dynamicContent, chatContainer, isScrolling, shouldScrollAutomatically]);

  // Remove the "smooth" effect while streaming to avoid the chat container jumping around. Re-enable it when streaming is done;
  useEffect(() => {
    if (chatContainer) {
      if (isCurrentlyStreaming) {
        chatContainer.style.scrollBehavior = 'unset';
      } else {
        chatContainer.style.scrollBehavior = 'smooth';
      }
    }
  }, [isCurrentlyStreaming, chatContainer]);

  const showKnowledgeGraphFooter = hasKnowledgeGraphSupport && message.suggestKnowledgeGraph;
  const messageClassName = showKnowledgeGraphFooter ? `${styles.wrapper} ${styles.withFooter}` : styles.wrapper;

  const contentContainer = (
    <div>
      <div ref={containerRef}>
        <ChatMessageMarkdownHtml html={usesStreaming ? dynamicContent : message.textAnswer} />
      </div>
      {isCurrentlyStreaming && dynamicContent.length < 1 && (
        // Show a loading indicator when streaming but no content has been received yet
        // It consists of the spinning Veezoo icon and a "Thinking..." message, with the dots being animated
        <div style={{ display: 'flex', alignItems: 'center', color: '#666666' }}>
          {infinity}
          <span>
            {t('thinking')}&nbsp;<span className={styles.loaderDot}>.</span>
            <span className={styles.loaderDot}>.</span>
            <span className={styles.loaderDot}>.</span>
          </span>
        </div>
      )}

      {// add an error message at the end of the streamed content if there was an error during stream processing
      isStreamError && <div className={styles.streamErrorMessageContainer}>{t('error')}</div>}
    </div>
  );

  const answer = (
    <div className={messageClassName}>
      {isWarningMessage ? (
        <Icon data-test="icon-warning" type="icon-warning" />
      ) : (
        (message.isError || isStreamError) && (
          <ErrorRoundedIcon style={{ fontSize: '2em', color: 'var(--error-red)' }} />
        )
      )}
      {isWarningMessage || message.isError || isStreamError ? (
        <span className={styles.desc}>{contentContainer}</span>
      ) : (
        contentContainer
      )}
    </div>
  );

  return (
    <div data-test="veezooInfoMessage" key={message.id}>
      <BaseMessage
        key={message.id}
        messageId={message.id}
        showVeezooIcon={message.showVeezooIcon}
        content={answer}
        customFooter={showKnowledgeGraphFooter ? customFooter : <></>}
        timestamp={message.timestamp}
      />
    </div>
  );
};

export default InfoMessage;

InfoMessage.propTypes = {
  message: PropTypes.object,
  hasKnowledgeGraphSupport: PropTypes.bool,
  isWarningMessage: PropTypes.bool,
  scrollToBottom: PropTypes.func,
  chatContainer: PropTypes.object,
  shouldScrollAutomatically: PropTypes.bool,
  isScrolling: PropTypes.bool
};
