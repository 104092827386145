import React, { useRef, useMemo } from 'react';
import { FeatureGroup, Map, Marker, Tooltip, TileLayer, Popup } from 'react-leaflet';
import { withTranslation } from 'react-i18next';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import styles from './maps-chart.scss';
import './chart.scss';

// Added explicit icon because otherwise the transformations in webpack
// Would lead to errors...
// Start
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import iconMarker from 'leaflet/dist/images/marker-icon.png';
import { formatNumber, parseNumber } from 'utils/numberFormat';
import { connect } from 'react-redux';
import { tableColumnTypes } from 'config/constants';

/**
 * Generates a unique HSL color based on the given category string.
 * This is used to color the pin & have a deterministic color for each category, independent of the order or amount of the categories.
 *
 * @param category
 * @returns {{saturation: number, lightness: number, hue: number}}
 */
function getHSLComponentsForCategory(category) {
  let hash = 0;
  const primeMultiplier = 53;

  for (let i = 0; i < category.length; i++) {
    hash = (hash ^ category.charCodeAt(i)) * primeMultiplier;
    hash = (hash << 7) | (hash >>> 25);
    hash &= 0xffffffff;
  }

  const hue = Math.abs(hash % 360); // Range 0-360
  const saturation = 100 + (hash % 50); // Range 100-150% (for more vivid colors)
  const lightness = 75 + (hash % 25); // Range 75-100% to avoid too dark images

  return { hue, saturation, lightness };
}

// Create a custom marker icon with a label
// number, number title, number value and color category can be null
function createNumberedIcon(title, numberTitle, number, colorCategory) {
  let filterStyle = '';
  if (colorCategory) {
    const { hue, saturation, lightness } = getHSLComponentsForCategory(colorCategory);
    filterStyle = `filter: hue-rotate(${hue}deg) saturate(${saturation}%) brightness(${lightness}%);`;
  }
  // Construct label content with optional number and dynamic hue rotation
  const labelHtml = `
    <div class="custom-marker-icon">
      <img src="${iconMarker}" style="${filterStyle}"/>
      <div class="custom-marker-label">
        <div><b>${title}</b></div>
        <div><b>${number ? `<div class="custom-marker-number">${numberTitle}</div>${number}` : ''}</b></div>
      </div>
    </div>
  `;
  return L.divIcon({
    html: labelHtml,
    className: 'custom-marker',
    iconSize: [25, 41],
    iconAnchor: [12, 41]
  });
}

require('react-leaflet-markercluster/dist/styles.min.css');

const defaultCoordinates = {
  lat: 46.8182,
  lng: +8.2275,
  zoom: 8
};

const VeezooMapChart = props => {
  const mapRef = useRef();
  const groupRef = useRef();
  const numberFormat = props.numberFormat;
  const positions = transformToPositions(props.answer.data, numberFormat);

  const positionMarkers = positions.map((position, index) => {
    const icon = createNumberedIcon(position.title, position.numberTitle, position.numberValue, position.colorCategory);
    return (
      <Marker key={`${position.title}_${index}`} position={[position.latitude, position.longitude]} icon={icon}>
        {position.tooltip && <Tooltip>{position.tooltip}</Tooltip>}
        <Popup>{position.popup}</Popup>
      </Marker>
    );
  });

  const bounds = positions.map(position => [position.latitude, position.longitude]);

  const centerPosition = useMemo(() => {
    if (!bounds || bounds.length < 1) {
      return [defaultCoordinates.lat, defaultCoordinates.lng];
    }

    let lowestLat = null;
    let lowestLng = null;
    let highestLat = null;
    let highestLng = null;

    bounds.forEach(bound => {
      if (!lowestLat || bound[0] < lowestLat) lowestLat = bound[0];
      if (!highestLat || bound[0] > highestLat) highestLat = bound[0];
      if (!lowestLng || bound[1] < lowestLng) lowestLng = bound[1];
      if (!highestLng || bound[1] > highestLng) highestLng = bound[1];
    });

    const centerLat = (highestLat - lowestLat) / 2 + lowestLat;
    const centerLng = (highestLng - lowestLng) / 2 + lowestLng;

    return [centerLat, centerLng];
  }, [bounds]);

  return (
    <>
      <Map
        center={centerPosition}
        zoom={defaultCoordinates.zoom}
        scrollWheelZoom={false}
        maxZoom={19}
        boundsOptions={{ padding: [10, 10] }}
        className={styles.map}
        ref={mapRef}
        // bounds should only be set if there are at least two bounds. Otherwise it shouldn't be set.
        // If set with less than two bounds, empty array or null/undefined, it will throw an error.
        {...(bounds?.length > 0 ? { bounds } : {})}
      >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <FeatureGroup ref={groupRef}>
          <MarkerClusterGroup>{positionMarkers}</MarkerClusterGroup>
        </FeatureGroup>
      </Map>
    </>
  );
};

// we want to have this constant, otherwise shallow comparison breaks when new empty ones get created
const EmptyArray = [];

// Create Selector for Performance Reasons
// See: https://redux.js.org/recipes/computing-derived-data
function transformToPositions(positionsJson, numberFormat) {
  if (!positionsJson.columns) {
    // abort mission
    return EmptyArray;
  }

  let latCol = null;
  let longCol = null;
  let numberCol = null;

  positionsJson.columns.forEach(column => {
    if (column.type === tableColumnTypes.LATITUDE) latCol = column;
    if (column.type === tableColumnTypes.LONGITUDE) longCol = column;
    // i.e. we take the last number column as the number column we wanna plot prominently
    if (column.type === tableColumnTypes.NUMBER) numberCol = column;
  });

  const markerCol =
    longCol && positionsJson.columns.find(column => column.identifier === longCol.targetColumnIdentifier);

  if (latCol && longCol && markerCol) {
    // find the first categorical column (that is not the marker column), where the distinct values are fewer than 10.
    // if there is none, leave it undefined. Distinct values should be computed here and not in the backend.
    const colorCategoryCol = positionsJson.columns.find(column => {
      // Ensure the column is categorical and isn't the marker column
      if (column.type === tableColumnTypes.CATEGORICAL && column.identifier !== markerCol?.identifier) {
        // Calculate distinct values for this column
        const distinctValues = new Set(positionsJson.data.map(row => row[column.identifier])).size;
        // Return true if the column has between 1 and 10 distinct values
        return distinctValues >= 1 && distinctValues <= 10;
      }
      return false;
    });

    const usedCols = [latCol, longCol, markerCol];
    const otherColumns = positionsJson.columns.filter(column => !usedCols.includes(column));

    return positionsJson.data
      .map((row, rowIndex) => {
        const markerName =
          typeof row[markerCol.identifier] === 'string' ? row[markerCol.identifier] : row[markerCol.identifier].value;

        const otherInfo = otherColumns.map((column, column_index) => {
          const itemIdentifier = row[column.identifier]?.value || row[column.identifier];
          return itemIdentifier ? (
            <div key={`${column.identifier}_${column_index}`}>
              <span className={styles.popUpAttributeTitle}>
                {column.title.replace(' [' + markerCol.title + ']', '')}:
              </span>
              <span>
                {' '}
                {column.type === tableColumnTypes.NUMBER
                  ? formatNumber(itemIdentifier, numberFormat)
                  : itemIdentifier.toString()}
              </span>
            </div>
          ) : null;
        });
        const popup = (
          <span key={`positionJson_${rowIndex}`}>
            <h3>
              {typeof row[markerCol.identifier] === 'string' ? (
                markerName
              ) : (
                <a href={row[markerCol.identifier].link}>{markerName}</a>
              )}
            </h3>
            {otherInfo}
          </span>
        );

        return {
          title: markerName,
          longitude: parseNumber(row[longCol.identifier]),
          latitude: parseNumber(row[latCol.identifier]),
          popup,
          tooltip: colorCategoryCol ? row[colorCategoryCol.identifier] : null,
          colorCategory: colorCategoryCol ? row[colorCategoryCol.identifier] : null,
          numberTitle: numberCol ? numberCol.title : null,
          numberValue: numberCol ? formatNumber(row[numberCol.identifier], numberFormat) : null
        };
        // we only want to show markers when there actually is a long and a lat
      })
      .filter(data => !isNaN(data.latitude) && !isNaN(data.longitude));
  }
  return [];
}

const mapStateToProps = state => ({ numberFormat: state.user.numberFormat });

export default withTranslation('veezoo')(connect(mapStateToProps)(VeezooMapChart));
