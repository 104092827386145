import { handleUnauthorized } from 'store/utils/authFetch';

import { fetchGet, handleError } from 'services/http';

import services from 'services';

import { GRAPH_ITEM_CLASS_FAILURE } from './graphItemClass';
import { fetchUserInformation } from '../user';

export const LITERAL_REQUEST = 'LITERAL_REQUEST';
export const LITERAL_SUCCESS = 'LITERAL_SUCCESS';
export const LITERAL_FAILURE = 'LITERAL_FAILURE';
export const EMPTY_LITERAL = 'EMPTY_LITERAL';

export const CHANGE_LITERAL_REQUEST = 'CHANGE_LITERAL_REQUEST';
export const CHANGE_LITERAL_SUCCESS = 'CHANGE_LITERAL_SUCCESS';
export const CHANGE_LITERAL_FAILURE = 'CHANGE_LITERAL_FAILURE';

export const fetchLiteralInfo = uri => {
  return dispatch => {
    dispatch({ type: GRAPH_ITEM_CLASS_FAILURE });

    dispatch({ type: LITERAL_REQUEST });

    fetchGet('/knowledge-graph/literals/' + encodeURIComponent(uri))
      .then(response => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 401) {
          handleUnauthorized(dispatch);
        } else {
          dispatch({ type: LITERAL_FAILURE });
          throw Error(response.statusText);
        }
      })
      .then(result => {
        dispatch({ type: LITERAL_SUCCESS, literal: result });
      })
      .catch(error => console.log('ERROR', error));
  };
};

export const removeLiteralInfo = () => {
  return {
    type: EMPTY_LITERAL
  };
};

export const updateLiteral = (uri, property, value) => {
  return async dispatch => {
    dispatch({ type: CHANGE_LITERAL_REQUEST, property });

    let body = { [property]: value };
    const result = await services.updateLiteral(uri, body);
    handleError(result, dispatch);
    if (!result.success) {
      dispatch({ type: CHANGE_LITERAL_FAILURE, property });
    } else {
      dispatch({ type: CHANGE_LITERAL_SUCCESS, literal: result.data, property });
      // refresh the user information as updating a literal possibly adds a milestone
      dispatch(fetchUserInformation());
    }
  };
};

// reducer which describes particular literal displayed on graph wizard
export const literalInfo = (state = {}, action) => {
  switch (action.type) {
    case LITERAL_SUCCESS:
      return action.literal;

    case EMPTY_LITERAL:
      return {};

    case CHANGE_LITERAL_SUCCESS:
      return action.literal;

    default:
      return state;
  }
};
