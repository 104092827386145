import { forwardRef } from 'react';
import { Link, useLocation } from 'react-router-dom';

// update search params is an object that contains the search params to be updated
// if the value of a param is not present, the param will be removed
// e.g. { id: 1, kgSidebar: true }
export const constructUrlGivenCurrentQueryParams = (to, currentSearch, updateSearchParams) => {
  const searchParams = new URLSearchParams(currentSearch);
  let search = '';
  if (updateSearchParams) {
    Object.entries(updateSearchParams).forEach(([key, value]) => {
      if (value === null) {
        searchParams.delete(key);
      } else {
        searchParams.set(key, value);
      }
    });
  }

  search = searchParams.entries().next().done ? '' : `?${searchParams.toString()}`;
  return to + search;
};

export const changeUrlGivenCurrentQueryParams = (history, updateSearchParams) => {
  const newUrl = constructUrlGivenCurrentQueryParams(
    history.location.pathname,
    history.location.search,
    updateSearchParams
  );
  history.replace(newUrl);
};

export const LinkWithQuery = forwardRef(({ children, to, updateSearchParams, ...props }, ref) => {
  const { search } = useLocation();
  return (
    <Link to={constructUrlGivenCurrentQueryParams(to, search, updateSearchParams)} {...props} ref={ref}>
      {children}
    </Link>
  );
});
