import React, { forwardRef } from 'react';
import MuiTextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import makeStyles from '@mui/styles/makeStyles';

const TextField = forwardRef(
  (
    {
      InputProps,
      sx = {},
      name,
      startIcon,
      endIcon,
      value,
      label,
      description,
      onChange,
      disabledWhenFilled,
      disabled,
      'data-test': dataTest,
      ...props
    },
    ref
  ) => {
    const useInputStyles = makeStyles(theme => ({
      root: {
        ...(theme.isDark ? {} : { backgroundColor: 'white' })
      },
      disabled: {
        backgroundColor: 'transparent',
        ...(disabledWhenFilled ? { color: theme.palette.primary.main } : '')
      }
    }));

    const inputClasses = useInputStyles();

    const setAdornment = (icon, position) => {
      return <InputAdornment position={position}>{icon}</InputAdornment>;
    };

    return (
      <MuiTextField
        InputProps={{
          startAdornment: startIcon ? setAdornment(startIcon, 'start') : null,
          endAdornment: endIcon ? setAdornment(endIcon, 'end') : null,
          classes: inputClasses,
          ...InputProps
        }}
        sx={sx}
        inputRef={ref}
        name={name}
        label={label}
        helperText={description}
        size="small"
        fullWidth
        value={value || ''}
        onChange={onChange}
        autoComplete="new-password"
        disabled={disabled || disabledWhenFilled}
        // add the 'data-test' attribute, falling back to the name by default
        {...(dataTest || name ? { 'data-test': dataTest || name } : {})}
        {...props}
      />
    );
  }
);

export default TextField;
