import { forwardRef, useEffect, useState } from 'react';
import { IMaskInput } from 'react-imask';

import { getSplittedValue } from './WeekPicker.utils';

// Mask component provided by "react-imask"
const WeekMaskInput = forwardRef(({ value, onChange, ...other }, ref) => {
  const mask = `0000{-W}00`;
  const [isLazy, setIsLazy] = useState(false);

  useEffect(() => {
    if (!!value && isLazy) {
      setIsLazy(false);
    }
    if (!value && !isLazy) {
      setIsLazy(true);
    }
  }, [value, isLazy]);

  return (
    <IMaskInput
      value={value}
      mask={mask}
      definitions={{ '#': /\d\d/ }}
      // unmask is already false by default, but we explicitly inform it
      // as a reminder that, by being false, the first param from "onAccept" will
      // return a masked value. In order to change it, check specifications in the example here:
      // https://www.npmjs.com/package/react-imask
      unmask={false}
      // lazy controls if the mask is displayed inside the input or not.
      // when false, mask is displayed. When true, it is hidden.
      // We change it because when input is empty, we don't want to display the mask.
      lazy={isLazy}
      inputRef={ref}
      onAccept={(newValue, props) => {
        const { year } = getSplittedValue(props._unmaskedValue);

        if (!year) {
          return onChange('');
        }
        return onChange(newValue);
      }}
      overwrite
      {...other}
    />
  );
});

export default WeekMaskInput;
