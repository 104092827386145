import { createContext, useContext } from 'react';

import services from 'services';
import { trackEvent } from 'utils/eventTracking';

const KnowledgeGraphContext = createContext(null);

export const KnowledgeGraphProvider = ({ children }) => {
  const deleteKnowledgeGraph = async ({
    knowledgeGraphId,
    withLoader,
    handleError,
    setKnowledgeGraph,
    errorMessage
  }) => {
    const result = await withLoader(services.deleteKnowledgeGraph(knowledgeGraphId), 'Deleting Knowledge Graph...');
    handleError && handleError(result);
    if (!result.success) {
      const message = result?.response?.data?.data?.message;
      trackEvent('Knowledge Graph Deletion Failed', {
        knowledgeGraph: { id: knowledgeGraphId },
        ...(message ? { error: { message } } : {})
      });
      errorMessage(message || 'There was an error while deleting Knowledge Graph.');
      return result;
    }
    trackEvent('Knowledge Graph Deleted', {
      knowledgeGraph: { id: knowledgeGraphId }
    });

    setKnowledgeGraph(null);
    return result;
  };

  return <KnowledgeGraphContext.Provider value={{ deleteKnowledgeGraph }}>{children}</KnowledgeGraphContext.Provider>;
};

export const useKnowledgeGraph = () => {
  return useContext(KnowledgeGraphContext);
};
