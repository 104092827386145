import { get, patch } from 'services/http';

const fetchClass = uri => {
  return get(`/knowledge-graph/classes/${encodeURIComponent(uri)}`);
};

const updateClass = (uri, body) => {
  return patch(`/knowledge-graph/classes/${encodeURIComponent(uri)}`, JSON.stringify(body));
};

export default { fetchClass, updateClass };
