import React, { useEffect, useRef, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form/dist/index.ie11';
import { yupResolver } from '@hookform/resolvers/dist/ie11/yup';
import * as yup from 'yup';
import { withTranslation } from 'react-i18next';

import PageLayout from 'layouts/PageLayout';

import { Button, TextField, PasswordField } from 'components/v3';

import Title from 'components/Signup/Title';

import { authenticate } from 'store/modules/network';

import styles from './index.module.scss';
import signupStyles from 'pages/Signup/signup.styles.scss';
import clsx from 'clsx';
import StepperContainer from 'components/Signup/StepperContainer/StepperContainer';

import { layouts } from 'config/constants';

import useMediaQuery from 'utils/mediaQueries';
import { openPopup } from 'studio/helpers';
import { withSnackbar } from 'notistack';

const validationRules = yup.object().shape({
  email: yup
    .string()
    .required('E-mail is required')
    .email('Format should be like: email@domain.com'),
  password: yup.string().required('Password is required')
});

const defaultValues = {
  email: '',
  password: ''
};

const getErrorMessage = error => {
  return (error && error.message) || '';
};

const Login = ({ isLoginUnsuccessful, isAccountBlocked, isSaml, dispatch, enqueueSnackbar, location }) => {
  const history = useHistory();
  const isMobile = useMediaQuery();

  const inputRef = useRef(null);

  const { handleSubmit, control, errors } = useForm({
    defaultValues,
    resolver: yupResolver(validationRules)
  });

  const onSubmit = data => {
    dispatch(authenticate(data.email, data.password));
  };

  useEffect(() => {
    // Used for Search Engine Indexing as it's a public page
    document.title = 'Veezoo - Login';
    return () => (document.title = 'Veezoo');
  });

  // the popup window to perform the SAML authentication in
  const [samlAuthenticationWindow, setSamlAuthenticationWindow] = useState(null);
  // specifies whether the popup window was blocked by the browser
  const [samlAuthenticationWindowBlocked, setSamlAuthenticationWindowBlocked] = useState(false);

  // opens a popup window and starts a SAML authentication therein
  const openSamlAuthenticationWindow = event => {
    setSamlAuthenticationWindow(null);
    setSamlAuthenticationWindowBlocked(false);
    const samlAuthenticationWindow = openPopup('login?startAuthentication=true', 'Login window', 700, 500);
    if (samlAuthenticationWindow) {
      setSamlAuthenticationWindow(samlAuthenticationWindow);
      // periodically check if the popup window got closed to reset the state
      const intervalId = setInterval(() => {
        if (samlAuthenticationWindow.closed) {
          clearInterval(intervalId);
          setSamlAuthenticationWindow(null);
        }
      }, 500);
    } else {
      // the popup window got blocked by the browser
      setSamlAuthenticationWindowBlocked(event ? 'user' : 'automatic');
      enqueueSnackbar('The login window got blocked by your browser', {
        variant: 'error',
        autoHideDuration: 3000
      });
    }
  };

  // closes the SAML authentication window and loads the URL from the 'redirectTo' parameter
  const closeSamlAuthenticationWindowAndReload = event => {
    // close the popup window: use functional setter to see the up-to-date value of the variable
    setSamlAuthenticationWindow(window => window?.close());

    // reload / redirect the page
    const params = new URLSearchParams(location.search);
    const url = params.get('redirectTo') || '/';
    window.location.href = url;
  };

  useEffect(() => {
    if (isSaml) {
      const eventName = 'veezoo.saml-popup-authentication-completed';
      // if we are in a popup / new window...
      if (window.opener) {
        // ...assume we got here after a SAML popup authentication and dispatch the event to the opener window
        const event = new CustomEvent(eventName);
        window.opener.dispatchEvent(event);
      } else {
        // opens the SAML authentication window (or tries to, as it will likely get blocked by the browser)
        openSamlAuthenticationWindow();
        // listen to the event dispatched when the login page is rendered after the SAML authentication in the popup
        window.addEventListener(eventName, closeSamlAuthenticationWindowAndReload);
        return () => window.removeEventListener(eventName, closeSamlAuthenticationWindowAndReload);
      }
    }
  }, []);

  return (
    <PageLayout>
      <StepperContainer customStepper={<></>}>
        {!isSaml && (
          <div className={signupStyles.loginButton}>
            <Button
              onClick={() => history.push('/signup')}
              classes={{
                root: { fontSize: `${isMobile ? '16px' : '20px'} !important`, color: 'black', fontWeight: '600' }
              }}
              mode="simple"
              data-test="gotoSignupButton"
            >
              Sign up
            </Button>
          </div>
        )}
        <div className={signupStyles.centerBothDirectionsParent}>
          <div className={signupStyles.centerBothDirectionChild} style={isSaml ? { width: 450 } : {}}>
            <div>
              <Title>Login</Title>
              <div className={signupStyles.subTitle}>Your answers are waiting for you. Just log in and ask!</div>
            </div>
            <div>
              {!isSaml && (
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className={signupStyles.mt_40}>
                    <div className={signupStyles.formField}>
                      <Controller
                        as={<TextField layout={layouts.signup} ref={inputRef} />}
                        id="EmailLoginInputHook"
                        name="email"
                        control={control}
                        label="Email"
                        error={Boolean(errors.email)}
                        helperText={getErrorMessage(errors.email)}
                      />
                    </div>
                    <div className={signupStyles.formField}>
                      <Controller
                        as={<PasswordField layout={layouts.signup} />}
                        id="PasswordLoginInputHook"
                        name="password"
                        control={control}
                        label="Password"
                        error={Boolean(errors.password)}
                        helperText={getErrorMessage(errors.password)}
                      />
                    </div>
                  </div>
                  <div className={clsx(signupStyles.mt_40, signupStyles.sendButton)}>
                    <Button type="submit" id="LoginInputButtonHook" layout={layouts.signup} size="large" mode="dark">
                      Log in to Veezoo
                    </Button>
                  </div>
                  {isLoginUnsuccessful && (
                    <div className={styles.wrongCredentials}>
                      Your login was not successful. <br />
                      Please check your credentials and/or IP and try again!
                    </div>
                  )}
                  {isAccountBlocked && (
                    <div className={styles.wrongCredentials}>
                      Your account is temporarily blocked due to <br />
                      too many wrong attempts to login. <br />
                      <br />
                      Please try again in 15 minutes or <a href="mailto:support@veezoo.com">contact us</a>.
                    </div>
                  )}
                  <div className={styles.forgotPassword}>
                    <a onClick={() => history.push('/password/reset')}>Forgot your password? We got your back.</a>
                  </div>
                </form>
              )}
              {isSaml && (
                <>
                  <div className={clsx(signupStyles.mt_40, signupStyles.sendButton)}>
                    <Button
                      layout={layouts.signup}
                      size="large"
                      mode="dark"
                      onClick={openSamlAuthenticationWindow}
                      disabled={!!samlAuthenticationWindow}
                    >
                      Log in to Veezoo
                    </Button>
                  </div>
                  <div
                    className={styles.wrongCredentials}
                    style={{ visibility: samlAuthenticationWindowBlocked ? 'visible' : 'hidden' }}
                  >
                    The login window was blocked by your browser.
                    {samlAuthenticationWindowBlocked === 'user'
                      ? ' Please make sure to allow popups for this site and click on the login button to try again.'
                      : ' Please click on the login button to try again.'}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </StepperContainer>
    </PageLayout>
  );
};

export default withTranslation('veezoo')(withSnackbar(withRouter(Login)));
