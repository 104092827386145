import React from 'react';

import InfinityIcon from 'images/infinity.png';

import styles from './loading.scss';

const SpinningInfinityLoader = () => {
  return <img className={styles.spinningInfinityIcon} src={InfinityIcon} alt="infinity" />;
};

export default SpinningInfinityLoader;
