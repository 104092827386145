import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';

const Modal = ({ children, closeModal, open = false }) => {
  return (
    <Dialog open={open} fullWidth onClose={closeModal}>
      {children}
    </Dialog>
  );
};

Modal.propTypes = {
  closeModal: PropTypes.func,
  open: PropTypes.bool
};

export default Modal;
