import React, { useEffect } from 'react';

import { contextPath } from 'config/baseURL';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import PageLayout from 'layouts/PageLayout';

import styles from './index.module.scss';

const iconSx = {
  '&.MuiSvgIcon-root': {
    width: 80,
    height: 80,
    color: 'var(--light-blue)'
  }
};

const openTab = () => window.open(contextPath, '_blank');

const UserRedirected = () => {
  useEffect(() => {
    openTab();
  }, [openTab]);

  return (
    <PageLayout>
      <div className={styles.container}>
        <div className={styles.iconContainer}>
          <ExitToAppIcon sx={iconSx} />
        </div>
        <div className={styles.header}>Redirecting...</div>
        <div className={styles.description}>
          We are opening Veezoo on a separate tab so you can comfortably set up your data.
          <br />
          Make sure to allow your browser to open a new tab for us!
          <br />
          <br />
          If the tab doesn&apos;t open, please{' '}
          <span className={styles.clickHere} onClick={openTab}>
            click here
          </span>
        </div>
      </div>
    </PageLayout>
  );
};

export default UserRedirected;
