import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import BlockGrid from 'components/BlockGrid';
import Title from 'components/Signup/Title';

import { handleError } from 'services/http';
import { trackEvent } from 'utils/eventTracking';

import { setFinishedSignup } from 'store/modules/user';

import services from 'services';
import sharedStyles from 'components/Signup/Signup.scss';

import StepperContainer from 'components/Signup/StepperContainer/StepperContainer';
import Stepper from 'components/Signup/Stepper';
import { fetchKnowledgeGraphs } from 'store/modules/knowledgeGraph';

const ChooseDataSource = ({ connectors, dispatch }) => {
  const [demoKnowledgeGraphs, setDemoKnowledgeGraphs] = useState(null);
  const history = useHistory();

  const onDemoKnowledgeGraphClick = useCallback(
    async id => {
      trackEvent('Demo Knowledge Graph Selected', { knowledgeGraph: { id } });
      const activateResult = await services.activateDemoKnowledgeGraph(id);
      handleError(activateResult, dispatch);
      if (activateResult.success) {
        const switchResult = await services.switchToKnowledgeGraph(id);
        handleError(switchResult, dispatch);
        if (!switchResult.success) {
          console.log('Could not switch to newly activated demo KG');
          return;
        }
        await dispatch(fetchKnowledgeGraphs());
        dispatch(setFinishedSignup());
        history.push(`/chat?id=${id}`);
      }
    },
    [dispatch, history]
  );

  const fetchDemoKnowledgeGraphs = useCallback(async () => {
    if (!demoKnowledgeGraphs) {
      const result = await services.getDemoKnowledgeGraphs();
      handleError(result, dispatch);
      if (result.success) {
        const demoKnowledgeGraphs = result.data.data.map(({ data }) => data);
        setDemoKnowledgeGraphs(demoKnowledgeGraphs);
      }
    }
  }, [dispatch, demoKnowledgeGraphs, onDemoKnowledgeGraphClick]);

  const formattedDemoKnowledgeGraphs = useMemo(() => {
    if (demoKnowledgeGraphs) {
      return demoKnowledgeGraphs.map(data => ({
        id: data.id,
        name: data.info.default.name,
        image: data.logoUrl,
        onClick: () => onDemoKnowledgeGraphClick(data.id)
      }));
    }
    return [];
  }, [demoKnowledgeGraphs, onDemoKnowledgeGraphClick]);

  useEffect(() => {
    fetchDemoKnowledgeGraphs();
  }, [fetchDemoKnowledgeGraphs]);

  return (
    <StepperContainer
      customContainerStyle={sharedStyles.smallerPadding}
      customStepper={<Stepper step={2} totalSteps={4} />}
    >
      <Title>Choose your data source</Title>
      <br />
      {formattedDemoKnowledgeGraphs?.length > 0 && (
        <>
          <h1>Sample Data</h1>
          <BlockGrid items={formattedDemoKnowledgeGraphs} />
        </>
      )}
      <>
        {formattedDemoKnowledgeGraphs?.length > 0 && <h1>Your Own Data</h1>}
        <BlockGrid items={connectors} loading={!connectors.length} />
      </>
    </StepperContainer>
  );
};

export default connect()(ChooseDataSource);
