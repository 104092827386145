import React, { Fragment, memo, useCallback, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { Controller, useForm } from 'react-hook-form/dist/index.ie11';
import FileCopyRoundedIcon from '@mui/icons-material/FileCopyRounded';
import UpdateIcon from '@mui/icons-material/Update';
import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import { useIconStyles, useStyles } from './KnowledgeGraphDBConnectionForm.styles';
import { defaultLanguage, layouts } from 'config/constants';
import { Button, Checkbox, NumberField, PasswordField, Select, TextField, KGFormTextField } from 'components/v3';
import TextArea from 'studio/components/TextArea';
import { getErrorInfoByFieldId, getErrorMessage, validation } from 'utils/forms';
import _ from 'lodash';

const initialSshFieldsState = { show: false, loading: false, success: false, error: false };

const defaultDatabase = 'mysql';

// Width of the first column (KG name and host fields)
const FirstColumnWidth = '67%';
// Width of the second column (language and port fields)
const SecondColumnWidth = '33%';

const buildTooltipMessage = (tooltipMessage, validationRules) => (
  <>
    {tooltipMessage || ''}
    {tooltipMessage && validationRules && (
      <>
        <br />
        <br />
      </>
    )}
    {validationRules &&
      validationRules.map((rule, index) => (
        <Fragment key={index}>
          - {rule}
          <br />
        </Fragment>
      ))}
  </>
);

const knowledgeGraphFields = [
  {
    id: 'knowledgeGraph.name',
    title: 'Knowledge Graph Name',
    description: 'The name of the Knowledge Graph',
    exampleValue: `Sales`,
    inputType: 'KGFormTextField',
    tooltip:
      "Think of this as a project or dataset name. If your data is for instance about sales data, call it 'Sales'.",
    validation: { required: true, maxLength: 28 },
    obfuscate: false,
    'data-test': 'newKnowledgeGraphName'
  },
  {
    id: 'knowledgeGraph.language',
    title: 'Language',
    description: 'The default language',
    exampleValue: 'English',
    inputType: 'Select',
    tooltip:
      'Choose the language you will use to ask questions to Veezoo. This should be the same language as the one your data is in.',
    obfuscate: false,
    'data-test': 'newKnowledgeGraphDefaultLanguage'
  }
];

const inputTypes = {
  TextField,
  KGFormTextField,
  NumberField,
  Select,
  PasswordField,
  TextArea,
  Password: PasswordField
};

const Form = ({
  knowledgeGraph,
  databases,
  initialDatabase,
  connection,
  postConnection,
  deleteKnowledgeGraph,
  testConnection,
  fetchPublicKey: fetchPublicKeyService,
  loading,
  successMessage,
  layout,
  languages,
  confirmButtonText,
  showKnowledgeGraphFields,
  disableDatabaseSelect,
  additionalButtons,
  isMobile
}) => {
  // Controls SSH fields state, such as 'fetching' status and display true/false;
  const [sshFields, setSshFields] = useState(initialSshFieldsState);

  // contains/updates the current selected database. Default value is 'mysql';
  const [database, setDatabase] = useState(defaultDatabase);

  const classes = useStyles();
  const iconClasses = useIconStyles();

  // Everytime we change the current database, we run over the 'databases' array to get the fields it contains;
  const databaseFields = useMemo(() => databases.find(db => db.id === database), [database, databases]);

  // Returns true/false to check if we are editing an existing database connection and if the selected database is the same as the one we are editing;
  // example: we already have a MYSQL connection and we are editing it, and on the `database` select, the selected database is MYSQL;
  // This is just meant to avoid code repetition as we need this validation more than once;
  const currentDatabaseExists = useMemo(() => connection && connection.databaseSystemId === databaseFields?.id, [
    connection?.databaseSystemId,
    databaseFields?.id
  ]);

  // Defines the fields that will be displayed for the current database, as well as some default values;
  const fields = useMemo(() => {
    if (!databaseFields) return {};
    const formattedFields = {};

    const rawFields = [
      ...(showKnowledgeGraphFields ? knowledgeGraphFields : []),
      ...databaseFields.connectionProperties
    ];

    rawFields.forEach(field => {
      const customFields = { width: '100%', defaultValue: '', shrink: true };

      if (field.id === 'knowledgeGraph.name') {
        customFields.width = FirstColumnWidth;
        customFields.disabled = !!knowledgeGraph;
        customFields.highlighted = !!knowledgeGraph;
        customFields.defaultValue = knowledgeGraph?.info?.default?.name || '';
        customFields.deleteKnowledgeGraph = deleteKnowledgeGraph;
      }

      if (field.id === 'knowledgeGraph.language') {
        customFields.disabled = !!knowledgeGraph;
        customFields.highlighted = !!knowledgeGraph;
        customFields.defaultValue = knowledgeGraph?.defaultLanguageId || defaultLanguage || '';
        customFields.options = languages;
        customFields.width = SecondColumnWidth;
      }

      // Only make it less wide if port field exists
      if (field.id === 'host' && rawFields.find(f => f.id === 'port')) {
        customFields.width = FirstColumnWidth;
      }

      if (field.id === 'port') {
        if (rawFields.find(f => f.id === 'host')) {
          customFields.width = SecondColumnWidth;
        }
        if (databaseFields.defaultPort) {
          customFields.defaultValue = databaseFields.defaultPort;
        }
      }

      formattedFields[field.id] = {
        ...field,
        ...customFields
      };
    });

    // Set the field default values based on the signup variables
    Object.entries(formattedFields).forEach(([field, value]) => {
      window.veezoo?.signupVariables?.database?.fields?.[field] &&
        (value.defaultValue = window.veezoo?.signupVariables?.database?.fields?.[field]);
    });

    return {
      ...formattedFields,
      // The SSHTunnel fields should always be present because, even though the SSH checkbox is not checked,
      // these properties are always rendered by the <Collapse /> component. Whether the fields are sent or not on the payload
      // is validated inside "buildPayload" function.
      sshTunnel: {
        host: {
          title: 'SSH Host',
          description: `Do not use a load balancer's IP address / hostname`,
          isRequired: true,
          validation: validation.required('SSH Host'),
          defaultValue: ''
        },
        port: {
          title: 'SSH Port',
          isRequired: true,
          validation: validation.required('SSH Port'),
          defaultValue: ''
        },
        user: {
          title: 'SSH User',
          isRequired: true,
          defaultValue: '',
          validation: validation.required('SSH User')
        },
        publickey: {
          title: 'Public Key',
          isRequired: true,
          validation: validation.required('Public Key'),
          defaultValue: ''
        }
      }
    };
  }, [knowledgeGraph, databaseFields, showKnowledgeGraphFields, languages]);

  const { handleSubmit, control, errors, reset, setValue, getValues } = useForm();

  // Changes the current selected database, thus changing the fields displayed;
  const selectDatabase = event => {
    const value = event.target.value;
    setDatabase(value);
  };

  // Copies the SSH public key to the clipboard, allowing the user to paste it with CTRL + V;
  const copySSH = () => {
    const values = getValues();
    const key = values?.sshTunnel?.publickey;
    navigator.clipboard.writeText(key);
    successMessage('Copied to clipboard!');
  };

  // As soon as we display the SSH Fields (if there are), we fetch a public key from the backend and insert into the
  // proper field;
  const fetchPublicKey = async () => {
    setSshFields(prev => ({ ...prev, loading: true, error: false }));
    const result = await fetchPublicKeyService();
    if (result.success) {
      setSshFields(prev => ({ ...prev, loading: false, success: true }));
      setValue('sshTunnel.publickey', result.data);
      return;
    }
    setSshFields(prev => ({ ...prev, loading: false, error: true }));
  };

  // Builds the payload that will be sent to the backend, based on the fields displayed;
  // We created a separate function because this formatting has to be done more than once;
  const buildPayload = values => {
    const payload = {
      databaseSystemId: database,
      ..._.mapValues(values, (value, key) => {
        if (fields[key]?.inputType === 'NumberField') {
          return Number(value);
        }
        return value;
      })
    };

    // Remove empty and obfuscated fields from the payload
    const formattedPayload = _.pickBy(payload, value => value !== '');

    if (!sshFields.show) {
      delete formattedPayload.sshTunnel;
    } else {
      delete formattedPayload.sshTunnel.publickey;
    }

    return formattedPayload;
  };

  // Tests the database connection via a backend request, returning a (potentially fixed) connection payload if
  // successfull, or null if failed.
  const testAndFixDatabaseConnection = async values => {
    const { knowledgeGraph, ...data } = values;
    const testPayload = buildPayload(data);
    const result = await testConnection(testPayload);
    if (!result.success) {
      // return null to indicate that the test failed
      return null;
    } else {
      // the test succeeded and potentially returned fixed connection details
      return result.data;
    }
  };

  // Tests the database connection so we can test if it actually works before submitting the form;
  const onTestDatabaseConnectionClick = async values => {
    const fixedPayload = await testAndFixDatabaseConnection(values);
    if (fixedPayload) {
      // Test succeeded!
      // The returned database connection may include some automatic fixes, so we update the UI
      updateFieldsFromPayload(fixedPayload);
      successMessage('Database connection succeeded!');
    }
  };

  // Submits the form, creating a new KG (if it's the case) and create a database connection, or update an existing DB connection
  // for an existing KG;
  const onSaveClick = async values => {
    const fixedPayload = await testAndFixDatabaseConnection(values);
    if (fixedPayload) {
      // Test succeeded!
      // The returned database connection may include some automatic fixes, so we update the UI
      updateFieldsFromPayload(fixedPayload);
      // We don't re-use the returned fixedPayload but re-built the payload from the form fields, as the fixedPayload
      // does not include obfuscated fields
      const fullPayload = buildPayload(getValues());
      return postConnection(fullPayload);
    }
  };

  // Transforms a payload returned by the backend to form field values to be used with the form component.
  const getFieldValuesFromPayload = payload => {
    const connectionProperties = {};

    databaseFields.connectionProperties.forEach(connectionProperty => {
      const id = connectionProperty.id;
      if (payload[id]) {
        connectionProperties[id] = payload[id];
      }
    });

    return {
      ...connectionProperties,
      ...(payload.sshTunnel ? { sshTunnel: payload.sshTunnel } : {})
    };
  };

  // Updates the form fields according to a given backend payload. Obfuscated fields not included in the payload will be
  // left unchanged (this makes sure we don't reset obfuscated fields such as passwords when testing a connection).
  const updateFieldsFromPayload = payload => {
    setSshFields(prev => ({ ...prev, show: !!payload.sshTunnel }));
    const fieldValues = getFieldValuesFromPayload(payload);
    Object.entries(fieldValues).forEach(([id, value]) => {
      setValue(id, value);
    });
  };

  // When changing the current selected database, if there is not an existing connection,
  // we reset the fields to the default values required for that DB connection;
  const resetFieldsToDefault = useCallback(() => {
    const newFields = Object.keys(fields).reduce((acc, field) => {
      acc[field] = fields[field].defaultValue;
      return acc;
    }, {});
    reset(newFields);
  }, [fields]);

  const definePlaceholder = useCallback(
    field => {
      if (currentDatabaseExists && field.obfuscate) return { placeholder: '********' };
      if (field.exampleValue) return { placeholder: field.exampleValue };
      return {};
    },
    [currentDatabaseExists]
  );

  // Defines the initial DB connection, as follows:
  // If we pass it from props (e.g., when creating a new KG), we use it;
  // If we don't pass it from props, but we are editing an existing DB Connection, we use this DB connection;
  // Otherwise we use the default DB connection (MYSQL);
  useEffect(() => {
    let databaseId;
    if (initialDatabase) {
      databaseId = initialDatabase.id;
    } else if (connection) {
      databaseId = connection.databaseSystemId;
    } else {
      databaseId = defaultDatabase;
    }
    setDatabase(databaseId);
  }, [initialDatabase, connection]);

  // Check the SSH fields checkbox status (true/false) and calls the fetchPublicKey function if it's true;
  useEffect(() => {
    if (sshFields.show && !sshFields.loading && !sshFields.success && !sshFields.error) {
      fetchPublicKey();
    }
  }, [sshFields, fetchPublicKey]);

  useEffect(() => {
    if (!knowledgeGraph) {
      setValue('knowledgeGraph.name', '');
      setValue('knowledgeGraph.language', 'en-US');
    }
  }, [knowledgeGraph]);

  // First we check if the database fields are loaded. After that we check if the current database exists.
  // If it exists, it means we are editing an existing DB connection, so we need to fill the fields with the current values;
  // Otherwise, we reset the fields to the default values;
  useEffect(() => {
    if (databaseFields) {
      if (currentDatabaseExists) {
        setSshFields(prev => ({ ...prev, show: !!connection.sshTunnel }));
        const resetLoad = getFieldValuesFromPayload(connection);
        reset(resetLoad);
      } else {
        // When changing database, hide SSH fields.
        setSshFields(initialSshFieldsState);
        resetFieldsToDefault();
      }
    }
  }, [databaseFields, currentDatabaseExists]);

  return (
    <>
      {layout !== layouts.signup && databaseFields?.docsUrl && (
        <p>
          <span>
            Check our guide on{' '}
            <a target="_blank" rel="noreferrer" href={databaseFields.docsUrl}>
              how to connect to {databaseFields.name}
            </a>
          </span>
        </p>
      )}
      <div className={classes.root}>
        <form autoComplete="off" data-test="databaseConnectionForm">
          {layout !== layouts.signup && (
            <div className={classes.fieldContainer}>
              <Select
                label="Database system"
                value={databases.length > 0 ? database : ''}
                description="The database system"
                onChange={selectDatabase}
                options={databases.map(item => ({ value: item.id, label: item.name }))}
                disabled={disableDatabaseSelect || !databases || loading}
                layout={layout}
              />
            </div>
          )}
          {Object.keys(fields).map(fieldId => {
            const field = fields[fieldId];
            const component = inputTypes[field.inputType];

            if (!component) return null;

            // obfuscated fields are not required for existing DB connections, as the backend will copy over the existing values
            const isRequired = !(currentDatabaseExists && field.obfuscate) && field.validation?.required;

            const rules = {
              ...(field?.validation?.required ? { required: validation.required(field.title, isRequired) } : {}),
              ...(field?.validation?.pattern
                ? { pattern: validation.pattern(field.title, field.validation.pattern) }
                : {}),
              ...(field?.validation?.maxLength ? { maxLength: validation.maxLength(field.validation.maxLength) } : {}),
              ...(field?.validation?.isNumber ? { isNumber: validation.isNumber(field.title) } : {})
            };

            // Organize rule messages to display inside tooltip, excluding the "required" rule (which is already displayed in the label by "*")
            const tooltipRules = Object.keys(rules).reduce((acc, rule) => {
              if (rule !== 'required') {
                acc.push(rules[rule].message);
              }
              return acc;
            }, []);

            const error = getErrorInfoByFieldId(errors, fieldId);

            return (
              <div
                key={fieldId}
                className={classes.fieldContainer}
                {...(!isMobile && field.width ? { style: { width: field.width } } : {})}
              >
                <Controller
                  as={component}
                  name={fieldId}
                  defaultValue={field.defaultValue}
                  rules={rules}
                  required={!!isRequired} // Even though we are passing the "rules" attribute, we still have to pass "required" because it triggers the "*" in the label;
                  control={control}
                  label={field.title}
                  error={!!error}
                  description={error?.message || field.description || ''}
                  disabled={loading || field.disabled}
                  highlighted={field.highlighted}
                  layout={layout}
                  data-test={field['data-test'] || 'database-input-' + field.id}
                  {...(field.deleteKnowledgeGraph && field.highlighted
                    ? { deleteClick: field.deleteKnowledgeGraph }
                    : {})}
                  {...(field.tooltip || tooltipRules.length > 0
                    ? { tooltip: buildTooltipMessage(field.tooltip, tooltipRules) }
                    : {})}
                  {...(field.shrink ? { shrink: field.shrink } : {})}
                  {...(field.options ? { options: field.options } : {})}
                  {...definePlaceholder(field)}
                />
              </div>
            );
          })}
          <div className={classes.fieldContainer}>
            <Checkbox
              checked={sshFields.show}
              onChange={check => setSshFields(prev => ({ ...prev, show: check }))}
              layout={layout}
              disabled={loading}
              size="small"
              content="Add SSH Tunnel Credentials"
            />
          </div>
          <div style={{ clear: 'both' }}></div>
          <Collapse in={sshFields.show} className={classes.ssh}>
            <div className={classes.fieldContainer}>
              <Controller
                shrink
                as={TextField}
                name="sshTunnel.host"
                defaultValue={fields?.sshTunnel?.host?.defaultValue || ''}
                control={control}
                label="SSH Host"
                error={!!errors?.sshTunnel?.host}
                description={
                  getErrorMessage(errors?.sshTunnel?.host) || "Do not use a load balancer's IP address / hostname"
                }
                disabled={loading}
                layout={layout}
                {...(sshFields.show ? { rules: fields.sshTunnel.host.validation } : {})}
              />
            </div>
            <div className={classes.fieldContainer}>
              <Controller
                shrink
                as={TextField}
                name="sshTunnel.port"
                defaultValue={fields?.sshTunnel?.port?.defaultValue || ''}
                control={control}
                label="SSH Port"
                error={!!errors?.sshTunnel?.port}
                description={getErrorMessage(errors?.sshTunnel?.port)}
                disabled={loading}
                {...(sshFields.show ? { rules: fields.sshTunnel.port.validation } : {})}
                layout={layout}
              />
            </div>
            <div className={classes.fieldContainer}>
              <Controller
                shrink
                as={TextField}
                name="sshTunnel.user"
                defaultValue={fields?.sshTunnel?.user?.defaultValue || ''}
                control={control}
                label="SSH User"
                error={!!errors?.sshTunnel?.user}
                description={getErrorMessage(errors?.sshTunnel?.user)}
                disabled={loading}
                {...(sshFields.show ? { rules: fields.sshTunnel.user.validation } : {})}
                layout={layout}
              />
            </div>
            <div className={classes.fieldContainer}>
              <Controller
                shrink
                as={TextField}
                name="sshTunnel.publickey"
                defaultValue={fields?.sshTunnel?.publickey?.defaultValue || ''}
                control={control}
                label="Public Key"
                endIcon={
                  sshFields.loading ? (
                    <CircularProgress size={20} />
                  ) : sshFields.success ? (
                    <FileCopyRoundedIcon classes={{ root: iconClasses.publicKeyIcon }} onClick={copySSH} />
                  ) : (
                    <UpdateIcon classes={{ root: iconClasses.publicKeyIcon }} onClick={fetchPublicKey} />
                  )
                }
                error={!!errors?.sshTunnel?.publickey}
                description={getErrorMessage(errors?.sshTunnel?.publickey)}
                disabled
                layout={layout}
              />
            </div>
          </Collapse>
          <div className={clsx(classes.buttonContainer, classes.fieldContainer)}>
            <div className={classes.buttonCell}>
              <Button
                layout={layout}
                variant="contained"
                color="primary"
                mode="light"
                size={isMobile ? 'mobile' : ''}
                onClick={handleSubmit(onTestDatabaseConnectionClick)}
                disabled={loading}
              >
                Test Connection
              </Button>
            </div>
            <div className={clsx(classes.buttonCell, classes.alignRight)}>
              {additionalButtons &&
                additionalButtons.map((button, buttonIndex) => {
                  return (
                    <Button
                      key={`key_${buttonIndex}`}
                      onClick={event => button.onClick(event, { reset, fields })}
                      disabled={loading}
                      layout={layout}
                      mode="light"
                      size={isMobile ? 'mobile' : ''}
                    >
                      {button.label}
                    </Button>
                  );
                })}
              <Button
                variant="contained"
                color="primary"
                mode="dark"
                size={isMobile ? 'mobile' : ''}
                onClick={handleSubmit(onSaveClick)}
                disabled={loading}
                layout={layout}
                data-test={currentDatabaseExists ? 'saveDatabaseConnection' : 'submitNewKnowledgeGraph'}
              >
                {confirmButtonText || (currentDatabaseExists ? 'Save' : 'Create')}
              </Button>
            </div>
          </div>
          <div style={{ clear: 'both' }}></div>
        </form>
      </div>
    </>
  );
};

export default memo(Form);

Form.defaultProps = {
  isMobile: false
};
