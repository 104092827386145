import { handleUnauthorized } from 'store/utils/authFetch';
import { fetchUserInformation } from '../user';

import { fetchGet, handleError } from 'services/http';

import services from 'services';

export const GRAPH_ITEM_CLASS_REQUEST = 'GRAPH_ITEM_CLASS_REQUEST';
export const GRAPH_ITEM_CLASS_SUCCESS = 'GRAPH_ITEM_CLASS_SUCCESS';
export const GRAPH_ITEM_CLASS_FAILURE = 'GRAPH_ITEM_CLASS_FAILURE';

export const CHANGE_GRAPH_CLASS_REQUEST = 'CHANGE_GRAPH_CLASS_REQUEST';
export const CHANGE_GRAPH_CLASS_SUCCESS = 'CHANGE_GRAPH_CLASS_SUCCESS';
export const CHANGE_GRAPH_CLASS_FAILURE = 'CHANGE_GRAPH_CLASS_FAILURE';

export const fetchGraphItemClass = item => {
  return dispatch => {
    dispatch({ type: GRAPH_ITEM_CLASS_FAILURE });

    dispatch({ type: GRAPH_ITEM_CLASS_REQUEST });

    fetchGet('/knowledge-graph/classes/' + encodeURIComponent(item.uri))
      .then(response => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 401) {
          handleUnauthorized(dispatch);
        } else {
          dispatch({ type: GRAPH_ITEM_CLASS_FAILURE });
          throw Error(response.statusText);
        }
      })
      .then(result => {
        dispatch({ type: GRAPH_ITEM_CLASS_SUCCESS, class: result });
      })
      .catch(error => console.log('ERROR', error));
  };
};

export const updateClass = (uri, property, value) => {
  return async dispatch => {
    dispatch({ type: CHANGE_GRAPH_CLASS_REQUEST, property });

    let body = { [property]: value };
    const result = await services.updateClass(uri, body);
    handleError(result, dispatch);
    if (!result.success) {
      dispatch({ type: CHANGE_GRAPH_CLASS_FAILURE, property });
    } else {
      dispatch({ type: CHANGE_GRAPH_CLASS_SUCCESS, class: result.data, property });
      // refresh the user information as updating a class possibly adds a milestone
      dispatch(fetchUserInformation());
      return result.data;
    }
  };
};

// reducer
export const graphItemClass = (state = [], action) => {
  switch (action.type) {
    case GRAPH_ITEM_CLASS_SUCCESS:
      return action.class;

    case GRAPH_ITEM_CLASS_FAILURE:
      return {};

    case CHANGE_GRAPH_CLASS_SUCCESS: {
      return action.class;
    }

    default:
      return state;
  }
};
