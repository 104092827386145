import React from 'react';
import { formatNumber } from 'utils/numberFormat';
import { Liquid } from 'utils/Liquid';
import { format as formatDate } from 'date-fns';
import * as locales from 'date-fns/locale';

const LiquidFormattedValue = ({ row, user, htmlTemplate }) => {
  // wraps the specified filter function, passing it as the last argument all (any) named arguments as an object
  const filter = f => (value, ...args) => {
    const positionalArguments = args.filter(arg => !Array.isArray(arg));
    const namedArguments = Object.fromEntries(args.filter(arg => Array.isArray(arg)));
    return f(value, ...positionalArguments, namedArguments);
  };

  /**
   * Formats the specified number based on the user's number format, taking into account any `locale` or `options`
   * overrides specified as arguments.
   *
   * The formatting is implemented using an `Intl.NumberFormat` object. The `locale` and `options` arguments, if
   * specified, override the corresponding options of the `Intl.NumberFormat` object, cf.:
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
   */
  const format_number = filter((number, { locale, ...options }) =>
    formatNumber(number, user.numberFormat, locale, options)
  );

  /**
   * Formats the specified number using a compact notation (with suffixes `K`, `M`, etc.), based on the user's number
   * format and taking into account any `locale` or `options` overrides specified as arguments.
   *
   * This is a shortcut for calling `format_number` and specifying `locale` and `option` overrides as follows:
   * `{{ ... | format_number: locale: 'en-US', notation: 'compact' }}`.
   */
  const format_number_compact = filter((number, { locale, ...options }) => {
    // use the 'en-US' locale by default to get a compact notation using 'K', 'M', etc.:
    //   - here are some examples of the compact notations for different locales:
    //                           en-US   de-DE      fr-FR    it-IT     pt-BR
    //           1234.567   ->   1.2K    1235       1,2 k    1235      1,2 mil
    //        1234567.891   ->   1.2M    1.2 Mio.   1,2 M    1,2 Mio   1,2 mi
    //     1234567891.234   ->   1.2B    1.2 Mrd.   1,2 Md   1,2 Mrd   1,2 bi
    //   - note that e.g. German and Italian don't label thousands for some reason
    //   - note that the examples use the default formatting options: however, we pass the user's number format to
    //     `formatNumber`, which changes the resulting formatting
    const localeOverride = locale || 'en-US';
    const optionsOverrides = { notation: 'compact', ...options };
    return formatNumber(number, user.numberFormat, localeOverride, optionsOverrides);
  });

  /**
   * Formats the specified date using the `format` function from the `date-fns` library, based on the specified format
   * and options.
   *
   * @see https://date-fns.org/v2.29.3/docs/format
   */
  const format_date = filter((date, format, options) => {
    // we use the user language (i.e. current KG language) and not the locale of the user's number format, since the
    // formatted date might contain prose text such as month names, week days etc.
    const locale = options?.locale || user.language;
    options = {
      ...options,
      // set the appropriate 'date-fns' locale, falling back to 'en-US' if not found
      locale: locales[locale.replace('-', '')] || locales.enUS
    };
    return formatDate(new Date(date), format, options);
  });

  const formattedValue =
    typeof row.value === 'number' ? formatNumber(row.value, user.numberFormat) : row.formattedValue || row.value;

  if (htmlTemplate) {
    const scope = {
      value: row.value,
      formatted_value: formattedValue,
      min_value: row.minValue,
      max_value: row.maxValue
    };
    const filters = {
      format_number: format_number,
      format_number_compact: format_number_compact,
      format_date: format_date
    };
    return <Liquid template={htmlTemplate} scope={scope} filters={filters} />;
  }

  return formattedValue;
};

export default LiquidFormattedValue;
