import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import omit from 'lodash/omit';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import CloseIcon from 'svg/v2/close.svg';

import { Button, LoadingCircle } from 'components/v3';
import { layouts } from 'config/constants';

import { useCustomModalStyles, useTssStyles } from './CustomModal.styles';

const CustomModal = ({
  title,
  content,
  onConfirm,
  confirmIcon,
  onClose,
  closeLabel,
  confirmLabel = 'Confirm',
  autoFocusOnConfirm = false,
  open = false,
  layout = layouts.default,
  disabled = false,
  disableConfirmButton = false,
  loading = false,
  hideButtons = false,
  hideCloseIcon = false,
  customButtons,
  DialogTitleProps = {},
  DialogContentProps = {},
  DialogActionsProps = {},
  paperStyles = {},
  maxWidth = false,
  ...props
}) => {
  const { styles: DialogTitlePropsStyles, ...DialogTitleOtherProps } = DialogTitleProps;
  const { styles: DialogContentPropsStyles, ...DialogContentOtherProps } = DialogContentProps;
  const { styles: DialogActionsPropsStyles, ...DialogActionsOtherProps } = DialogActionsProps;

  // removes 'tReady' prop which would lead to a warning when passed along to 'Dialog'.
  const dialogProps = omit(props, ['tReady']);

  const customClasses = useCustomModalStyles({
    hideButtons,
    paperStyles,
    DialogTitlePropsStyles,
    DialogContentPropsStyles,
    DialogActionsPropsStyles
  });

  const { classes: tssClasses } = useTssStyles();

  const { t } = useTranslation();

  const showDividers = layout !== layouts.veezoo && layout !== layouts.signup;

  const handleConfirm = async () => {
    // don't close the dialog if the 'onConfirm' callback returns false
    if ((await onConfirm()) !== false) {
      onClose();
    }
  };

  const handleClose = () => onClose();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={maxWidth}
      sx={customClasses[layout]}
      disableEnforceFocus
      {...dialogProps}
    >
      <DialogTitle {...DialogTitleOtherProps}>
        {title}
        <div>
          {loading ? (
            <LoadingCircle size={11} color="var(--primary-color)" data-test="customModalLoading" />
          ) : (
            !hideCloseIcon && (
              <button
                className={tssClasses.closeButton}
                onClick={onClose}
                data-test="customModalCloseButton"
                title={t('close')}
              >
                <CloseIcon className={tssClasses.closeIcon} />
              </button>
            )
          )}
        </div>
      </DialogTitle>
      <DialogContent dividers={showDividers} {...DialogContentOtherProps}>
        {content}
      </DialogContent>
      {!hideButtons && (
        <DialogActions {...DialogActionsOtherProps}>
          {customButtons || (
            <>
              <Button onClick={onClose} layout={layout}>
                {closeLabel || (onConfirm ? 'Cancel' : 'Close')}
              </Button>
              {onConfirm && (
                <Button
                  autoFocus={autoFocusOnConfirm}
                  onClick={handleConfirm}
                  layout={layout}
                  mode="dark"
                  disabled={disableConfirmButton || disabled || loading}
                  {...(confirmIcon && { icon: confirmIcon })}
                >
                  {confirmLabel}
                </Button>
              )}
            </>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default CustomModal;

CustomModal.propTypes = {
  autoFocusOnConfirm: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  content: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  confirmLabel: PropTypes.string,
  confirmIcon: PropTypes.any,
  closeLabel: PropTypes.string,
  onConfirm: PropTypes.func,
  layout: PropTypes.string,
  disabled: PropTypes.bool,
  disableConfirmButton: PropTypes.bool,
  hideButtons: PropTypes.bool,
  hideCloseIcon: PropTypes.bool,
  customButtons: PropTypes.any,
  loading: PropTypes.bool,
  DialogTitleProps: PropTypes.object,
  DialogContentProps: PropTypes.object,
  DialogActionsProps: PropTypes.object,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  paperStyles: PropTypes.object
};
