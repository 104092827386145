import services from 'services';
import { handleError } from 'services/http';

export const LANGUAGES_LIST_REQUEST = 'LANGUAGES_LIST_REQUEST';
export const LANGUAGES_LIST_SUCCESS = 'LANGUAGES_LIST_SUCCESS';
export const LANGUAGES_LIST_FAILURE = 'LANGUAGES_LIST_FAILURE';

export const fetchAllLanguages = () => async dispatch => {
  dispatch({ type: LANGUAGES_LIST_REQUEST });

  const result = await services.getLanguages();
  handleError(result, dispatch);

  if (!result.success) {
    return dispatch({ type: LANGUAGES_LIST_FAILURE });
  }

  dispatch({
    type: LANGUAGES_LIST_SUCCESS,
    languages: result.data.data
  });
};

// describe a language reducer
export const languagesState = { data: [], fetching: false, fetched: false };

export const languages = (state = languagesState, action) => {
  switch (action.type) {
    case LANGUAGES_LIST_REQUEST:
      return {
        ...state,
        fetching: true
      };

    case LANGUAGES_LIST_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: action.languages
      };

    case LANGUAGES_LIST_FAILURE:
      return {
        ...state,
        fetching: false,
        fetched: false
      };

    default:
      return state;
  }
};
