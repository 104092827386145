import { authFetchGet } from 'store/utils/authFetch';
import { fetchPatch } from 'services/http';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const VALIDATE_TOKEN_REQUEST = 'VALIDATE_TOKEN_REQUEST';
export const VALIDATE_TOKEN_SUCCESS = 'VALIDATE_TOKEN_SUCCESS';
export const VALIDATE_TOKEN_FAILURE = 'VALIDATE_TOKEN_FAILURE';

export const resetPassword = (token, newPassword, email) => {
  return dispatch => {
    dispatch({ type: RESET_PASSWORD_REQUEST });

    fetchPatch('/user/password/reset', { token: token, newPassword: newPassword }).then(response => {
      if (response.ok) {
        dispatch({
          type: RESET_PASSWORD_SUCCESS
        });
      } else {
        response.text().then(t =>
          dispatch({
            type: RESET_PASSWORD_FAILURE,
            message: t
          })
        );
      }
    });
  };
};

export const validateToken = token => {
  return dispatch => {
    // Set the isFetchingAuthentication flag so the UI knows it should show a loader.
    dispatch({ type: VALIDATE_TOKEN_REQUEST });

    // Check if the user is authenticated by doing a dummy request.
    authFetchGet('/user/password/reset/' + token, dispatch)
      .then(response => {
        response.ok
          ? response.text().then(t => dispatch({ type: VALIDATE_TOKEN_SUCCESS, message: t }))
          : response.text().then(t => dispatch({ type: VALIDATE_TOKEN_FAILURE, message: t }));
      })
      .catch(error => console.log('ERROR', error));
  };
};

// reducer
export const defaultPasswordResetState = {
  somethingWentWrong: false,
  isResettingPassword: false,
  isTokenValid: false,
  message: '',
  isPasswordChanged: false
};

export function passwordReset(state = defaultPasswordResetState, action) {
  switch (action.type) {
    case VALIDATE_TOKEN_REQUEST:
      return defaultPasswordResetState;

    case VALIDATE_TOKEN_SUCCESS:
      return {
        ...state,
        somethingWentWrong: false,
        isTokenValid: true,
        message: action.message
      };

    case VALIDATE_TOKEN_FAILURE:
      return {
        ...state,
        somethingWentWrong: true,
        message: action.message
      };

    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isResettingPassword: true
      };

    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        somethingWentWrong: true,
        isResettingPassword: false,
        message: action.message
      };

    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        somethingWentWrong: false,
        isResettingPassword: false,
        isPasswordChanged: true
      };

    default:
      return state;
  }
}
