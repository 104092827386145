import { useMemo } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import TextField from '@mui/material/TextField';

import useMediaQuery from 'utils/mediaQueries';

import { dateViews } from 'config/constants';
import { isValidDate } from 'utils/datetimeUtils';

import sx from './DatePicker.styles';

const formats = {
  dayMonthYear: 'dd/MM/yyyy',
  monthYear: 'MM/yyyy',
  year: 'yyyy'
};

const DatePicker = ({
  views: rawViews = [dateViews.day, dateViews.month, dateViews.year],
  value,
  onChange,
  error = false
}) => {
  const isMobile = useMediaQuery();

  // DatePicker doesn't recognize view "date", so if it is present, we convert it to "day"
  const views = useMemo(() => rawViews.map(view => (view === dateViews.date ? dateViews.day : view)), [rawViews]);

  const inputFormat = useMemo(() => {
    if (!views || views.length < 1) return formats.dayMonthYear;

    const hasDay = views.some(view => view === dateViews.day);
    const hasMonth = views.some(view => view === dateViews.month);
    const hasYear = views.some(view => view === dateViews.year);

    if (hasDay) return formats.dayMonthYear;
    if (hasMonth) return formats.monthYear;
    if (hasYear) return formats.year;

    return formats.dayMonthYear;
  }, [views]);

  const hasError = useMemo(() => value && !isValidDate(value), [value]) || error;

  const commonProps = {
    value,
    onChange,
    views,
    inputFormat,
    showDaysOutsideCurrentMonth: true,
    disableHighlightToday: true,
    renderInput: params => (
      <TextField
        {...params}
        sx={sx.textField}
        error={hasError}
        data-test="keyboard-date-picker"
        {...(hasError ? { helperText: 'Invalid date format' } : {})}
      />
    )
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {isMobile ? (
        <MobileDatePicker {...commonProps} DialogProps={{ sx: sx.container }} />
      ) : (
        <DesktopDatePicker {...commonProps} PopperProps={{ sx: sx.container }} />
      )}
    </LocalizationProvider>
  );
};

export default DatePicker;
