import { forwardRef, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import { useTranslation } from 'react-i18next';

import getQuarter from 'date-fns/getQuarter';

import { TextField } from 'components/v3';
import QuarterSelector from './QuarterSelector';

import Popover from '@mui/material/Popover';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import { isValidDate, formatQuarterStringToDatetime } from 'utils/datetimeUtils';

import useStyles from './QuarterPicker.styles';

// Mask component provided by "react-imask"
const QuarterMaskInput = forwardRef(({ onChange, ...other }, ref) => {
  const { t } = useTranslation();
  const mask = `0000{-${t('quarter-initial')}}#`;

  return (
    <IMaskInput
      mask={mask}
      definitions={{ '#': /[1-4]/ }}
      // unmask is already false by default, but we explicitly inform it
      // as a reminder that, by being false, the first param from "onAccept" will
      // return a masked value. In order to change it, check specifications in the example here:
      // https://www.npmjs.com/package/react-imask
      unmask={false}
      lazy={false} // make placeholder always visible
      inputRef={ref}
      onAccept={onChange}
      overwrite
      {...other}
    />
  );
});

const QuarterPicker = ({ value: incomingDateValue, onChange }) => {
  const styles = useStyles();
  const { t } = useTranslation();

  const quarterInitial = t('quarter-initial');
  const initialInputValueState = `____-${quarterInitial}_`;

  const [anchorEl, setAnchorEl] = useState(null);
  const [inputValue, setInputValue] = useState(initialInputValueState);

  const hasError = useMemo(() => !isValidDate(formatQuarterStringToDatetime(inputValue, t)), [inputValue, t]);

  // Initial load (if any), should happen only once.
  useEffect(() => {
    if (incomingDateValue) {
      const year = incomingDateValue.getFullYear();
      const quarter = getQuarter(incomingDateValue);

      setInputValue(`${year}${quarterInitial}${quarter}`);
    }
  }, []);

  const handleInputChange = value => {
    setInputValue(value);
    const inputValueAsDatetime = formatQuarterStringToDatetime(value, t);
    onChange(inputValueAsDatetime);
  };

  const openPopover = event => setAnchorEl(event.currentTarget);
  const closePopover = () => setAnchorEl(null);

  return (
    <>
      <TextField
        layout="veezoo"
        error={hasError}
        value={inputValue}
        onChange={handleInputChange}
        name="quarterTextField"
        id="quarterTextField"
        InputProps={{ inputComponent: QuarterMaskInput }}
        endIcon={<CalendarMonthIcon onClick={openPopover} sx={styles.calendar} />}
      />
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        sx={styles.popover}
        onClose={closePopover}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left'
        }}
      >
        <QuarterSelector quarterDateValue={inputValue} onConfirm={handleInputChange} onClose={closePopover} />
      </Popover>
    </>
  );
};

export default QuarterPicker;

QuarterPicker.propTypes = {
  value: PropTypes.instanceOf(Date),
  onChange: PropTypes.func
};
