import clsx from 'clsx';
import TextFitter from './TextFitter/TextFitter';
import classnames from 'classnames';
import styles from './number-chart.scss';
import useMediaQuery from 'utils/mediaQueries';
import LiquidFormattedValue from './utils/LiquidFormattedValue';

const SimpleValueChart = ({ isCentered, chart, user }) => {
  const isMobile = useMediaQuery();

  const chartData = JSON.parse(chart);

  const verticalCenterCorrectionClass = classnames({
    verticalCenterCorrection: isCentered
  });

  const row = {
    value: chartData.value
  };

  return (
    <div className={isCentered ? styles.centralize : ''}>
      <div className={clsx(styles.containerNotOverflowing, verticalCenterCorrectionClass)}>
        <TextFitter max={isMobile ? 34 : 48}>
          <LiquidFormattedValue row={row} user={user} htmlTemplate={chartData.htmlTemplate} />
        </TextFitter>
      </div>
    </div>
  );
};

export default SimpleValueChart;
