import React, { memo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Controller, useForm } from 'react-hook-form/dist/index.ie11';
import { yupResolver } from '@hookform/resolvers/dist/ie11/yup';
import * as yup from 'yup';
import clsx from 'clsx';

import Title from 'components/Signup/Title';
import PageLoader from 'components/Signup/PageLoader';
import MessageModal from 'components/Signup/MessageModal';

import { Button, PasswordField, Stepper, TextField } from 'components/v3';

import { signup } from 'store/modules/network';
import services from 'services';

import { trackEvent } from 'utils/eventTracking';
import { handleError } from 'services/http';

import styles from '../index.module.scss';
import signupStyles from 'pages/Signup/signup.styles.scss';

import StepperContainer from 'components/Signup/StepperContainer/StepperContainer';

import { layouts } from 'config/constants';

const validationRules = yup.object().shape({
  firstName: yup
    .string()
    .test('len', 'Must be lower than 50 characters', val => val.length < 50)
    .required('First Name is required'),
  lastName: yup
    .string()
    .test('len', 'Must be lower than 50 characters', val => val.length < 50)
    .required('Last Name is required'),
  password: yup.string().required('Password is required')
});

const defaultValues = {
  firstName: '',
  lastName: '',
  password: ''
};

const getErrorMessage = error => {
  return (error && error.message) || '';
};

const getInvitationErrorMessage = errorCode => {
  switch (errorCode) {
    case 1:
      return "The invitation wasn't found";
    case 2:
      return 'The invitation has expired and can no longer be used';
    case 3:
      return 'The invitation has already been accepted and can no longer be used';
    default:
      return 'There was an unknown error getting the invitation';
  }
};

const CreateAccount = ({
  // at least one of invitationId or verifyEmailToken needs to be set
  invitationId,
  verifyEmailToken,
  isSigningUp,
  signupSuccess,
  signupFailure,
  signupStatusCode,
  signupError,
  isFirstTimeLogin,
  isAuthorized,
  dispatch
}) => {
  const [savedFields, setSavedFields] = useState(null);

  // true while the user is being created and the first login is taking place
  const userCreationIsPending = isFirstTimeLogin || isSigningUp;

  // true while the request to get the invitation is pending
  const [invitationIsPending, setInvitationIsPending] = useState(false);
  // the invitation
  const [invitation, setInvitation] = useState(null);
  // an error code if getting the invitation failed
  //   1: "not found", 2: "expired", 3: "already accepted": 4: "unknown error"
  const [invitationErrorCode, setInvitationErrorCode] = useState(null);
  // whether the user email address has been successfully verified by invitation
  const isVerifiedByInvitation = !!invitation;

  // true while the request to verify the email via the verification token is pending
  const [verifyEmailIsPending, setVerifyEmailIsPending] = useState(false);
  const [verifiedEmail, setVerifiedEmail] = useState(null);
  const [verifyEmailErrorMessage, setVerifyEmailErrorMessage] = useState(null);
  const [verifyEmailAlreadyExistsError, setVerifyEmailAlreadyExistsError] = useState(false);
  // whether the user email address has been successfully verified by an email verification token
  const isVerifiedByToken = !!verifiedEmail;

  const isLoading = userCreationIsPending || invitationIsPending || verifyEmailIsPending;

  const [modal, setModal] = useState({
    open: false,
    message: 'Modal message',
    type: 'info'
  });

  const { handleSubmit, control, errors, reset } = useForm({
    defaultValues,
    resolver: yupResolver(validationRules)
  });

  const fetchInvitation = async id => {
    setInvitationIsPending(true);
    const response = await services.getInvitation(id);
    handleError(response, dispatch);
    if (response.success) {
      const newInvitation = response.data.data;
      if (newInvitation.isExpired) {
        // invitation expired
        setInvitationErrorCode(2);
      } else if (newInvitation.isAccepted) {
        // invitation already accepted
        setInvitationErrorCode(3);
      } else {
        setInvitation(newInvitation);
      }
    } else if (response.response.status === 404) {
      // not found
      setInvitationErrorCode(1);
    } else {
      // unknown error
      setInvitationErrorCode(4);
    }
    setInvitationIsPending(false);
  };

  const fetchVerifiedSignupEmail = async token => {
    setVerifyEmailIsPending(true);
    const response = await services.getVerifiedSignupEmail(token);
    handleError(response, dispatch);
    if (response.success) {
      setVerifiedEmail(response.data.email);
    } else if (response.response?.status === 409) {
      // HTTP status 409 CONFLICT
      setVerifyEmailAlreadyExistsError(true);
    } else {
      setVerifyEmailErrorMessage(response.response.data);
    }
    setVerifyEmailIsPending(false);
  };

  const reopenForm = () => {
    setModal(prevModal => ({ ...prevModal, open: false }));
    reset(savedFields);
  };

  const onSubmit = data => {
    setSavedFields(data);

    const user = {
      firstName: data.firstName.trim(),
      lastName: data.lastName.trim(),
      password: data.password,
      ...(isVerifiedByInvitation ? { invitationId: invitation.id } : { verifyEmailToken }),
      locale: Intl.DateTimeFormat().resolvedOptions().locale || 'en-US'
    };

    const credentials = {
      email: isVerifiedByInvitation ? invitation.email : verifiedEmail,
      password: data.password
    };

    dispatch(signup(user, credentials));
  };

  // handle the case of a successful account creation
  useEffect(() => {
    if (signupSuccess) {
      if (isAuthorized) {
        if (isVerifiedByInvitation) {
          trackEvent('Invitation Accepted', {});
        } else if (isVerifiedByToken) {
          trackEvent('Email Verified by Token', {});
        }
      }
    }
  }, [signupSuccess, isVerifiedByInvitation, isVerifiedByToken, isAuthorized]);

  // handle the case of a failed account creation
  useEffect(() => {
    if (signupFailure) {
      let message;
      if (signupStatusCode === 409) {
        message = 'The provided email address already exists.';
      } else if (signupStatusCode === 403) {
        message =
          'Signing up is disabled since your organization is using single sign-on with SAML. Instead, you have to be given access to Veezoo at your Identity Provider. Please reach out to your IT administrator.';
      } else if (signupError.data.validationErrors?.length) {
        // use the first validation error
        const validationError = signupError.data.validationErrors[0];
        // transform camel case property name to lowercase words
        const field = validationError.path.replace(/([A-Z])/g, ' $1').toLowerCase();
        message = `Field '${field}'`;
        if (validationError.type === 'error.invalidCharacter') {
          message += ` contains invalid characters: ${validationError.values}`;
        } else {
          message += `: ${signupError.data.message}`;
        }
      }
      setModal({
        open: true,
        type: 'error',
        message: (
          <span>
            There was an error while signing up.
            <br />
            {message}
          </span>
        )
      });
    }
  }, [signupFailure, setModal]);

  // once the component mounts, fetch the invitation or verified signup email (one of these needs to be set)
  useEffect(() => {
    // don't make the API call if we're already authorized (this is needed due to the brittle logic in Signup/index.js:
    //  authorizing the user involves a remount of this component before the parent will switch to the next step)
    if (!isAuthorized) {
      if (invitationId) {
        fetchInvitation(invitationId);
      } else if (verifyEmailToken) {
        fetchVerifiedSignupEmail(verifyEmailToken);
      }
    }
  }, []);

  let textBlock;
  if (isVerifiedByInvitation) {
    textBlock = (
      <div>
        <Title>Join your colleagues on Veezoo</Title>
        <div className={signupStyles.subTitle}>Veezoo needs your name to refer to you</div>
      </div>
    );
  } else if (isVerifiedByToken) {
    textBlock = (
      <div>
        <Title>Complete your Profile.</Title>
        <div className={signupStyles.subTitle}>Veezoo needs your name to refer to you</div>
      </div>
    );
  } else {
    // don't show a title if we don't have the invitation or verified email fetched yet (loader will be shown)
    textBlock = <></>;
  }

  let loader;
  if (invitationIsPending) {
    loader = (
      <div className={signupStyles.loaderContainer}>
        <PageLoader message="Getting the invitation..." />
      </div>
    );
  } else if (verifyEmailIsPending) {
    loader = (
      <div className={signupStyles.loaderContainer}>
        <PageLoader message="Verifying your e-mail..." />
      </div>
    );
  } else if (userCreationIsPending) {
    loader = (
      <div className={signupStyles.loaderContainer}>
        <PageLoader message="Creating user, please wait a minute..." />
      </div>
    );
  } else {
    loader = <></>;
  }

  let errorBlock;
  if (invitationErrorCode) {
    errorBlock = (
      <div className={signupStyles.mt_40}>
        <span className={signupStyles.errorMessage}>
          {getInvitationErrorMessage(invitationErrorCode)}.
          <br />
          <br />
          Please reach out to the person who invited you or contact us at{' '}
          <a href="mailto:support@veezoo.com">support@veezoo.com</a>.
        </span>
      </div>
    );
  } else if (verifyEmailAlreadyExistsError) {
    errorBlock = (
      <div className={signupStyles.mt_40}>
        <span className={signupStyles.errorMessage}>This email is already associated with an account.</span>
        <br />
        <br />
        <span className={signupStyles.errorMessage}>
          You can <a href="/login">log in</a> or <a href="/password/reset">reset your password</a> if you forgot it.
        </span>
      </div>
    );
  } else if (verifyEmailErrorMessage) {
    errorBlock = (
      <div className={signupStyles.mt_40}>
        <span className={signupStyles.errorMessage}>{verifyEmailErrorMessage}</span>
      </div>
    );
  } else {
    errorBlock = <></>;
  }

  const accountDetailsForm = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={signupStyles.pageContent}>
        <div>
          <div className={signupStyles.sidedFormFields}>
            <div className={clsx(signupStyles.formField, styles.half, signupStyles.mr_30)}>
              <Controller
                as={TextField}
                layout={layouts.signup}
                name="firstName"
                control={control}
                label="First Name"
                error={Boolean(errors.firstName)}
                helperText={getErrorMessage(errors.firstName)}
                data-test="signupFirstName"
              />
            </div>
            <div className={clsx(signupStyles.formField, styles.half)}>
              <Controller
                as={TextField}
                layout={layouts.signup}
                name="lastName"
                control={control}
                label="Last Name"
                error={Boolean(errors.lastName)}
                helperText={getErrorMessage(errors.lastName)}
                data-test="signupLastName"
              />
            </div>
          </div>
          <div className={signupStyles.formField}>
            <Controller
              as={PasswordField}
              layout={layouts.signup}
              name="password"
              control={control}
              label={invitationId ? 'New Password' : 'Password'}
              error={Boolean(errors.password)}
              helperText={getErrorMessage(errors.password)}
              data-test="signupPassword"
            />
          </div>
        </div>
      </div>
      <div className={signupStyles.sendButton}>
        <Button
          type="submit"
          layout={layouts.signup}
          disabled={signupSuccess}
          size="large"
          mode="dark"
          data-test="profileSubmitButton"
        >
          Create Account
        </Button>
      </div>
    </form>
  );

  return (
    <>
      <StepperContainer customStepper={<Stepper step={1} totalSteps={5} />}>
        <div className={signupStyles.centerBothDirectionsParent}>
          <div className={signupStyles.centerBothDirectionChild}>
            {isLoading && loader}
            {!isLoading && textBlock}
            {!isLoading && errorBlock}
            {!isLoading && (isVerifiedByInvitation || isVerifiedByToken) && accountDetailsForm}
          </div>
        </div>

        <MessageModal
          message={modal.message}
          open={modal.open}
          onConfirm={reopenForm}
          closeModal={reopenForm}
          type={modal.type}
        />
      </StepperContainer>
    </>
  );
};

const mapStateToProps = state => {
  return {
    isAuthorized: state.network.isAuthorized,
    signupSuccess: state.network.signupSuccess,
    signupFailure: state.network.signupFailure,
    signupStatusCode: state.network.signupStatusCode,
    signupError: state.network.signupError,
    isSigningUp: state.network.isSigningUp,
    isFirstTimeLogin: state.network.isFirstTimeLogin
  };
};

export default connect(mapStateToProps)(memo(CreateAccount));
