import React, { useEffect, useState } from 'react';
import { useGradientBoxStyles } from './styles.js';
export const GradientBox = ({ isLoading, children }) => {
  const [value, setValue] = useState(0);
  const { classes: styles } = useGradientBoxStyles({ isLoading, value });

  useEffect(() => {
    let intervalId;
    if (isLoading) {
      intervalId = setInterval(() => {
        setValue(prevValue => (prevValue + 1) % 360);
      }, 10);
    } else {
      intervalId && clearInterval(intervalId);
    }
    return () => clearInterval(intervalId); // Clear the interval when the component unmounts
  }, [isLoading]);

  return <div className={styles.root}>{children}</div>;
};
