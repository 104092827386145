import React, { forwardRef } from 'react';
import TextField from 'studio/components/TextField';
import makeStyles from '@mui/styles/makeStyles';

const TextArea = forwardRef(({ name, value, label, description, onChange, resize, ...props }, ref) => {
  const useStyles = makeStyles(theme => ({
    root: {
      ...(theme.isDark ? {} : { backgroundColor: 'white' })
    },
    disabled: {
      backgroundColor: 'transparent'
    },
    input: {
      resize: resize || 'none'
    }
  }));

  const classes = useStyles();
  return (
    <TextField
      ref={ref}
      name={name}
      label={label}
      description={description}
      InputProps={{ classes }}
      fullWidth
      value={value || ''}
      onChange={onChange}
      multiline
      minRows={5}
      maxRows={5}
      {...props}
    />
  );
});

export default TextArea;
