import React, { useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useTssStyles, useDialogStyles } from './ConfirmationModal.styles';

import { layouts } from 'config/constants';

const ConfirmationModal = props => {
  const {
    children,
    open,
    layout = layouts.default,
    title = '',
    onConfirm = null,
    confirmButtonText = 'Confirm',
    onClose = null,
    confirmDataTest = '',
    cancelDataTest = '',
    disableConfirm = false,
    maxWidth = 'sm',
    fixedHeight = undefined
  } = props;

  const [loading, setLoading] = useState(false);

  const { classes } = useTssStyles();
  const dialogClasses = useDialogStyles();

  return (
    <Dialog
      sx={dialogClasses[layout].title}
      maxWidth={maxWidth}
      fullWidth
      open={open}
      onClose={onClose}
      disabled={loading}
      data-test="confirmationModal"
      PaperProps={{
        style: { minHeight: fixedHeight, maxHeight: fixedHeight }
      }}
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent sx={dialogClasses[layout].content}>
        {loading ? (
          <div className={classes.progress}>
            <CircularProgress />
          </div>
        ) : (
          children
        )}
      </DialogContent>
      <DialogActions sx={dialogClasses[layout].actions}>
        <Button
          variant={layout === layouts.default ? 'text' : 'outlined'}
          color="primary"
          onClick={async () => {
            if (onClose) {
              setLoading(true);
              await onClose();
              setLoading(false);
            }
          }}
          disabled={loading}
          data-test={cancelDataTest}
        >
          {onConfirm ? 'Cancel' : 'Close'}
        </Button>
        {onConfirm && (
          <Button
            variant={layout === layouts.default ? 'text' : 'contained'}
            autoFocus
            color="primary"
            onClick={async () => {
              setLoading(true);
              await onConfirm();
              setLoading(false);
            }}
            disabled={loading || disableConfirm}
            data-test={confirmDataTest}
          >
            {confirmButtonText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
