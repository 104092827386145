import { get, post } from 'services/http';

const getDemoKnowledgeGraphs = () => get('/studio/api/connectors/demo');

const activateDemoKnowledgeGraph = id => post(`/studio/api/connectors/demo/${encodeURIComponent(id)}`);

const getConnectors = () => get('/studio/api/connectors');

const createKnowledgeGraphFromConnector = (applicationId, defaultLanguageId, name) => {
  const body = {
    languages: [defaultLanguageId],
    defaultLanguageId,
    info: {
      [defaultLanguageId]: { name }
    }
  };
  return post(`/studio/api/connectors/${applicationId}/fivetran`, body);
};

export default { getDemoKnowledgeGraphs, activateDemoKnowledgeGraph, getConnectors, createKnowledgeGraphFromConnector };
