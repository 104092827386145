import { Check, Save, Send, Undo } from '@material-ui/icons';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import {
  discardLastModifiedVisualization,
  MODIFY_VISUALIZATION_SUCCESS,
  modifyVisualizationLlm
} from 'store/modules/visualizations';
import { connect } from 'react-redux';
import Button from 'components/v2/Button';
import CodeDialog from 'components/shared/CodeDialog';
import { extractMetaPartialAnswers } from 'components/message/BoardAnswerMessage';
import { GradientBox } from 'components/message/ModifyVisualizationBlock/GradientBox';
import { updateBoard } from 'store/modules/board-widgets';
import { withTranslation } from 'react-i18next';
import { Close } from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';
import InfoTooltip from 'components/tooltips/InfoTooltip';
import { DialogContentText } from '@mui/material';
import { trackEvent } from 'utils/eventTracking';
import { useDialogTextStyles, useModifyVisualizationStyles } from 'components/message/ModifyVisualizationBlock/styles';
import { findVisibleHighChartObject, mergeChartOptions } from 'utils/chartUtils';

const mergeOptionsToString = (originalOptions, customOptions, modifiedOptions, base = {}) => {
  return JSON.stringify(mergeChartOptions(originalOptions, customOptions, modifiedOptions, base), null, 2);
};

/**
 * Generic method to strip from a highcharts data point the numeric answer value.
 *
 * The data point can come in various forms depending on the chart type.
 */
const maskHighChartsNumericValue = (dataPoint, wipeAllIfArray) => {
  // Check if the dataPoint is an array, which could be [x, y], [x, y, z], etc.
  if (Array.isArray(dataPoint)) {
    if (wipeAllIfArray) {
      // return as many 'x's as the length of the array
      return Array(dataPoint.length).fill('x');
    } else if (dataPoint.length === 2) {
      // this case covers e.g. linechart where we have on the x the time in milliseconds
      return [dataPoint[0], 'x'];
    } else if (dataPoint.length === 3) {
      // We are only in here in a heatmap, and in that case the first two data points are the coordinates.
      return [dataPoint[0], dataPoint[1], 'x'];
    } else {
      // generic fallback, not sure if we ever get in here
      return ['x'];
    }
  }
  // Check if the dataPoint is an object
  else if (typeof dataPoint === 'object' && dataPoint !== null) {
    let modifiedDataPoint = { ...dataPoint };
    const keysToReplace = ['value', '0', '1', 'x', 'y', 'z'];

    keysToReplace.forEach(key => {
      if (key in modifiedDataPoint) {
        modifiedDataPoint[key] = 'x';
      }
    });
    return modifiedDataPoint;
  }
  // If the dataPoint is a simple number
  else {
    return 'x';
  }
};
/**
 * The HighCharts options contain pretty much all information to render a graph, including the actual series data.
 *
 * This function is used to strip & transform the options to a format that can be shown in a prompt.
 * It removes unnecessary options, and also masks the numeric value of data points with 'x'.
 *
 * Still we allow categorical data to be used within the prompt, so a user can reference particular
 * data points in the visualization.
 *
 * Structure of the series / data array for different chart types
 *  ColumnChart, BarChart: 1 series, 1d data array
 *  (Percentage / Stacked)AreaChart, LineChart: 1 series, 2d data array, x and y, x being time, y being value
 *  MultiColumn, MultiBar, (Percentage)Stacked(Bar|Column): n series, each as with ColumnChart
 *  TopColumn n series, each with 2d data array: [x,y], x being the index, y being the value (will fall in line case)
 *  Pie: 1 series, object data-array with name and y-value
 *  DrillDownCharts: 1 series, object data-array with name/type and y-value
 *  TreeMap: 1 series, object data-array with name/id/parent/color and 'value'
 *  Multi(Axis)LineChart: n series, each as with LineChart
 *  HeatMap: 1 series, 3d data array, [x, y, z], with x and y being coordinates, z being the value
 *  MultiBubbleChart: n series, object data array with x, y, z, name with x,y,z being values
 *  MultiScatterChart: 2 series, 1 series for the regression line
 *      => regression line should be purged, the other series is an object data array with 0, 1, name, x, y.
 *      Everything except name are numeric values.
 *  MultiHistogramChart:
 *    n series, each with 2d data array, x and y and both being numeric values. x being the 'bin' - we mask y only
 *  LineColumnChart: 2 series, 1 series for the line, 1 series for the column as with ColumnChart and LineChart
 *  ChoroplethMap: 1 series, object data-array with name and 'value'. Also contains mapData which should be removed.
 */
export const transformOptionsForPrompt = chartOptions => {
  // Ensure the function doesn't modify the original chartOptions by creating a deep copy
  const newChartOptions = JSON.parse(JSON.stringify(chartOptions));

  // Remove other non-required top-level options if they exist
  delete newChartOptions.exporting; // Removes exporting options
  delete newChartOptions.credits; // Removes credits
  delete newChartOptions.responsive; // Removes responsive options

  // Let the prompt not get too long, semi arbitrary limit
  const shouldStripSomeData = JSON.stringify(newChartOptions).length > 50000;

  if (Array.isArray(newChartOptions.series)) {
    [...(newChartOptions.drilldown?.series || []), ...(newChartOptions.series || [])].forEach(series => {
      // Specific cleanup based on type
      switch (newChartOptions.chart?.type) {
        case 'scatter':
          // Scatter charts can contain a regression line with concrete data points which we want to remove completely.
          if (series.isRegressionLine) {
            // delete
            delete series.data;
            delete series.regressionOutputs;
            series.name = 'x'; // this is to mask the equation "Equation = y = 21.39x + 24260.92"
          } else {
            delete series.regressionSettings; // this series also contains concrete data points, hence we remove it
            // If the scatter contains an array of numbers [x, y] we should mask all of them as they are data values
            series.data = series.data.map(d => maskHighChartsNumericValue(d, true));
          }
          break;
        case 'map':
          if (series.mapData) {
            delete series.mapData;
          }
          series.data = series.data.map(d => maskHighChartsNumericValue(d, false));
          break;
        default:
          // if the series is an array of numbers, we let the first value be visible as this is usually
          // the x-axis value containing the time or index.
          // If not it's either an object or should be covered in the cases above.
          series.data = series.data.map(d => maskHighChartsNumericValue(d, false));
          break;
      }
      if (shouldStripSomeData && series.data?.length > 100) {
        // keep only 100 data points
        series.data = series.data.slice(0, 100);
      }
    });
  }

  return newChartOptions;
};

const ModifyVisualizationBlock = ({
  show,
  dispatch,
  visualizationType,
  originalVisualizationOptions,
  customVisualizationOptions,
  modifiedVisualizations,
  partialAnswerId,
  messageId,
  isEditableBoard,
  boardId,
  boardWidgets,
  widgetId,
  isFetchingVisualizationModification,
  onAcceptChanges,
  t
}) => {
  const classes = useModifyVisualizationStyles({ show, isFetchingVisualizationModification });
  const dialogTextClasses = useDialogTextStyles();

  // The content of the input box
  const [inputText, setInputText] = useState('');
  // Do we show the code dialogue
  const [showOptions, setShowOptions] = useState(false);
  // The metaWidgets in case the current answer is part of a board and could potentially be updated
  const metaWidgets = extractMetaPartialAnswers(boardWidgets);
  // The relevant modifications done in the current session
  const modifiedVisualizationOptionsArray = useMemo(
    () =>
      modifiedVisualizations.filter(
        m => m.partialAnswerId === partialAnswerId && m.visualizationType === visualizationType
      ),
    [modifiedVisualizations, partialAnswerId, visualizationType]
  );

  // The content of the Code Dialogue / Options Screen
  const [options, setOptions] = useState(() =>
    mergeOptionsToString(originalVisualizationOptions, customVisualizationOptions, modifiedVisualizationOptionsArray)
  );
  const [optionsBeforeModification, setOptionsBeforeModification] = useState(options);

  const showAcceptDiscardButtons = modifiedVisualizationOptionsArray.length > 0;

  useEffect(() => {
    if (show) {
      trackEvent('Modify Visualization Opened', { visualizationType, partialAnswerId });
    }
  }, [show]);

  useEffect(() => {
    if (show) {
      trackEvent('Modify Visualization Options Changed', {
        visualizationType,
        partialAnswerId,
        modifiedVisualizationOptionsArray
      });
    }
  }, [modifiedVisualizationOptionsArray]);

  // if the options are being shown and edited, in case the user deletes the whole input, put an empty json object
  // a bit hacky but does the job. Could consider adding a button to reset the option, but no time now.
  useEffect(() => {
    if (showOptions && options.length === 0 && optionsBeforeModification.length > 0) {
      setOptions('{}');
    }
  }, [options, showOptions]);
  const handleInputChange = event => {
    setInputText(event.target.value);
  };

  // save the options before opening the json dialogue
  useEffect(() => {
    if (showOptions) {
      trackEvent('Modify Visualization JSON Options Opened', {
        visualizationType,
        partialAnswerId,
        modifiedVisualizationOptionsArray
      });
      setOptionsBeforeModification(options);
    }
  }, [showOptions]);

  const handleConfirm = () => {
    if (inputText.length > 0 && !isFetchingVisualizationModification) {
      const chart = findVisibleHighChartObject(messageId);
      // Currently rendered options
      const chartOptionsJson = transformOptionsForPrompt(chart.getOptions());
      const chartOptions = JSON.stringify(chartOptionsJson, null, 2);
      const previousModificationInputTexts = modifiedVisualizationOptionsArray.reduce((acc, cur) => {
        if (cur.mergeWithPrevious) {
          acc.push(cur.inputText);
        }
        return acc;
      }, []);

      trackEvent('Modify Visualization Request', { inputText, visualizationType, partialAnswerId });

      dispatch(
        modifyVisualizationLlm({
          visualizationType: visualizationType,
          partialAnswerId: partialAnswerId,
          inputText: inputText,
          theme: JSON.stringify(Highcharts.theme, null, 2),
          currentVisualizationOptions: chartOptions,
          previousInputTexts: previousModificationInputTexts
        })
      );
    }
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      handleConfirm();
    }
  };

  // Empty the input after we have received an answer from the backend
  useEffect(() => {
    if (!isFetchingVisualizationModification) {
      setInputText('');
    }
  }, [isFetchingVisualizationModification]);

  // Content for the JSON Code Dialogue
  useEffect(() => {
    setOptions(
      mergeOptionsToString(originalVisualizationOptions, customVisualizationOptions, modifiedVisualizationOptionsArray)
    );
  }, [originalVisualizationOptions, customVisualizationOptions, modifiedVisualizationOptionsArray]);

  // Are the options valid json, we use this to disable or enable the save button in the code dialogue
  let invalidJson = false;
  try {
    JSON.parse(options);
  } catch (e) {
    invalidJson = true;
  }

  const handleModifiedOptionsAccept = () => {
    setInputText('');
    if (isEditableBoard) {
      // We merge the modified options with the original options in the releavant widget
      // potentially also the type of the widget changed
      // Then we update the board, note: the user still has to publish the board (if it's shared)
      const updatedWidgets = metaWidgets.map(widget => {
        if (widget.id === widgetId) {
          return {
            ...widget,
            type: visualizationType,
            visualizationOptions: options
          };
        }
        return widget;
      });
      dispatch(updateBoard(boardId, updatedWidgets, false, false));
    }
    onAcceptChanges();
  };

  const highchartsTypeForDocs = findVisibleHighChartObject(messageId)?.getOptions()?.chart?.type;

  return (
    <div className={classes.root}>
      <CodeDialog
        title={t('modify-visualization.visualization-options')}
        subtitle={
          <DialogContentText classes={dialogTextClasses}>
            {t('modify-visualization.edit-options-description')}
            <br />
            <a
              target="_blank"
              rel="noreferrer"
              href={`https://api.highcharts.com/highcharts/plotOptions.${highchartsTypeForDocs}`}
            >
              {t('modify-visualization.highcharts-docs')}
            </a>
          </DialogContentText>
        }
        code={options}
        onClose={() => {
          // If the options are already hidden, it means we are closing the dialogue after a save
          if (!showOptions) {
            setOptions(optionsBeforeModification);
          }
          setShowOptions(false);
        }}
        disableConfirmButton={invalidJson}
        open={showOptions}
        language="json"
        monacoEditorOptions={{
          readOnly: false
        }}
        editorProps={{
          onChange: newValue => {
            setOptions(newValue);
          }
        }}
        confirmLabel={t('save')}
        confirmIcon={<Save />}
        onConfirm={() => {
          trackEvent('Modify Visualization JSON Saved', { visualizationType, partialAnswerId, options });
          dispatch({
            type: MODIFY_VISUALIZATION_SUCCESS,
            modifiedVisualizationOptions: options,
            partialAnswerId,
            visualizationType,
            text: '', // we edited via json, not via a question
            mergeWithPrevious: false // on confirm, the new state is the entire new state (no merging)
          });
          setShowOptions(false);
        }}
      />
      <div className={classes.outerDiv}>
        <span className={classes.centerText}>{t('modify-visualization.what-do-you-want-to-update')}</span>
        <GradientBox isLoading={isFetchingVisualizationModification}>
          <div className={classes.flexCenter}>
            <input
              type="text"
              value={inputText}
              placeholder={t('modify-visualization.placeholder')}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              autoFocus={true}
              readOnly={isFetchingVisualizationModification}
              // on enter
              className={classes.inputField}
            />
            <InfoTooltip text={t('modify-visualization.edit-options-description')}>
              <Button
                label={t('modify-visualization.edit-options')}
                onClick={() => {
                  setShowOptions(!showOptions);
                }}
                className={classes.editButton}
              />
            </InfoTooltip>
            <Button
              showOnlyIcon={true}
              className={classes.sendButton}
              onClick={handleConfirm}
              disabled={isFetchingVisualizationModification || inputText.length === 0}
              icon={isFetchingVisualizationModification ? <CircularProgress size="auto" /> : <Send />}
            />
          </div>
        </GradientBox>
        <div className={classes.flexCenter}>
          {showAcceptDiscardButtons && (
            <Fragment>
              <Button
                label={
                  isEditableBoard ? t('modify-visualization.update-widget') : t('modify-visualization.accept-changes')
                }
                onClick={handleModifiedOptionsAccept}
                icon={<Check />}
              />
              <Button
                label={t('modify-visualization.discard-last-change', {
                  count: modifiedVisualizationOptionsArray.length
                })}
                onClick={() => {
                  setInputText('');
                  dispatch(discardLastModifiedVisualization({ partialAnswerId, visualizationType }));
                }}
                icon={<Undo />}
              />
            </Fragment>
          )}
        </div>
      </div>
      <Button
        onClick={onAcceptChanges}
        title={t('delete')}
        icon={<Close />}
        className={classes.closeButton}
        showOnlyIcon={true}
      />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const expandedMiniWidget = state.expandedMiniWidgets.find(mw => mw.messageId === ownProps.messageId);
  const widgetId = expandedMiniWidget?.widgetId;
  const boardId = state.chatMessages.find(m => expandedMiniWidget && m.id === expandedMiniWidget.messageId)?.boardId;
  const isEditableBoard = state.board.boardsList.find(b => b.id === boardId)?.isEditable;
  const boardWidgets = state.boardWidgets.find(bw => bw.boardId === boardId)?.widgets;

  return {
    modifiedVisualizations: state.modifiedVisualizations,
    isEditableBoard: isEditableBoard,
    boardId: boardId,
    boardWidgets: boardWidgets,
    widgetId: widgetId,
    isFetchingVisualizationModification: state.network.isFetchingVisualizationModification
  };
};

export default withTranslation('veezoo')(connect(mapStateToProps)(ModifyVisualizationBlock));
