import { get, post } from 'services/http';

const getPlans = () => get('/studio/api/plans');

const getCountries = () => get('/studio/api/countries');

const getSubscriptions = () => get('/studio/api/subscriptions');

const getSubscription = id => get(`/studio/api/subscriptions/${encodeURIComponent(id)}`);

const updateSubscription = (id, configuration, billingInfo, creditCard) => {
  const body = { configuration, billingInfo, creditCard };
  return post(`/studio/api/subscriptions/${encodeURIComponent(id)}`, body);
};

const updateBillingInfo = (id, billingInfo) =>
  post(`/studio/api/subscriptions/${encodeURIComponent(id)}/billing-info`, billingInfo);

const updateCreditCard = (id, creditCard) =>
  post(`/studio/api/subscriptions/${encodeURIComponent(id)}/credit-card`, creditCard);

const getSubscriptionUpdatePreview = (subscriptionId, configuration, countryCode) => {
  const params = new URLSearchParams();
  if (countryCode) {
    params.set('countryCode', countryCode);
  }
  return post(
    `/studio/api/subscriptions/${encodeURIComponent(subscriptionId)}/invoices/upcoming`,
    configuration,
    params
  );
};

const getInvoices = subscriptionId => get(`/studio/api/subscriptions/${encodeURIComponent(subscriptionId)}/invoices`);

export default {
  getPlans,
  getCountries,
  getSubscriptions,
  getSubscription,
  updateSubscription,
  updateBillingInfo,
  updateCreditCard,
  getSubscriptionUpdatePreview,
  getInvoices
};
