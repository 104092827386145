import { memo, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';

import SimpleNumberChart from './SimpleNumberChart';
import TwoNumberChart from './TwoNumberChart';
import NumberComparisonChart from './NumberComparisonChart';
import VeezooMapChart from './VeezooMapChart';
import { TablePlotFull, TablePlotWidget } from './TablePlot';
import ErrorBoundary from 'error-handler/ErrorBoundary';
import { Q2QStateContextProvider } from 'components/shared/Q2QStateContext';

import _ from 'lodash';

import './chart.scss';

import { chartTypes } from 'config/constants';
import AnswerContextMenu from 'components/AnswerContextMenu/AnswerContextMenu';
import { discardLastModifiedVisualization } from 'store/modules/visualizations';
import { findVisibleHighChartObject, mergeChartOverrides } from 'utils/chartUtils';
import SimpleValueChart from './SimpleValueChart';

export const isValueLikeChart = type =>
  [
    chartTypes.TWO_NUMBER_CHART,
    chartTypes.NUMBER_COMPARISON_CHART,
    chartTypes.SIMPLE_NUMBER_CHART,
    chartTypes.SIMPLE_VALUE_CHART
  ].includes(type);

export const isTableChart = type => [chartTypes.TABLE_CHART, chartTypes.HIERARCHICAL_TABLE_CHART].includes(type);

const renderHighChart = (formattedChart, id, nonce) => {
  const doc = new DOMParser().parseFromString(formattedChart, 'text/html'); // eslint-disable-line no-undef
  const scriptElements = doc.getElementsByTagName('script');
  const [verifiedScripts, potentialXssScripts] = _.partition(
    scriptElements,
    scriptElement => scriptElement.getAttribute('chart-script-nonce') === nonce
  );
  if (potentialXssScripts.length > 0) {
    console.error('Potential XSS scripts found (should be safe though):', potentialXssScripts);
  }
  // if there is already a highcharts object with the same id, we need to destroy it first
  const existingChart = findVisibleHighChartObject(id);
  if (existingChart) {
    existingChart.destroy();
  }

  const extractedScript = [].map.call(verifiedScripts, el => el.textContent);
  window.eval(extractedScript[0]); // eslint-disable-line no-eval

  // For all rendered charts add a scrollbar if labels are hidden
  addScrollbarToHighchartsIfLabelsHidden();

  // Find the highcharts object that was just rendered
  return findVisibleHighChartObject(id);
};

const addScrollbarToHighchartsIfLabelsHidden = () => {
  // We iterate over all charts we have rendered in our app but only update those that don't have yet a scrollbar
  Highcharts.charts.forEach(chart => {
    if (!chart) return;

    // Collect all updates needed
    const updates = {
      xAxis: {},
      yAxis: {}
    };

    chart?.axes.forEach(a => {
      const hasCategories = a.categories;
      const isHidingLabels = a.tickInterval > 1;
      const hasScrollbar = a.scrollbar;
      const isXAxis = a.isXAxis;
      const isYAxis = a.coll === 'yAxis'; // there is somehow no a.isYAxis...

      if (isHidingLabels && hasCategories && !hasScrollbar) {
        const numLabels = a.categories.length;
        // tickInterval says basically that every x'th label is shown (depending on screen size on render)
        // by dividing total labels by it, we get the theoretically fitting number of labels
        // dividing it further by 1.5 makes it looks less crammed.
        const newMax = Math.floor(numLabels / a.tickInterval / 1.5);
        if (isXAxis) {
          updates.xAxis = { scrollbar: { enabled: true }, max: newMax };
        } else if (isYAxis) {
          updates.yAxis = { scrollbar: { enabled: true }, max: newMax };
        }
      }
    });

    // Apply all updates at once to avoid multiple redraws / reflow issues
    if (Object.keys(updates.xAxis).length > 0 || Object.keys(updates.yAxis).length > 0) {
      chart.update(updates, true);
    }
  });
};

const Chart = memo(
  ({
    chart,
    dispatch,
    type,
    visualizationOptions, // these are visualization options directly attached to the visualization
    customVisualizationOptions, // these are user supplied visualization options (e.g. from the meta widget)
    modifiedVisualizations,
    chartColors,
    id,
    nonce,
    height,
    isCentered,
    isTableInWidget,
    isInAnswerMessage,
    answer,
    titleHeight,
    user
  }) => {
    const formattedChart = useMemo(() => chart.replace(/VEEZOO_REPLACEMENT_CHART_ID_PLACEHOLDER/g, id), [chart, id]);

    // get the relevant modifications for this chart
    const modifiedVisualizationsFiltered = useMemo(
      () =>
        modifiedVisualizations.filter(
          m => m.partialAnswerId === answer?.partialAnswerId && m.visualizationType === type
        ),
      [modifiedVisualizations, answer?.partialAnswerId, type]
    );

    const chartAsObject = useMemo(() => {
      if (type === chartTypes.TABLE_CHART) {
        return typeof chart !== 'object' ? JSON.parse(chart) : chart;
      }
      return chart;
    }, [chart, type]);

    const chartComponent = useMemo(() => {
      if (type === chartTypes.SIMPLE_NUMBER_CHART) {
        return <SimpleNumberChart isCentered={isCentered} chart={chart} user={user} />;
      }

      if (type === chartTypes.SIMPLE_VALUE_CHART) {
        return <SimpleValueChart isCentered={isCentered} chart={chart} user={user} />;
      }

      if (type === chartTypes.TWO_NUMBER_CHART) {
        return <TwoNumberChart isCentered={isCentered} chart={chart} user={user} />;
      }

      if (type === chartTypes.NUMBER_COMPARISON_CHART) {
        return <NumberComparisonChart isCentered={isCentered} chart={chart} user={user} />;
      }

      if (type === chartTypes.VEEZOO_MAP_CHART) {
        return <VeezooMapChart chart={chart} answer={answer} />;
      }

      if (type === chartTypes.TABLE_CHART) {
        if (isTableInWidget) {
          return (
            <TablePlotWidget
              answer={answer}
              isCentered={isCentered}
              height={height}
              isInAnswerMessage={isInAnswerMessage}
              json={chartAsObject}
              titleHeight={titleHeight}
            />
          );
        }

        return (
          <TablePlotFull
            answer={answer}
            isCentered={isCentered}
            isInAnswerMessage={isInAnswerMessage}
            json={chartAsObject}
          />
        );
      }

      return (
        <div
          className="sizeHTMLchart"
          id={'scriptContainer' + id}
          dangerouslySetInnerHTML={{
            __html: formattedChart
          }}
        />
      );
    }, [chart, type, isCentered, isTableInWidget, height, titleHeight, isInAnswerMessage, chartAsObject, id]);

    // apply the chart options to the highcharts object
    useEffect(() => {
      // Render the chart first from scratch and potentially update it with the new options
      const highChartsObject = renderHighChart(formattedChart, id, nonce);
      if (visualizationOptions || customVisualizationOptions || modifiedVisualizationsFiltered.length > 0) {
        try {
          // Merge all modifications into one object
          const highChartOptions = mergeChartOverrides(
            visualizationOptions,
            customVisualizationOptions,
            modifiedVisualizationsFiltered,
            highChartsObject?.getOptions() // we need to pass the base options in, so we can do a proper merge and don't leave it to highcharts to not fuck up
          );
          highChartsObject.update(highChartOptions, true, true, false);
        } catch (error) {
          console.error('Error parsing visualization options', error);
          // revert to original options if we run into troubles
          renderHighChart(formattedChart, id, nonce);

          // if we crashed also remove any potential new modifications from the state
          if (modifiedVisualizationsFiltered.length > 0) {
            dispatch(
              discardLastModifiedVisualization({ partialAnswerId: answer.partialAnswerId, visualizationType: type })
            );
          }
        }
      }
    }, [
      visualizationOptions,
      customVisualizationOptions,
      modifiedVisualizationsFiltered,
      formattedChart,
      id,
      nonce,
      type,
      chartColors
    ]);

    useEffect(() => {
      chartColors.map((color, idx) => (window.Highcharts.theme.colors[idx] = color));
    }, [chartColors]);

    return <Q2QStateContextProvider answerData={answer}>{chartComponent}</Q2QStateContextProvider>;
  }
);

const mapStateToProps = state => ({
  chartColors: state.theme.chartColors,
  modifiedVisualizations: state.modifiedVisualizations,
  user: state.user
});

const ChartWithError = props => (
  <ErrorBoundary>
    <AnswerContextMenu answerId={props.answer?.answerId} interpretationId={props.answer?.interpretationId} />
    <Chart {...props} />
  </ErrorBoundary>
);

export default connect(mapStateToProps)(ChartWithError);
