import { tss } from 'tss-react';

export const useStyles = tss.create({
  modificationParagraph: {
    marginTop: '10px'
  },

  widgetChanges: {
    listStyleType: 'none',
    marginTop: '6px',
    paddingLeft: '10px'
  },

  titleIcon: {
    width: '14px',
    height: '14px',
    marginRight: '6px',
    fill: 'var(--secondary-color)'
  },

  added: {
    color: 'var(--green)'
  },

  deleted: {
    color: 'var(--red)'
  },

  oldWidgetText: {
    color: 'var(--secondary-light-color)'
  }
});
