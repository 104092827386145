import classnames from 'classnames';
import clsx from 'clsx';
import styles from './number-chart.scss';

import TextFitter from './TextFitter/TextFitter';

import useMediaQuery from 'utils/mediaQueries';
import LiquidFormattedValue from './utils/LiquidFormattedValue';

const SimpleNumberChart = ({ isCentered, chart, user }) => {
  const isMobile = useMediaQuery();

  const chartData = JSON.parse(chart);

  const verticalCenterCorrectionClass = classnames({
    verticalCenterCorrection: isCentered
  });

  const descriptionClass = classnames(styles.descriptionSimpleNumber, {
    verticalCenterCorrection: isMobile
  });
  const row = {
    value: chartData.number
  };

  return (
    <div className={isCentered ? styles.centralize : ''}>
      <div className={clsx(styles.containerNotOverflowing, verticalCenterCorrectionClass)}>
        <TextFitter max={isMobile ? 34 : 48}>
          <LiquidFormattedValue row={row} user={user} htmlTemplate={chartData.htmlTemplate} />
        </TextFitter>
      </div>
      {chartData.description && (
        <div className={clsx(styles.containerNotOverflowing, descriptionClass)}>
          <TextFitter max={14}>{chartData.description}</TextFitter>
        </div>
      )}
    </div>
  );
};

export default SimpleNumberChart;
