import { tss } from 'tss-react';

const fontFamily = 'Lexend';
const primaryColor = 'var(--primary-color)';

export const textFieldSx = {
  '& .MuiOutlinedInput-input': {
    fontFamily,
    fontSize: '14px',
    padding: '8px 12px',
    color: 'inherit',
    lineHeight: '20px',
    height: '32px',
    boxSizing: 'border-box'
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'white'
  },
  '& .MuiOutlinedInput-notchedOutline': {
    top: 0,
    border: 'solid thin var(--light-gray)',
    borderRadius: '4px',
    '& > legend': {
      display: 'none'
    }
  },
  '& .MuiFormHelperText-root': {
    fontSize: '12px'
  },
  '& .MuiInputLabel-shrink': {
    transform: 'none !important',
    position: 'static'
  },
  '& .MuiOutlinedInput-root.Mui-disabled': {
    backgroundColor: 'transparent'
  }
};

export const getStaticDatePickerClasses = tss.create({
  // I'd rather write the classes themselves directly here, but "tss.create" requires a className,
  // at root level of the object, so I added a "default". In the component it will be
  // "staticDatePickerClasses.default" instead of only "staticDatePickerClasses" (as I'd find much cleaner).
  default: {
    '& .MuiDayPicker-weekContainer': {
      '& .dayContainer': {
        position: 'relative'
      },
      '& .MuiPickersDay-root': {
        borderRadius: 0,
        fontFamily,
        width: '40px',
        margin: 0,
        fontSize: '14px',
        fontWeight: 300,
        color: 'rgba(0, 0, 0, 0.54)',
        '&.MuiPickersDay-dayOutsideMonth': {
          color: '#dddddd'
        }
      },
      '& .dayContainer:first-of-type .MuiPickersDay-root': {
        borderTopLeftRadius: '4px',
        borderBottomLeftRadius: '4px'
      },
      '& .dayContainer:last-child .MuiPickersDay-root': {
        borderTopRightRadius: '4px',
        borderBottomRightRadius: '4px'
      },
      '& .selectedRow .MuiPickersDay-root': {
        backgroundColor: primaryColor,
        color: 'white',
        '&:focus': {
          backgroundColor: primaryColor
        }
      },
      '&:hover': {
        '& .dayContainer:not(.selectedRow) .MuiPickersDay-root': {
          backgroundColor: 'rgba(0, 0, 0, 0.04) !important'
        }
      }
    },

    '& .MuiDayPicker-weekDayLabel': {
      fontFamily,
      fontSize: '12px'
    },
    '& .MuiPickersArrowSwitcher-button': {
      padding: '3px',
      '& svg': {
        width: '25px',
        height: '25px',
        fill: primaryColor
      }
    },
    '& .MuiPickersCalendarHeader-labelContainer': {
      fontSize: '14px'
    },
    '& .MuiDialogActions-root': {
      display: 'none'
    }
  }
});

export const getDayWrapperStyles = tss.create({
  weekLabel: {
    top: '7px',
    left: '-11px',
    color: 'white',
    backgroundColor: 'var(--primary-color)',
    width: 19,
    height: 18,
    border: 'solid thin white',
    cursor: 'pointer',
    display: 'flex',
    position: 'absolute',
    fontSize: '11px',
    alignItems: 'center',
    borderRadius: '50%',
    justifyContent: 'center',
    zIndex: 1
  }
});
