import { cloneElement } from 'react';

import { useStyles } from './ToggleButtonList.styles';

const ToggleButtonList = ({ direction, justifyContent, items, selectedValue, setSelectedValue }) => {
  const { classes, cx } = useStyles({ direction, justifyContent });

  const handleClick = value => setSelectedValue(value);

  return (
    <div className={classes.container}>
      {items.map((item, index) => {
        const Icon = item?.icon;
        const hasIcon = Icon ? cloneElement(Icon, { className: classes.icon }) : null;

        const isSelected = selectedValue === item.value;
        return (
          <div
            key={`item_${index}`}
            className={cx(classes.buttonContainer, isSelected ? classes.buttonSelected : '')}
            onClick={() => handleClick(item.value)}
            data-test={item.dataTest}
          >
            {hasIcon}
            <span {...(item.dataTest ? { 'data-test': item.dataTest } : {})}>{item.label}</span>
          </div>
        );
      })}
    </div>
  );
};

export default ToggleButtonList;
