import answer from './answer';
import authentication from './authentication';
import billing from './billing';
import board from './board';
import boardWidgets from './board-widgets';
import chatMessages from './chat-messages';
import customerSelection from './customer-selection';
import customersOfTheDay from './customers-of-the-day';
import connector from './connector';
import database from './database';
import discovery from './discovery';
import environment from './environment';
import file from './file';
import invitation from './invitation';
import knowledgeGraph from './knowledgeGraph';
import synchronization from './synchronization';
import organization from './organization';
import question from './question';
import sharing from 'services/sharing';
import user from './user';
import role from './role';
import saml from './saml';
import editor from './editor';
import entity from './graph/entity';
import cls from './graph/class';
import resource from './graph/resource';
import literal from './graph/literal';
import measure from './graph/measure';
import relationship from './graph/relationship';
import integrations from 'services/integrations';
import localization from 'services/localization';
import settings from 'services/settings';
import tenant from './tenant';
import jwtAuthentication from './jwtAuthentication';
import embedding from './embedding';
import variable from './variable';
import tests from './tests';
import devKg from './devKg';
import tenantKg from './tenantKg';
import recipes from './recipes';
import visualization from './visualization';

export default {
  ...answer,
  ...authentication,
  ...billing,
  ...board,
  ...boardWidgets,
  ...chatMessages,
  ...customerSelection,
  ...customersOfTheDay,
  ...connector,
  ...database,
  ...discovery,
  ...environment,
  ...file,
  ...invitation,
  ...integrations,
  ...knowledgeGraph,
  ...devKg,
  ...tenantKg,
  ...organization,
  ...synchronization,
  ...question,
  ...sharing,
  ...user,
  ...tenant,
  ...entity,
  ...visualization,
  ...cls,
  ...literal,
  ...measure,
  ...resource,
  ...relationship,
  ...role,
  ...saml,
  ...editor,
  ...localization,
  ...settings,
  ...jwtAuthentication,
  ...embedding,
  ...variable,
  ...tests,
  ...recipes
};
