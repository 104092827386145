import React, { memo, useCallback, useState, useEffect, useRef } from 'react';

import { sx, useStyles } from './styles';
import UISelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import MonacoEditor from 'react-monaco-editor';

import services from 'services';
import Title from 'components/v2/Typography/Title';
import { withSnackbar } from 'notistack';
import { trackEvent } from 'utils/eventTracking';

import { Button, TextField } from 'components/v3';

import { layouts, languagesIso } from 'config/constants';
import { vqlMonarchLanguageDefinition, vqlLanguageId, vqlTheme } from './VqlSyntaxHighlighter';

let editor = null;

const FILTER_DEFINITION_TYPE = 'FILTER';

/**
 * Form for saving new definitions.
 *
 * The possibleDefinitions property is a list of objects with the following fields:
 *   - definitionType: type of the new definition, e.g. measure or filter
 *   - language: the language currently used by the user
 *   - connectTo, returnResource: additional information needed for the backend
 *   - connectToName, returnName: names of what the definition will connect to and what the return of the definition is named in the answer
 *   - vql: generated VQL for the definition that the user can still adapt
 */
const SaveDefinitionForm = ({ possibleDefinitions, language, onClose, t, enqueueSnackbar }) => {
  const { classes: styles } = useStyles();

  const inputRef = useRef(null);

  const [fields, setFields] = useState({
    // Index into possibleDefinitions indicating which one is currently selected
    definitionIdx: 0,
    // Name that the user has written, undefined if no input so far
    name: undefined,
    // Identifier of the definition once successfully created
    createdDefinitionIdentifier: undefined
  });

  const [disabled, setDisabled] = useState(false);

  // Name adapts to the selected definition if the user hasn't given any input yet
  const chosenPossibleDefinition = possibleDefinitions[fields.definitionIdx];
  const nameWithDefault = fields.name === undefined ? chosenPossibleDefinition.returnName || '' : fields.name;

  // the VQL being edited in the editor
  const [vqlToEdit, setVqlToEdit] = useState(null);

  // sets the VQL to be edited
  useEffect(() => {
    const vqlContent = chosenPossibleDefinition.vql;
    const numberOfLines = vqlContent.split('\n').length;
    const minimumNumberOfLines = 5;
    const linesToAdd = Math.max(1, minimumNumberOfLines - numberOfLines);
    const filledContent = vqlContent + '\n'.repeat(linesToAdd);
    setVqlToEdit(filledContent);
  }, []);

  const saveDefinition = useCallback(async () => {
    if (!nameWithDefault) {
      return;
    }
    setDisabled(true);

    const vql = editor
      .getModel()
      .getValue()
      .trim();
    const { definitionType, connectTo, returnResource, dateFormat, connectToVklName } = chosenPossibleDefinition;

    await services
      .saveDefinition(nameWithDefault, definitionType, connectTo, returnResource, dateFormat, vql)
      .then(response => {
        setFields(prev => ({ ...prev, createdDefinitionIdentifier: response.data }));
        enqueueSnackbar(`Saved new definition for ${fields.name}`, {
          variant: 'info',
          autoHideDuration: 3000
        });
        trackEvent('New Definition Created', {
          name: nameWithDefault,
          definitionType,
          connectTo: connectToVklName,
          vql
        });
      })
      .then(_ => onClose());
    setDisabled(false);
  }, [fields]);

  const handleInputChange = event => {
    const value = event.target.value;
    setFields(prev => ({ ...prev, name: value }));
  };

  const handleDefinitionChange = definitionIdx => {
    if (definitionIdx === null) return;

    // Reset VQL to the one given by the definition when changing the selection
    if (editor) {
      editor.getModel().setValue(possibleDefinitions[definitionIdx].vql);
    }

    setFields(prev => ({ ...prev, definitionIdx }));
  };

  const onKeyDown = useCallback(
    event => {
      if (event.key === 'Enter') {
        // preventDefault necessary, otherwise the <button /> "+" icon that opens the modal would
        // re-trigger by default, causing the modal to re-open endlessly.
        event.preventDefault();
        saveDefinition();
      }
    },
    [saveDefinition]
  );

  const handleFocus = event => event.target.select();

  // Focus name field on opening modal or changing selected definition
  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.focus();
    }
  }, [fields]);

  // After the editor mounted we set its height according to the content.
  // This ensures that the displayed query is always shown vertically in full.
  // We arbitrarily set the min height to 100px.
  const editorDidMount = newEditor => {
    editor = newEditor;
    const contentHeight = Math.max(100, editor.getContentHeight());
    editor.layout({ height: contentHeight });
  };

  const isFilter = chosenPossibleDefinition.definitionType === FILTER_DEFINITION_TYPE;

  const nameInput = (
    <TextField
      layout={layouts.veezoo}
      value={nameWithDefault}
      ref={inputRef}
      onFocus={handleFocus}
      disabled={disabled}
      onChange={handleInputChange}
      onKeyDown={onKeyDown}
      error={!nameWithDefault}
      InputProps={{ 'data-test': 'saveDefinitionFormName' }}
    />
  );

  const optionalConnectName = isFilter && (
    <span className={styles.parentClassNameContainer}>{chosenPossibleDefinition.connectToName}</span>
  );

  // For these languages, we will display the form as: Name [   ]
  // e.g. [ Deleted ] User =>  Usuário [ Excluído ]
  const languagesWithInvertedForm = [languagesIso.portuguese, languagesIso.italian, languagesIso.french];
  const completeNameField = languagesWithInvertedForm.includes(language) ? (
    <>
      {optionalConnectName}
      <div className={styles.rightElement}>{nameInput}</div>
    </>
  ) : (
    <>
      {nameInput}
      <div className={styles.rightElement}>{optionalConnectName}</div>
    </>
  );

  const editorWillMount = useCallback(monaco => {
    monaco.languages.register({ id: vqlLanguageId });
    monaco.languages.setMonarchTokensProvider(vqlLanguageId, vqlMonarchLanguageDefinition);
    monaco.editor.defineTheme('vqlTheme', vqlTheme);
  }, []);

  return (
    <div className={styles.form}>
      <div>
        <Title size="small" className={styles.title}>
          {t('save-new-definition.when-i-say')}
        </Title>
        <div className={isFilter && styles.nameWithParentClassNameContainer}>{completeNameField}</div>
      </div>
      <div>
        <Title size="small" className={styles.title}>
          {t('save-new-definition.i-want-to')}
        </Title>
        <UISelect
          value={fields.definitionIdx}
          variant="standard"
          disabled={disabled}
          sx={sx.select}
          onChange={(e, _) => handleDefinitionChange(e?.target?.value)}
        >
          {possibleDefinitions.map(({ definitionType, returnName, connectToName }, idx) => (
            <MenuItem sx={sx.menuItem} key={idx} value={idx}>
              {` ${t('save-new-definition.types.' + definitionType.toLowerCase())} ${t(
                'save-new-definition.x-on-y'
              )} ${connectToName} `}
            </MenuItem>
          ))}
        </UISelect>
      </div>
      <div>
        <Title size="small" className={styles.title}>
          {t('save-new-definition.defined-by')}
        </Title>
        {!!vqlToEdit && (
          <MonacoEditor
            theme="vqlTheme"
            language={vqlLanguageId}
            value={vqlToEdit}
            disabled={disabled}
            options={{
              quickSuggestions: false,
              selectOnLineNumbers: true,
              scrollBeyondLastLine: false,
              minimap: {
                enabled: false
              },
              automaticLayout: true
            }}
            // We set this callback for resizing the editor to fit the content
            // See: https://github.com/microsoft/monaco-editor/issues/794
            editorDidMount={editorDidMount}
            editorWillMount={editorWillMount}
          />
        )}
      </div>

      <div className={styles.buttonContainer}>
        <Button
          layout={layouts.veezoo}
          mode="dark"
          fullWidth
          onClick={saveDefinition}
          disabled={disabled}
          data-test="saveDefinitionFormConfirm"
        >
          {t('create')}
        </Button>
      </div>
    </div>
  );
};

export default withSnackbar(memo(SaveDefinitionForm));
