import React, { memo } from 'react';
import { PropTypes } from 'prop-types';

import UIPopover from '@mui/material/Popover';
import Backdrop from '@mui/material/Backdrop';
import Paper from '@mui/material/Paper';

import { getSx, backdropSx } from './styles';

const PopoverPosition = ({ children, updatePositionRef, isEditing = false, x, y, open = false, ...props }) => {
  const sx = getSx({ allowBackgroundInteraction: true });

  return (
    <>
      <UIPopover
        action={updatePositionRef}
        open={open}
        anchorReference="anchorPosition"
        anchorPosition={{ top: y, left: x }}
        sx={sx}
        transitionDuration={0}
        {...props}
      >
        {/* MUI's Popover has its own Paper, but we don't use it here because we need external padding, and margin wouldn't fill our need */}
        <Paper>{children}</Paper>
      </UIPopover>
      <Backdrop sx={backdropSx} open={open && isEditing} />
    </>
  );
};

export default memo(PopoverPosition);

PopoverPosition.propTypes = {
  children: PropTypes.any,
  updatePositionRef: PropTypes.any,
  isEditing: PropTypes.bool,
  x: PropTypes.number,
  y: PropTypes.number,
  open: PropTypes.bool
};
