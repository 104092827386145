export default function nameSplit(name = '') {
  if (name === undefined || name === null) {
    return name;
  }

  if (name) {
    let initials = String(name).trim();
    const nameSplit = initials.toUpperCase().split(' ');

    if (nameSplit.length === 1) {
      initials = nameSplit[0] ? nameSplit[0].charAt(0) + nameSplit[0].charAt(1) : '?';
    } else {
      initials = nameSplit[0].charAt(0) + nameSplit[1].charAt(0);
    }

    name = initials;
  } else {
    name = '??';
  }

  return name;
}
