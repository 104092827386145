export const vklLanguageId = 'vkl';
export const vklLanguageConfiguration = {
  // Enable matching braces
  surroundingPairs: [{ open: '{', close: '}' }, { open: '[', close: ']' }],
  autoClosingPairs: [{ open: '{', close: '}' }, { open: '[', close: ']' }],
  brackets: [['{', '}'], ['[', ']']]
};

export const vklTypes = {
  class: 'class',
  object: 'object'
  // add more types here as needed
};

// the token types
// (using the '.js' suffix to inherit JavaScript coloring (?))
export const vklTokenTypes = {
  annotation: 'annotation.js',
  colon: 'colon.js',
  comment: 'comment.js',
  curlyBracket: 'delimiter.bracket',
  delimiter: 'delimiter.js',
  dot: 'dot.js',
  identifier: 'identifier.js',
  keyword: 'keyword.js',
  number: 'number.js',
  squareBracket: 'delimiter.square',
  string: 'string.js',
  stringEscape: 'string.escape.js',
  stringEscapeInvalid: 'string.escape.invalid.js',
  stringInvalid: 'string.invalid.js',
  type: 'type.js',
  whitespace: ''
};

const types = vklTokenTypes;

// Based on, but heavily modified from, the default Javascript highlighting
// Found at https://microsoft.github.io/monaco-editor/monarch.html (version as of 2020/10/23)
export const vklMonarchLanguageDefinition = {
  // Set defaultToken to invalid to see what you do not tokenize yet
  defaultToken: 'invalid',
  // leave empty to avoid default '.vkl' postfix
  tokenPostfix: '',

  keywords: ['import', 'true', 'false'],

  // we include these common regular expressions
  symbols: /[=><!~?:&|+\-*/^%]+/,
  escapes: /\\(?:[abfnrtv\\"']|x[0-9A-Fa-f]{1,4}|u[0-9A-Fa-f]{4}|U[0-9A-Fa-f]{8})/,
  digits: /\d+(_+\d+)*/,

  // The main tokenizer for our languages
  tokenizer: {
    root: [[/[{}]/, types.curlyBracket], { include: 'common' }],

    common: [
      // special case for import keyword
      [/(import)(\s*)(:)/, [types.keyword, types.whitespace, types.colon]],
      // show properties nicely
      [/([A-Za-z][\w$]*?)(\s*)(:)/, [types.type, types.whitespace, types.colon]],
      // property with language tag
      [
        /([A-Za-z][\w$]*)(\.)(\w\w|all)(\s*)(:)/,
        [types.type, types.dot, types.annotation, types.whitespace, types.colon]
      ],
      // show types nicely, same as
      [/([A-Za-z][\w$]*)(\s+)(?=\w)/, [types.keyword, types.whitespace]],

      // identifiers and keywords
      [
        /[A-Za-z_][\w]*/,
        {
          cases: {
            '@keywords': types.keyword,
            '@default': types.identifier
          }
        }
      ],

      // whitespace
      { include: '@whitespace' },

      // delimiters
      [/[()[\]]/, '@brackets'],
      [/[<>](?!@symbols)/, '@brackets'],
      [
        /@symbols/,
        {
          cases: {
            '@default': types.whitespace
          }
        }
      ],

      // numbers
      [/(@digits)/, types.number],

      // delimiter: after number because of .\d floats
      [/[;,.]/, types.delimiter],

      // strings
      [/('''.*''')|(""".*""")/, types.string], // single line triple quoted string
      [/""".*$/, types.string, '@endTripleQuotedString'], // start multi-line triple quoted string
      [/"([^"\\]|\\.)*$/, types.stringInvalid], // non-terminated string
      [/'([^'\\]|\\.)*$/, types.stringInvalid], // non-terminated string
      [/"/, types.string, '@string_double']
    ],

    whitespace: [
      [/[ \t\r\n]+/, types.whitespace],
      [/\/\*/, types.comment, '@comment'],
      [/\/\/.*$/, types.comment],
      [/#.*$/, types.comment]
    ],

    comment: [[/[^/*]+/, types.comment], [/\*\//, types.comment, '@pop'], [/[/*]/, types.comment]],

    string_double: [
      [/[^\\"]+/, types.string],
      [/@escapes/, types.stringEscape],
      [/\\./, types.stringEscapeInvalid],
      [/"/, types.string, '@pop']
    ],

    endTripleQuotedString: [[/\\"/, types.string], [/.*"""/, types.string, '@popall'], [/.*$/, types.string]],

    bracketCounting: [
      [/{/, types.curlyBracket, '@bracketCounting'],
      [/}/, types.curlyBracket, '@pop'],
      { include: 'common' }
    ]
  }
};

/**
 * Logs the specified messages to the console depending on the specified 'debug' flag.
 */
const log = (debug, ...messages) => debug && console.log(...messages);

/**
 * Measures the specified operation and logs the execution time depending on the specified 'debug' flag.
 */
export const measure = (debug, op) => {
  const startTime = performance.now();
  const result = op();
  log(debug, `Running time: ${performance.now() - startTime} ms)`);
  return result;
};

/**
 * Tokenizes the specified range from the specified model, returning a two-dimensional array containing the tokens
 * for each line contained in the range.
 */
const tokenize = (monaco, model, range) => {
  // get the value from the range
  const value = model.getValueInRange(range);
  return (
    monaco.editor
      // tokenize the value
      .tokenize(value, vklLanguageId) // map over the tokens line per line
      ?.map((lineTokens, lineIndex) => {
        // returns the offset of the token, adjusted for tokens on the first line to account for the range's start column
        const getOffset = token => token && (lineIndex === 0 ? range.startColumn - 1 + token.offset : token.offset);
        // map over the tokens of the current line
        return lineTokens.map((token, tokenIndex) => {
          // the token's line number
          const lineNumber = range.startLineNumber + lineIndex;
          // the token's offset into the line
          const offset = getOffset(token);
          // the token's length, calculated as the distance to the start of the next token or to the end of the line
          const length = (getOffset(lineTokens[tokenIndex + 1]) || model.getLineLength(lineNumber)) - offset;
          // the token's position (of its first character)
          const position = new monaco.Position(lineNumber, offset + 1);
          // the token's range
          const tokenRange = new monaco.Range(lineNumber, position.column, lineNumber, position.column + length);
          // the token's value
          const value = model.getValueInRange(tokenRange);
          return {
            ...token,
            offset,
            length,
            position,
            range: tokenRange,
            value
          };
        });
      })
  );
};

/**
 * Tokenizes the specified line from the specified model.
 */
const tokenizeLine = (monaco, model, lineNumber) => {
  const range = new monaco.Range(lineNumber, 1, lineNumber, model.getLineLength(lineNumber) + 1);
  return tokenize(monaco, model, range)[0];
};

/**
 * Finds tokens matching the specified type chain starting at the specified position and working either backwards or
 * forwards.
 *
 * Tokens must match the types in the type chain in the specified order. If all types of the chain match, then an
 * array containing the matching tokens are returned. Note that only non-whitespace tokens are considered, all
 * whitespace tokens are discarded.
 *
 * Illustrative example:
 *   - content: 'class Order |{| name: "Order" }'
 *     the start position is at the start of the |{| token as depicted
 *   - tokens: |class|, | |, |Order|, | |, |{|, | |, |name|, |:|, | |, |"Order"|, | |, |}|
 *   - // backward-match tokens [Order], [class]
 *     findToken(..., [identifier, keyword])          returns [|Order|, |class|]
 *   - // search fails
 *     findToken(..., [keyword])                      returns null
 *   - // forward-match tokens [{], [name]
 *     findToken(..., [curlyBracket, type], [], true) returns [|{|, |name|]
 *
 * @param startPosition the position to start the search
 * @param typeChain the type(s) to find in this order
 * @param ignoreLeadingTypes types for leading tokens to ignore before matching tokens to the type chain
 * @param isForwards true to search forwards, false to search backwards (default: false)
 * @param debug specifies whether to print debug information to the console (default: false)
 * @returns the tokens matching the types in the type chain or null if the search fails
 */
export const findTokens = (
  monaco,
  model,
  startPosition,
  typeChain,
  ignoreLeadingTypes = [],
  isForwards = false,
  debug = false
) => {
  log(debug, 'findToken', startPosition, isForwards, typeChain.slice(), ignoreLeadingTypes);
  // contains the tokens matching the type chain
  const tokens = [];
  // specifies whether we are done ignoring leading tokens with the specified types
  let doneIgnoringLeadingTypes = ignoreLeadingTypes.length === 0;
  // the line number to start the search
  let lineNumber = startPosition.lineNumber;
  // given the direction, decrement or increment by 1 line
  const increment = isForwards ? 1 : -1;
  // the line number to end the search
  const endLineNumber = isForwards ? model.getLineCount() : 1;
  // sanity check
  if ((isForwards && lineNumber > endLineNumber) || (!isForwards && lineNumber < endLineNumber)) {
    return null;
  }
  // loop over all lines between and including the start and end lines
  while (lineNumber !== endLineNumber + increment) {
    // get the tokens of the line
    const lineTokens = tokenizeLine(monaco, model, lineNumber);
    log(debug, lineNumber, 'lineTokens', lineTokens);
    const filteredLineTokens = lineTokens
      ?.filter(
        // on the start line, only keep tokens whose ranges match given the start position and direction
        token =>
          lineNumber !== startPosition.lineNumber ||
          (isForwards && token.range.endColumn > startPosition.column) ||
          (!isForwards && token.range.startColumn < startPosition.column)
      ) // filter whitespace tokens
      ?.filter(token => token.type !== '');
    log(debug, lineNumber, 'filteredLineTokens', filteredLineTokens.slice());

    let token = null;
    // ignore leading tokens as long as they match any of the specified types to ignore
    while (filteredLineTokens.length > 0 && !doneIgnoringLeadingTypes) {
      // remove the next token to check
      token = isForwards ? filteredLineTokens.shift() : filteredLineTokens.pop();
      // check if it should be ignored
      if (!ignoreLeadingTypes.includes(token.type)) {
        // add it back to the tokens
        isForwards ? filteredLineTokens.unshift(token) : filteredLineTokens.push(token);
        // we are done ignoring leading tokens
        doneIgnoringLeadingTypes = true;
      }
    }

    // match tokens from the type chain in order
    while (doneIgnoringLeadingTypes && filteredLineTokens.length > 0 && typeChain.length > 0) {
      // remove the next token to check
      token = isForwards ? filteredLineTokens.shift() : filteredLineTokens.pop();
      // remove the type to check against
      const type = typeChain.shift();
      // return if the token type doesn't match
      if (token.type !== type) {
        return null;
      }
      tokens.push(token);
    }
    // if we have matched all types from the type chain...
    if (typeChain.length === 0) {
      // ...return the last matched token
      return tokens;
    } else {
      // ...else move to the previous/next line
      lineNumber += increment;
    }
  }
};

/**
 * Return the token that matched the last type (if any) in the specified type chain.
 *
 * @see findTokens
 */
export const findToken = (
  monaco,
  model,
  startPosition,
  typeChain,
  ignoreLeadingTypes = [],
  isForwards = false,
  debug = false
) => findTokens(monaco, model, startPosition, typeChain, ignoreLeadingTypes, isForwards, debug)?.pop();

/**
 * Returns the active VKL keyword of the specified model at the specified cursor position.
 *
 * The active VKL keyword is the keyword that corresponds to the VKL definition currently in focus given the cursor
 * position. For example, given the cursor '|':
 *   - 'class Order { | }'                    ->    'class'
 *   - 'class Order { name: "Or|der" }'       ->    'name'
 *   - 'class Order { number Amount { | }'    ->    'number'
 *
 * The active keyword is determined based on tokenization and the resulting token types. The returned keyword may not
 * actually be a valid VKL keyword (e.g. if you were to spell 'class' as 'classs', the latter would be returned).
 *
 * Note regarding the implementation:
 * ----------------------------------
 *
 * This method determines tokens by tokenizing individual lines and full code blocks. Tokenizing individual lines can
 * lead to wrong tokenization, e.g. inside multi-line strings, and tokenizing full blocks might take long. Since
 * Monaco / VS Code anyway needs to keep track of tokens, it would be more precise and faster if we could access
 * these instead. The tokens are available via the model's 'getLineTokens' method and in internal objects, but I wasn't
 * able to get the tokens in a human-readable and useful format including the token types from the VKL language
 * definition.
 *
 * An alternative possible solution would be to keep track of tokens on our side in an array and to make use of the
 * model's 'onDidChangeTokens' event ('IModelTokensChangedEvent') to listen to tokenization changes and update our
 * tokens on the affected lines accordingly.
 *
 * Some references / related code:
 * - https://github.com/microsoft/vscode/blob/main/src/vs/editor/common/tokens/lineTokens.ts
 * - https://github.com/microsoft/vscode/blob/main/src/vs/editor/standalone/browser/inspectTokens/inspectTokens.ts
 * - https://github.com/microsoft/vscode/blob/main/src/vs/editor/standalone/browser/colorizer.ts
 */
export const getActiveVklKeyword = (monaco, model, cursorPosition, debug = false) =>
  measure(debug, () => {
    log(debug, 'getActiveKeyword', cursorPosition);
    // the line number and column to get the active keyword from
    let { lineNumber, column } = cursorPosition;
    // the character at the cursor position
    const cursorCharacter = model.getValueInRange(new monaco.Range(lineNumber, column, lineNumber, column + 1));
    // the character to the left of the cursor position
    const previousCharacter = model.getValueInRange(new monaco.Range(lineNumber, column - 1, lineNumber, column));
    // move the position to the left if we are to the right of a closing bracket and not to the left of a closing bracket
    // (this is to match the keyword to the way how Monaco highlights enclosing brackets)
    if (
      (previousCharacter === ']' || previousCharacter === '}') &&
      cursorCharacter !== ']' &&
      cursorCharacter !== '}'
    ) {
      column--;
    }
    const adaptedPosition = new monaco.Position(lineNumber, column);
    log(debug, 'cursor', cursorPosition, cursorCharacter, previousCharacter, adaptedPosition);
    // the token of the active keyword
    let keywordToken = null;
    // the brackets of the enclosing block
    const blockBrackets = model.bracketPairs.findEnclosingBrackets(adaptedPosition);
    // case: we're inside a parenthesized block
    if (blockBrackets) {
      const [openingBlockBracketRange, closingBlockBracketRange] = blockBrackets;
      log(debug, 'blockBrackets', openingBlockBracketRange, closingBlockBracketRange);
      // the start position of the opening block bracket
      const openingBlockBracketStartPosition = new monaco.Position(
        openingBlockBracketRange.startLineNumber,
        openingBlockBracketRange.startColumn
      );
      // the keyword of the block
      const blockKeywordToken =
        // backward-match tokens |Order|, |class| in example 'class Order |{|'
        findToken(monaco, model, openingBlockBracketStartPosition, [types.identifier, types.keyword]) ||
        // backward-match tokens |:|, |synonym| in example 'synonym: |[|'
        findToken(monaco, model, openingBlockBracketStartPosition, [types.colon, types.type]) ||
        // backward-match tokens |:|, |en|, |.|, |synonym| in example 'synonym.en: |[|'
        findToken(monaco, model, openingBlockBracketStartPosition, [
          types.colon,
          types.annotation,
          types.dot,
          types.type
        ]) ||
        // backward-match tokens |:|, |import| in 'import: |[|'
        findToken(monaco, model, openingBlockBracketStartPosition, [types.colon, types.keyword]);
      log(debug, 'blockKeywordToken', blockKeywordToken);
      // the character of the opening block bracket
      const openingBlockBracket = model.getValueInRange(openingBlockBracketRange);
      // case: we're inside a square bracket block, i.e. an array or the import block
      if (openingBlockBracket === '[') {
        // return the block keyword
        keywordToken = blockKeywordToken;
      }
      // case: we're inside a block without a keyword, i.e. (probably) the top-level enclosing block
      else if (!blockKeywordToken) {
        const cursorPosition = new monaco.Position(lineNumber, column);
        // find the keyword of a nested block on the cursor's line
        const nestedBlockKeywordToken =
          // forward-match token |class| in example '|class Order {'
          findToken(monaco, model, cursorPosition, [types.keyword], [], true) ||
          // backward-match token |class| in example 'class |Order {'
          findToken(monaco, model, cursorPosition, [types.keyword]) ||
          // backward-match tokens |Order|, |class| in example 'class Order |{'
          findToken(monaco, model, cursorPosition, [types.identifier, types.keyword]);
        // only use it if it is on the same line as the cursor
        if (nestedBlockKeywordToken?.position.lineNumber === lineNumber) {
          keywordToken = nestedBlockKeywordToken;
        }
      }
      // case: we're inside a keyword block, e.g. a class or number block
      else {
        // The approach now is to get the token at the cursor position and then to find a keyword token in that token's
        // vicinity in an approriate way given the token's type. We tokenize the full enclosing block in order to get as
        // precise a tokenization as possible. To avoid potential performance issues, we only tokenize blocks up to a
        // certain size. If a block is too large, we take a shortcut and only tokenize the cursor line itself. This is
        // imprecise and may lead to a wrong tokenization / token type.

        // the tokens on the line of the cursor
        let cursorLineTokens;
        // the range of the block
        const blockRange = new monaco.Range(
          openingBlockBracketRange.startLineNumber,
          openingBlockBracketRange.startColumn,
          closingBlockBracketRange.endLineNumber,
          closingBlockBracketRange.endColumn
        );
        // the number of lines of the block
        const blockLineCount = blockRange.endLineNumber - blockRange.startLineNumber + 1;
        // only tokenize full blocks up to a certain size
        if (blockLineCount <= 1000) {
          log(debug, 'tokenize full enclosing block');
          // the tokens of the block
          const blockTokens = tokenize(monaco, model, blockRange);
          // get the tokens on the cursor's line (offset into the block)
          cursorLineTokens = blockTokens[lineNumber - openingBlockBracketRange.startLineNumber];
        } else {
          log(debug, 'tokenize only cursor line');
          // tokenize only the cursor's line (this is imprecise and may lead to a wrong tokenization)
          cursorLineTokens = tokenizeLine(monaco, model, lineNumber);
        }
        log(debug, 'cursorLineTokens', cursorLineTokens);
        // the token to search the active keyword from
        let token;
        // the token at the position of the cursor
        token =
          cursorLineTokens.find(token => token.range.endColumn > column) ||
          // if cursor is at last position
          cursorLineTokens[cursorLineTokens.length - 1];
        log(debug, 'cursorToken', token);
        // if the cursor token is whitespace...
        if (token?.type === '') {
          const tokenIndex = cursorLineTokens.indexOf(token);
          // ...get the first non-whitespace token to its left, if any
          token = cursorLineTokens
            .slice(0, tokenIndex)
            .reverse()
            .find(token => token.type !== types.whitespace);
          log(debug, 'leftToken', token);
          if (!token) {
            // ...else get the first non-whitespace token to its right
            token = cursorLineTokens.slice(tokenIndex + 1).find(token => token.type !== '');
            log(debug, 'rightToken', token);
          }
        }
        log(debug, 'token', token);

        // case: there is no token, i.e. we're on an empty line, or the token is a comment
        if (!token || token.type === types.comment) {
          // use the block keyword
          keywordToken = blockKeywordToken;
        }
        // case: the token is an opening or closing bracket
        else if (token.type === types.curlyBracket || token.type === types.squareBracket) {
          keywordToken =
            // opening bracket: backward-match tokens |Amount|, |number| in example 'number Amount |{|'
            findToken(monaco, model, token.position, [types.identifier, types.keyword]) ||
            // closing bracket: fall back to the block keyword
            blockKeywordToken;
        }
        // case: token is a keyword
        else if (token.type === types.keyword || token.type === types.type) {
          // use the token itself
          keywordToken = token;
        }
        // case: token is a dot
        else if (token.type === types.dot) {
          // backward-match token |description| in example 'description|.|'
          keywordToken = findToken(monaco, model, token.position, [types.type]);
        }
        // case: token is an annotation
        else if (token.type === types.annotation) {
          // backward-match tokens |.|, |description| in example 'description.|en|'
          keywordToken = findToken(monaco, model, token.position, [types.dot, types.type]);
        }
        // case: token is a colon
        else if (token.type === types.colon) {
          keywordToken =
            // backward-match tokens |description| in example 'description|:|'
            findToken(monaco, model, token.position, [types.type]) ||
            // backward-match tokens |en|, |.|, |description| in example 'description.en|:|'
            findToken(monaco, model, token.position, [types.annotation, types.dot, types.type]);
        } else {
          // case: token is an identifier
          if (token.type === types.identifier) {
            // backward-match e.g. tokens |number| in example 'number |Amount|'
            keywordToken = findToken(monaco, model, token.position, [types.keyword]);
          }
          // case: token is a property value (e.g. identifier, string, number, etc.)
          if (!keywordToken) {
            // we ignore any of the following leading types before the colon because:
            //   - for identifiers, there maybe be multiple identifier tokens separated by delimiters (e.g. 'kb.Order')
            //   - for multi-line strings, there are multiple string tokens
            //   - for numbers, there may be a delimiter and a second number token (e.g. '1.5')
            const ignoreLeadingTypes = [
              types.identifier,
              types.delimiter,
              types.string,
              types.stringInvalid,
              types.stringEscape,
              types.stringEscapeInvalid,
              types.number
            ];
            keywordToken =
              // backward-match tokens |:|, |description| in example 'description: |"abc"|'
              findToken(monaco, model, token.position, [types.colon, types.type], ignoreLeadingTypes) ||
              // backward-match tokens |:|, |en|, |.|, |description| in example 'description.en: |"abc"|'
              findToken(
                monaco,
                model,
                token.position,
                [types.colon, types.annotation, types.dot, types.type],
                ignoreLeadingTypes
              );
          }
        }
      }
    }
    // case: we're outside any parenthesized block
    else {
      keywordToken =
        // forward-match token |import| in '|import'
        findToken(monaco, model, cursorPosition, [types.keyword], [], true) ||
        // backward-match token |import| in 'import|:|'
        findToken(monaco, model, cursorPosition, [types.keyword]) ||
        // backward-match tokens |:|, |import| in 'import: |[|'
        findToken(monaco, model, cursorPosition, [types.colon, types.keyword]);
    }
    log(debug, 'keywordToken', keywordToken);
    const keyword = keywordToken?.value;
    log(debug, 'keyword', keyword);
    return keyword;
  });
