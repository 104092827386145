import makeStyles from '@mui/styles/makeStyles';

export default makeStyles({
  title: {
    fontWeight: '400',
    fontSize: 16,
    lineHeight: '16px'
  },
  innerTitle: {
    color: 'var(--primary-color)'
  },
  paper: { borderRadius: 4 },
  container: {
    padding: '10px 0'
  },
  section: {
    padding: '4px 16px'
  },
  addFilter: {
    padding: '4px 16px',
    textAlign: 'right'
  },
  flexContainer: {
    display: 'flex',
    '& > div': {
      marginLeft: 5,
      marginRight: 5,
      '&:first-child': {
        marginLeft: 0
      },
      '&:last-child': {
        marginRight: 0
      }
    }
  },
  verticalPadding8: {
    padding: '8px 0'
  },
  flexGrow1: {
    flexGrow: 1
  },
  sidedInputFields: {
    width: '100%',
    maxWidth: '48%'
  },
  width200: {
    width: 200,
    maxWidth: 200
  },
  width400: {
    width: 400,
    maxWidth: 400
  },
  ml10: {
    marginLeft: 10
  },
  mb5: {
    marginBottom: 5
  },
  error: {
    color: '#f44336'
  },
  hr: {
    borderTop: 'solid thin var(--light-gray)',
    borderLeft: 'solid 0px transparent',
    borderRight: 'solid 0px transparent',
    borderBottom: 'solid 0px transparent'
  },
  closeIcon: {
    width: 10,
    height: 10,
    marginLeft: 5,
    cursor: 'pointer',
    '& path': { fill: 'var(--primary-color)' },
    '&:hover': { '& path': { fill: '#f44336' } }
  },
  timeIcon: {
    width: 13,
    height: 13,
    marginRight: 4,
    marginLeft: 5,
    cursor: 'pointer',
    '& path': { fill: 'var(--primary-color)' }
  },
  timeComponentContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  addTimeButton: {
    fontSize: 14,
    display: 'inline-flex',
    alignItems: 'center',
    marginTop: 12,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.7,
      '& $timeIcon path': {
        opacity: 0.7
      }
    }
  },
  removeTime: {
    border: 'solid 1px rgba(0, 0, 0, 0.7)',
    width: 10,
    height: 10,
    minWidth: 10,
    minHeight: 10,
    marginLeft: 5,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '&:hover': { border: 'solid thin #f44336' },
    '& $timeCloseIcon path': {
      fill: '#f44336'
    }
  },
  timeCloseIcon: {
    width: 6,
    height: 6,
    '& path': { fill: 'var(--primary-color)' },
    '&:hover': { '& path': { fill: '#f44336' } }
  },
  datepickerContainer: {
    maxHeight: 265,
    overflowY: 'auto'
  },
  capitalized: {
    textTransform: 'capitalize'
  }
});
